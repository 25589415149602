/* eslint-disable import/no-anonymous-default-export */
// eslint-disable-next-line import/no-anonymous-default-export
import GoogleLogin from 'react-google-login';
import { gapi } from 'gapi-script';
// import FacebookLogin from 'react-facebook-login';
// import FacebookIcon1 from '../../assets/icons/facebookicon1.svg';
import React, {useState, useEffect, useRef } from 'react';
import { FormControl, OutlinedInput, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Box, InputLabel, FormHelperText } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import ForgetPassword from '../../components/modal/forgetPassword'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ReactFlagsSelect from "react-flags-select";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { Email } from '@mui/icons-material';
import isEmail from 'validator/lib/isEmail';
import validator from 'validator'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {register} from '../../redux/actions/auth.action'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';

import Loading from '../../components/loading/Loading';

import API from '../../redux/API'
import './style.css'
import { toast } from 'react-toast';
const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

export default () => {
    const {currentUser} = useSelector(state => state.auth);


    const style= {
        title1: "font-[Poppins] text-[60px] text-[#17686E] font-[700] leading-[90px]",
        title2: "font-[Poppins] text-[60px] text-[#17686E] font-normal leading-[90px]",
        subtitle: "font-[Poppins] text-[32px] text-[#313131] font-normal leading-[148%] mb-[65px]",
        googleTxt: "font-[Poppins] text-[14px] text-[#313131] font-normal leading-[148%]",
        facebookTxt: "font-[Poppins] text-[14px] text-[#FFFFFF] font-normal leading-[148%]",
        ouTxt: "ml-[5px] mr-[5px] font-[Poppins] text-[14px] text-[#7E7E7E] font-normal leading-[148%]",
        googleBtn: "mb-[15px] !rounded-[20px] !border-solid !border-[#C6C6C6] !border-[1px] !bg-[#FFFFFF] w-[300px] h-[40px] flex justify-center",
        facebookBtn: "mb-[15px] !rounded-[20px] !border-solid !border-[#C6C6C6] !border-[1px] !bg-[#3669B7] w-[300px] h-[40px] flex justify-center items-center",
        line: "h-[1px] w-[137px] border-solid border-[1px] border-[#17686E]",
        content: "!w-[300px] !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px] !rounded-[7px] border-solid border-[1px] border-[#E2E4E5]",
        forgetPassword: "cursor-pointer font-[Poppins] text-[10px] text-[#313131] font-normal leading-[148%] underline",
        line1: "h-[1px] w-[300px] border-solid border-[1px] border-[#17686E] mb-[15px] bg-[#17686E]",
        noaccountTxt: "font-[Poppins] text-[12px] text-[#313131] font-normal leading-[148%]",
        registerTxt: "cursor-pointer font-[Poppins] text-[12px] text-[#313131] font-normal leading-[148%] underline",
        msgcontainer: "border-solid border-[0.5px] border-[#17686E] rounded-[7px] bg-opacity-[0.11] bg-[#17686E] mb-[15px]",
        msgTxt: "mt-[8px] ml-[9px] mr-[9px] mb-[14px] font-[Poppins] text-[12px] text-[#313131] font-normal leading-[148%] text-center",
        countryTxt: '!pb-[0px] w-[300px] font-[Poppins] text-[12px] text-[#313131] font-normal !rounded-[7px] border-solid border-[1px] border-[#E2E4E5]'
        //countryTxt: 'w-[300px] bg-[#17686E] bg-opacity-[0.11] !pb-[0px] border-solid border-[2px] border-[#17686E] rounded-[7px]'
    }
    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID
    useEffect(() => {
        const initClient = () => {
              gapi.client.init({
              clientId: clientId,
              scope: 'profile'
            });
         };
         gapi.load('client:auth2', initClient);
     });

    const {type} = useParams();
    const navigate = useNavigate();
    useEffect(()=>{
        if(currentUser){
            navigate('/home');
        }
    }, [currentUser])
    const responseGoogle = (response) => {
        //console.log(response);
        let requestParams = {
            token: response.tokenId,
            code: country,
            ic_profile: profile === 1 ? 0: 1,
            googleId: response.googleId,
            ds_email: response.wt.cu
        };
        API.post("auth/registerwithgoogle", requestParams)
        .then((result) => {
          if (result.data.status === "success") {
              return navigate('/login')
          }
          toast.info(result.data.message?result.data.message:'Erro')
          return navigate('/login')

        })
        .catch((err) => {
            // toast.error(err.data.message?err.data.message:'Erro')
            console.log(err)
        });
    };
    const responseGoogle1 = (response) => {
        console.log(response);

      }
    // const responseFacebook = (response) => {

    //     console.log(response);
        
    // }
    const [emailfieldDirty, setEmailFieldDirty] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const handleEmailChange = (e) =>{
        if(isEmail(e.target.value)){
            setIsEmailValid(true);
        }
        else{
            setIsEmailValid(false);
        }
        const capitalizedInput = e.target.value
          .toLowerCase() // Convert the entire input to lowercase
          .split(' ')
          .map(word => word.charAt(0).toLowerCase() + word.slice(1))
          .join(' ');
        setEmail(capitalizedInput);
    }
    const [namefieldDirty, setNameFieldDirty] = useState(false);
    const [isNameValid, setIsNameValid] = useState(false);
    const handleNameChange = (e) =>{
        const value = e.target.value;
        const formattedName = value.replace(/\s{2,}/g, ' ');
        const capitalizedInput = formattedName
          .toLowerCase() // Convert the entire input to lowercase
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        // Expressão regular para verificar um espaço em branco seguido por outra letra
        const regex = /\s\w/;
        if (regex.test(value)) {
            setIsNameValid(true);
        } else {
            setIsNameValid(false);
        }
        setName(capitalizedInput);
    }
    const [passwordfieldDirty, setPasswordFieldDirty] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const handlePasswordChange = (e) =>{
        const value = e.target.value;
        if (validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
          })){
            setIsPasswordValid(true);
        }
        else{
            setIsPasswordValid(false);
        }
        setPassword(e.target.value);
    }
    const [password2fieldDirty, setPassword2FieldDirty] = useState(false);
    const [isPassword2Valid, setIsPassword2Valid] = useState(false);
    const handlePassword2Change = (e) =>{
        const value = e.target.value;
        if(value == password ){
            setIsPassword2Valid(true);
        }
        else{
            setIsPassword2Valid(false);
        }
        setPassword2(e.target.value);
    }
    const [country, setCountry] = useState('BR');
    const profileList = ['Sou cliente', 'Sou pesquisador']
    const [profile, setProfile] = useState(type=='client' ? 1 : (type == 'researcher' ? 2 : 0));
    const [openModal, setOpenModal] = React.useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isAgreed, setAgreed] = useState(true);
    const [msgvisisble, setMsgVisible] = useState(false);
    const [password2, setPassword2] = useState('');
    const [showPassword1, setShowPassword1] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const handleClickShowPassword1 = () => {
        setShowPassword1(!showPassword1);
      };
    
      const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
      };

    const [showPassword2, setShowPassword2] = useState(false);
    const handleClickShowPassword2 = () => {
        setShowPassword2(!showPassword2);
    };
    
    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };

    const dispatch = useDispatch();

    const handleRegister = () => {
        let requestParams = {
            nm_user: name,
            ds_password: password,
            ds_email: email,
            id_country: country,
            ic_profile: profile === 1 ? 0 : 1
        };
        setLoadingOpen(true);
        dispatch(register(requestParams, setLoadingOpen, navigate))
    }

    const [allcountries,setallcountries]=useState([])

    const getallcountries =()=>{
        API.post('/order/getallcountries')
        .then((res)=>{
            if(res.data.allcountries){
                var aux = []
                res.data.allcountries.map((single)=>{
                    const cd_isoalpha2 = single.cd_isoalpha2
                    aux.push({cd_isoalpha2:cd_isoalpha2})
                })
            }
            setallcountries(res.data.allcountries)
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    useEffect(()=>{
        getallcountries()
    },[])

    const getCountryNamePt = (isoAlpha2) => {
        const country = allcountries.find((c) => c.cd_isoalpha2 === isoAlpha2);
        return country ? country.nm_country_pt : '';
    };    

    const customLabels = allcountries.reduce((obj, country) => {
        obj[country.cd_isoalpha2] = country.nm_country_pt;
        return obj;
      }, {});

    return (
        <div className="w-full flex justify-center items-center mt-[80px] mb-[80px]">
            {loadingOpen && <Loading  nameToShow="Carregando"/>}
            <ForgetPassword openModal={openModal} setOpenModal={setOpenModal} showMessage={()=>setMsgVisible(true)} />
            <div className="w-[304px] flex flex-col items-center">
                {/* <div className="flex mb-[50px]">
                    <div className={style.title1}>DOCS</div>
                    <div className={style.title2}>TREE</div>
                </div> */}
                <div className={style.subtitle}>Crie uma conta</div>
                {
                    msgvisisble &&
                    <div className={style.msgcontainer}>
                        <div className={style.msgTxt}>
                        Se você for cadastrado e tiver um e-mail válido, você receberá um e-mail com as instruções para alterar sua senha.
                        </div>
                    </div>
                }

                {/* <div>
                    <FacebookLogin
                        className={style.googleBtn}
                        appId="2180693298624739" //APP ID NOT CREATED YET
                        fields="name,email,picture"
                        textButton={<div className={style.facebookTxt}>Continue com Facebook</div>}
                        cssClass={style.facebookBtn}
                        icon={<Box component="img" src={FacebookIcon1} className="mr-[14px]"/>}
                        callback={responseFacebook}
                    />
                </div> */}
                {/*
                <div>
                    <GoogleLogin
                        className={style.googleBtn}
                        clientId={clientId} 
                        buttonText={<div className={style.googleTxt}>Continue com Google</div>}
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle1}
                        isSignedIn={true}
                    />
                </div>
                */}
                <div className="flex justify-center items-center mb-[15px]">
                    <div className={style.line}></div>
                    {/*<div className={style.ouTxt}>Ou</div>*/}
                    <div className={style.line}></div>
                </div>
                <div className={(!isNameValid && namefieldDirty) ?'mb-[25px]': 'mb-[10px]'}>
                    <FormControl>
                        <TextField
                            label="Nome completo*"
                            value = {name}
                            onBlur={() => setNameFieldDirty(true)}
                            error={!isNameValid && namefieldDirty}
                            helperText={(!isNameValid && namefieldDirty)?"Deve conter pelo menos 1 sobrenome.":null}
                            className="h-[40px] w-[300px] rounded-[7px]"
                            onChange={handleNameChange}
                            variant="filled"
                            sx={{
                                paddingBottom: '0px',
                                borderRadius: '7px',
                                '& p':{
                                    color:'red !important',
                                    fontSize: '10px',
                                    fontFamily: 'Poppins',
                                    fontWeight: '400',
                                    lineHeight: '148%'
                                  },
                                '& .MuiFilledInput-input':{
                                    paddingTop: '17px',
                                    paddingBottom: '2px',
                                    background: 'none',
                                    border: '1px solid #E2E4E5',
                                    borderRadius: '7px',
                                },
                                '& .MuiFilledInput-root:before': {
                                    borderBottom: 'none !important',
                                },
                                '& .MuiFilledInput-root':{
                                    borderRadius: '7px',
                                    background: '#FFFFFF'
                                },
                                '& .MuiFilledInput-root:after': {
                                    borderBottom: 'none !important',
                                },
                                "& .MuiFilledInput-root.Mui-focused": {
                                    border: '2px solid #17686E !important',
                                    background: 'rgba(23, 104, 110, 0.11)',
                                  }
                            }}
                            InputLabelProps={{
                                sx: {
                                  color: "#C6C6C6",
                                  [`&.${inputLabelClasses.shrink}`]: {
                                    color: "#17686E"
                                  },
                                  fontFamily: 'Poppins',
                                  fontWeight: '400',
                                  fontSize: '14px',
                                  marginTop: '-5px',
                                  borderRadius: '7px'
                                }
                              }}
                            />
                    </FormControl>                   
                </div>
                <div className={(!isEmailValid && emailfieldDirty) ?'mb-[28px]': 'mb-[10px]'}>
                <FormControl>
                        <TextField
                            label="E-mail*"
                            onBlur={() => setEmailFieldDirty(true)}
                            error={!isEmailValid && emailfieldDirty}
                            helperText={(!isEmailValid && emailfieldDirty)?"Entre com um email válido.":null}
                            value = {email}
                            className="h-[40px] w-[300px] rounded-[7px]"
                            onChange={handleEmailChange}
                            variant="filled"
                            sx={{
                                '& p':{
                                    color:'red !important',
                                    fontSize: '10px',
                                    fontFamily: 'Poppins',
                                    fontWeight: '400',
                                    lineHeight: '148%'
                                  },
                                paddingBottom: '0px',
                                borderRadius: '7px',
                                '& .MuiFilledInput-input':{
                                    paddingTop: '17px',
                                    paddingBottom: '2px',
                                    background: 'none',
                                    border: (!isEmailValid && emailfieldDirty) ? '1px solid red' :'1px solid #E2E4E5',
                                    borderRadius: '7px',
                                },
                                '& .MuiFilledInput-root:before': {
                                    borderBottom: 'none !important',
                                },
                                '& .MuiFilledInput-root':{
                                    borderRadius: '7px',
                                    background: '#FFFFFF'
                                },
                                '& .MuiFilledInput-root:after': {
                                    borderBottom: 'none !important',
                                },
                                "& .MuiFilledInput-root.Mui-focused": {
                                    border: (!isEmailValid && emailfieldDirty) ? 'red' :'2px solid #17686E !important',
                                    background: 'rgba(23, 104, 110, 0.11)',
                                  }
                            }}
                            InputLabelProps={{
                                sx: {
                                  color: "#C6C6C6",
                                  [`&.${inputLabelClasses.shrink}`]: {
                                    color: "#17686E"
                                  },
                                  fontFamily: 'Poppins',
                                  fontWeight: '400',
                                  fontSize: '14px',
                                  marginTop: '-5px',
                                  borderRadius: '7px'
                                }
                              }}
                            />
                    </FormControl>
                </div>
                <div className='mb-[5px]'>
                <FormControl variant="outlined">
                    <OutlinedInput
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value = {password}
                        onBlur={() => setPasswordFieldDirty(true)}
                        error={!isPasswordValid && passwordfieldDirty}
                        onChange={handlePasswordChange}
                        sx={{
                            '& fieldset': {
                                  border: 'none !important'
                            }
                        }}
                        InputProps={{
                            className: style.content,
                        }}
                        type={showPassword1 ? 'text' : 'password'}
                        className={style.content}
                        placeholder='Senha*'
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword1}
                                onMouseDown={handleMouseDownPassword1}
                                edge="end"
                                >
                                { showPassword1 ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        hiddenLabel="hiddenLabel"
                        id="outlined-basic2"
                        variant="outlined"/>
                        {
                            !isPasswordValid && passwordfieldDirty &&
                            <FormHelperText error className="!text-[10px] !font-[Poppins] !text-[#FF0000] !font-normal !leading-[148%]">
                                Você precisa de pelo menos uma senha complexa com pelo menos 8 caracteres
                            </FormHelperText>
                        }
                    </FormControl>
                </div>
                <div className='mb-[5px]'>
                    <FormControl variant="outlined">
                        <OutlinedInput
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{
                                '& fieldset': {
                                      border: 'none !important'
                                }
                            }}
                            value = {password2}
                            onBlur={() => setPassword2FieldDirty(true)}
                            error={!isPassword2Valid && password2fieldDirty}
                            onChange={handlePassword2Change}
                            InputProps={{
                                className: style.content
                            }}
                            type={showPassword2 ? 'text' : 'password'}
                            className={style.content}
                            placeholder='Confirme a senha*'
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword2}
                                    onMouseDown={handleMouseDownPassword2}
                                    edge="end"
                                    >
                                    { showPassword2 ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            hiddenLabel="hiddenLabel"
                            id="outlined-basic1"
                            variant="outlined"/>
                        {
                            !isPassword2Valid && password2fieldDirty &&
                            <FormHelperText error className="!text-[10px] !font-[Poppins] !text-[#FF0000] !font-normal !leading-[148%]">
                                Incompatibilidade de senha.
                            </FormHelperText>
                        }
                    </FormControl>
                </div>
                <div className='mb-[5px]'>
                    <ReactFlagsSelect
                        className={style.countryTxt+" flag"}
                        selected={country}
                        onSelect={(code) => {console.log(code); setCountry(code)}}
                        searchable="true"
                        searchPlaceholder="Pesquisar"
                        customLabels={customLabels}
                    />
                </div>
                <div className="mb-[5px]">
                    <Select
                        value={profile}
                        placeholder='Selecione perfil de cadastro*'
                        displayEmpty="displayEmpty"
                        className="w-[300px] h-[40px] !rounded-[7px] border-solid border-[1px] border-[#E2E4E5]"
                        sx={{
                            '& .MuiSelect-icon ':{
                                right: '4px !important'
                            },
                            '& fieldset': {
                                  border: 'none !important'
                            }
                        }}
                        MenuProps={{
                            sx:{
                                "& .Mui-selected": {
                                    backgroundColor: "rgba(23, 104, 110, 0.4) !important"
                                },
                                "& .MuiMenuItem-root:hover": {
                                    backgroundColor: "rgba(23, 104, 110, 0.11)"
                                },
                                '& .MuiMenuItem-root': {
                                    whiteSpace: 'normal',
                                },

                            }
                        }} 
                        >
                        <MenuItem value={0}>
                            <p className="whitespace-nowrap font-[Poppins] font-normal text-[14px] leading-[24px] text-[#C6C6C6]">Selecione perfil de cadastro*</p>
                        </MenuItem>
                        {
                            //dynamic
                            profileList.map((item, index) => {
                                return (
                                    <MenuItem value={index+1}>
                                        <p
                                            className="whitespace-nowrap font-[Poppins] font-normal text-[14px] leading-[24px]">{item}</p>
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </div>
                <div className="flex w-[300px] justify-between items-center">
                    <div>
                        <FormControlLabel
                        label={
                        <div className="flex items-center">
                            <div className="mt-[2px] font-[Poppins] font-normal text-[10px] leading-[148%] text-[#313131]">
                            Ao criar sua conta, você está aceitando os termos de serviço e a política de privacidade do DocsTree.
                            </div>                 
                        </div>}
                        control={<Checkbox      
                            checked ={isAgreed}  
                            onChange={(e)=>{
                                setAgreed(e.target.checked);
                            }}   
                            sx={{
                                '& .MuiSvgIcon-root':{
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '3px'
                                },
                                color: '#17686E',
                                '&.Mui-checked': {
                                    color: '#17686E',
                                },

                            }}      
                        />}/> 
                    </div>

                </div>
                <div className="mb-[15px]">
                    <HeaderBTNStyle
                        sx={{
                            '&:disabled' : {
                                backgroundColor: '#C6C6C6',
                                cursor: 'not-allowed'
                            }
                        }}
                        disabled={!isNameValid || !isEmailValid || !isPassword2Valid || !isPasswordValid || !country || !profile || !isAgreed}
                        onClick={handleRegister}
                    >
                    Criar conta
                    </HeaderBTNStyle>
                </div>
                <div className={style.line1}></div>
                <div className='flex justify-center'>
                    <div className={style.noaccountTxt}>Já tem uma conta? Faça login&nbsp;</div>
                    <div className={style.registerTxt} onClick={() => navigate('/login')}>aqui</div>
                </div>
            </div>
            {/* <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop> */}
        </div>
    )
}