/* eslint-disable import/no-anonymous-default-export */

import { styled } from '@mui/material/styles';
import React, {useState, useEffect, useLayoutEffect} from 'react';
import {
    TextareaAutosize
} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import PhoneInput from 'react-phone-input-2';
import useWindowSize from '../../hooks/useWindowSize'
import { useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import IconButton from '@mui/material/IconButton';
import API from '../../redux/API';
import { toast } from 'react-toast';

import Loading from '../../components/loading/Loading';

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '0px auto',
    marginTop: '25px',
    marginBottom: '80px',
    border: '1px solid #E2E4E5',
    borderRadius: '7px',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
    }
}));

const ContainerStyle1 = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '0px auto',
    marginTop: '80px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
    }
}));

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));


export default (props) => {
    const { currentUser } = useSelector(state => state.auth);

    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const [profile, setProfile] = useState('');
    const profileList = [
        {tp_customer:0, spec:'Sou cliente'}, 
        {tp_customer:1, spec:'Sou pesquisador'}, 
    ]
    const [reason, setReason] = useState(0);
    const [comment, setComment] = useState('');
    const [email, setEmail] = useState('')
    const [fileLimit, setFileLimit] = useState(false);
    const [order, setOrder] = useState('');
    const [name, setName] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [verifyHuman, setVerifyhuman] = useState('');
    const MAX_COUNT = 5;
    const [selectedFiles, setSelectedFiles] = useState([])
    const [reasonListClient, setReasonListClient] = useState([]);
    const { currentProfile } = useSelector(state => state.auth);
    const [reasonListResearcher, setReasonListResearcher] = useState([]); 
    
    const [canSend, setCanSend] = useState(false); 



    const commentChange = (event) => {
        setComment(event.target.value);
    }
    useEffect(() => {
        if(currentUser){
            setPhone(currentUser.ds_telephone === undefined ? '' : currentUser.ds_telephone)
            setProfile(currentProfile === undefined ? '' : (currentProfile ? 1 : 0))
            setEmail(currentUser.ds_email === undefined ? '' : currentUser.ds_email);
            setName(currentUser.nm_user === undefined ? '' : currentUser.nm_user);
        }
    }, [currentUser])
    useLayoutEffect(() => {
        if(reasonListClient.length === 0)
        {
            API.get(`user/contactus?language=${'pt'}`)
            .then((result) => {
                let temp = result.data.reasonListClient.map((item) => {return {_id: item._id, reason:item.nm_contactreason}});
                temp.unshift({_id: 0, reason:'-- Escolha um motivo --'})
                setReasonListClient(temp)

                temp = result.data.reasonListResearcher.map((item) => {return {_id: item._id, reason:item.nm_contactreason}});
                temp.unshift({_id: 0, reason:'-- Escolha um motivo --'})
                setReasonListResearcher(temp)
            })
            .catch((err) => {
                console.log(err);
                console.error(err.request.response)
            });
        } 
    }, [])

    const handlePhoneChange = (e) => {
        var currentValue = e;
         setPhone(currentValue);
    }
    
    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;
        files.some((file) => {
            if(uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if(uploaded.length == MAX_COUNT) setFileLimit(true);
                if(uploaded.length > MAX_COUNT) {
                    alert(`You can only add a maximum of ${MAX_COUNT} files`);
                    setFileLimit(false);
                    setUploadedFiles([])
                    limitExceeded = true;
                    return true;
                }
            }
            if(!limitExceeded) setUploadedFiles(uploaded)
           
        })
    }

    const handleFileEvent = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        setSelectedFiles(chosenFiles);
        handleUploadFiles(chosenFiles);
    }

    const handleDelete = (value) =>{
        let temp = [...uploadedFiles];
        let index = temp.map((e)=>{return e.name}).indexOf(value);
        if(index > -1) temp.splice(index, 1);
        //console.log(temp);
        setUploadedFiles(temp)
    }

    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] whitespace-nowrap mb-[5px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px]"
    }

    const handleSave = () => {
        setLoading(true)
        console.log("Sending contact form");

        let formData = new FormData();

            if (currentUser) {
                formData.append("id_user", currentUser._id ? currentUser._id : currentUser.id);
            }
            
        formData.append("nm_customer", name);
        formData.append("ds_telephone", phone);
        formData.append("id_contactreason", reason);
        formData.append("ds_contactcomments", comment);
        formData.append("cd_order", order);
        formData.append("ds_email", email);

            if (currentProfile===0 || currentProfile===1) {
                formData.append("tp_customer", currentProfile);
            } else {
                formData.append("tp_customer", profile);
            }

            if (selectedFiles.length>0) {
                selectedFiles.map((item) => {
                    formData.append("file", item);
                })
            }


        //console.log("form ===== ");
        //console.log(formData);

        API.post("user/addcontact", formData, {
            headers: {
            "content-type": "multipart/form-data",
            },
        })
        .then((result) => {
            if (result.data.status === "success") {
                toast.success('Sucesso!!! Entraremos em contato em breve');
                setOrder('');
                setReason(0);
                setComment('');
                setUploadedFiles([]);
                setSelectedFiles([]);
                setVerifyhuman(false);
                setLoading(false)

                window.location.reload()

            }
        })
        .catch((err) => {
            toast.error("Erro ao cadastrar. Verifique os dados e tente novamente.")
            console.log(err);
            setLoading(false)
        });
    }


    useEffect(()=>{
        if (!name) {
            setCanSend(false)
        }
        else if (!email) {
            setCanSend(false)
        }
        else if (profile !== 0 && profile !== 1) {
            setCanSend(false)
        }
        else if (!reason) {
            setCanSend(false)
        }
        else if (!comment) {
            setCanSend(false)
        }
        else if (!verifyHuman) {
            setCanSend(false)
        }
        else {
            setCanSend(true)
        }


        const alldata = {
            name:name,
            email:email,
            phone:phone,
            order:order,
            profile:profile,
            reason:reason,
            comment:comment,
            // profileList:profileList,
            // reasonListResearcher:reasonListResearcher,
            // reasonListClient:reasonListClient,

        }

        //console.log(alldata);

    },[name,email, phone, order, profile,profileList, reason, comment,  reasonListResearcher, reasonListClient])

    return (
       <RootStyle>
        {loading && <Loading/>}
            <ContainerStyle1>
                <div className='font-[Poppins] font-normal text-[32px] leading-[148%] color-[#313131]'>Fale Conosco</div>
            </ContainerStyle1>
            <ContainerStyle className='flex-col'>
                <div className='flex flex-col items-center'>
                    <div className="w-[96%] mb:block sm:flex justify-around mt-[21px]">
                        <div className='mb:w-[100%] sm:w-[50%] flex mb-[25px] mr-[25px]'>
                                <div className='w-full'>
                                    <div className={style.title}>Nome completo <span className='text-[#DE2B2B]'>*</span></div>
                                    <div>
                                        <TextField
                                            className="w-full"
                                            value = {name}
                                            onChange={(e) => {setName(e.target.value)}}
                                            InputProps={{
                                                className: style.content
                                            }}
                                            hiddenLabel="hiddenLabel"
                                            id="outlined-basic"
                                            variant="outlined"
                                        />
                                    </div>
                                </div>
                        </div>
                        <div className='mb:w-[100%] sm:w-[50%] flex mb-[25px]'>
                            <div className='w-full'>
                                <div className={style.title}>E-mail <span className='text-[#DE2B2B]'>*</span></div>
                                <div>
                                    <TextField
                                        autoComplete="email"
                                        type="email"
                                        className="w-full"
                                        value={email}
                                        onChange={(e) => {setEmail(e.target.value)}}
                                        InputProps={{
                                            className: style.content,
                                            type: "email",
                                            requried: "true   "
                                        }}
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[96%] mb:block sm:flex justify-around">
                        <div className='mb:w-[100%] sm:w-[50%] flex mb-[25px] mr-[25px]'>
                            <div className='w-full'>
                                <div className={style.title}>Telefone</div>
                                <div>
                                    <PhoneInput
                                        country={'br'}
                                        value={phone}
                                        inputStyle={
                                            {
                                                height: '40px',
                                                width: '100%',
                                                fontFamily: 'Poppins',
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                color: '#313131'
                                            }
                                        }
                                        className={style.content}
                                        placeholder="+55 (00) 000000000"
                                        onChange={handlePhoneChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='mb:w-[100%] sm:w-[50%] flex mb-[25px]'>
                            <div className='w-full'>
                                <div className={style.title}>Número do pedido</div>
                                <div>
                                    <TextField
                                        autoComplete="email"
                                        type="email"
                                        className="w-full"
                                        value={order}
                                        onChange={(e) => {setOrder(e.target.value)}}
                                        InputProps={{
                                            className: style.content,
                                            type: "email",
                                            requried: "true   "
                                        }}
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[96%] mb:block sm:flex justify-around">
                        <div className='mb:w-[100%] sm:w-[50%] flex mb-[25px] mr-[25px]'>
                            <div className='w-full'>
                            <div className={style.title}>Perfil do usuário <span className='text-[#DE2B2B]'>*</span></div>
                            <div>
                                <Select
                                    value={profile}
                                    onChange={(e) => {
                                        setProfile(e.target.value)
                                    }}
                                    displayEmpty="displayEmpty"
                                    className="w-[100%] h-[40px]"
                                    MenuProps={{
                                        sx:{
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(23, 104, 110, 0.4) !important"
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(23, 104, 110, 0.11)"
                                            },
                                            '& .MuiMenuItem-root': {
                                                whiteSpace: 'normal',
                                            },
                                        }
                                    }} 
                                    >
                                    {
                                        //dynamic
                                        profileList.map((item,index) => {
                                            return (
                                                <MenuItem value={item.tp_customer}>
                                                    <p
                                                        className="whitespace-nowrap font-[Poppins] font-normal text-[14px] leading-[24px]">{item.spec}</p>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                            </div>
                        </div>
                        <div className='mb:w-[100%] sm:w-[50%] flex mb-[25px]'>
                            <div className='w-full'>
                                <div className={style.title}>Motivo do chamado <span className='text-[#DE2B2B]'>*</span></div>
                                <div>
                                <Select
                                    value={reason}
                                    onChange={(e) => {
                                        setReason(e.target.value)
                                    }}
                                    placeholder='Selecione perfil do usuário antes'
                                    displayEmpty="displayEmpty"
                                    className="w-[100%] h-[40px]"
                                    MenuProps={{
                                        sx:{
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(23, 104, 110, 0.4) !important"
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(23, 104, 110, 0.11)"
                                            },
                                            '& .MuiMenuItem-root': {
                                                whiteSpace: 'normal',
                                            },
                                        }
                                    }} 
                                    >
                                    {
                                        (profile === 0 || profile === 1 ? [] : [
                                            <MenuItem key={0} value={0}>
                                                <p className="whitespace-nowrap font-[Poppins] font-normal text-[14px] leading-[24px] text-[#C6C6C6]">Selecione perfil do usuário antes</p>
                                            </MenuItem>
                                        ])
                                    }
                                    {
                                        //dynamic
                                        (profile === 0 ? reasonListClient : (profile === 1 ? reasonListResearcher : [])).map((item) => {
                                            return (
                                                <MenuItem value={item._id}>
                                                    <p
                                                        className="whitespace-nowrap font-[Poppins] font-normal text-[14px] leading-[24px]">{item.reason}</p>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-[96%]'>
                        <div className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mt-[20px] mb-[3px]">Digite seus comentários
                            <span className='text-[#DE2B2B]'> *</span>
                        </div>
                        <div>
                            <TextareaAutosize
                                id="standard-multiline-static"
                                label="Multiline"
                                onChange={commentChange}
                                InputProps={{
                                    disableUnderline: true
                                }}
                                placeholder='Mensagem'
                                defaultValue={comment}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={comment}
                                className="pt-[9px] pl-[13px] resize-y !min-h-[160px] font-[Poppins] font-normal text-[14px] leading-[24px] text-[#313131] w-[100%] !border-solid !border-[#E2E4E5] !border-[1px] rounded-[7px]"/>
                        </div>
                    </div>
                </div>
                <div className='w-[96%]' >
                    <div className="flex mb:justify-center md:justify-start mb-[20px] mb:ml-[0px] md:ml-[30px] mt-[20px]">
                        <div className="block  mb: text-center md:text-left">
                            <div className="mb-[20px]">
                                <input id='fileUpload' type='file' multiple onChange={handleFileEvent} disabled={fileLimit} className='hidden'/>
                                <label htmlFor='fileUpload'>
                                    <a>
                                        <span className='rounded-[5px] font-normal font-[Poppins] text-[#17686E] bg-[#FFFFFF] text-[14px] w-[250px] h-[40px] text-center cursor-pointer pl-[43px] pr-[43px] pt-[11px] pb-[11px] mt-[11px] leading-[21px] border-solid border-[1px] border-[#17686E] flex items-center justify-center'>
                                            Anexar arquivo
                                        </span>
                                    </a>
                                </label>
                            </div>
                            <div className="uploaded-files-list mb:block xl:flex ml-[25px] mb:mt-[20px] md:mt-[0px]">
                                {
                                    uploadedFiles.map(file => (
                                        <div className="rounded-[10px] font-[Poppins] text-[12px] bg-[#E2E4E5] pl-[10px] pr-[10px] pt-[5px] pb-[5px] mr-[10px] mb-[5px]">
                                            {file.name}
                                             <IconButton
                                                onClick={()=>{handleDelete(file.name)}}
                                                aria-label="delete">
                                                <ClearOutlinedIcon/>
                                            </IconButton>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center mb-[20px] mb:w-[202px] sm:w-[302px] h-[70px] mb:ml-[calc(50%-100px)] sm:ml-[calc(50%-150px)]">
                        <ReCAPTCHA
                            sitekey="6Ld1-g0kAAAAAH7gGajMrpDvpw3oPpZOSuhjrklv"
                            onChange={(e)=>{setVerifyhuman(e);}}
                        />
                    </div>
                    <div className="flex justify-center mb-[25px]">
                        <HeaderBTNStyle
                            disabled = {!canSend}
                            sx={{
                                '&:disabled' : {
                                    backgroundColor: '#C6C6C6',
                                    cursor: 'not-allowed'
                                }
                            }}
                            onClick={handleSave}
                            >
                        Enviar
                        </HeaderBTNStyle>
                    </div>
                </div>
            </ContainerStyle>
       </RootStyle>
    )
}