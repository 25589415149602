import { useState } from "react";
import { Icon } from "@iconify/react";
import { styled } from "@mui/material/styles";
import {
  InputBase,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Box,
} from "@mui/material";
import CardList from "../../components/card/JdCardList";
import Cross_button from "../../assets/icons/Cross_button.svg";

import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import API from "../../redux/API";
import { toast } from "react-toast";
import { json } from "react-router-dom";

// -------------------------------------------------------------
const RootStyle = styled("div")(({ theme }) => ({
  width: "100%",
}));

const SearchStyle = styled("div")(({ theme }) => ({
  width: "60%",
  display: "flex",
  alignItems: "center",
  border: "1px solid #C6C6C6",
  borderRadius: "5px",
  padding: "5px",
  margin: "0px 0 10px 0",
}));

const ResultStyle = styled("div")(({ theme }) => ({
  backgroundColor: "#e2e4e541",
  padding: "5px 15px 5px 27px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "5px",
}));

const TitleStyle = styled("div")({
  color: "#313131",
  fontFamily: "Poppins",
  fontSize: "15px",
  marginLeft: "10px",
  display: "flex",
});

const AnyFilter = styled("div")({
  color: "#17686E",
  fontFamily: "Poppins",
  fontSize: "15px",
  fontWeight: "bold",
});

const ResultTextStyle = styled("div")({
  color: "#313131",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 600,
  margin: "20px 0px",
});

const Search_Result_Box = styled("div")({
  padding: "5px 13px",
  border: "1px solid #17686E",
  borderRadius: "15px",
  display: "flex",

  margin: "6px",
});

const Search_Result_Box_text = styled("div")({
  color: "#313131",
  fontFamily: "Poppins",
  fontSize: "12px",
  whiteSpace: "nowrap",
  marginRight: "31px",
});

// -------------------------------------------------------------

export default function Content(props) {
  var filter = props.filter;
  var filterList = props.filterList;
  var setFilterList = props.setFilterList;

  var allfilters = props.allfilters
  var setallfilters = props.setallfilters

  var filterList = props.filterList;



  const [age, setAge] = useState("");

  var allValues = [
    {id:1, filterName:"Mais recentes",},
    {id:2, filterName:"Mais antigo",},
    {id:3, filterName:"Ordem alfabética (a-z)",},
    {id:4, filterName:"Ordem alfabética (z-a)",},
  ];

  const handleChange = (item) => {
    const filterType = 'dropbox'
    let aux = item.target.value;
    let auxFilter =[...filterList]
    let nameFromID = allValues.filter(single=>single.id===aux)
    let flag = filterList.some(obj=>obj.type===filterType)
    if (flag) {
      auxFilter = filterList.filter((e) => e.type != filterType)
    }
    auxFilter.push({name:nameFromID[0].filterName,type:filterType})
    setAge(nameFromID[0].filterName);
    props.postFilters(aux, filterType);
    setFilterList(auxFilter);
  }





  const removefilterX = (item) => {
    // FILTERING THE OBJECT THAT IS SENT TO BACKEND
    setallfilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      // TO CAN REMOVE FROM X BUTTON, NEED TO PARSE AGAIN THE VALUES FROM STRING NAME TO NUMBER (THE BACK RECEIVE JUST NUMBER FOR THESE CASES)
      if (item.type==='level') {
        if (item.name==='Iniciante') {
          item.name=0
        }
        if (item.name==='Especialista') {
          item.name=1
        }
        if (item.name==='Assessor') {
          item.name=2
        }
      }
      if (item.type==='sendType') {
        if (item.name==='Digital') {
          item.name=0
        }
        if (item.name==='Físico') {
          item.name=1
        }
        if (item.name==='Apostilado') {
          item.name=2
        }
      }
      // DROPBOX IS THE ONLY ONE THAT HAS NO ARRAY, JUST ONE NUMBER. SO DON'T NEED TO MAP OR FILTER, JUST SET TO NULL AGAIN
        if (item.type==='dropbox') {
            updatedFilters[item.type]=''
            return updatedFilters;
        }
        else{
          const filteredItems = updatedFilters[item.type].filter(
            filter => filter != item.name
            );
            updatedFilters[item.type] = filteredItems;
            return updatedFilters;
          }
      })
    // FILTERING THE FILTER ARRAY BELOW THE SEARCH (CLOSE TABS)
    if (filterList.some(obj => obj.type === item.type && obj.name === item.name)) {
      const updatedFilterList = filterList.filter(obj => !(obj.type === item.type && obj.name === item.name));
      setFilterList(updatedFilterList)
    }
  }  


  
  // filter list just for searchbar
  const [filterlistSEARCH, setFilterlistSEARCH] = useState('');
  const [searchON, setSearchON] = useState(null)





  const handleKeyPress =(event) => {
    
    if (event.key == "Enter") {

      if(filterlistSEARCH){
        const regex = /^(?!^\s*$).+/;
        const hasCharacters = regex.test(filterlistSEARCH);
        if(hasCharacters){
          handleFilterList(filterlistSEARCH);
        }
      }
    }
  }


  const handleFilterList = (item) => {
    let aux =[...filterList]
    const filterType = 'searchBar'
    let flag = filterList.includes(item)
    if (flag) {
        aux = filterList.filter((e) => e.name != item)
    } else {
        aux.push({name:item,type:'searchBar'})
    }
    props.postFilters(item, filterType)
    setFilterList(aux);
}


  return (
    <RootStyle>
      {/* Search bar */}
      <SearchStyle
                  sx={{ width:'100%'}}

      >
        <Icon icon="fluent:search-12-regular" color="#C6C6C6" width="20" />
          <InputBase
            id="searchBar"
            sx={{ ml: 2, flex: 1 , width:'100%'}}
            placeholder="Pesquisar"
            value={filterlistSEARCH}
            onChange={(e) => {setFilterlistSEARCH(e.target.value)}}          
            onKeyPress={(event)=>{handleKeyPress(event)}}
          />
      </SearchStyle>

      <ResultStyle>
        <ResultTextStyle>{props.totalOrders} resultados</ResultTextStyle>

        <FormControl size="small" sx={{ width: 200, backgroundColor: "white"}}>
          <InputLabel id="demo-simple-select-label" sx={{ fontFamily: "Poppins", fontWeight: 400, color: "#313131"}}>Filtrar por</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            label="Age"
            onChange={handleChange}
            placeholder="Filtrar por"
            disabled={props.filtersBlock == true}
            MenuProps={{
              sx: {
                  "& .Mui-selected": {
                      backgroundColor: "rgba(23, 104, 110, 0.4) !important"
                  },
                  "& .MuiMenuItem-root:hover": {
                      backgroundColor: "rgba(23, 104, 110, 0.11)"
                  },
                  '& .MuiMenuItem-root': {
                      whiteSpace: 'normal'
                  }
              }
          }}
          >
            {/* Cada opção tem um valor de referência */}

            {allValues.map((elem) => (
              <MenuItem value={elem.id}>{elem.filterName}</MenuItem>
            ))}

            {/* <MenuItem value={'Mais recentes'}>Mais recentes</MenuItem>
            <MenuItem value={'Mais antigo'}>Mais antigo</MenuItem>
            <MenuItem value={'Ordem alfabética (a-z)'}>Ordem alfabética (a-z)</MenuItem>
            <MenuItem value={'Ordem alfabética (z-a)'}>Ordem alfabética (z-a)</MenuItem> */}
          </Select>
        </FormControl>
      </ResultStyle>

      <Stack direction="row" mt={2} flexWrap={"wrap"}>
        {props.filterList?.map((item, index) => (
          <Search_Result_Box key={index}>
            <Search_Result_Box_text>{item.name}</Search_Result_Box_text>
            <Box
              component="img"
              src={Cross_button}
              alt="fechar-icone"
              width="9px"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                removefilterX(item);
              }}
            />
          </Search_Result_Box>
        ))}

        {searchON  &&(
          <Search_Result_Box key={searchON}>
            <Search_Result_Box_text>
              {searchON}
            </Search_Result_Box_text>
            <Box
              component="img"
              src={Cross_button}
              alt="fechar-icone"
              width="9px"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                removefilterX(filterlistSEARCH, "searchBar");
              }}
            />
          </Search_Result_Box>
        )}

        {/* 0:
{JSON.stringify(props.filtersDropbox[0])}
<br/>

1:
{JSON.stringify(props.filtersDropbox[1])}
<br/>

2:
{JSON.stringify(props.filtersDropbox[2])}
<br/>

3:
{JSON.stringify(props.filtersDropbox[3])}
<br/> */}
      </Stack>

      {props.totalOrders == 0 && (
        <TitleStyle>
          A busca por "
          <AnyFilter>

            
            {filterList.length > 0 &&
                (filterlistSEARCH && searchON  ? searchON+', ' : null)
            }


            {filterList.length == 0 &&
                (filterlistSEARCH && searchON  ? searchON : null)
            }


            {filterList.map(function (item,index) {
              if(index < filterList.length-1){
                return `${item.name}, `;
              }
              return `${item.name}`;
            })}
          </AnyFilter>
          " não possui resultados.
        </TitleStyle>
      )}

      <CardList
        cardContent={props.cardContent}
        showOrderByPage={(a) => {
          props.setTotalOrders("Carregando");
          props.setCurrentPage(a);
          props.setCardContent([]);
          props.getOrders(a);
        }}
        totalPages={props.totalPages}
        page={props.page}
        setPage={props.setPage}
        filtersBlock={props.filtersBlock}
        setFiltersBlock={props.setFiltersBlock}
        filterCountry={props.filterCountry}
        showFlag={props.showFlag}
        showCountryName={props.showCountryName}
        orderTypeList={props.orderTypeList}

      />
    </RootStyle>
  );
}
