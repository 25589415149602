import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowSize from '../../hooks/useWindowSize'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import API from '../../redux/API';
import {
    Grid,
    Box,
    Stack,
    TextareaAutosize,
    Divider,
} from '@mui/material';


import { useDispatch } from 'react-redux'
import { setMySpeciality } from '../../redux/actions/profile.action';
import { ConstructionOutlined } from '@mui/icons-material';
import { setUser } from '../../redux/actions/auth.action';
import { toast } from 'react-toast';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, padding: '0px 43px',zIndex:9999 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500], 
                        zIndex:9999 
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 500,
    color: '#313131',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
        textAlign: 'left'
    },
}));



const GridWrapper = styled('div')(({ theme }) => ({
    padding: '40px 0px 35px 0px',
    [theme.breakpoints.up('sm')]: {
        padding: '70px 0px 95px 0px'
    },
    [theme.breakpoints.up('md')]: {
        padding: '15px 3px 105px 3px'
    },
}));

export default function Modal({userplan, openModal, setOpenModal, specialitylist, setSpecialitylist, changed, setChanged }) {
    const navigate = useNavigate();

    const [page, setPage] = React.useState(1);

    const { width } = useWindowSize()
    const dispatch = useDispatch();
    const handleClose = () => {
        setOpenModal(false);
    };
    const profile = useSelector(state => state.profile);
    const [speciality, setSpeciality] = React.useState(0);
    const {currentUser} = useSelector(state => state.auth);
    useEffect(() => {
        if(currentUser?.about_me?.specialities !== undefined){
            setSpeciality(currentUser.about_me.specialities);
        }
        else setSpeciality([0]);
    }, [currentUser])
    const handleConfirm = () => {

        setOpenModal(false)
        if(JSON.stringify(speciality) !== JSON.stringify(currentUser.about_me.specialities)){
            setChanged(true);
        } 
        else{
            setChanged(false);
        }

        dispatch(setUser({...currentUser, about_me:{...currentUser.about_me, specialities:speciality}}))
        dispatch(setMySpeciality(speciality));
    };
    const getSpeciality = (id) => {
        let temp;
        specialitylist.map((item) => {
            if(item._id == id) temp = item.nm_country_pt;
        })
        return temp;
    }
    const handleChange = (event) => {
        const limit = userplan.nm_countries
        if (limit) {
            let {
            target: { value },
            } = event;

            let tspeciality = (typeof value === 'string' ? value.split(',') : value);
            // changing the speciality id for speciality name, from other collection

            var allnamesmodified = [];
            tspeciality.map((single) => {
            let thisspeciality = specialitylist.find((speciality) => speciality._id === single);
            allnamesmodified.push(thisspeciality.nm_country_pt);
            });
            
            let index = tspeciality.indexOf('-- Selecione --');
            if(index > -1)        tspeciality.splice(index, 1);
            
            // alert((allnamesmodified))
            if (tspeciality.length<=limit) {
                return setSpeciality(tspeciality)
            }
            else{
                return toast.info(`Seu plano atual permite até ${limit} especialidades`)
            }
        }
      }; 


    return (
        <div style={{maxHeight:'50%', maxWidth:'50%'}}>
           
            {page > 0 && page < 4 &&
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openModal} sx={{
                        '.MuiPaper-root': {
                            maxWidth: '837px',
                            width: '100%',
                        }
                    }}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} >
                    </BootstrapDialogTitle>
                    <DialogContent>
                        <GridWrapper className={page == 1 ? '!pb-[70px]' :'!pb-[100px]'}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>

                                    <HeaderText>Adicionar Especialidade</HeaderText>
                                    <Divider sx={{ marginTop: '15px', marginBottom: '15px', width: '100%'}}/>
                                    <div className="mb:w-full md:w-[60%]">
                                            <div
                                                className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mt-[33px] mb-[3px]">Especialidade no país &nbsp;
                                                <span className='text-[#DE2B2B]'>*</span>
                                            </div>
                                            <div>
                                                <Select

                                                    // sx={{maxHeight:'300px'}}

                                                    value={speciality}
                                                    multiple
                                                    onChange={handleChange}
                                                    displayEmpty="displayEmpty"
                                                    className="w-[100%] mb:h-[50px] md:h-[34px]"
                                                    inputProps={{
                                                        'aria-label' : 'Without label'
                                                    }}
                                                    renderValue={(selected) => {
                                                        return selected.map((item)=>getSpeciality(item)).join(', ')
                                                    }}
                                                    MenuProps={{
                                                        sx: {
                                                            "& .Mui-selected": {
                                                                backgroundColor: "rgba(23, 104, 110, 0.4) !important"
                                                            },
                                                            "& .MuiMenuItem-root:hover": {
                                                                backgroundColor: "rgba(23, 104, 110, 0.11)"
                                                            },
                                                            '& .MuiMenuItem-root': {
                                                                whiteSpace: 'normal'
                                                            },
                                                            maxHeight:'50%',
                                                        }
                                                    }}>
                                                      <MenuItem value="0" disabled sx={{
                                                                    '&:disabled' : {
                                                                        color: '#FFFFFF'
                                                                    }
                                                                }}>
                                                        <p
                                                            className="whitespace-normal font-[Poppins] font-normal text-[14px] leading-[24px]">-- Selecione --</p>
                                                        </MenuItem>
                                                    {
                                                        //dynamic
                                                        
                                                        specialitylist.map((item,index) => {
                                                            return (
                                                                
                                                                <MenuItem key={index} value={item._id}>
                                                                    <Checkbox edge="start" sx={{'&.Mui-checked':{color:'#17686E'}}} checked={speciality.indexOf(item._id) > -1} />
                                                                     <p className="whitespace-normal font-[Poppins] font-normal text-[14px] leading-[24px]">{item.nm_country_pt}</p>
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                    </div>
                                    
                                </Grid>

                            </Grid>
                        </GridWrapper>
                    </DialogContent>
                    <Stack flexDirection={ width > 640 ? 'row' : 'column'} justifyContent={width>640?'end':'center'} marginRight={width>640?'53px':'0px'} paddingTop={'20px'} className="items-center" >
                        <HeaderBTNStyle onClick={handleConfirm} disabled={speciality.length == 0 || speciality[0]=='0'} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Salvar</HeaderBTNStyle>
                    </Stack>
                    <DialogActions></DialogActions>
                    <DialogActions></DialogActions>
                </BootstrapDialog>
            }
        </div >
    );
}
