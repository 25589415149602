// Esse é o arquivo que mede as vitais da web
const reportWebVitals = onPerfEntry => {
  // Verifica se o parâmetro é uma função
  if (onPerfEntry && onPerfEntry instanceof Function) {
    // Importa as bibliotecas web-vitals
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

// Exporta a função reportWebVitals
export default reportWebVitals;