import { styled } from '@mui/material/styles';

import DesktopTable from '../../components/Table/DesktopTable.js'
import MobileTable from '../../components/Table/MobileTable.js'
import useWindowSize from '../../hooks/useWindowSize'
import { useState } from 'react';
import API from '../../redux/API.js';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
    }
}));

const TitleText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '18px',
    color: '#313131',
    padding: '46px 0px 21px',
    [theme.breakpoints.up('md')]: {
        fontSize: '20px',
        padding: '39px 0px 18px'
    }
}));
 

export default function ExpertProfile({ data }) {
    const { width } = useWindowSize()
    const [tdata, setTdata] = useState([]);
    const { currentUser } = useSelector(state => state.auth);

    const [allorders,setallorders]=useState([])
    const statusInfo = [
        {
            status: 'Em aberto',
            flags:['Aguardando propostas', 'Proposta disponível', 'Em negociação']
        },
        {
            status: 'Em andamento',
            flags:['Pesquisando', 'Aguardando análise']
        },
        {
            status: 'Concluído',
            flags:['Avaliação disponível', 'Concluído']
        },
        {
            status: 'Cancelado',
            flags:['Cancelado']
        }
    ]
    useEffect(() => {
        API.get(`/order/getorderwithclient?id_customer=${currentUser.id === undefined ? currentUser._id : currentUser.id}`)
        .then((result) => {
            if (result.data.status === "success") {

                let temp = []
                setallorders(result.data.orders)
                result.data.orders.map((item) => {
                    let date = new Date(item.createdAt);
                    const yy = date.getFullYear() % 100;
                    let mm = date.getMonth() + 1; // Months start at 0!
                    let dd = date.getDate();

                    if (dd < 10) dd = '0' + dd;
                    if (mm < 10) mm = '0' + mm;

                    const formattedToday = dd + '/' + mm + '/' + yy;

                    date = new Date(item.updatedAt);
                    const yy1 = date.getFullYear() % 100;
                    let mm1 = date.getMonth() + 1; // Months start at 0!
                    let dd1 = date.getDate();
                    let hour = date.getHours();
                    let minute = date.getMinutes()
                    if (dd1 < 10) dd1 = '0' + dd1;
                    if (mm1 < 10) mm1 = '0' + mm1;
                    if (hour < 10) hour = '0' + hour;
                    if (minute < 10) minute = '0' + minute;
                    const formattedUpdateDate = dd1 + '/' + mm1 + '/' + yy1 + ' - '+hour + 'h'+minute;

                    // creating status and flags
                        var cd_orderstatus = 0
                        var cd_statusflag = 0
                        var auxBids = [...item.bids]
                        var auxEvents = [...item.event]

                            if(item.bids.length>0){
                                cd_orderstatus=0
                                cd_statusflag=1
                            }
                        auxBids.map((single)=>{
                            if(single.ic_bidnegotiated){
                                cd_orderstatus=0
                                cd_statusflag = 2 
                            }
                        })
                            if(item.cd_orderstatus===1){
                                cd_orderstatus = 1
                                cd_statusflag = 0
                            }

                            auxEvents.map((single)=>{
                                if(single.nm_event==='needvalidation'||single.nm_event==='needvalidationstamped'){
                                    cd_orderstatus=1
                                    cd_statusflag = 1 
                                }
                                else if(single.nm_event==='isvalidatedstamped'||single.nm_event==='isvalidated'){
                                    cd_orderstatus=1
                                    cd_statusflag = 0 
                                }
                            })
                                if(item.cd_orderstatus===2){
                                    cd_orderstatus=2
                                    cd_statusflag = 0
                                    if(item.ic_userreviewed){
                                        cd_orderstatus=2
                                        cd_statusflag = 1 
                                    }
                                }

                                if(item.cd_orderstatus===3){
                                    cd_orderstatus=3
                                    cd_statusflag = 0
                                }


                        const hasurgencyboost = Object.values(item.boosts).some(boost=>(boost.tp_boost==='Urgency'&&boost.ic_boostactive))
                        const hashighlightboost = Object.values(item.boosts).some(boost=>(boost.tp_boost==='Hightlight'&&boost.ic_boostactive))


                    let titem = {
                        id: item._id,
                        name: item.nm_ancestor,
                        iconflag: item.tp_order ? 2 : 1,
                        docType: item.id_documenttype.nm_documenttype!=='Outros'?'Certidão de '+item.id_documenttype.nm_documenttype:item.nm_otherdocument,
                        status: statusInfo[cd_orderstatus].status,
                        proposal: item.bids.length,
                        publicdate: formattedToday,
                        flag: item.cd_orderstatus,
                        value: item.vl_budget,
                        flagstatus: statusInfo[cd_orderstatus].flags[cd_statusflag],
                        updatetime: 'Utima atualização: ' + formattedUpdateDate,
                        createdAt: item.createdAt,
                        tp_order:item.tp_order,
                        id_researcher:item.id_researcher,
                        ic_userreviewed:item.ic_userreviewed,
                        cd_orderstatus:item.cd_orderstatus,
                        needBoost:hasurgencyboost&&hashighlightboost?false:true,
                    }
                    temp.push(titem);
                })
                
                setTdata(temp);
            }
        })
        .catch((err) => {
            console.log(err)
                // toast.error(JSON.parse(err.request.response).message)
        });  
    }, [])
    return (
        <RootStyle>
            <TitleText>Minhas solicitações</TitleText>
            {width > 900 && <DesktopTable rows={tdata} allorders={allorders}/>}
            {width < 900 && <MobileTable rows={tdata} allorders={allorders}/>}
        </RootStyle>
    )
}