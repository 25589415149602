export function setMyLanguage(data) {
    // Define a ação "setMyLanguage" que armazena os dados de idioma escolhidos pelo usuário
    return { type: "setMyLanguage", data };
}
export function deleteMyLanguage(data) {
    // Define a ação "deleteMyLanguage" que exclui os dados de idioma escolhidos pelo usuário
    return { type: "deleteMyLanguage", data };
}
export function setMySpeciality(data) {
    // Define a ação "setMySpeciality" que armazena os dados de especialidade escolhida pelo usuário
    return { type: "setMySpeciality", data };
}
export function deleteMySpeciality(data) {
    // Define a ação "deleteMySpeciality" que exclui os dados de especialidade escolhida pelo usuário
    return { type: "deleteMySpeciality", data };
}