import React, { useEffect } from "react";
import Check from '../../assets/icons/checkitem_green.svg'
// import ReactHtmlParser from 'react-html-parser';



export default (props)=>{


    const isMobile = window.innerWidth < 700;

    const sanitizeHTML = (htmlString) => {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        return doc.body.textContent || '';
      };
    
    
    return (


        
            <div style={{padding:'10px',   gap:'10px', fontFamily:'poppins' }}>
                <div style={{backgroundColor:'#17686E', width:'100%',  height:'0.5px'}}></div>
                <div style={{display:'flex', width:'100%', height:'30px', gap:'10px', padding:'10px', alignItems:'center', justifyItems:'center', margin:'10px auto 10px auto', alignContent:'center', justifyContent:'center', }}>
                    <h5>Seu pedido:</h5>
                    <h5 style={{fontSize:'20px', fontWeight:'600', }}>{props.cardinfo.bidname}</h5>
                </div>
                <div style={{backgroundColor:'#17686E', width:'100%',  height:'0.5px'}}></div>

                <div style={isMobile?{                
                    width:'280px',
                    maxHeight:'280px',
                    overflow:'auto',
                    }
                    :
                    { display:'flex', width:'100%',   margin:'10px auto 50px auto',}}> 
                    <div style={{width:'50%',maxWidth:'300px', maxHeight:'200px', display:'flex',  margin:'10px', width:'70%', margin:'30px auto 30px auto' , borderRadius:'10px', overflow:'hidden'}}>
                        <img src={props.cardinfo.bidImage} width={'100%'} height={'auto'}/>
                    </div>
                        
                    <div style={{width:'1px',height:'100%', backgroundColor:'red', }}></div>
                    
                    <div style={isMobile?{}:{ display:'flex', fontSize:'15pt', maxWidth:'50%',  maxHeight:'300px',   overflow:'auto', marginBottom:'30px' }}> 
                        <div style={{display:'flex', flexDirection:'column', gap:'10px', padding:'10px', alignItems:'center', }}>
                            <h5 style={{fontWeight:'600', }} >Detalhes:</h5>
                            <div style={{ display:'flex', flexDirection:'column', overflowWrap:'break-word',  wordWrap: 'break-word',  wordBreak: 'break-word', gap:'10px', }}>
                                {props.cardinfo.moreinfo && props.cardinfo.moreinfo.map((singleinfo)=>{
                                    return (
                                        <div style={{ display:'flex', gap:'10px', fontSize:'12pt',alignItems:'center' }}>
                                        
                                            <div style={{ minWidth:'15px', maxWidth:'15px' , }}>
                                                <img src={Check} width={'100%'} height={'auto'} />
                                            </div>
                                            <h5 style={{fontWeight:'400', }} dangerouslySetInnerHTML={{ __html: singleinfo }} />
                                            

                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>



                <div style={isMobile?{margin:'30px auto 5px 0px' ,position:'absolute',  bottom:'0'}:{  margin:'30px auto 10px 20px' ,position:'absolute',  bottom:'0' }}>
                    {props.cardinfo.normalprice && 
                        <div style={{fontSize:'10pt',  display:'flex', gap:'10px',  alignItems:'center',  }}>
                            <h5>Preço normal:</h5>
                            <h5 style={{fontWeight:'300', textDecoration:'line-through', color: 'red'}}>{props.cardinfo.normalprice?`R$ ${props.formatpricetoshow(props.cardinfo.normalprice)}`:''}</h5>
                        </div>
                    }
                    <div style={isMobile?{fontWeight:'600',fontSize:'12pt',  gap:'10px', alignItems:'center',}:{ fontWeight:'600',fontSize:'15pt', display:'flex', gap:'10px', alignItems:'center', }}>
                        <h5>Preço promocional:</h5>
                        <h5 style={{ fontSize:'15pt',  color:'green'}}>R$ {props.formatpricetoshow(props.cardinfo.promoprice)||''}</h5>
                    </div>
                </div>

                <div onClick={()=>{props.setpage(2)}} 
                    style={isMobile?
                        {
                            display:'flex',
                            alignItems:'center',
                            alignContent:'center',
                            justifyContent:'center',
                            justifyItems:'center',
                            padding:'5px 10px 5px 10px',
                            maxHeight: '100px',
                            maxWidth: '100px',
                            fontWeight:'400', 
                            backgroundColor: '#17686E',
                            borderRadius: '5px',
                            border: 'none',
                            fontSize: '12pt',
                            color: '#fff',
                            cursor: 'pointer',
          
    
                            margin:'60px 0px 5px auto'
    
                        }
                        :
                        { 
                        display:'flex',
                        alignItems:'center',
                        alignContent:'center',
                        justifyContent:'center',
                        justifyItems:'center',
                        padding:'5px 10px 5px 10px',
                        maxHeight: '100px',
                        maxWidth: '180px',
                        fontWeight:'400', 
                        backgroundColor: '#17686E',
                        borderRadius: '5px',
                        border: 'none',
                        fontSize: '12pt',
                        color: '#fff',
                        cursor: 'pointer',
      

                        margin:'30px 10px 10px auto'
                    
                        }}
                >
                    Próximo
                </div>





            </div>
        
        
        
    )
}