/* eslint-disable import/no-anonymous-default-export */
import React, {useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableInfo from './tableInfo'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import API from '../../redux/API'
import { current } from '@reduxjs/toolkit';
import { toast } from 'react-toast';
import { setUser } from '../../redux/actions/auth.action';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));



export default () => {
    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] mb:mt-[15px] xl:mt-[10px] mb-[10px] w-[272px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] h-[40px]"
    }

    const { currentUser } = useSelector(state => state.auth);
    const dispatch = useDispatch()
    const [pix, setPix] = useState('');
    const [email, setEmail] = useState('');
    const [bankID, setbankID] = useState('');
    const [agency, setagency] = useState('');
    const [accountNumber, setaccountNumber] = useState('');
    const [accountType, setaccountType] = useState('');
    const [isEmailOK, setIsEmailOK] = useState(false);
    const [avaiable, setavaiable] = useState(0);
    const [singleOrderWithdraw, setsingleOrderWithdraw] = useState({});
    const [canSend, setcansend] = useState(false);
    const [confirmation, setconfirmation] = useState(false);
    const [tdata, setTdata] = useState([]);
    const [methods, setMethods] = useState([{name:'Pix'},{name:'Transferência'}]);
    const [selectedMethod, setselectedMethod] = useState({name:'Transferência'});


    useEffect(() => {
        //console.log(JSON.stringify(singleOrderWithdraw))
    }, [singleOrderWithdraw])
    useEffect(() => {
        if(currentUser){
            setEmail(currentUser.ds_paymentemail);
        }
        else setEmail('');
    }, [currentUser])
    useEffect(() => {
        if(currentUser){
            const data ={
                id_researcher:currentUser.id === undefined ? currentUser._id : currentUser.id,
                canWithdraw:true,
            }
            API.post(`/order/getorderwithresearcher`, data)
                  .then((result) => {
                if(result.data.status === 'success'){
                //    toast.success('Sucesso.');

                let avaiableAUX = 0

                result.data.orders.map((singleOrder)=>{
                    return avaiableAUX+=singleOrder.vl_budget
                })

                setavaiable(avaiableAUX)

                }
              })
              .catch((err) => {
                // toast.error('erro ao buscar ordens')
                console.log(err);
            });
            }
    }, [currentUser])

    const setwithdraw = () => {
        if (!currentUser.ic_securitymode) {
            return toast.info('Para sacar, sua autenticação de dois fatores precisa estar ativa.\nAtive na aba "Segurança"')
        }

        const data = {
            ds_email: currentUser.ds_email,
            // ds_paymentemail: email,

            value: singleOrderWithdraw._id?singleOrderWithdraw.vl_budget:currentUser.vl_withdrawal,
            method:selectedMethod.name, 
            pix:pix,
            accountType:accountType,
            accountNumber:accountNumber,
            agency:agency,
            bankID:bankID,
            orders:singleOrderWithdraw._id?[singleOrderWithdraw]:tdata,

          }
          API.post('/user/setwithdrawinfo', data)
          .then((result) => {
            if(result.data.status === 'success'){
                toast.success('Saque solicitado.');
                setsingleOrderWithdraw({})
                dispatch(setUser(result.data.user))
                setconfirmation(false)
            }
          })
          .catch((err) => {
            toast.error(JSON.parse(err.request.response).message)
            console.log(err);
            setconfirmation(false)
        });
    }

    useEffect(()=>{
        if (tdata ) {
            if (selectedMethod.name==='Pix') {
                if (pix.length>10) {
                    return setcansend(true)
                }
            }
            else if (selectedMethod.name==='Transferência') {
                if (agency && accountNumber && accountType && bankID) {
                    return setcansend(true)
                }
            }
        }
        return setcansend(false)
    },[singleOrderWithdraw,tdata,selectedMethod,agency,accountNumber, accountType, bankID, pix])


    const handleEmail = (e)=>{
        let value = e.target.value
        setEmail(value)
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (emailRegex.test(value)) {
            return setIsEmailOK(true)
        }
        return setIsEmailOK(false)
    }


    const navigate = useNavigate()
    const [userFees,setuserFees]=useState(0)
    useEffect(()=>{
        API.post('user/gatplaninfo',{userID:currentUser._id})
        .then((res)=>{
            setuserFees(res.data.userFees)
            if (res.data.message) {
                toast.success(res.data.message)
            }
        })
        .catch((error)=>{
            toast.error(error.message?error.message:'Erro ao atualizar dados do plano')
            console.log(error);
            navigate('/account')
        })
    },[])

    return (
        <div className='mb:w-full xl:w-[95%] float-right'>
            {confirmation &&
            <div style={{display:'flex', flexDirection:'column', position:'fixed', width:'100%', height:'100%',top:'0',left:'0', zIndex:'9999', }}>
                <div style={{display:'flex', position:'fixed', width:'100%',height:'100%',top:'0',left:'0', backgroundColor:'rgba(0,0,0,0.5)', zIndex:'9999'}} onClick={()=>{setconfirmation(false)}}></div>

                    <div style={{display:'flex',flexDirection:'column', minWidth:'30%', maxWidth:'400px',   background:'white', zIndex:'9999999', borderRadius:'10px', margin:'auto', gap:'10px', padding:'10px', }} >
                        <div style={{fontSize:'19pt', textAlign:'center', fontWeight:'bold', color:'#17686E', marginBottom:'20px', }}>Confirmação de saque:</div>
                        <div style={{display:'flex', flexDirection:'column', fontSize:'15pt', }}>
                            <div style={{display:'flex', flexDirection:'column', margin:'auto'}}>
                                <div style={{display:'flex', margin:'auto auto 20px 0px', }}>Valor:<p style={{fontWeight:'bold', marginLeft:'10px'}}>{singleOrderWithdraw.vl_budget?`R$ ${Number.parseFloat(singleOrderWithdraw.vl_budget * ((userFees/-100)+1)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "*").replace('.', ',').replace('*', '.')}`:`R$ ${Number.parseFloat(currentUser.vl_withdrawal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "*").replace('.', ',').replace('*', '.')}`}</p></div>
                                <div style={{display:'flex', }}>Método:<p style={{fontWeight:'bold', marginLeft:'10px'}}>{selectedMethod.name}</p></div>
                                {selectedMethod.name==='Pix' &&
                                    <div style={{display:'flex', }}>Chave Pix:<p style={{fontWeight:'bold', marginLeft:'10px'}}>{pix?pix:'Digite a chave pix para continuar'}</p></div>
                                }
                                {selectedMethod.name==='Transferência' &&
                                    <>
                                        <div style={{display:'flex', }}>Tipo de conta:<p style={{fontWeight:'bold', marginLeft:'10px'}}>{accountType}</p></div>
                                        <div style={{display:'flex', }}>Número da conta:<p style={{fontWeight:'bold', marginLeft:'10px'}}>{accountNumber}</p></div>
                                        <div style={{display:'flex', }}>Agência:<p style={{fontWeight:'bold', marginLeft:'10px'}}>{agency}</p></div>
                                        <div style={{display:'flex', }}>Identificador do Banco:<p style={{fontWeight:'bold', marginLeft:'10px'}}>{bankID}</p></div>
                                    </>
                                }
                            </div> 
                        </div>
                        <div style={{display:'flex',flexDirection:'column', margin:'20px auto auto auto'}} >
                            <HeaderBTNStyle  onClick={()=>{setwithdraw()}}  disabled={!singleOrderWithdraw.vl_budget && (!currentUser.vl_withdrawal || currentUser.vl_withdrawal<1)} 
                            sx={{
                            '&:disabled': {
                                backgroundColor: '#C6C6C6',
                                cursor: 'not-allowed',
                            },
                            margin: 'auto auto 20px auto'
                            }}
                        >Confirmar</HeaderBTNStyle>
                        </div>
                    </div>

            </div>
            }
            <div className='w-[100%] flex mb:items-center xl:items-start  flex-col'>
                <div className='mb:w-[96%] xl:w-full flex justify-between xl:flex-row mb:flex-col-reverse'>
                    
                <div className='xl:w-[50%] flex justify-between xl:flex-col mb:flex-col' style={{padding:'10px', gap:'10px'}}>
                    <div className='mb:mt-[25px] xl:mt-[0px] mb:w-full xl:w-[100%] h-[107px]  rounded-[5px]'>
                        <div className='font-[Poppins] text-[14px] leading-[148%] font-light'>Método de pagamento</div>
                        <div className='mt-[5px]'>
                        <FormControl sx={{width:'100%', height:'40px'}}>
                            <Select
                            className="w-full h-[40px]"
                            value={selectedMethod.name}
                            label="Age"
                            onChange={(e)=>{setselectedMethod({name:e.target.value})}}
                            >
                                {methods && methods.map((singleMethod)=>{
                                    return <MenuItem value={singleMethod.name}>{singleMethod.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        </div>
                    </div>

                    {selectedMethod.name==='Pix'
                    &&
                    <div className='mb:mt-[25px] xl:mt-[0px] mb:w-full xl:w-[100%] h-[107px]  rounded-[5px]'>
                        <div className='font-[Poppins] text-[14px] leading-[148%] font-light'>Chave Pix</div>
                        <div className='mt-[5px]'>
                            <TextField
                                className="w-full h-[40px]"
                                defaultValue=''
                                value = {pix}
                                onChange = {(e)=>{setPix(e.target.value)}}
                                InputProps={{
                                    className: style.content,
                                }}
                                hiddenLabel="hiddenLabel"
                                id="outlined-basic"
                                variant="outlined"/>
                        </div>
                        <div className='bg-[#BA483A] bg-opacity-10 rounded-[5px] min-h-[26px] mt-[15px] flex items-center'>
                            <div className='bg-[#BA483A] rounded-l-[5px] mb:h-[40px] md:h-[30px] w-[10px]'></div>
                            <div className='pt-[5px] pb-[5px] ml-[7px] font-[Poppins] text-[10px] leading-[148%] font-light'>

                                <strong className="font-semibold">Importante:</strong>
                                &nbsp;Seu pagamento será realizado na chave Pix informada
                            </div>
                        </div>
                    </div>
                    }
                    {selectedMethod.name==='Transferência'
                    &&
                    <div  className='mb:mt-[25px] xl:mt-[0px] mb:w-full xl:w-[100%]  rounded-[5px]'>
                        <div style={{display:'grid', gridTemplateColumns:'repeat(auto-fit, minmax(200px, 1fr))', gap:'10px', }}>
                            <div>
                                <div className='font-[Poppins] text-[14px] leading-[148%] font-light'>Identificador do Banco</div>
                                <div className='mt-[5px]'>
                                    <TextField
                                        className="w-full h-[40px]"
                                        defaultValue=''
                                        value = {bankID}
                                        onChange = {(e)=>{setbankID(e.target.value)}}
                                            InputProps={{
                                            className: style.content,
                                        }}
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"/>
                                </div>
                            </div>
                            <div>
                                <div className='font-[Poppins] text-[14px] leading-[148%] font-light'>Agência</div>
                                <div className='mt-[5px]'>
                                    <TextField
                                        className="w-full h-[40px]"
                                        defaultValue=''
                                        value = {agency}
                                        onChange = {(e)=>{setagency(e.target.value)}}
                                            InputProps={{
                                            className: style.content,
                                        }}
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"/>
                                </div>
                            </div>
                            <div>
                                <div className='font-[Poppins] text-[14px] leading-[148%] font-light'>Número da conta</div>
                                <div className='mt-[5px]'>
                                    <TextField
                                        className="w-full h-[40px]"
                                        defaultValue=''
                                        value = {accountNumber}
                                        onChange = {(e)=>{setaccountNumber(e.target.value)}}
                                            InputProps={{
                                            className: style.content,
                                        }}
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"/>
                                </div>
                            </div>
                            <div>
                                <div className='font-[Poppins] text-[14px] leading-[148%] font-light'>Tipo de conta</div>
                                <div className='mt-[5px]'>
                                    <FormControl sx={{width:'100%', height:'40px'}}>
                                    <Select
                                    className="w-full h-[40px]"
                                    value={accountType}
                                    label="Age"
                                    onChange={(e)=>{setaccountType(e.target.value)}}
                                    >
                                        <MenuItem value={'Conta Corrente'}>Conta Corrente</MenuItem>
                                        <MenuItem value={'Poupança'}>Poupança</MenuItem>
                                    </Select>
                                </FormControl>

                                    {/* <TextField
                                        className="w-full h-[40px]"
                                        defaultValue=''
                                        value = {email}
                                        onChange = {handleEmail}
                                        InputProps={{
                                            className: style.content,
                                        }}
                                        hiddenLabel="hiddenLabel"
                                        id="outlined-basic"
                                        variant="outlined"/> */}
                                </div>
                            </div>
                        </div>
                            <div className='bg-[#BA483A] bg-opacity-10 rounded-[5px] min-h-[26px] mt-[15px] flex items-center'>
                                <div className='bg-[#BA483A] rounded-l-[5px] mb:h-[40px] md:h-[30px] w-[10px]'></div>
                                <div className='pt-[5px] pb-[5px] ml-[7px] font-[Poppins] text-[10px] leading-[148%] font-light'>

                                    <strong className="font-semibold">Importante:</strong>
                                    &nbsp;Seu pagamento será realizado na conta informada
                                </div>
                            </div>
                    </div>
                    }
                </div>

                    <div className='mt-[0px] mb:w-[96%] xl:w-[50%] h-[107px] pt-[12px] pb-[12px] mb:pl-[5px] xl:pl-[27px] mb:pr-[5px] xl:pr-[23px] bg-[#17686E]  bg-opacity-[0.11] rounded-[5px]'>
                        <div className='flex justify-between '>
                            <div className="font-[Poppins] text-[12px] leading-[18px] font-light">
                            Valor já retirado:
                            </div>
                            <div className="font-[Poppins] text-[12px] leading-[18px] font-normal">
                            R$ {Number.parseFloat(currentUser.vl_totalwithdrawn).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "*").replace('.', ',').replace('*', '.')}
                            </div>
                        </div>
                        <div className='flex justify-between mt-[10px]'>
                            <div className="font-[Poppins] text-[12px] leading-[18px] font-light">
                            Saldo em andamento
                            </div>
                            <div className="font-[Poppins] text-[12px] leading-[18px] font-normal">
                            R$ {Number.parseFloat(currentUser.vl_balanceinprogress).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "*").replace('.', ',').replace('*', '.')}
                            </div>
                        </div>
                        <div className='flex justify-between mt-[10px]'>
                            <div className="font-[Poppins] text-[12px] leading-[18px] font-semibold">
                            Saldo disponível para saque
                            </div>
                            <div className="font-[Poppins] text-[12px] leading-[18px] font-semibold">
                            R$ {Number.parseFloat(currentUser.vl_withdrawal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "*").replace('.', ',').replace('*', '.')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' mb:w-[100%] xl:w-full mt-[37px]'>
                    <div className='font-[Poppins] text-[14px] leading-[148%] font-light mb-[11px] mb:text-center xl:text-left'>Resumo de valores</div>
                    <TableInfo userFees={userFees} singleOrderWithdraw={singleOrderWithdraw} setsingleOrderWithdraw={setsingleOrderWithdraw} tdata={tdata} setTdata={setTdata}/>
                    <div className="flex mb:justify-center md:justify-end mt-[36px] mb-[16px]">
                    <HeaderBTNStyle disabled={!canSend || (!singleOrderWithdraw.vl_budget && (!currentUser.vl_withdrawal || currentUser.vl_withdrawal<1))} onClick={()=>{setconfirmation(true)}} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Sacar</HeaderBTNStyle>
                </div>
                </div>

            </div>
        </div>
    )
}