import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {NestedMenuItem} from 'mui-nested-menu';
import FilterButton from '../buttons/FilterButton';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
const TitleText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '16px',
    color: '#C6C6C6',
    borderRadius: '5px',
    whiteSpace: 'nowrap',
    paddingLeft: '10px',
    [theme.breakpoints.up('md')]: {
    }
}));

export default function BasicMenu({filtermenu, setFiltermenu, filterWithmenu}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };  

    const handleChange = (index1, index2) => {
        let temp = [...filtermenu];
        temp[index1].data[index2].checked = !temp[index1].data[index2].checked;
        setFiltermenu(temp);
        filterWithmenu();
    }

    const handleChange1 = (index, index1, index2) => {
        let temp = [...filtermenu];
        temp[index].data[index1].submenu[index2].checked = !temp[index].data[index1].submenu[index2].checked;
        if(temp[index].data[index1].submenu[index2].menu === 'Todos'){
            temp[index].data[index1].submenu.map((item, index22) => {
                temp[index].data[index1].submenu[index22].checked = temp[index].data[index1].submenu[index2].checked;
            })
        }
        setFiltermenu(temp);
        filterWithmenu();
    }
    return (
        <div>
            <FilterButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                text={'Filtro'}
                event={handleClick}
            />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >

                {
                    filtermenu.map((item, index) => {
                        return(
                        <NestedMenuItem
                            label={item.name}
                            parentMenuOpen={open}
                            sx={{
                                "& p":{
                                    fontFamily: 'Poppins',
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '24px'
                                }
                            }}
                        >
                            {
                                item.data.map((item1, index1) => {
                                    return(
                                        item1.nested === false ?
                                        <MenuItem className="h-[36px]" onClick={() => handleChange(index, index1)}>
                                            <Checkbox edge="start" sx={{'&.Mui-checked':{color:'#17686E'}}} checked={item1.checked}/>
                                            <p className="whitespace-nowrap font-[Poppins] font-normal text-[14px] leading-[24px]">{item1.menu}</p>
                                        </MenuItem>:
                                        <NestedMenuItem
                                            label={item1.menu}
                                            parentMenuOpen={open}
                                            sx={{
                                                "& p":{
                                                    fontFamily: 'Poppins',
                                                    fontWeight: 400,
                                                    fontSize: '14px',
                                                    lineHeight: '24px'
                                                }
                        
                                            }}>
                                            {
                                                item1.submenu.map((item2, index2) => {
                                                    return(
                                                        <MenuItem className="h-[36px]" onClick={() => handleChange1(index, index1, index2)}>
                                                            <Checkbox edge="start" sx={{'&.Mui-checked':{color:'#17686E'}}} checked={item2.checked}/>
                                                            {
                                                                item2.menu === 'Todos' ?
                                                                <p className="whitespace-nowrap font-[Poppins] font-semibold text-[14px] leading-[24px]">{item2.menu}</p>:
                                                                <p className="whitespace-nowrap font-[Poppins] font-normal text-[14px] leading-[24px]">{item2.menu}</p>
                                                            }
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </NestedMenuItem>
                                    )
                                })
                            }
                        </NestedMenuItem>
                        )
                    })
                }
            </Menu>
        </div>
    );
}
