//Importa o arquivo de estilo "App.css"
import './App.css';

//Importa os componentes do react-router-dom
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useState } from 'react';
import { useSelector } from 'react-redux';

//Importa os componentes de cabeçalho e rodapé
import Header from './components/header';
import Footer from './components/footer';

//Importa o container de toast do react-toast
import { ToastContainer, toast } from "react-toast";
import './components/toast/toast.css';
 
//Importa as páginas da aplicação
import Home from './pages/Home';
import Researcher from './pages/Researcher';
import Publications from './pages/Publications';
import Orders from './pages/Orders';
import PageNotFound from './pages/PageNotFound';
import SelectPlan from './pages/SelectPlan';
import UpComing from './pages/UpComing';
import ExpertProfile from './pages/ExpertProfile';
import Myorders from './pages/MyOrders/index.js';
import Update from './pages/Update'
import Notification from './pages/Notification';
import PersonalInformation from './pages/PersonalInformation';
import ContactUs from './pages/ContactUs'
import ImportDoc from './pages/ImportDoc';
import BoostOrder from './pages/BoostOrder';
import LoginForm from './pages/LoginPage';
import RegisterForm from './pages/RegisterPage';
import Testing from './pages/test';
import RequestDoc from './pages/RequestDoc'
import CookieNotification from './components/cookieNotification';
import PrivacyPolicy from './pages/privacyPolicy';
import TermsAndConditions from './pages/termsandconditions';
import FAQ from './pages/FAQ';
// import OurPlans from './pages/OurPlans';
import ImportPlans from './pages/ImportPlans';
import UnsubscriptionPage from './pages/unsubscription';
//Importa os componentes Outlet e useLocation do react-router-dom
import { Outlet, useLocation } from 'react-router-dom';
import SelectProfilePage from './pages/SelectProfilePage';
import DocumentDigital from './pages/DocumentDigital';
import EvaluateResearcher from './pages/EvaluateResearcher';
import OrderDetailResearcher from './pages/OrderDetailResearcher';
import ValidateCode from './pages/ValidateCode';
import Modal from './components/modal/SendDocument';
import Card from './pages/ValidateCode/card';
import AddDocs from './pages/ImportDoc/AddDocs';

//Importa as ações de autenticação e notificação
import {setUser, setCurrentProfile} from './redux/actions/auth.action'

//Importa o React e o hook useEffect
import React, {useEffect} from 'react';

//Importa o hook useDispatch do react-redux
import { useDispatch } from 'react-redux'
import { current } from '@reduxjs/toolkit';
import Checkout from './components/Checkout';
import RedirectAfterLogin from './components/RedirectAfterLogin/Index';

import Hotjar from '@hotjar/browser';

// Componente funcional Layout
const Layout = ({ hideHeaderPaths = [] }) => {
  // Obtendo o nome da rota atual
  const { pathname } = useLocation();
  return (
    <>
      {!hideHeaderPaths.includes(pathname) && <Header />}
      <Outlet />
      {!hideHeaderPaths.includes(pathname) && <Footer />}
    </>
  );
}

function App() {  
  // Dispatch da store do Redux
  const dispatch = useDispatch();

  const {currentUser} = useSelector(state => state.auth);
  const { currentProfile } = useSelector(state => state.auth);

  // Verifica se existe o token no localStorage ou sessionStorage
  const token = localStorage.token || sessionStorage.token;

    // useEffect para verificar se existe o token e salvar os dados do usuário na store do Redux
    useEffect(() => {
        if (token !== undefined) {
            if (token.length > 9) {
                dispatch(setUser(JSON.parse(localStorage.user)));
                
            }
            if(localStorage.currentprofile !== "undefined") dispatch(setCurrentProfile(JSON.parse(localStorage.currentprofile)))
        }
    }, [token, dispatch]);

    const checkIsLoggedIn = ()=>{
      if(currentUser){    
        return true
      }
      return false    
    }

    const checkNeedIs2Auth = ()=>{
      if(currentUser){    
          if (!currentUser.ic_emailverified) {
            return true
          }
          if(currentUser.ic_securitymode){
            const is2step = localStorage.is2step
            const has2step = localStorage.has2step

            console.log(is2step?" is2step true":"is2step false");
            console.log(has2step?" has2step true":"has2step false");
 
            if(has2step){
              if(has2step!=='true' || !is2step || is2step!=='true' ){
                
                console.log("need authentication");

                return true
              }
            }
          }
        return false
      }
    }

    // const [optionsToRedirect,setoptionsToRedirect]=useState({})
    // const [ic_optionsToRedirect,setic_optionsToRedirect]=useState(false)
    // const isTryingBuyBeforeLogin = (option)=>{
    //   if(option && option.type && option.path ){
    //     toast(option.path)
    //     setoptionsToRedirect(option)
    //     return setic_optionsToRedirect(true)
    //   }
    //   return setic_optionsToRedirect(false)
    // }
    // const checkredirect=()=>{
    //   if(ic_optionsToRedirect){
    //     return true
    //   }
    //   return false
    // }
    // useEffect(()=>{
    //   toast(ic_optionsToRedirect)
    // },[ic_optionsToRedirect])

    const isAuth = checkIsLoggedIn()
    const need2Auth = checkNeedIs2Auth()
    // const needredirect = checkredirect()
    const [has_attachments, setHas_attachments] = useState(false);
    const [progress, setProgress] = useState(100);
    const [orderID, setOrderID] = useState('');      
    const [openModal, setOpenModal] = useState(true);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout hideHeaderPaths={["/login"]} />}>


{/* FREE routes */}
              <Route exact path='/' element={<Navigate to='/home'  replace />} />
              <Route path='/home' element={<Home />} />
              <Route path="/privacy" element={ <PrivacyPolicy />  } />
              <Route exact path='/researcher' element={ <Researcher  />} />
              <Route exact path='/publications' element={ <Publications />  } />
              <Route exact path='/upcoming' element={ <UpComing />  } />
              <Route exact path='/contactus' element={ <ContactUs/>  } />
              <Route exact path='/login' element={ <LoginForm/> } />
              <Route exact path='/terms-and-conditions' element={ <TermsAndConditions />  } />
              <Route exact path='/register'>
                <Route exact path=':type' element={<RegisterForm/>} /> 
              </Route>
              <Route exact path='/selectprofile' element={<SelectProfilePage/> } />
              <Route exact path='/validatecode' element={ <ValidateCode/>} /> 
              <Route exact path='/faq' element={ <FAQ/>} />

{/* PRIVATE */}


        {isAuth?  //check if is logged 

        (<> //is
          {need2Auth ?
          <>
            <Route path="*" element={<Navigate to="/validatecode" />} />
          </>
            :
            (<>
                <Route exact path='/account' element={<PersonalInformation/>}  />
                <Route exact path='/account_user' element={<PersonalInformation type="user"/>} />
                <Route exact path='/account_researcher' element={<PersonalInformation type="expert"/>} />
                <Route exact path='/myorders' element={<Myorders /> } />
                <Route exact path='/selectPlan' element={<SelectPlan /> } />
                {/* <Route exact path='/expertprofile' element={<ExpertProfile />}>
                      <Route exact path=':id' element={<ExpertProfile />} /> 
                    </Route> */}
                <Route exact path='/order/:orderID' element={<Orders />} />
                <Route exact path='/expertprofile/:id' element={ <ExpertProfile /> }/>
                <Route exact path='/update'>
                      <Route exact path=':orderid' element={  <Update type="expert" />}  /> 
                </Route>
                <Route exact path='/createorder' element={<RequestDoc/>} />
                <Route exact path='/editorder/:orderID' element={<RequestDoc/>} />
                <Route exact path='/importorder' element={<ImportDoc/>} />
                <Route exact path='/boostorder' element={<BoostOrder/>} />
                <Route exact path='/orderdetail/:orderID' element={<DocumentDigital type="customer"/>} />
                <Route exact path='/orderdetail_researcher/:orderID' element={<OrderDetailResearcher/>} />
                <Route exact path='/update_customer/:orderID' element={<Update type="customer" />} />
                <Route exact path='/update_report_user/:orderID' element={<Update type="report_user" />} />
                <Route exact path='/update_report_researcher/:orderID' element={<Update type="report_researcher" />} />
                <Route exact path='/feedback/researcher/:researcherID/:orderID' element={<EvaluateResearcher/>} />
                <Route exact path='/notification' element={<Notification />} />
                {/* <Route exact path='/our-plans' element={<OurPlans />} /> */}
                <Route exact path='/import-plans' element={<ImportPlans />} />
                <Route exact path='/unsubscribe' element={<UnsubscriptionPage />} />
            </>)
          }

        </>)
        :
        (<> //isn't
              <Route path="*" element={<Navigate to="/login" />} />
        </>)
        }


                                  <Route exact path='/test' element={<Checkout type='test'/>} />

          </Route>
        </Routes>
        {/* {
       !window.location.pathname.startsWith("/login")? <Footer/> : null
    } */}
      </BrowserRouter>
      <div style={{ position: 'fixed', zIndex: '999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999' }}>
        <ToastContainer delay={3000} position="bottom-center" toastClassName="my-toast-container" />
      </div>
      <div style={{ position: 'relative', zIndex: 99999999999999999999999999999999999999999999999999999999999999999999999999999999999999999 }}>
        <CookieNotification/>    
      </div>
    </>
  );
}

export default App;
