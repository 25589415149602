import { useState } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
    Grid
} from '@mui/material';
// components
import Banner from './banner';
import Filter from './filter';
import Content from './content';
import { useSelector, useDispatch } from 'react-redux';
import API from '../../redux/API';
import { toast } from 'react-toast';

import { toshowflag } from '../../components/showFlags/showFlags';
import { toshowCountryName } from '../../components/showFlags/showFlags';
import { current } from '@reduxjs/toolkit';

import Loading from '../../components/loading/Loading';
import { useEffect } from 'react';
// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',

    margin: '10px auto',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
        // padding: '30px',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
        // padding: '10px'
    }
}));

const checkboxCSS = {
    '& .MuiSvgIcon-root': { fontSize: 16 },
    color: '#17686E',
    '&.Mui-checked': {
        color: '#17686E',
    }
}


// -------------------------------------------------------------


export default function Publications() {
    const navigate = useNavigate()
    const {currentUser} = useSelector(state => state.auth);
    const { width } = useWindowSize()
    const [filterList, setFilterList] = useState([])


    const [allfilters,setallfilters] = useState({
        documentType:[],
        countries:[],
        date:[],
        level:[],
        sendType:[],
        searchBar:[],
        dropbox:'',

    })


    const [loading, setLoading] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)      
    const [cardContent, setCardContent] = useState([])
    const [totalOrders, setTotalOrders] = useState('Carregando')
    const [totalPages, setTotalPages] = useState(1)    
    const [filterOne, setFilterOne] = useState([])
    const [filterTwo, setFilterTwo] = useState([])
    const [filterThree, setFilterThree] = useState([])
    const [filterFour, setFilterFour] = useState([])
    const [filterCountry , setFilterCountry] = useState([])
    var countriesOrder = []
    const [filtersBlock , setFiltersBlock] = useState(false)
    const [changePage, setChangePage] = useState(false)
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState([])
    const [filtersDropbox , setFiltersDropbox] = useState([])
    const showFlag = toshowflag
    const showCountryName = toshowCountryName
    const [orderTypeList, setOrderTypeList] = useState([]);

useEffect(()=>{
    API.get(`order/getorderboosttypes?language=${'pt'}&currency=${'Reais (BRL)'}`)
    .then((result) => {
        setOrderTypeList(result.data.boostlist)
    })
    .catch((err) => {
        console.error(err.request.response)
    });
},[]) 

    const getFilters = async(req,res)=>{                
        API.post('order/getfilterstypes')
            .then((result) => {
                if(result.data.status === 'success'){
                    //toast.success('filtros obtidos');
                    
                    setFilterOne(result.data.allDocuments)
                    setFilterTwo(result.data.profLevel)
                    setFilterThree(result.data.sendType)
                    setFilterCountry(result.data.filterCountry)

                    setFiltersDropbox([
                        result.data.allDocuments,
                        result.data.profLevel,
                        result.data.sendType,
                        result.data.filterCountry
                    ])

                }
                else{
                    toast.error('Erro ao obter filtros')
                }
            })

    }

    const getOrders = async (currentPage)=>{
        if(currentUser && currentUser.cd_researcher){
        setLoading(true)

        // bloqueia filtros enquanto pesquisa, impedindo sobrecarga do server
        setFiltersBlock(true)
        //toast.info('Filtrando sua pesquisa');
            var userID = null
            if(currentUser){
                if(currentUser.id){
                    userID=currentUser.id
                }
                else if(currentUser._id){
                    userID=currentUser._id
                }
            }
            
            API.post('order/getallorders', 
            {
            currentPage:currentPage,
            filters:allfilters,
            userID:userID
            })
                .then((result) => {
                    setLoading(false)
                    if(result.data.status === 'success'){
                        toast.success('Ordens atualizadas');
                        // map all orders, and just show  that the researcher can accept, if It is tp_ordervisibility
                        if(result.data.allOrders){
                            const aux = ([...result.data.allOrders]).map((single)=>{
                                if(single.tp_ordervisibility && single.allowUsers && single.allowUsers.length>0 && !single.allowUsers.some(singleuser=>singleuser===currentUser.cd_researcher)){
                                    return null
                                }
                                return single
                            }).filter(Boolean)
                            setCardContent(aux)
                        }
                        setTotalOrders(result.data.totalOrders)
                        setTotalPages(result.data.totalPages)
                        setFiltersBlock(false)
                        }
                    else{
                        setTotalOrders(result.data.totalOrders)
                        setFiltersBlock(false)
                    }
                })
            }
            // return navigate('/login')
    }


    const postFilters = async(item, filterType,originalName)=>{
        var aux = {...allfilters}

        if (filterType!=='dropbox') {
            
            if (aux.hasOwnProperty(filterType) && aux[filterType].includes(item)) {
                aux[filterType] = aux[filterType].filter(e => e !== item);
            } else {
                aux[filterType].push(item)
            }
        }
        else if (filterType==='dropbox') {
            aux.dropbox=item
        }
        setallfilters(aux)
        
    }


    
    const ischecked = (item,type)=>{
        if (filterList.some(obj=>obj.name === item && obj.type === type)) {
            return true    
        }
        return false
    }

    useEffect(()=>{
        getFilters()
    },[])
    useEffect(()=>{
        getOrders()
    },[allfilters])

    return (
        <RootStyle>
                        {loading && 
                <>
                    <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        zIndex: 9998, // certifique-se de que este valor é maior do que qualquer outro elemento na página
                    }}
                    >
                    <Loading />
                </div>
                </>
            }

            <Banner />
            <ContainerStyle>
                <Grid container spacing={3}>
                    {width > 1000 && 
                    <>
                        <Grid item xs={3}>
                            <Filter 
                            filterList={filterList}
                            setFilterList={setFilterList} 
                            filterOne={filterOne}
                            filterTwo={filterTwo}
                            filterThree={filterThree}
                            filterCountry={filterCountry}
                            postFilters={postFilters}
                            setFilter={setFilter}
                            showFlag={showFlag}
                            showCountryName={showCountryName}
                            filtersDropbox={filtersDropbox}
                            ischecked={ischecked}
                            filtersBlock={filtersBlock}
                            setFiltersBlock={setFiltersBlock}

                            allfilters = {allfilters}
                            setallfilters = {setallfilters}

                            checkboxCSS={checkboxCSS}
                            />
                        </Grid>

                        <Grid item xs={9}>
                            <Content
                            filterList={filterList} 
                            setFilterList={setFilterList}
                            filterOne={filterOne}
                            filterTwo={filterTwo}
                            filterThree={filterThree}
                            postFilters={postFilters}
                            filterCountry={filterCountry}
                            showFlag={showFlag}
                            showCountryName={showCountryName}
                            filtersDropbox={filtersDropbox}
                            setFilter={setFilter}
                            filtersBlock={filtersBlock}
                            setFiltersBlock={setFiltersBlock}
                            page={page}
                            setPage={setPage}
                            setTotalOrders={setTotalOrders}
                            totalOrders={totalOrders}
                            totalPages={totalPages}
                            cardContent={cardContent}
                            setCurrentPage={setCurrentPage}
                            setCardContent={setCardContent}
                            getOrders={getOrders}
                            allfilters = {allfilters}
                            setallfilters = {setallfilters}
                            orderTypeList={orderTypeList}
                            />
                        </Grid>
                    </>
                    }
                    {width <= 1000 &&
                        <Grid item xs={12}>
                            <Content 
                            filterList={filterList} 
                            setFilterList={setFilterList}
                            filtersBlock={filtersBlock}
                            setFiltersBlock={setFiltersBlock}
                            filterCountry={filterCountry}
                            showFlag={showFlag}
                            showCountryName={showCountryName}
                            filtersDropbox={filtersDropbox}
                            postFilters={postFilters}
                            page={page}
                            setPage={setPage}
                            setTotalOrders={setTotalOrders}
                            totalOrders={totalOrders}
                            totalPages={totalPages}
                            cardContent={cardContent}
                            setCurrentPage={setCurrentPage}
                            setCardContent={setCardContent}
                            getOrders={getOrders}
                            allfilters = {allfilters}
                            setallfilters = {setallfilters}
                            orderTypeList={orderTypeList}

                            />
                        </Grid>
                    }
                </Grid>
            </ContainerStyle>
        </RootStyle>
    )
}