/* eslint-disable import/no-anonymous-default-export */
import React, {useState} from 'react';
import {
    Box,
    Stack,
    Button,
} from '@mui/material';

export default ({ text, img, img1, active, event }) => {
    const [curImg, setCurImg] = useState(false);
    return (
        <div  onMouseOver={() => {setCurImg(true)}} onMouseOut={() => setCurImg(false)}>
            
        <Stack direction="row" spacing={2} className={active ? (text === 'Informações pessoais' ? 'flex justify-center w-[242px] cursor-pointer  hover:bg-[#17686e] hover:bg-opacity-[0.11] bg-[#17686E] bg-opacity-[0.11] rounded-tl-[5px] border-solid border-[1px] border-[#17686E]' : 'flex justify-center w-[242px] cursor-pointer  bg-[#17686E] bg-opacity-[0.11]  border-solid border-[1px] border-[#17686E] hover:bg-[#17686e] hover:bg-opacity-[0.11]') : 'flex justify-center w-[242px] cursor-pointer hover:bg-[#17686e] hover:bg-opacity-[0.11]'} onClick={event}>
            { 
                active || curImg?
                active?<Box component={'img'} src={img1} className='w-[18px] '/>:
                <Box component={'img'} src={img1} className='w-[15px] '/>:
                <Box component={'img'} src={img} className='w-[15px] '/>
            }
            {
                active ?
                <button className='hover:text-[#17686e] !ml-[10px] h-[51.6px] font-[Poppins] font-semibold text-[14px] leading-[148%] text-[#17686E] w-[162px] text-left '>{text}</button>:
                <button className='hover:text-[#17686e] !ml-[10px] h-[51.6px] font-[Poppins] font-normal text-[14px] leading-[148%] text-[#C6C6C6] w-[162px] text-left'>{text}</button>
            }
        </Stack>
        
        </div>
    )
}