import React, {useState, useEffect, useLayoutEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowSize from '../../hooks/useWindowSize'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { Box, TextField,InputAdornment, TextareaAutosize, Checkbox} from '@mui/material';
import WarningIcon from '../../assets/icons/Warning.svg'
import SuccessIcon from '../../assets/icons/successicon.gif'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import './style.css'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, padding: '0px 43px' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#17686E',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
    border: '1px solid #17686E'
}));



export default function Modal({ openModal, setOpenModal, data, confirmFunc, pageNum, orderINFO, isDigital, isPhysical ,isStamped , setproblemComment , problemComment , problemDoc, setproblemDoc, reportProblem, problemPage, setproblemPage}) {
    const navigate = useNavigate();

    const { width } = useWindowSize();
    const style = {
        content: "!w-[80%] !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px] !rounded-[7px]",
    }
    const [doclist, setdoclist] = useState([])
    const [doc, setdoc] = React.useState(['Nome do arquivo ou o tipo do documento...']);
    const reasonList = ['-- Selecione --', 'Este valor não cabe no meu orçamento', 'Este valor não cabe no meu orçamento e negociação recusada', 'Não estou interessado neste pesquisador', 'Outros'];
    const [reasonType, setReasonType] = useState('-- Selecione --');
    const dispatch = useDispatch();
    const [initialPage, setInitialPage] = useState(pageNum);
    const handleClose = () => {
        setOpenModal(false);
    };
    const [comment, setComment] = useState('');
    const commentChange = (e) => {
        setproblemComment(e.target.value);
    }
    const handlePreviousPage = () => {
        if(problemPage === 1)    setOpenModal(false);
        else{
            setPage(problemPage - 1)
        }
    }
    const handleChange = (event) => {
        let {
          target: { value },
        } = event;

        let tdoc = (typeof value === 'string' ? value.split(',') : value);
        let index = tdoc.indexOf('Nome do arquivo ou o tipo do documento...');
        if(index > -1)        tdoc.splice(index, 1);
        setproblemDoc(tdoc)
      }; 
    useLayoutEffect(() => {
        setInitialPage(pageNum);
        setproblemPage(pageNum);
        if(data.indexOf('Nome do arquivo ou o tipo do documento...') === -1)        data.unshift('Nome do arquivo ou o tipo do documento...');
        setdoclist(data);
        setproblemComment('');
        setproblemDoc(['Nome do arquivo ou o tipo do documento...'])
      }, [openModal]);
    const [page, setPage] = useState(openModal);
    


    const handleNextPage = (e) => {
        if(problemPage === 2){
            setOpenModal(false)
        }
        else if(problemPage===1){
            reportProblem()
            // setproblemPage(problemPage + 1);
        }
//        confirmFunc();
    }


    const handleUpdate = (e) => {
        navigate(`/update/${orderINFO.id?orderINFO.id:orderINFO._id}`)
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openModal} sx={{
                    '.MuiPaper-root': {
                        maxWidth: '837px',
                        width: '100%',
                    }
                }}
            >
                <div className='min-h-[350px]'>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
                {
                    problemPage === 1 &&
                    <DialogContent className='mt-[58px] '>
                        <div className='flex flex-col items-center'>
                            <div className='w-[90%]'>
                                <div className="font-[Poppins] font-medium text-[22px] text-[#313131] leading-[100%] w-full text-left">Reportar problema no documento</div>
                            </div>
                            <div className='w-[90%] mt-[15px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                        </div>
                        <div className='flex flex-col items-center'>
                            <div className="flex flex-col items-start w-[90%]">
                                <div className='mt-[18px] font-[Poppins] font-light text-[12px] text-[#313131] leading-[120%]'>
                                Fale diretamente com seu pesquisador para informar o erro ou problema, para que ele faça os ajustes.
                                </div>
                                <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]  w-full'>
                                Documento com problema:
                                </div>
                                <Select
                                    value={problemDoc}
                                    multiple
                                    onChange={handleChange}
                                    displayEmpty="displayEmpty"
                                    className="w-[100%] mb:h-[34px] md:h-[34px]"
                                    inputProps={{
                                        'aria-label' : 'Without label',
                                        sx:{
                                            fontFamily: 'Poppins',
                                            color: (problemDoc[0] === 'Nome do arquivo ou o tipo do documento...' ? '#C6C6C6':'#313131'),
                                            fontSize: '12px',
                                        }
                                    }}
                                    sx={{
                                    '& fieldset': {
                                        border: '1px solid #E2E4E5 !important'
                                  }}}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={{
                                        sx: {
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(23, 104, 110, 0.4) !important"
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(23, 104, 110, 0.11)"
                                            },
                                            '& .MuiMenuItem-root': {
                                                whiteSpace: 'normal',
                                                height: '30px'
                                            }
                                        }
                                    }}>
                                    {
                                        //dynamic
                                        doclist.map((item, index) => {
                                            return (
                                                
                                                <MenuItem value={item} disabled={index == 0 ? true : false}  sx={{
                                                    '&:disabled' : {
                                                        color: '#C6C6C6'
                                                    }
                                                }}>
                                                    {
                                                    index>0 &&  <Checkbox edge="start" sx={{'&.Mui-checked':{color:'#17686E'}}} checked={problemDoc.indexOf(item) > -1} />
                                                    }    
                                                        <p
                                                        className="whitespace-nowrap font-[Poppins] font-normal text-[14px] leading-[24px]">{item}</p>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                                <div className="flex justify-between items-center w-full mt-[18px]">
                                    <div className=' font-[Poppins] font-light text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                    Detalhe qual o problema com o documento para seu pesquisador:
                                    </div>
                                </div>

                                <TextareaAutosize
                                        id="standard-multiline-static"
                                        label="Multiline"
                                        onChange={commentChange}
                                        InputProps={{ disableUnderline: true }}
                                        defaultValue={problemComment}
                                        InputLabelProps={{ shrink: true }}
                                        value={problemComment}
                                        className="pt-[9px] pl-[13px] !min-h-[55px] resize-y font-[Poppins] font-normal text-[14px] leading-[24px] text-[#313131] w-[100%] !border-solid !border-[#E2E4E5] !border-[1px] rounded-[7px]"
                                    />
                            </div>
                        </div>
                    </DialogContent>
                    
                }
                {
                    problemPage === 2 &&
                    <DialogContent className='mb:mt-[50px] md:mt-[118px]  !overflow-unset '>
                    <div className='flex flex-col items-center'>
                        <div className='w-[90%] min-h-[120px] flex md:flex-row mb:flex-col items-center'>
                            <div className="b:mr-[0px] md:mr-[25px] !w-[117px]">
                                <Box component="img" src={SuccessIcon} alt="sucesso-icone" className='max-w-[117px] h-[117px]'/>
                            </div>
                            <div className='flex flex-col justify-center w-[90%]'>
                                <div className='md:mt-[0px] mb:mt-[10px] font-[Poppins] font-medium mb-text-[18px] md:text-[22px] text-[#313131] leading-[100%] mb:text-center md:text-left'>
                                Reportado com sucesso!
                                </div>
                                <div className='mb:w-[100%] md:w-[95%] mt-[20px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                                <div className="mb:w-[100%] md:w-[95%] mt-[18px] font-[Poppins] font-light text-[14px] text-[#313131] leading-[120%]  mb:text-center md:text-left">
                                As respostas do pesquisador ficarão disponível no área de atualização do pedido para você poder acompanhar.
                                </div>
                            </div>
                        </div>
                    </div>
                    </DialogContent>
                }
               
                    
                </div>
                <div className='md:mt-[0px] mb:mt-[20px]'>         
                    {
                    problemPage === 1 &&
                    <div className='flex w-full justify-center mb-[30px] mb:flex-col md:flex-row items-center'> 
                        <HeaderBTNStyle onClick={handleNextPage} disabled={problemComment === '' || (problemDoc.length === 1 && problemDoc[0] === 'Nome do arquivo ou o tipo do documento...')} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Enviar</HeaderBTNStyle>
                    </div>
                }
                {
                    problemPage === 2 &&
                    <div className='flex w-full justify-center mb-[30px] mb:flex-col md:flex-row items-center'> 
                        <HeaderBTNStyle1 onClick={handleUpdate} className="mb:mr-[0px] md:mr-[26px]" sx={{'&:disabled': {backgroundColor: '#C6C6C6',cursor: 'not-allowed'}}}>Atualizações</HeaderBTNStyle1>
                        <HeaderBTNStyle onClick={handleNextPage} sx={{'&:disabled': {backgroundColor: '#C6C6C6',cursor: 'not-allowed'}}}>Voltar para pedido</HeaderBTNStyle>
                    </div>
                }
               </div>


            </BootstrapDialog>
        </div >
    );
}
