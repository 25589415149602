import React, {useState, useEffect, useLayoutEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowSize from '../../hooks/useWindowSize'
import PartyPopperIcon from '../../assets/icons/party-popper.gif'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { Box, TextField,InputAdornment, TextareaAutosize} from '@mui/material';
import SecurityIcon from '../../assets/icons/securityicon1.svg';
import PasswordIcon from '../../assets/icons/passwordicon1.svg';
import Tooltip from '@mui/material/Tooltip';
import InformationIcon from '../../assets/icons/informationicon.svg';
import WarningIcon from '../../assets/icons/Warning.svg'
import SuccessIcon from '../../assets/icons/successicon.gif'
import {
    Grid,
    Stack,
    Divider,
} from '@mui/material';
import AmountInput from '../Input/AmountInput.js';



import API from '../../redux/API';
import { useLocation } from "react-router-dom";
import { toast } from "react-toast";

import Loading from '../loading/Loading';

import './style.css'
import { current } from '@reduxjs/toolkit';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;


    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        setIsMobile(mediaQuery.matches);
      }, []);
    

    return (
        <DialogTitle sx={{ m: 0, padding: '0px 43px' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#17686E',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
    border: '1px solid #17686E'
}));


const HeaderText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 500,
    color: '#313131',
    textAlign: 'center',
    lineHeight: '100%',
    [theme.breakpoints.up('md')]: {
        textAlign: 'left'
    },
}));

const DescriptionTextBold = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 600,
    color: '#313131',
    [theme.breakpoints.up('md')]: {
    },
}));

const Text_span = styled('span')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 500,
    color: '#DE2B2B',
}));


const GridWrapper = styled('div')(({ theme }) => ({
    padding: '40px 0px 10px 0px',
    [theme.breakpoints.up('md')]: {
        padding: '15px 3px 10px 3px'
    },
}));





export default function Modal({ openModal, setOpenModal, data, confirmFunc, pageNum, researcherID, orderID, setResult,value,setValue}) {

    const navigate = useNavigate();
    const {currentUser} = useSelector(state => state.auth);

    const { width } = useWindowSize();
    const style = {
        content: "!w-[80%] !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px] !rounded-[7px]",
    }
    const [displayAmount, setDisplayAmount] = useState('')
    const dispatch = useDispatch();
    const [initialPage, setInitialPage] = useState(pageNum);
    const handleClose = () => {
        window.location.reload()
        // setOpenModal(false);
    };
    const handleConfirm = () => {
        confirmFunc(); 
        setOpenModal(false);
        
    }
    const handlePreviousPage = () => {
        if(initialPage == 1){
            setPage(page-1);
        }
        else{
            setOpenModal(false);
        }
    }
    const handlePreviousPage1 = () => {
        setPage(page-1);
    }
    const handleToNegotiation = () => {
        setPage(2);
    }
    useLayoutEffect(() => {
        setInitialPage(pageNum);
        setPage(pageNum);
      }, [openModal]);
    const [page, setPage] = useState(openModal);
    const [vl_negotiation, setvl_negotiation] = useState(null);
    const [stateMoney, setStateMoney] = useState('false');

    const handleAmountChange = (e) => {
        let value = e.target.value;

        alert(e.target.value)
        setValue(e.target.value)

        if((value[value.length - 1] >= '0' && value[value.length - 1] <= '9') || value[value.length - 1] === '.' )     setDisplayAmount(value.replace(',', '.'))
    }

    const setFormatNumber = () => {
        if(displayAmount === '') setDisplayAmount('0,00');
        else
        {
            let displayText = Number.parseFloat(displayAmount.replace(',', '.')).toFixed(2).replace('.', ',')
            setDisplayAmount(displayText)
        }
    }
    const [comment, setComment] = useState('');
    const commentChange = (e) => {
        setComment(e.target.value);
    }







    const handleSendNegotiation = (e) => {
        setPage(3);
    }




// send negociation
    const handleConfirmNegotiation = (e) => {
        setLoading(true)
        const datatosend ={
            researcherID: data.researcherID,
            orderID: data.orderID,
            vl_negotiation: value,
            ds_comment: comment,
            userID: currentUser.id?currentUser.id:currentUser._id


        }
        
        API.post(`order/sendnegociation`, datatosend)
        .then((result) => {
            setLoading(false)
          if(result.data.status === 'success'){
              toast.success(result.data.message?result.data.message:"Sucesso");
              setResult(1);
              setPage(4);

              window.location.reload()
              
              
            }
            else{
                toast.error('Erro ao obter dados da ordem.')
                window.location.reload()
          }
        })
        .catch((err) => {
            setLoading(false)
          if(err.message === 'Request failed with status code 500'){
              toast.error('Você precisa estar logado corretamente para acessar')
          }else{
              toast.error(`Erro ao obter dados da ordem ||| ${err.message} `) 
          }

          navigate('/login')
          console.log(err);
      });

        
    }






    const handleNegotiationStart = (e) => {
        setOpenModal(false);
        // navigate(`/orderdetail/${data.orderID}`);

        window.location.reload()
    }
    const handleInitialProposal = (e) => {
        setPage(1);
    }

    const [loading, setLoading] = useState(false)

    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        setIsMobile(mediaQuery.matches);
      }, []);
    
    
    return (
        <div>

            {loading && 
                <>
                    <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        zIndex: 9998, // certifique-se de que este valor é maior do que qualquer outro elemento na página
                    }}
                    >
                    <Loading />
                </div>
                </>
            }

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openModal} sx={{
                    '.MuiPaper-root': {
                        maxWidth: '837px',
                        width: '100%',
                    }
                }}
            >
                <div className='min-h-[350px]'>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
                {
                    page === 1 &&
                    <DialogContent className='mt-[58px]  '>
                        <div className='flex flex-col items-center'>
                            <div className='mb:w-full md:w-[90%]'>
                            <div className="font-[Poppins] font-medium mb:text-[20px] md:text-[22px] text-[#313131] leading-[100%] w-full text-center">Você está prestes a aceitar uma proposta para o seu pedido.</div>
                                <div className='mt-[40px] flex items-center justify-between h-[47px]'>
                                    <div className='flex'>
                                        <div className='mr-[10px]'>
                                            <Box component='img' src={data.avatar} width='40px' height='40px'/>
                                        </div>
                                        <div>
                                            <div className="font-[Poppins] font-normal text-[14px] text-[#313131] leading-[21px]">{data.name}</div>
                                            <div className="font-[Poppins] italic font-[250] text-[14px] text-[#313131] leading-[21px]">Pesquisador</div>
                                        </div>
                                    </div>
                                    <div className='flex flex-col items-end'>
                                            <div className="font-[Poppins] font-semibold text-[14px] text-[#313131] leading-[21px]">Valor proposta </div>
                                            <div className="font-[Poppins] font-semibold text-[14px] text-[#313131] leading-[21px]">R$  {Number.parseFloat(data.price).toFixed(2).replace('.', ',')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-[92%] mt-[15px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                        </div>
                    
                        <div className="flex mt-[20px] justify-center">
                            <div className='w-[92%] flex md:flex-row mb:flex-col'>
                                <div className='flex items-center  mb:mr-[0px] md:mr-[55px]  h-[73px]'>
                                    <div className='mr-[10px]'><Box component='img' src={SecurityIcon} width="21px" height="28px"/></div>
                                    <div className='font-[Poppins] font-light text-[11px] text-[#313131] leading-[120%] md:w-[171px] mb:w-[90%]'>
                                    Seu pedido está assegurado pela Docstree. Caso não esteja satisfeito com o trabalho, reembolsaremos o valor.
                                    </div>
                                </div> 
                                <div className='flex items-center h-[73px]'>
                                    <div className='mr-[10px]'><Box component='img' src={PasswordIcon} width="21px" height="28px"/></div>
                                    <div className='font-[Poppins] font-light text-[11px] text-[#313131] leading-[120%] md:w-[171px]  mb:w-[90%]'>
                                    Liberamos o pagamento para o pesquisador apenas após sua confirmação, quando o documento for entregue.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    
                }
                {
                    page === 2 &&

                    
                    <DialogContent className='mt-[58px] '>
                        <div className='flex flex-col items-center'>
                            <div className='w-[90%]'>
                                <div className="font-[Poppins] font-medium text-[22px] text-[#313131] leading-[100%] w-full text-left">Deseja negociar o valor que quer pagar?</div>
                            </div>
                            <div className='w-[90%] mt-[15px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                        </div>
                        <div className='flex flex-col items-center'>
                            <div className="flex flex-col items-start w-[90%]">
                            <div className='mt-[18px] mb-[10px] font-[Poppins] font-light text-[12px] text-[#313131] leading-[120%]'>
                                Você pode enviar uma contra proposta para seu pesquisador com o valor desejado.
                            </div>
                                {/* <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                Valor da negociação:
                                </div>
                                <TextField
                                    id="outlined-start-adornment"
                                    value={displayAmount}
                                    onChange={handleAmountChange}
                                    onBlur={setFormatNumber}
                                    placeholder="0,00"
                                    sx={{'& .MuiOutlinedInput-root': {height: '33px', width: '177px'},
                                    '& fieldset': {
                                        border: 'none !important'
                                  }}}
                                    InputProps={{
                                        className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5]",
                                        startAdornment: <InputAdornment position="start"><span className='font-[Poppins] font-normal text-[14px] text-[#313131] leading-[148%]'>R$</span></InputAdornment>,
                                    }}
                                    /> */}

                            <Grid item xs={12} sm={12} md={5}>
                                <DescriptionTextBold>Valor da proposta <Text_span>&nbsp;*</Text_span></DescriptionTextBold>
                                <AmountInput
                                 text={'R$'} 
                                 holder={'0.00'} 
                                 expectNumber={50} 
                                 redtext={'Mínimo de proposta é R$ 50,00. Insira um valor maior. '} 
                                 setStateMoney={setStateMoney} 
                                 typeButton={1}
                                 
                                 setValue={setValue}
                                price={value}

                                 />
                            </Grid>

                                <div className="flex justify-between items-center w-full mt-[18px]">
                                    <div className=' font-[Poppins] font-light text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                        Deixe uma mensagem para o pesquisador (opcional)
                                    </div>
                                    <Tooltip title={<div className="text-center font-[Poppins] pl-[10px] pr-[10px] pt-[5px] pb-[5px] text-[15px] leading-[148%] color-[#313131]">Para que o pesquisador tenha mais informações sobre os seus antepassados, cole o link do perfil do seu antepassado do Family Search. Lembrando que o perfil do seu antepassado é público no Family Search, caso ele seja uma pessoa falecida.</div>}>
                                        <Box component={'img'} src={InformationIcon} className='w-[17px] h-[17px] mr-[10px]'/>
                                    </Tooltip>
                                </div>

                                <TextareaAutosize
                                        id="standard-multiline-static"
                                        label="Multiline"
                                        onChange={commentChange}
                                        InputProps={{ disableUnderline: true }}
                                        defaultValue={comment}
                                        InputLabelProps={{ shrink: true }}
                                        value={comment}
                                        className="pt-[9px] pl-[13px] !min-h-[55px] resize-y font-[Poppins] font-normal text-[14px] leading-[24px] text-[#313131] w-[100%] !border-solid !border-[#E2E4E5] !border-[1px] rounded-[7px]"
                                    />
                            </div>
                        </div>
                    </DialogContent>

                   
                    
                    
                }
                {
                    page === 3 &&
                    <DialogContent className='mb:mt-[50px] md:mt-[118px] !overflow-unset '>
                        <div className='flex flex-col items-center'>
                            <div className='w-[90%] min-h-[120px] flex md:flex-row mb:flex-col items-center'>
                                <div className="mb:mr-[0px] md:mr-[25px] !w-[117px]">
                                    <Box component="img" src={WarningIcon} alt="alerta-icone" className='w-[117px] h-[117px]'/>
                                </div>
                                <div className='flex flex-col justify-center w-[90%]'>
                                    <div className='font-[Poppins] font-medium mb-text-[18px] md:text-[22px] text-[#313131] leading-[100%] mb:text-center md:text-left'>
                                    Confirmação de envio de negociação
                                    </div>
                                    <div className='w-[95%] mt-[20px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                                    <div className="w-[95%] mt-[18px] font-[Poppins] font-light text-[14px] text-[#313131] leading-[120%] mb:text-center md:text-left">
                                    Caso o pesquisador rejeite sua proposta, a negociação com ele será cancelada, e os detalhes estarão disponíveis na proposta dele em seu pedido. Não será mais possível negociar com ele (a).
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    
                }
                {
                    page === 4 &&
                    <DialogContent className='mb:mt-[50px] md:mt-[118px] !overflow-unset '>
                        <div className='flex flex-col items-center'>
                        <div className='w-[90%] min-h-[120px] flex md:flex-row mb:flex-col items-center'>
                            <div className="mb:mr-[0px] md:mr-[25px] !w-[117px]">
                                    <Box component="img" src={SuccessIcon} alt="sucesso-icone" className='max-w-[117px] h-[117px]'/>
                                </div>
                                <div className='flex flex-col justify-center w-[90%]'>
                                    <div className='font-[Poppins] font-medium mb-text-[18px] md:text-[22px] text-[#313131] leading-[100%]  mb:text-center md:text-left'>
                                    Negociação enviada com secesso!
                                    </div>
                                    <div className='w-[95%] mt-[20px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                                    <div className="w-[95%] mt-[18px] font-[Poppins] font-light text-[14px] text-[#313131] leading-[120%] mb:text-center md:text-left">
                                    Agora, basta aguardar. Assim que o pesquisador responder à sua proposta, você receberá uma notificação.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    
                }
                                {
                    page === 5 &&
                    <DialogContent className='mt-[58px] '>
                        <div className='flex flex-col items-center'>
                            <div className='w-[90%]'>
                                <div className="font-[Poppins] font-medium text-[22px] text-[#313131] leading-[100%] w-full text-left">A negociação enviada foi recusada pelo pesquisador :(</div>
                                <div className='mt-[40px] flex items-center justify-between h-[47px]'>
                                    <div className='flex'>
                                        <div className='mr-[10px]'>
                                            <Box component='img' src={data.avatar} width='40px' height='40px'/>
                                        </div>
                                        <div>
                                            <div className="font-[Poppins] font-normal text-[14px] text-[#313131] leading-[21px]">{data.name}</div>
                                            <div className="font-[Poppins] italic font-[250] text-[14px] text-[#313131] leading-[21px]">Pesquisador</div>
                                        </div>
                                    </div>
                                    <div className='flex flex-col items-end'>
                                            <div className="font-[Poppins] font-semibold text-[14px] text-[#313131] leading-[21px]">Valor proposta </div>
                                            <div className="font-[Poppins] font-semibold text-[14px] text-[#313131] leading-[21px]">{data.price_negotiation ?`${Number.parseFloat(data.price_negotiation).toFixed(2).replace('.', ',')}`:data.price?`${Number.parseFloat(data.price).toFixed(2).replace('.', ',')}`:'0,00'}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-[92%] mt-[15px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                        </div>
                    
                        <div className="flex mt-[20px] justify-center">
                            <div className='w-[92%] flex flex-col'>
                                <div className='w-[90%]'>
                                <div className="font-[Poppins] font-light text-[14px] text-[#313131] leading-[120%] mt-[14px]">
                                Apesar da negociação ter sido recusada, você ainda pode aceitar a proposta inicial deste pesquisador. 
                                </div>
                                <div className="font-[Poppins] font-light text-[14px] text-[#313131] leading-[120%] mt-[10px]">
                                Se preferir, você também pode buscar uma proposta de outro pesquisador.
                                </div>
                                </div>


                            </div>
                        </div>
                    </DialogContent>
                    
                }
                </div>
                <div className='md:mt-[0px] mb:mt-[20px]'>
                {
                    page === 1 &&
                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'>
                        <HeaderBTNStyle1 onClick={handleToNegotiation} disabled={data.negotiationRefuse || data.existNegociation} className="mb:mr-[0px] md:mr-[26px]" sx={{'&:disabled': {backgroundColor: '#C6C6C6', border: '1px solid #C6C6C6', color: '#FFFFFF', cursor: 'not-allowed'}}}>Negociar</HeaderBTNStyle1>
                        <HeaderBTNStyle onClick={handleConfirm} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Aceitar proposta</HeaderBTNStyle>
                    </div>
                }
                {
                    page === 2 &&

                    <>
                    {isMobile?
                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'> 
                        <HeaderBTNStyle onClick={handleSendNegotiation} disabled={value < 50 ? true : false} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Enviar negociação</HeaderBTNStyle>
                        <HeaderBTNStyle1 onClick={handlePreviousPage} className="mb:mr-[0px] md:mr-[26px]" sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Voltar</HeaderBTNStyle1>
                    </div>
                    
                    :

                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'> 
                        <HeaderBTNStyle1 onClick={handlePreviousPage} className="mb:mr-[0px] md:mr-[26px]" sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Voltar</HeaderBTNStyle1>
                        <HeaderBTNStyle onClick={handleSendNegotiation} disabled={value < 50 ? true : false} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Enviar negociação</HeaderBTNStyle>
                    </div>
                    
                    }
                    </>
                }
                {
                    page === 3 &&

                    <>
                    {isMobile?

                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'> 
                        <HeaderBTNStyle onClick={handleConfirmNegotiation} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Confirmar negociação</HeaderBTNStyle>
                        <HeaderBTNStyle1 onClick={handlePreviousPage1} className="mb:mr-[0px] md:mr-[26px]" sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Voltar</HeaderBTNStyle1>
                    </div>
                    :
                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'> 
                        <HeaderBTNStyle1 onClick={handlePreviousPage1} className="mb:mr-[0px] md:mr-[26px]" sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Voltar</HeaderBTNStyle1>
                        <HeaderBTNStyle onClick={handleConfirmNegotiation} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Confirmar negociação</HeaderBTNStyle>
                    </div>

                    }
                    </>
                }
                {
                    page === 4 &&
                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'> 
                         <HeaderBTNStyle onClick={handleNegotiationStart} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Ver propostas</HeaderBTNStyle>
                    </div>
                }
                                {
                    page === 5 &&
                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'> 
                        {!data.ic_bidrejected && !data.isCancelledByResearcher &&<HeaderBTNStyle1 onClick={handleInitialProposal} disabled={data.negotiationAccepted} className="mb:mr-[0px] md:mr-[26px]" sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Aceitar proposta inicial</HeaderBTNStyle1>}
                        <HeaderBTNStyle onClick={handleNegotiationStart} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Ver propostas</HeaderBTNStyle>
                    </div>
                }
                </div>
                {/* <Stack flexDirection={ width > 640 ? 'row' : 'column'} justifyContent={'center'} className="items-center" >
                <HeaderBTNStyle onClick={handleConfirm}  disabled={(!verifyHuman || !email) ? true : false} sx={{'&:disabled': {backgroundColor: '#C6C6C6'}}}>Enviar</HeaderBTNStyle>
                </Stack> */}
            </BootstrapDialog>
        </div >
    );
}
