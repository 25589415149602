import { styled } from '@mui/material/styles';
import { Stack, FormControlLabel, FormGroup, Checkbox } from '@mui/material';
// -------------------------------------------------------------
const FilterTitleStyle = styled('p')({
    color: '#313131',
    fontFamily: 'Poppins',
    fontSize: '14px',
    margin: '20px 0px 10px',
    fontWeight: 600,
});
// -------------------------------------------------------------

export default function Filter6(props) {
    // { filter }


    var filter = props.filter
    var filterList = props.filterList
    var setFilterList = props.setFilterList
  

    const handleFilterList = (item) => {
        let auxName = 0
        if(item==='Especialista'){
            auxName= 1
        }
        else if(item==='Assessor'){
            auxName= 2
        }

        let aux =[...filterList]
        const filterType = 'level'
        let flag = filterList.some(obj=>obj.name===item)
        if (flag) {
            aux = filterList.filter((e) => e.name != item)
        } else {
            aux.push({name:item,type:'level'})
        }
        // alert(item + ' =  ' + filterType)
        props.postFilters(auxName, filterType)
        setFilterList(aux);
    }


    
    return (
        <>
            <FilterTitleStyle>Nível exigido</FilterTitleStyle>
            <Stack>
                <FormGroup>
                    {filter.map((item, index) => (
                        <FormControlLabel key={index}

                            onChange={() => {
                                handleFilterList(item);
                            }}



                            control={
                                <Checkbox


                                checked={props.ischecked(item,'level')}


                                
                                    disabled={props.filtersBlock == true}

                                    sx={props.checkboxCSS}
                                    />
                            }
                            label={item} size="small"
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontFamily: 'Poppins',
                                    fontSize: '14px',
                                    color: '#313131',
                                }
                            }}
                        />
                    ))}
                </FormGroup>
            </Stack>
        </>
    )
}