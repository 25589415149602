import { styled } from '@mui/material/styles';
import {
    Grid,
    Box,
    Stack,
    Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom'
// components
import ProposalButton from '../../components/buttons/ProposalButton';
import WarningIcon from '../../assets/icons/Warning.svg'

// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const ButtonWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '84px 0px 80px',

    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
    }
}));

const ContentWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '81px 10px 0px',

    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
        padding: '81px 25% 0px 18%'
    }
}));

const Header = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '30px',
    fontWeight: 400,
    color: '#313131',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
        textAlign: 'center',
        fontSize: '40px',
    },
    [theme.breakpoints.up('md')]: {
        textAlign: 'left',
        fontSize: '50px',
    },
}));

const Description = styled('div')(({ theme }) => ({
    width: '100%',
    fontFamily: 'Poppins',
    fontSize: '10px',
    fontWeight: 300,
    color: '#313131',

    paddingTop: '48px',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
        width: '100%',
        textAlign: 'center',
        fontSize: '12px',
    },
    [theme.breakpoints.up('md')]: {
        width: '80%',
        textAlign: 'left',
        fontSize: '14px',
    },
}));

const headerData = [
    'Página em construção',
    'Tivemos problema no carregamento'
]

export default function UpComing() {
    const navigate = useNavigate();

    return (
        <RootStyle>
            <ContentWrapper>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <Stack flexDirection={'row'} justifyContent={'center'}>
                            <Box component={'img'} width={'117px'} src={WarningIcon} />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <Header>{headerData[0]}</Header>
                        <Divider sx={{ marginTop: '15px' }} />
                        <Description>
                            Está página ainda está em construção e em breve será atualizada para trazer mais informações para nossos usuários.
                        </Description>
                    </Grid>♠
                </Grid>
            </ContentWrapper>
            <ButtonWrapper>
                <ProposalButton type={1} fontsize={14} text={'Voltar ao início'} event={() => navigate('/')} />
            </ButtonWrapper>
        </RootStyle>
    )
}