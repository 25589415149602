/* eslint-disable no-duplicate-case */

// Constante que armazena dados iniciais com relação à linguagem e especialidade do usuário
const data = {
    // Array para armazenar linguagens do usuário
    language: [],
    // Array para armazenar especialidades do usuário
    speciality: []
};
    
    // Função exportada padrão com o estado inicial data e uma ação como parâmetro
// eslint-disable-next-line default-param-last
export default function updateReducer(state = data, action) {
    // Cria uma cópia do estado atual
    const temp = { ...state };
    // Switch statement para identificar qual ação está sendo executada
    switch (action.type) {
        // Se a ação for "setMyLanguage", define o valor de temp.language como o dado passado na ação
        case "setMyLanguage":
            temp.language = action.data;
            return temp;
        // Se a ação for "deleteMyLanguage", remove a linguagem específica do array temp.language
        case "deleteMyLanguage":
            const language = [...temp.language];
            language.splice(language.indexOf(action.data), 1);
            temp.language = language;
            return temp;
        // Se a ação for "setMySpeciality", define o valor de temp.speciality como o dado passado na ação
        case "setMySpeciality":
            temp.speciality = action.data;
            return temp;
        // Se a ação for "deleteMySpeciality", remove a especialidade específica do array temp.speciality
        case "deleteMySpeciality":
            const speciality = [...temp.speciality];
            //console.log(action.data);
            speciality.splice(speciality.indexOf(action.data), 1);
            temp.speciality = speciality;
            return temp;
        // Se nenhuma ação correspondente for encontrada, retorna o estado atual
        default:
            return temp;
    }
}