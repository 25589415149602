import { useState, useEffect } from 'react'
import logo from '../../assets/image/logo.svg'
import useWindowSize from '../../hooks/useWindowSize'
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react';
import { Link as RouterLink, redirect } from 'react-router-dom'; 
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/actions/auth.action'
import { gapi } from 'gapi-script';
import DropDownIcon from '../../assets/icons/dropdown_icon.svg'
import NotificationIcon from '../../assets/icons/alerticon2.svg'
import OutsideAlerter from '../../hooks/OutSideAlerter';
import {IMAGE_BASE} from '../../config/constants'
import UpImage from '../../assets/icons/Polygon_up.svg'
import NoProfileIcon from '../../assets/icons/noprofileicon.png'
import API from '../../redux/API';
import { setCurrentProfile } from '../../redux/actions/auth.action';
import { setNotification } from '../../redux/actions/notification.action';
import { useLayoutEffect } from 'react';
import './style.css'
import { toast } from 'react-toast';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/styles";


import { CheckPrifile2 } from '../validateProfile2/checkProfile';

// -------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    padding: '20px 0 20px',
    boxShadow: '0px 6px 24px #00000014',
    background: 'white',
    zIndex: 999,
    top: '0px',
    position: 'sticky',
}));


const scrolLayout = makeStyles((theme) => ({
    root: {
      "&::-webkit-scrollbar": {
        height: '2px',
      },
      "&::-webkit-scrollbar-track": {
          borderRadius: '20px',
          backgroundColor: 'rgb(217,217,217,0.3)'
  
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7A7A7A",
        borderRadius: '20px',
      },
    },
    root1: {
      "&::-webkit-scrollbar": {
        width: '2px',
      },
      "&::-webkit-scrollbar-track": {
          borderRadius: '5px',
          backgroundColor: 'rgb(217,217,217,0.3)'
  
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7A7A7A",
        borderRadius: '5px',
      },
    },
  }));


const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: '0px 15px',
    margin: '0px auto',
    [theme.breakpoints.up('sm')]: {
        // padding: '0px 30px',
        flexDirection: 'row',
        width: '90%',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
        // padding: '0px'
    }
}));

const InnerContainerStyle = styled('div')(({ theme }) => ({
    width: '85%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '10px',
    marginRight: '10px',
}));

const MenuWrapperStyle = styled('div')(({ theme }) => ({
    display: 'flex',
}));

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#17686E',
    border: '1px solid #17686E',
    borderRadius: '5px',
    backgroundColor: '#fff',
    fontSize: '12px',
    padding: '10px',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
        fontSize: '15px',
        padding: '10px',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '17px',
        padding: '15px',
    },
}));

const MenuItemStyle = styled('p')(({ theme }) => ({
    color: '#313131',
    fontFamily: 'Poppins',
    fontSize: '16px',
    padding: '0 10px',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline'
    }
}));

const borderStyles = {
    borderTop: '1px solid #C6C6C6'
  };

// -------------------------------------------------------------

const MobileMenu = styled('div')(({ theme }) => ({

    position: 'fixed',
    top: 0,
    left: 0,
    right: 'inherit',
    zIndex: 1100,
    width: 0,
    height: '100%',
    transition: 'all 0.5s ease 0s',
    overflow: 'auto',
    background: '#FFFFFF',
    '& > div': {
        padding: 20
    }
}));

const MobileItem = styled('div')(({ theme }) => ({

    fontSize: 16,
    marginTop: 15,
    color: 'black',
    cursor: 'pointer'
}));

const Overlay = styled('div')(({ theme }) => ({

    position: 'fixed',
    zIndex: 1000,
    width: '100%',
    height: '100%',
    background: 'rgb(0 0 0 / 40%)',
    opacity: 1,
    transition: 'opacity 0.3s ease 0s',
    top: 0,
    left: 0
}));
const Ellipse = styled('div')(({ theme }) => ({
    position: 'absolute',
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    top: '10px',
    left: '15px'
}));

const AvatarWrapper = styled('div')({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
});

const IconWrapper = styled('div')(({ theme }) => ({

    display: 'flex',
    alignItems: 'center'
}));

const MobileMenuIcon = styled('div')(({ theme }) => ({

    marginRight: 10,
    ariaLabel: "delete",
    size: "small",
    display: 'block',
}));

const CustomLink = styled(RouterLink)(({ theme }) => ({
    // ...theme.typography.subtitle2,
    // height: 44,
    // color: 'inherit',
    // overflow: 'hidden',
    // WebkitLineClamp: 2,
    // display: '-webkit-box',
    // WebkitBoxOrient: 'vertical',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }));
  
// -------------------------------------------------------------

export default function Header(props) {
    const classes = scrolLayout();

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const {currentUser} = useSelector(state => state.auth);
    const {notification} = useSelector(state => state.notification);
    const { width } = useWindowSize()
    const [isOpen, setIsOpen] = useState(false)
    // const [isPopOverlay, setIsPopOverlay] = useState(false)
    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID
    const [readStatus, setReadStatus] = useState(false)

    useEffect(() => {
        const initClient = () => {
              gapi.client.init({
              clientId: clientId,
              scope: 'profile'
            });
         };
         gapi.load('client:auth2', initClient);
     });

    const menuList1 = [
        { name: 'Como funciona' },
        { name: 'Seja pesquisador' },
        { name: 'Ajuda' }
    ]

    const menuList2 = [
        { name: 'Cadastre-se' },
        { name: 'Entrar' }
    ]

    const [menuShow, setMenuShow] = useState(false);
    const [menuShow1, setMenuShow1] = useState(false);
    const [unviewed, setUnviewed] = useState(false);

    useLayoutEffect(()=>{
        if(notification){
            setUnviewed(false);
            notification.map((item) => {
                if(!item.ic_viewed) setUnviewed(true);
            })
        }
    }, [notification]);
    const menuToggle = () => {
        setMenuShow(!menuShow);
    }
    const menuToggle1 = () => {
        setMenuShow1(!menuShow1);
    }
    const [shownotification, setshownotification] = useState(false);
    const [shownotification1, setshownotification1] = useState(false);
    const getPassedday = (date) => {
        const current = new Date(new Date().toUTCString());
        let  offset = Math.floor((current-new Date(date))/1000/60/60/24);
        if(offset === 0) return 'hoje';
        else return `há ${offset} dia`;
    }
    const location = useLocation();

    const setViewed = (item) => {
        API.post(`user/setviewednotification`, {id_customer:item.id_customer, _id:item._id})
        .then((result) => {
            if (result.data.status === "success") {
                dispatch(setNotification(result.data.notifications));
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const { currentProfile } = useSelector(state => state.auth);



    useEffect(()=>{
        if(currentUser && currentUser._id){
            //console.log(currentUser);
            API.post(`user/getnotification`,{currentUser:currentUser}) 
            .then((result) => {
                if (result.data.status === "success") {
                    dispatch(setNotification(result.data.notifications));
                    // let temp = result.data.totalcount;
                    // if(temp % 5 !== 0){
                    //     setTotalCnt(Math.floor(temp / 5) + 1 );
                    // }
                    // else setTotalCnt(temp / 5);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }, [currentUser, currentProfile])


    
    // conferindo se está criando novo perfil + alicando condições
    const [isProfile2, setIsProfile2] = useState(false)
    
    useEffect(() => {
        if(currentUser && currentProfile){
                var checkPrifile2 = false
                var checkPrifile2 = CheckPrifile2(currentUser, currentProfile)

                if(checkPrifile2 === true){
                    setIsProfile2(true)
                    navigate('/account')
                }
                else{
                    setIsProfile2(false)
                }
            }
            else{
                setIsProfile2(false)
            }

        }, [currentUser, currentProfile]);
        
        
    // impedindo cliques, quando criando novo perfil
          const handleClick = (event) => {
            if (isProfile2 == true) {
                toast.info('Para acessar os recursos, salve sua conta com o novo perfil.')
              event.preventDefault();
              event.stopPropagation();
    
            }
          };            

    return (
        <>
            <RootStyle>
                <ContainerStyle
                                
                // onLoad={chProfile2()}
                                
                >
                    {(((!currentUser || currentProfile !== 1) && width < 1100) || (currentUser && currentProfile === 1&& width < 1100)) && (
                        <IconWrapper>
                            <MobileMenuIcon onClick={()=>{handleClick(); setIsOpen(true)}}>
                                <Icon icon="pajamas:hamburger" color="#17686e" height="30" />
                            </MobileMenuIcon>
                        </IconWrapper>
                    )}
                    {width > 200 &&             
                    
                            currentUser ? 
                            <CustomLink to='/myorders'>
                                <Box onClick={handleClick} component="img" src={logo} title="site-logo" alt="DocsTree-logo" width="152px" height="45px" />
                            </CustomLink> 
                            :
                            <CustomLink to='/home'>
                                <Box onClick={handleClick} component="img" src={logo} title="site-logo" alt="DocsTree-logo" width="152px" height="45px" />
                            </CustomLink>
                    }
                    {
                        width>200 &&(width<900 || (width < 1400 && currentUser && currentProfile === 1)) && 
                                (currentUser && width<900 &&
                                <div className='flex relative'>
                                    <div className='flex h-[41px] items-center relative w-fit cursor-pointer mr-[25px]' onClick={menuToggle1}>
                                        <div className='rounded-[20px]'>
                                            <Box component='img' src={IMAGE_BASE+(currentProfile === 0 ? `accounts/${currentUser._id?currentUser._id:currentUser.id}/${currentUser.nm_customeravatarfile}` : `accounts/${currentUser._id?currentUser._id:currentUser.id}/${currentUser.nm_researcheravatarfile}`)} className='w-[41px] h-[41px] rounded-[20px]'></Box>
                                        </div>
                                        {
                                            menuShow1 && 
                                            <div className="top-[45px] absolute right-[5px] w-[315px] ">

                                            <OutsideAlerter event={()=>setMenuShow(false)} className="h-fit">

                                                <div className="drop-shadow  xl:ml-[0px]">
                                                    <Box component={'img'} src={UpImage} className="ml-[288px] z-0"/>
                                                    <div className="bg-[#FFFFFF] rounded-[5px] w-full mt-[-2px] z-10 flex flex-col justify-around">
                                                        
                                                        <div className="flex justify-start hover:bg-[#17686E] hover:bg-opacity-30 z-30 cursor-pointer"  onClick={()=>{dispatch(setCurrentProfile(currentProfile ? 1 : 0))}}>
                                                            <div className="flex min-h-[56px] items-center">
                                                                
                                                                <Box component={'img'} src={IMAGE_BASE+(currentProfile === 0 ? `accounts/${currentUser._id?currentUser._id:currentUser.id}/${currentUser.nm_customeravatarfile}` : `accounts/${currentUser._id?currentUser._id:currentUser.id}/${currentUser.nm_researcheravatarfile}`)}  className='w-[41px] h-[41px] rounded-[20px] ml-[6px]'/>
                                                                <div className='flex flex-col justify-between ml-[10px]'>
                                                                    <div className="font-[Poppins] font-semibold text-[14px] leading-[21px] text-[#313131]">{currentProfile ? (currentUser.ic_researchertype ? currentUser.nm_user : currentUser.nm_company) : currentUser.nm_user}</div>
                                                                    <div className="font-[Poppins] font-light text-[14px] leading-[21px] text-[#313131]">{!currentProfile ? 'Cliente' : 'Pesquisador'}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div onClick={()=>{handleClick(); navigate(currentProfile === 1 ? `/expertprofile/${currentUser._id?currentUser._id:currentUser.id}` : '/myorders')}} className='h-[40px] items-center flex justify-center w-full'><div className="w-full text-center hover:text-[#17686E] hover:font-semibold cursor-pointer font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]">Meu Perfil</div></div>
                                                        <div onClick={()=>{handleClick(); navigate('/account'); window.scrollTo(0, 0);}} className='h-[40px] items-center flex justify-center w-full'><div className="w-full text-center hover:text-[#17686E] hover:font-semibold cursor-pointer font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]">Configurações</div></div>
                                                        <div onClick={()=>{handleClick(); navigate('/contactus'); window.scrollTo(0, 0);}} className='h-[40px] items-center flex justify-center w-full'><div className="w-full text-center hover:text-[#17686E] hover:font-semibold cursor-pointer font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]">Ajuda</div></div>
                                                        <div className='border-container'>
                                                            <div style={borderStyles}></div>
                                                        </div>                                                        
                                                        <div className="flex justify-start hover:bg-[#17686E] hover:bg-opacity-30 z-30  cursor-pointer"  onClick={()=>{dispatch(setCurrentProfile(currentProfile ? 0 : 1));navigate('/myorders')}}>
                                                            <div className="flex min-h-[56px] items-center">
                                                                
                                                            <Box component={'img'} src={currentUser.ic_profile !== 2 ? ((currentProfile === currentUser.ic_profile) ? NoProfileIcon : (IMAGE_BASE+`accounts/${currentUser._id?currentUser._id:currentUser.id}/` +(currentProfile === 1 ? currentUser.nm_customeravatarfile : currentUser.nm_researcheravatarfile))) :IMAGE_BASE+`accounts/${currentUser._id?currentUser._id:currentUser.id}/`+(currentProfile === 1 ? currentUser.nm_customeravatarfile : currentUser.nm_researcheravatarfile)}  className='w-[41px] h-[41px] rounded-[20px] ml-[6px]'/>
                                                                <div className='flex flex-col justify-between ml-[10px]'>
                                                                    <div className="font-[Poppins] font-semibold text-[14px] leading-[21px] text-[#313131]">{!currentProfile ? (currentUser.ic_researchertype ? currentUser.nm_user : currentUser.nm_company) : currentUser.nm_user}</div>
                                                                    <div className="font-[Poppins] font-light text-[14px] leading-[21px] text-[#313131]">{currentProfile ? 'Cliente' : 'Pesquisador'}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='border-container'>
                                                            <div style={borderStyles}></div>
                                                        </div>
                                                        <div 
                                                            onClick={() => {
                                                                dispatch(logout(navigate, '/', gapi))
                                                                setIsProfile2(false);
                                                            
                                                            
                                                            }} 
                                                            className='h-[40px] items-center flex justify-center w-full'><div className="w-full text-center hover:text-[#BA483A] hover:font-semibold cursor-pointer font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]">Sair</div></div>
                                                    </div>
                                                </div>
                                            </OutsideAlerter>
                                            </div>
                                        }
                                    </div>
                                    
                                    <AvatarWrapper>
                                        <Box component="img" src={NotificationIcon} alt="notificacao-icone" onClick={()=>{setshownotification1(true)}} className='top-[50%]'></Box>
                                        {unviewed && <Ellipse sx={{ backgroundColor: readStatus === false ? '#FE4E6E' : 'none' }} />}
                                        <div className={`absolute left-[-390px] top-[35px] z-10 ` + classes.root}
                                        
                                        style={{maxHeight:'400px', overflow:'auto'}} 
                                        >

                                        {
                                            shownotification1 &&
                                            <OutsideAlerter event={()=>setshownotification1(false)}>
                                                <div className="drop-shadow  xl:ml-[0px]">
                                                    <Box component={'img'} src={UpImage} className="ml-[390px] z-0"/>
                                                    <div className="bg-[#FFFFFF] rounded-[5px] mb:w-[95%] md:w-[443px] mt-[-2px] flex flex-col justify-around mb:ml-[35px] md:ml-[0px]">
                                                        <div className='flex justify-end items-center cursor-pointer h-[24px]'>
                                                            <div  className='z-20 font-[Poppins] font-light text-[10px] leading-[24px] text-[#313131] mr-[10px] hover:underline' onClick={()=>navigate('/notification')}>Ver todos</div>
                                                            <div className='w-[10px] h-[10px] mr-[6px] z-20 notisettingicon' onClick={()=>{navigate('/account', {state:{initial: 3}})}}></div>
                                                        </div>
                                                        <div className="font-[Poppins] font-semibold text-[14px] leading-[148%] text-[#313131] text-center mt-[-15px] hover:underline" onClick={()=>navigate('/notification')}>Notificações</div>
                                                        { unviewed && <div className='w-full border-solid border-[1px] border-[#C6C6C6] mt-[5px] mb-[15px]'></div>}
                                                        {
                                                            unviewed?
                                                            notification.map((item)=>{
                                                                if(item.ic_viewed) return;
                                                                return(
                                                                <div className="flex justify-between mb-[15px] hover:bg-[#17686E] hover:bg-opacity-10 z-30" 
                                                                    onClick={()=>{
                                                                        item.nm_redirectpage?
                                                                            window.location.href = item.nm_redirectpage
                                                                            :
                                                                            navigate('/notification')
                                                                        setViewed(item);
                                                                    }}
                                                                >
                                                                    <div className="flex min-h-[50px] items-center">
                                                                        
                                                                        <Box component={'img'} src={`${IMAGE_BASE}accounts/${item.id_user}/${item.nm_avatar}`}  className='w-[41px] h-[41px] rounded-[20px] ml-[6px]'/>
                                                                        <div className='flex flex-col justify-between ml-[10px]'>
                                                                            <div className="font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]">{item.ds_title}</div>
                                                                            <div className="font-[Poppins] font-light text-[10px] leading-[24px] text-[#C6C6C6]">{item.ds_content}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="font-[Poppins] font-light text-[10px] leading-[24px] text-[#C6C6C6] w-[72px] text-center">
                                                                        {getPassedday(item.createdAt)}
                                                                    </div>  
                                                                </div>
                                                                )
                                                            }):
                                                            <div className="font-[Poppins] font-light text-[12px] leading-[24px] text-[#C6C6C6] text-center">Não há novas notificações.</div>
                                                        }
                                                    </div>
                                                    
                                                </div>
                                                
                                             </OutsideAlerter>
                                        }
                                        </div>
                                    </AvatarWrapper>
                                </div>)
                        
                    }
                    <InnerContainerStyle className={ ((currentUser && currentProfile === 1 && width>1100) || ((!currentUser || currentProfile === 0) && width>900) ) ? '!flex': '!hidden'}>
                        <MenuWrapperStyle>
                            {/* {menuList1.map((item, index) => (
                                <MenuItemStyle key={index}>{item.name}</MenuItemStyle>
                            ))} */}

{/* NÃO LOGADO */}
                            {
                                 (!currentUser || currentProfile === undefined) &&

                                    <Link to="/home#how">
                                            <MenuItemStyle >Como funciona</MenuItemStyle>
                                    </Link>
                            } 

{/* LOGADO COMO 0 */}
                            {
                                currentUser && currentProfile == 0 && 
                                <CustomLink to='/home#how'>
                                <MenuItemStyle onClick={handleClick}>Como funciona</MenuItemStyle>
                                </CustomLink>
                                // :
                                // <CustomLink to='/researcher'>
                                // <MenuItemStyle onClick={handleClick}>Como funciona</MenuItemStyle>
                                // </CustomLink>
                            }

{/* LOGADO COMO 1 */}
                            {
                                currentUser && currentProfile == 1 && 
                                // <CustomLink to='/home'>
                                // <MenuItemStyle onClick={handleClick}>Como funciona</MenuItemStyle>
                                // </CustomLink>
                                // :
                                <CustomLink to='/researcher'>
                                <MenuItemStyle onClick={handleClick}>Como funciona</MenuItemStyle>
                                </CustomLink>
                            }

                            {
                                (!currentUser || currentProfile === undefined) &&
                                <>
                                    <CustomLink to='/researcher'>
                                        <MenuItemStyle>Seja pesquisador</MenuItemStyle>
                                    </CustomLink>
                                    <CustomLink to='/contactus'>
                                        <MenuItemStyle>Ajuda</MenuItemStyle>
                                    </CustomLink>
                                </>
                            }
                            {
                                (currentUser && currentProfile === 0 && location.pathname === '/home') && 
                                <>
                                    <CustomLink to='/researcher'>
                                        <MenuItemStyle>Seja pesquisador</MenuItemStyle>
                                    </CustomLink>
                                    <CustomLink to='/createorder'>
                                        <MenuItemStyle>Buscar documento</MenuItemStyle>
                                    </CustomLink>
                                </>
                            }
                            {
                                (currentUser && currentProfile === 0 && location.pathname !== '/home') && 
                                <>
                                    <CustomLink to='/createorder'>
                                        <MenuItemStyle onClick={handleClick}>Buscar documento</MenuItemStyle>
                                    </CustomLink>
                                    <CustomLink to='/myorders'>
                                        <MenuItemStyle onClick={handleClick}>Pedidos</MenuItemStyle>
                                    </CustomLink>
                                </>
                            }
                            {
                                (currentUser && currentProfile === 1) && 
                                <>
                                    {/* <CustomLink to='/myorders'>
                                        <MenuItemStyle>Minhas propostas</MenuItemStyle>
                                    </CustomLink> */}
                                    <div className="hover:underline" onClick={()=>{navigate('/myorders', {state: {initial: 1}})}}>
                                        <MenuItemStyle onClick={handleClick}>Minhas propostas</MenuItemStyle>
                                    </div>
                                    <CustomLink to='/importorder'>
                                        <MenuItemStyle onClick={handleClick}>Importar pedido</MenuItemStyle>
                                    </CustomLink>
                                    <CustomLink to='/publications'>
                                        <MenuItemStyle onClick={handleClick}>Buscar trabalho</MenuItemStyle>
                                    </CustomLink>
                                </>
                            }
                        </MenuWrapperStyle>
                        { (
                            <MenuWrapperStyle>
                                {!currentUser && menuList2.map((item, index) => {
                                    if(index === 0 ) return  <MenuItemStyle key={index} onClick={() => navigate('/selectprofile')}>{item.name}</MenuItemStyle>
                                    if(index === 1) 
                                    {
                                         
                                        return  <MenuItemStyle key={index} onClick={() => navigate('/login')}>{item.name}</MenuItemStyle>
                                    }
                                    
                                })}
                                {
                                    currentUser && 
                                    <>
                                    <div className='flex h-[41px] items-center relative w-fit'>
                                        <div className='flex h-[41px] items-center relative w-fit cursor-pointer' onClick={menuToggle}>
                                            <div className='rounded-[20px]'>
                                                <Box component='img' src={IMAGE_BASE+`accounts/${currentUser._id?currentUser._id:currentUser.id}/`+(currentProfile === 0 ? currentUser.nm_customeravatarfile : currentUser.nm_researcheravatarfile)} className='w-[41px] h-[41px] rounded-[20px]'></Box>
                                            </div>
                                            <div className='flex-col justify-between ml-[18px] mb:!hidden lg:!flex'>
                                                <div className='font-[Poppins] text-[14px] leading-[21px] font-normal text-[#313131] whitespace-nowrap'>{currentProfile ? (currentUser.ic_researchertype ? currentUser.nm_user : currentUser.nm_company) : currentUser.nm_user}</div>
                                                <div className='font-[Poppins] text-[14px] leading-[21px] font-[275] text-[#313131]'>{!currentProfile ? 'Cliente' : 'Pesquisador'}</div>
                                            </div>
                                            <Box component='img' src={DropDownIcon} className='ml-[40px]  '   ></Box>
                                        </div>
                                        {
                                            menuShow && 
                                            <div className="top-[35px] absolute right-[-5px] w-[315px] ">

                                            <OutsideAlerter event={()=>setMenuShow(false)} className="h-fit">

                                                <div className="drop-shadow  xl:ml-[0px]">
                                                    <Box component={'img'} src={UpImage} className="ml-[288px] z-0"/>
                                                    <div className="bg-[#FFFFFF] rounded-[5px] w-full mt-[-2px] z-10 flex flex-col justify-around">
                                                        
                                                        <div className="flex justify-start hover:bg-[#17686E] hover:bg-opacity-30 z-30 cursor-pointer" onClick={()=>{dispatch(setCurrentProfile(currentProfile ? 1 : 0))}}>
                                                            <div className="flex min-h-[56px] items-center">
                                                                
                                                                <Box component={'img'} src={`${IMAGE_BASE}accounts/${currentUser._id?currentUser._id:currentUser.id}/${currentProfile === 0 ? currentUser.nm_customeravatarfile : currentUser.nm_researcheravatarfile}`}  className='w-[41px] h-[41px] rounded-[20px] ml-[6px]'/>
                                                                <div className='flex flex-col justify-between ml-[10px]'>
                                                                    <div className="font-[Poppins] font-semibold text-[14px] leading-[21px] text-[#313131]">{currentProfile ? (currentUser.ic_researchertype ? currentUser.nm_user : currentUser.nm_company) : currentUser.nm_user}</div>
                                                                    <div className="font-[Poppins] font-light text-[14px] leading-[21px] text-[#313131]">{!currentProfile ? 'Cliente' : 'Pesquisador'}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div onClick={()=>{handleClick(); navigate(currentProfile === 1 ? `/expertprofile/${currentUser._id?currentUser._id:currentUser.id}` : '/myorders')}} className='h-[40px] items-center flex justify-center w-full'><div className="w-full text-center hover:text-[#17686E] hover:font-semibold cursor-pointer font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]">Meu Perfil</div></div>
                                                        <div onClick={()=>{handleClick(); navigate('/account')}} className='h-[40px] items-center flex justify-center w-full'><div className="w-full text-center hover:text-[#17686E] hover:font-semibold cursor-pointer font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]">Configurações</div></div>
                                                        <div onClick={()=>{handleClick(); navigate('/contactus'); window.scrollTo(0, 0);}} className='h-[40px] items-center flex justify-center w-full'><div className="w-full text-center hover:text-[#17686E] hover:font-semibold cursor-pointer font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]">Ajuda</div></div>
                                                        <div className='border-container'>
                                                            <div style={borderStyles}></div>
                                                        </div>
                                                        <div className="flex justify-start hover:bg-[#17686E] hover:bg-opacity-30 z-30  cursor-pointer" 
                                                        onClick={()=>{
                                                            
                                                            
                                                            dispatch(setCurrentProfile(currentProfile ? 0 : 1))
                                                            
                                                            
                                                            
                                                            // chechProfile2()


                                                            navigate('/account')
                                                        
                                                            
                                                            
                                                        }}

                                                        
                                                            >

                                                            <div className="flex min-h-[56px] items-center">
                                                                
                                                                <Box component={'img'} 
                                                                src={
                                                                    currentUser.ic_profile !== 2 ?
                                                                        ((currentProfile === currentUser.ic_profile) ? NoProfileIcon : (IMAGE_BASE+`accounts/${currentUser._id?currentUser._id:currentUser.id}/`+(currentProfile === 1 ? currentUser.nm_customeravatarfile : currentUser.nm_researcheravatarfile)))
                                                                      :
                                                                        `${IMAGE_BASE}accounts/${currentUser._id?currentUser._id:currentUser.id}/${currentProfile === 1 ? currentUser.nm_customeravatarfile : currentUser.nm_researcheravatarfile}`
                                                                }
                                                                     
                                                                     
                                                                className='w-[41px] h-[41px] rounded-[20px] ml-[6px]'/>
                                                                <div className='flex flex-col justify-between ml-[10px]'>
                                                                    <div className="font-[Poppins] font-semibold text-[14px] leading-[21px] text-[#313131]">{!currentProfile ? (currentUser.ic_researchertype ? currentUser.nm_user : currentUser.nm_company) : currentUser.nm_user}</div>
                                                                    <div className="font-[Poppins] font-light text-[14px] leading-[21px] text-[#313131]">{currentProfile ? 'Cliente' : 'Pesquisador'}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='border-container'>
                                                            <div style={borderStyles}></div>
                                                        </div>
                                                        <div 
                                                        onClick={() => {
                                                            dispatch(logout(navigate, '/', gapi))
                                                            setIsProfile2(false);
                                                        
                                                        
                                                        
                                                        }} 
                                                        className='h-[40px] items-center flex justify-center w-full'><div className="w-full text-center hover:text-[#BA483A] hover:font-semibold cursor-pointer font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]">Sair</div></div>
                                                    </div>
                                                </div>
                                            </OutsideAlerter>
                                            </div>
                                        }
                                        {/* <MenuItemStyle onClick={() => {dispatch(logout(navigate, gapi))}}>{"Sair"}</MenuItemStyle> */}
   
               
                                    </div>
                                    
                                    <AvatarWrapper className="ml-[46px]">
                                        <Box component="img" src={NotificationIcon} alt="notificacao-icone" onClick={()=>{setshownotification(true)}} className='top-[50%] '></Box>
                                        {unviewed && <Ellipse sx={{ backgroundColor: readStatus === false ? '#FE4E6E' : 'none' }} />}
                                        <div className={`absolute left-[-390px] top-[35px] z-10 ` + classes.root}
                                        
                                        style={{maxHeight:'400px', overflow:'auto'}} 
                                        >
                                        {
                                            shownotification &&
                                            <OutsideAlerter event={()=>setshownotification(false)} className="h-fit">
                                                <div className="drop-shadow  xl:ml-[0px]" >
                                                    <Box component={'img'} src={UpImage} className="ml-[390px] z-0"/>
                                                    <div className="bg-[#FFFFFF] rounded-[5px] w-[443px] mt-[-2px] z-10 flex flex-col justify-around">
                                                        <div className='flex justify-end items-center cursor-pointer h-[24px]'>
                                                            <div className='z-20 font-[Poppins] font-light text-[10px] leading-[24px] text-[#313131] mr-[10px] hover:underline' onClick={()=>navigate('/notification')}>Ver todos</div>
                                                            <div className='w-[10px] h-[10px] mr-[6px] z-20 notisettingicon' onClick={()=>{navigate('/account', {state:{initial: 3}})}}></div>
                                                        </div>
                                                        <div className="font-[Poppins] font-semibold text-[14px] leading-[148%] text-[#313131] text-center mt-[-15px] hover:underline" onClick={()=>navigate('/notification')}>Notificações</div>

                                                        { unviewed && <div className='w-full border-solid border-[1px] border-[#C6C6C6] mt-[5px] mb-[15px]'></div>}
                                                        {
                                                            unviewed?
                                                            notification.map((item)=>{
                                                                if(item.ic_viewed) return;
                                                                return(
                                                                <div className="flex justify-between mb-[15px] hover:bg-[#17686E] hover:bg-opacity-10 z-30"                                                                     
                                                                onClick={()=>{
                                                                    item.nm_redirectpage?
                                                                        window.location.href = item.nm_redirectpage
                                                                        :
                                                                        navigate('/notification')
                                                                    setViewed(item);
                                                                }}
                                                            >
                                                                    <div className="flex min-h-[50px] items-center">
                                                                        
                                                                        <Box component={'img'} src={`${IMAGE_BASE}accounts/${item.id_user}/${item.nm_avatar}`}  className='w-[41px] h-[41px] rounded-[20px] ml-[6px]'/>
                                                                        <div className='flex flex-col justify-between ml-[10px]'>
                                                                            <div className="font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]">{item.ds_title}</div>
                                                                            <div className="font-[Poppins] font-light text-[10px] leading-[24px] text-[#C6C6C6]">{item.ds_content}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="font-[Poppins] font-light text-[10px] leading-[24px] text-[#C6C6C6] w-[72px] text-center">
                                                                        {getPassedday(item.createdAt)}
                                                                    </div>  
                                                                </div>
                                                                )
                                                            }):
                                                            <div className="font-[Poppins] font-light text-[12px] leading-[24px] text-[#C6C6C6] text-center">Não há novas notificações.</div>
                                                        }
                                                    </div>
                                                </div>
                                            </OutsideAlerter>
                                        }
                                        </div>
                                    </AvatarWrapper>
                                    </>
                                }
                            </MenuWrapperStyle>
                        )}
                    </InnerContainerStyle>
                    {
                        !currentUser &&
                        <CustomLink to='/login'>
                            <HeaderBTNStyle>
                                Buscar documento
                            </HeaderBTNStyle>
                        </CustomLink>

                    }

                </ContainerStyle>
            </RootStyle>

            <MobileMenu sx={{ width: isOpen ? '70%' : '0px' }}>
                <Box>
                    <Box component="img" src={logo} title="DocsTree-logo" alt="logo" width="152px" height="30px" />
{/* NÃO LOGADO */}
                    {   
                        (!currentUser || currentProfile === undefined) &&
                            <CustomLink to='/home#how'>
                            <MobileItem>Como funciona</MobileItem>
                        </CustomLink>
                    }

{/* LOGADO COMO 0 */}
                    {
                        currentUser && currentProfile == 0 && 
                        <CustomLink to='/home#how'>
                        <MobileItem>Como funciona</MobileItem>
                        </CustomLink>
                        // :
                        // <CustomLink to='/researcher'>
                        // <MobileItem>Como funciona</MobileItem>
                        // </CustomLink>
                    }

{/* LOGADO COMO 1 */}
                    {
                        currentUser && currentProfile == 1 && 
                        // <CustomLink to='/home'>
                        // <MobileItem>Como funciona</MobileItem>
                        // </CustomLink>
                        // :
                        <CustomLink to='/researcher'>
                        <MobileItem>Como funciona</MobileItem>
                        </CustomLink>
                    }


                    {
                        (!currentUser || currentProfile === undefined) &&
                        <>
                            <CustomLink to='/researcher'>
                                <MobileItem>Seja pesquisador</MobileItem>
                            </CustomLink>
                            <CustomLink to='/contactus'>
                                <MobileItem>Ajuda</MobileItem>
                            </CustomLink>
                        </>
                    }
                    {
                        (currentUser && currentProfile === 0 && location.pathname === '/home') && 
                        <>
                            <CustomLink to='/researcher'>
                                <MobileItem>Seja pesquisador</MobileItem>
                            </CustomLink>
                            <CustomLink to='/createorder'>
                                <MobileItem>Buscar documento</MobileItem>
                            </CustomLink>
                        </>
                    }
                    {
                        (currentUser && currentProfile === 0 && location.pathname !== '/home') && 
                        <>
                            <CustomLink to='/createorder'>
                                <MobileItem>Buscar documento</MobileItem>
                            </CustomLink>
                            <CustomLink to='/myorders'>
                                <MobileItem>Pedidos</MobileItem>
                            </CustomLink>
                        </>
                    }
                    {
                        (currentUser && currentProfile === 1) && 
                        <>
                            <CustomLink to='/myorders'>
                                <MobileItem>Minhas propostas</MobileItem>
                            </CustomLink>
                            <CustomLink to='/importorder'>
                                <MobileItem>Importar pedido</MobileItem>
                            </CustomLink>
                            <CustomLink to='/publications'>
                                <MobileItem>Buscar trabalho</MobileItem>
                            </CustomLink>
                        </>
                    }
                    {
                    !currentUser ?
                    <>

                        <CustomLink to='/selectprofile'>
                            <MobileItem>Cadastre-se</MobileItem>
                        </CustomLink>
                        <CustomLink to='/login'>
                            <MobileItem>Entrar</MobileItem>
                        </CustomLink>
                    </>:
                    <CustomLink >
                        <MobileItem 
                        onClick={() => {
                            
                            dispatch(logout(navigate, '/', gapi))
                            setIsProfile2(false);
                    
                        }}
                        
                        
                        
                        
                        >Sair</MobileItem>
                    </CustomLink>
                    }

                </Box>
            </MobileMenu>
            {isOpen && (<Overlay onClick={() => setIsOpen(false)}></Overlay>)}
            {/* {isPopOverlay && (<Overlay sx={{ top: 121 }}></Overlay>)} */}
        </>
    )
}