import React from "react";


import * as ReactLoadingg from 'react-loadingg';
// const { BoxLoading } = ReactLoadingg;


const background = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 99998,
  backgroundColor: 'rgba(0, 0, 0, 0.2)'
};
const loadingStyles = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99999,
    };
const textLoadingStyles = {
    position: 'fixed',
    top: '55%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',


    textAlign: 'center',

    fontSize: '18px', 
    fontWeight: 'bold', 
    color: '#17686E' ,
    zIndex: 9999,

    };


export default function Loading(props) {

      return (
      <div style={background}>
                <ReactLoadingg.WaveLoading  size='large' color="#17686E" style={loadingStyles}>
                </ReactLoadingg.WaveLoading>
                  <div style={textLoadingStyles}>{props.nameToShow}</div>
        </div>
      )
    }


