import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowSize from '../../hooks/useWindowSize'
import {
    Grid,
    Box,
    Stack,
    TextareaAutosize,
    Divider,
} from '@mui/material';
import ProposalButton from '../buttons/ProposalButton';
import WarningIcon from '../../assets/icons/Warning.svg'
import SuccessIcon from '../../assets/icons/Success.svg'

import { useDispatch } from 'react-redux'
import { setResult } from '../../redux/actions/update.action';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, padding: '0px 43px' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const HeaderText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 500,
    color: '#313131',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
        textAlign: 'left'
    },
}));

const DescriptionText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 300,
    color: '#313131',
    [theme.breakpoints.up('md')]: {
    },
}));

const DescriptionTextBold = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 600,
    color: '#313131',
    [theme.breakpoints.up('md')]: {
    },
}));

const Text_span = styled('span')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 500,
    color: '#DE2B2B',
}));

const GridWrapper = styled('div')(({ theme }) => ({
    padding: '40px 0px 35px 0px',
    [theme.breakpoints.up('sm')]: {
        padding: '70px 0px 95px 0px'
    },
    [theme.breakpoints.up('md')]: {
        padding: '68px 3px 105px 3px'
    },
}));

export default function Modal({ openModal, setOpenModal, confirmFunc }) {
    const navigate = useNavigate();

    const [page, setPage] = React.useState(1);
    const [stateMoney, setStateMoney] = React.useState('false');
    const [stateDay, setStateDay] = React.useState('false');
    const { width } = useWindowSize()
    const text11 = "Ao confirmar que há algum problema com o pedido, o status será automaticamente atualizado para “<strong>Pesquisando</strong>”, necessitando que o cliente passe novamente pelas validações."
    const text12 = "Seu pagamento será efetuado assim que este problema for concluído."
    const dispatch = useDispatch();
    const [comment, setComment] = React.useState('')
    const handleClose = () => {
        setOpenModal(false);
    };

    const handleNext = () => {
        setPage(page + 1);
    };

    const handleBack = () => {
        setPage(page - 1);
    };
    const handleSubmit = () => {
        // dispatch(setResult({result: 2, comment: comment}))
        setOpenModal(false)
        confirmFunc();
    };
    const handleConfirm = () => {
        // dispatch(setResult({result: 1, comment: ""}))
        setOpenModal(false)
    };

    useEffect(() => {
        setPage(1);
      }, []);
    
    return (
        <div>
           
            {page > 0 && page < 4 &&
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openModal} sx={{
                        '.MuiPaper-root': {
                            maxWidth: '837px',
                            width: '100%',
                        }
                    }}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    </BootstrapDialogTitle>
                    <DialogContent sx={{ padding: '0px 43px !important' }}>
                        <GridWrapper className={page == 1 ? '!pb-[70px]' :'!pb-[100px]'}>
                            <Grid container spacing={2}>
                                {
                                <Grid item xs={12} sm={12} md={3}>
                                    <Stack alignItems={'center'}>
                                        
                                            {
                                               page==1 ?<Box component="img" src={WarningIcon} alt="alerta-icone" width="125px" />:
                                               <Box component="img" src={SuccessIcon} alt="sucesso-icone" width="113px" />
                                            }
                                    </Stack>
                                </Grid>
                                }
                                <Grid item xs={12} sm={12} md={8}>

                                    <HeaderText>Conta encerrada com sucesso</HeaderText>
                                    <Divider sx={{ marginTop: '19px', marginBottom: '18px', maxwidth: '90%'}}/>
                                    {page == 1 &&
                                        <div className="font-[Poppins] font-light text-[14px] leading-[120%] text-[#313131]">
                                            Tem certeza que deseja encerrar sua conta na plataforma e perder as melhores oportunidades para encontrar os documentos com segurança e praticidade?
                                            <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                            Você perderá todos as informações e preferências salvas!
                                        </div>
                                    }
                                    {page == 2 &&
                                        <div className="font-[Poppins] font-light text-[14px] leading-[120%] text-[#313131]">

                                                Você poderá retornar e se cadastrar novamente sempre que precisar, estaremos te esperando!!
                                        </div>
                                    }
                                </Grid>

                            </Grid>
                        </GridWrapper>
                    </DialogContent>
                    <Stack flexDirection={ width > 640 ? 'row' : 'column'} justifyContent={'center'} paddingTop={'20px'} className="items-center" >
                        {page == 1 &&
                            <>
                                <ProposalButton type={1} fontsize={14} text={'Voltar'} event={handleConfirm} />
                                <Box sx={{ marginLeft: '25px' }} />
                                { width < 640 ? <Box sx={{ marginBottom: '10px' }} />: null} 
                                <ProposalButton type={4} fontsize={14} text={'Fechar conta'} event={handleNext} />
                            </>
                        }
                        {page == 2 &&
                            <>
                                <ProposalButton type={1} fontsize={14} text={'Início'} event={handleSubmit} />
                            </>
                        }
                    </Stack>
                    <DialogActions></DialogActions>
                    <DialogActions></DialogActions>
                </BootstrapDialog>
            }
        </div >
    );
}
