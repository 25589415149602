/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-anonymous-default-export */
import { Rating, Box } from "@mui/material"
import MoreImg from '../../assets/image/More.png'
import { styled } from '@mui/material/styles';
import DollarIcon from '../../assets/icons/dollaricon.svg'
import ClockIcon from '../../assets/icons/clockicon.svg'
import OutsideAlerter from '../../hooks/OutSideAlerter';
import { useEffect, useState } from "react";
import UpImage from '../../assets/icons/Polygon_up.svg'
import AcceptProposal from '../../components/modal/AcceptProposal'
import DeclineProposal from '../../components/modal/DeclineProposal'
import { useNavigate } from "react-router-dom";
import DiamondIcon from '../../assets/icons/diamondicon.svg'
import DiamondIcon1  from '../../assets/icons/diamondicon1.svg'
import API from "../../redux/API";
import { toast } from "react-toast";
import { useLocation } from "react-router-dom";
import Checkout from "../../components/Checkout";

import Loading from "../../components/loading/Loading";

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    border: '1px solid #17686E',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#17686E',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    fontWeight: 600,
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle2 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#464A53',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '220px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    fontWeight: 400,
    marginTop: '11px',
    lineHeight: '21px',
    marginLeft: '20px'

}));

export default (props) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(false)
    const [openModal,setopenModal]=useState(false)
    const [cardContent,setCardContent]=useState({})
    const [checkoutData,setcheckoutData]=useState({})
    const [value, setValue] = useState(0)


    const [showProposalCtrl, setShowProposalCtrl] = useState(false);
    const {avatar, name, rating, description, level, price, deadline, negotiationRefuse, negotiationAccepted, researcherID, orderID, acceptedBID_ID, isCancelledByResearcher, existNegociation, ic_bidrejected, currentUser, dt_bid,price_negotiation} = props.data;
    const countryknowledge = props.data.countryknowledge && props.data.countryknowledge.length > 0 
        ? "Conhecimento: "+ (props.data.countryknowledge.join(', '))
        : "Conhecimento: -";
    const [result, setResult] = useState(0);
    const [openAcceptProposal, setOpenAcceptProposal] = useState(false);
    const confirmFunc = () => {
        setCardContent({
            // importNumber:5,
            title:'Iniciante',
            normalPrice:'69',
            currentPrice:'59',
            benefits:['2 país como especialidade','4 dias úteis para receber','15% de taxa de intermediação','Propostas até especialista','90 propostas mensais','10 projetos em execução'],
            isHighlighted:false,
            comment:'Perfeito para quem está iniciando sua jornada de pesquisa e possui pouco histórico!',
            image:props.imagetocard,
        })
        setcheckoutData({
            bidname:`Iniciar pesquisa`,
            bidImage:props.imagetocard,
            normalprice:'',
            promoprice:price,
            moreinfo:[`Você aceitará a proposta do pesquisador. Após isso, ele poderá começar a pesquisa, conforme solicitado.`],
        })
        setopenModal(true);

    } 

    const reasonList = [    
        '-- Selecione --',
        'Este valor não cabe no meu orçamento', 
        'Este valor não cabe no meu orçamento e negociação recusada', 
        'Não estou interessado neste pesquisador', 
        'Outros'
        ];
    
    const [reasonType, setReasonType] = useState('-- Selecione --');


    const confirmDecline = () => {
        setLoading(true)
        const data = {
            orderID:orderID,
            researcherID:researcherID,
            reasonType:reasonType,

        }
        API.post(`order/declinenegociation`, data)
        .then((result)=>{
            setLoading(false)
            if(result.data.status === 'success'){
                toast.success(result.data.message?result.data.message:"Sucesso");
                window.location.reload();
            }
            else{
                toast.error(result.data.message)
                window.location.reload();

            }
        })
        .catch((err)=>{
            toast.error(err)
            setLoading(false)
            window.location.reload();

        })

    }

    const [openDeclineProposal, setOpenDeclineProposal] = useState(false);
    const [page, setPage] = useState(1);
    const cancel = props.cancel
    
    // set order status
    useEffect(()=>{
        if(existNegociation){
            // setResult(3)
        }
        if(cancel){
            setResult(2)
        }
        if(negotiationRefuse){
            setResult(0);
        }
        if(negotiationAccepted || (acceptedBID_ID && acceptedBID_ID === researcherID)){
            setResult(1);
        }
        if(acceptedBID_ID && acceptedBID_ID !== researcherID){
            setResult(2)
        }
        if(ic_bidrejected || isCancelledByResearcher){
            setResult(2);
        }

    },[props])

    return (
        <div className={'mt-[20px] w-full rounded-[5px] border-[2px] border-solid ' +(result !== 1 ? 'border-[#C6C6C6]' : 'border-[#17686E]')}>

            {loading && 
                                <>
                                    <div
                                    style={{
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                        zIndex: 9998, // certifique-se de que este valor é maior do que qualquer outro elemento na página
                                    }}
                                    >
                                    <Loading />
                                </div>
                                </>
                            }

            {openModal && <Checkout openModal={openModal} setopenModal={setopenModal} type={'acceptproposal'} cardContent={cardContent} checkoutData={checkoutData} researcherID={researcherID} orderID={orderID}/>}

            <AcceptProposal openModal={openAcceptProposal} setOpenModal={setOpenAcceptProposal} data={props.data} confirmFunc={confirmFunc} pageNum={page} setResult={setResult} setValue={setValue} value={value}/>
            <DeclineProposal openModal={openDeclineProposal} setOpenModal={setOpenDeclineProposal} confirmFunc={confirmDecline} pageNum={1} reasonList={reasonList} setReasonType={setReasonType} reasonType={reasonType}/>
            <div className="!h-[80px] flex items-center justify-between">
                <div className='flex items-center ml-[17px]'>
                <div className='mr-[18px]' style={{borderRadius:'200px', overflow:'hidden',maxHeight: '50px',maxWidth: '50px'}}>
                    <img src={avatar} style={{ height:'100%' }} />
                </div>
                    <div className='font-[Poppins] font-[600] leading-[27px] text-[18px] text-[#000000]'>{name}</div>
                </div>

                <div className="flex flex-col items-center justify-center ml-[10%]">
                    <div className='font-[Poppins] font-[400] leading-[21px] text-[14px] text-[#313131]'>Avaliação</div>
                    <div className="mt-[0px]">                    
                        <Rating name="half-rating" readOnly defaultValue={rating} precision={0.1} sx={{
                        '& .MuiRating-iconFilled': {
                            color: '#17686E',
                        }
                    }}/></div>
                    <div className='font-[Poppins] font-[400] leading-[100%] text-[14px] text-[#313131]'>{rating ? `${rating.toFixed(2)}/5` : 'Sem classificação'}</div>
                </div>
                <div className="flex flex-col justify-evenly ml-[5%]">
                    <div  className='font-[Poppins] font-[400] leading-[21px] text-[14px] text-[#000000]'>Nível</div>
                    {
                    level === 'Assessor' && 
                        <div className="flex items-center">
                            <Box component="img" src={DiamondIcon} alt="diamante-icone" width='20px' height='20px' className="ml-[-27px]"></Box>
                            <div  className='ml-[7px] font-[Poppins] font-[600] leading-[21px] text-[14px] text-[#000000]'>{level}</div>
                        </div>
                     }

                    {
                    level === 'Especialista' &&
                        <div  className='font-[Poppins] font-[600] leading-[21px] text-[14px] text-[#000000]'>{level}</div>
                    }

                    {
                    level === 'Iniciante' &&
                        <div  className='font-[Poppins] font-[600] leading-[21px] text-[14px] text-[#000000]'>{level}</div>
                    }
                </div>
                <div className="text-center w-[40%] font-[Poppins] font-[275] leading-[21px] text-[14px] text-[#000000]">
                   {countryknowledge}
                    
                </div>
                <div className='mr-[27px] relative'>
                    <Box component='img' src={MoreImg} width='7.2px' height='20px' className='cursor-pointer' onClick={() => setShowProposalCtrl(true)}/>
                    <div className='absolute right-[-85px] top-[20px] z-10'>
                {
                    showProposalCtrl &&
                    <OutsideAlerter event={()=>setShowProposalCtrl(false)}>
                        <div className="drop-shadow  xl:ml-[0px]">
                            <Box component={'img'} src={UpImage} className="ml-[153px] z-0"/>
                            <div className="bg-[#FFFFFF] rounded-[5px] w-[250px] mt-[-2px] z-10 flex flex-col justify-around">
                                {
                                    result === 0 &&
                                    <>
                                        <div className='flex justify-center items-center cursor-pointer h-[40px]'>
                                            <div onClick={()=>{setOpenAcceptProposal(true); setPage(1);}} className='font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]'>Aceitar proposta</div>
                                        </div>
                                        <div className='flex justify-center items-center cursor-pointer h-[40px]'>
                                            <div className='font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]' onClick={()=>{setOpenDeclineProposal(true);}}>Recusar proposta</div>
                                        </div>         
                                        {
                                            negotiationRefuse  &&
                                            <div  onClick={()=>{setOpenAcceptProposal(true); setPage(5);}} className='flex justify-center items-center cursor-pointer h-[40px]'>
                                            <div className='font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]'>Mostrar negociação recusada</div>
                                            </div> 
                                            
                                        }
                                        { !existNegociation && !negotiationRefuse &&
                                            <div  onClick={()=>{setOpenAcceptProposal(true); setPage(2);}} className='flex justify-center items-center cursor-pointer h-[40px]'>
                                                <div className='font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]'>Negociar proposta</div>
                                            </div> 
                                        }
                                        { existNegociation &&
                                            <div  className='flex justify-center items-center cursor-pointer h-[40px]' style={{cursor: 'default'}}>
                                                <div className='font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]'>Negociação em andamento</div>
                                            </div> 
                                        }
                                    </>
                                }                               
                                <div className='flex justify-center items-center cursor-pointer h-[40px]'>
                                    <div className='font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]' onClick={()=>{navigate(`/expertprofile/${researcherID}`)}}>Ver Perfil</div>
                                </div>                        
                            </div>
                        </div>
                    </OutsideAlerter>
                }
                </div>
                </div>
            </div>
            
            <div className='ml-[17px] mr-[20px] fit border-solid border-[1px] border-[#C6C6C6] opacity-90'></div>
            <div className="flex">
                <div className='flex flex-col'>
                    <div className="font-[Poppins] font-[400] leading-[21px] text-[14px] text-[#000000] ml-[24px] mt-[15px]">Data da proposta: {new Date(dt_bid).toLocaleDateString()}</div>
                    <div className="font-[Poppins] font-[400] leading-[21px] text-[14px] text-[#000000] ml-[24px] mt-[15px]">Descrição da proposta:</div>
                    <div className='w-[90%] overflow-y-auto font-[Poppins] font-[400] leading-[24px] text-[12px] text-[#313131] ml-[33px] h-[110px] text-justify'>
                     {description}
                    </div>
                    {/* button to accept */}
                    <div className='mt-[15px] ml-[17px] mb-[31px]'>
                        { !cancel &&
                        <>
                            {result === 0 && <HeaderBTNStyle onClick={() => {setOpenAcceptProposal(true); setPage(1);}}>Aceitar proposta</HeaderBTNStyle>}
                            {result === 1 && <HeaderBTNStyle1 className="!cursor-default">Pesquisador escolhido</HeaderBTNStyle1>}
                            {result === 2 && <HeaderBTNStyle1 disabled sx={{'&:disabled': {backgroundColor: '#C6C6C6', color: '#FFFFFF', cursor: 'not-allowed'}}}>Proposta recusada</HeaderBTNStyle1>}
                            {result === 3 && <HeaderBTNStyle1 disabled sx={{'&:disabled': {backgroundColor: '#C6C6C6', color: '#FFFFFF', cursor: 'not-allowed'}}}>Em negociação</HeaderBTNStyle1>}
                            {negotiationRefuse && !ic_bidrejected && <HeaderBTNStyle2 onClick={() => {setOpenAcceptProposal(true); setPage(5);}}>Mostrar negociação recusada</HeaderBTNStyle2>}
                        </>
                        }   
                        {cancel  && <HeaderBTNStyle1 disabled sx={{'&:disabled': {backgroundColor: '#C6C6C6', color: '#FFFFFF', cursor: 'not-allowed'}}}>Ordem Cancelada</HeaderBTNStyle1>}                        
                    </div>
                </div>
                <div className="flex flex-col items-center justify-evenly mr-[48px] ml-auto">
                    <div className="flex justify-around items-center w-[212px] h-[80px] bg-[#17686E] bg-opacity-[0.11] rounded-[5px]">
                        <div><Box component='img' src={DollarIcon} width='25px' height='43px'></Box></div>
                        <div className="flex flex-col items-center justify-evenly h-[80%] w-[130px]">
                            <div className='font-[Poppins] font-[400] leading-[21px] text-[14px] text-[#313131]'>Valor proposto</div>
                            <div className='font-[Poppins] font-[600] text-[18px] leading-[100%] text-[#313131] mt-[5px]'>
                            {Number.parseFloat(price).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-around items-center w-[212px] h-[80px] bg-[#17686E] bg-opacity-[0.11] rounded-[5px]">
                        <div><Box component='img' src={ClockIcon} width='33px' height='43px'></Box></div>
                        <div className="flex flex-col items-center justify-evenly h-[80%] w-[130px]">
                            <div className='font-[Poppins] font-[400] leading-[21px] text-[14px] text-[#313131]'>Prazo de Entrega</div>
                            <div className='font-[Poppins] font-[600] leading-[100%] text-[18px] text-[#313131]'>
                            {Number(deadline).toLocaleString('pt-BR', { minimumFractionDigits: 0 })}
                            {' dias'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
