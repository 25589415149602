import { styled } from '@mui/material/styles';
import React, {useState, useEffect, useLayoutEffect} from 'react';
import DesktopTable from '../../components/Table/DesktopTable1.js'
import MobileTable from '../../components/Table/MobileTable1.js'
import useWindowSize from '../../hooks/useWindowSize'
import { useSelector } from 'react-redux';
import API from '../../redux/API.js';

// -------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
    }

}));

const TitleText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '100%',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    marginBottom: '5px',
    width: '159px'
}));

export default function ExpertProfile({ data, initial }) {
    const { width } = useWindowSize()
    const active = "!text-[#17686E] !font-semibold";
    const hover = "hover:text-[#17686e] ";
    const [option, setOption] = useState(false); 
    const selectOption = (value) => {
        setOption(value);
    }
    const [tdata, setTdata] = useState([]);
    const [tdata1, setTdata1] = useState([]);
    const { currentUser } = useSelector(state => state.auth);

    const statusInfo = [
        {
            status: 'Em aberto',
            flags:['Proposta enviada', 'Em negociação', 'Aguardando cliente']
        },
        {
            status: 'Em andamento',
            flags:['Pesquisando', 'Aguardando análise']
        },
        {
            status: 'Concluído',
            flags:['Pagamento em andamento', 'Concluído', 'Avaliação Disponivel','Aguardando liberar o saque' , 'Saque liberado', ]
        },
        {
            status: 'Cancelado',
            flags:['Proposta recusada', 'Cancelado']
        }
    ]
    // useLayoutEffect(() => {
    //     if(initial == true){
    //         setOption(false);
    //     }
    //     else setOption(true);
    // }, [data,initial])


    const getData = ()=>{
        const data ={
            id_researcher:currentUser.id === undefined ? currentUser._id : currentUser.id,
            option:option
        }
        API.post(`/order/getorderwithresearcher`, data)
        .then((result) => {
            if (result.data.status === "success") {

                var id_researcher = null
                
                if(currentUser.id === undefined){
                    id_researcher = currentUser._id
                }
                else{
                    id_researcher = currentUser.id
                }

                let temp = []
                result.data.orders.map((item) => {
                    let date = new Date(item.createdAt);
                    const yy = date.getFullYear() % 100;
                    let mm = date.getMonth() + 1; // Months start at 0!
                    let dd = date.getDate();

                    if (dd < 10) dd = '0' + dd;
                    if (mm < 10) mm = '0' + mm;

                    const formattedToday = dd + '/' + mm + '/' + yy;

                    date = new Date(item.updatedAt);
                    const yy1 = date.getFullYear() % 100;
                    let mm1 = date.getMonth() + 1; // Months start at 0!
                    let dd1 = date.getDate();
                    let hour = date.getHours();
                    let minute = date.getMinutes()
                    if (dd1 < 10) dd1 = '0' + dd1;
                    if (mm1 < 10) mm1 = '0' + mm1;
                    if (hour < 10) hour = '0' + hour;
                    if (minute < 10) minute = '0' + minute;
                    const formattedUpdateDate = dd1 + '/' + mm1 + '/' + yy1 + ' - '+hour + 'h'+minute;

                    let thisvalue = null

                    item.bids.map((elem)=>{
                        if(elem.id_researcher === id_researcher){
                            thisvalue = elem.vl_researcherbid
                        }
                    })
                    if(item.tp_order){
                        thisvalue = 0
                    }

                    // creating status and flags
                    var cd_orderstatus = 0
                    var cd_statusflag = 0
                    var auxBids = [...item.bids]
                    var auxEvents = [...item.event]

                        // if(item.bids.length>0){
                        //     cd_orderstatus=0
                        //     cd_statusflag=1
                        // }
                    // auxBids.map((single)=>{
                    //     if(single.id_researcher === currentUser.id?currentUser.id:currentUser._id){
                    //         if(single.ic_bidnegotiated){
                    //             cd_orderstatus=0
                    //             cd_statusflag = 1 
                    //         }
                    //         if(single.negotiation.ic_negotiationdenied){
                    //             cd_orderstatus=0
                    //             cd_statusflag = 2
                    //         }
                    //     }
                    // })

                    if(item.cd_orderstatus===3){
                        cd_orderstatus=3
                        cd_statusflag = 1
                    }else{
                                            for (let index = 0; index < auxBids.length; index++) {
                                                const single = auxBids[index];
                
                                            // }
                                                                // auxBids.map((single)=>{
                                                                    if(single.id_researcher===currentUser._id){
                                                                        if(single.ic_bidnegotiated){
                                                                            cd_orderstatus=0
                                                                            cd_statusflag = 1 
                                                                        }
                                                                        if(single.negotiation.ic_negotiationdenied){
                                                                            cd_orderstatus=0
                                                                            cd_statusflag = 2
                                                                        }
                                            
                                                                        if(single.ic_bidcanceled){
                
                                                                            cd_orderstatus=3
                                                                            cd_statusflag = 0
                                                                            item.cd_orderstatus=3 
                                                                        }
                                                                        if(single.ic_bidrejected){
                                                                            cd_orderstatus=3
                                                                            cd_statusflag = 0
                                                                            item.cd_orderstatus=3 
                                                                        }
                                                                    }
                                                                    else if(single.ic_negotiationaccepted){
                                                                        cd_orderstatus=3
                                                                        cd_statusflag = 0 
                                                                        item.cd_orderstatus=3
                                                                    }
                                                }
                                                                // })
                
                                            if(item.cd_orderstatus===1){
                                                cd_orderstatus = 1
                                                cd_statusflag = 0
                
                                                auxEvents.map((single)=>{
                                                    if(single.nm_event==='needvalidation'||single.nm_event==='needvalidationstamped'){
                                                        cd_statusflag = 1 
                                                    }
                                                    else if(single.nm_event==='isvalidatedstamped'||single.nm_event==='isvalidated'){
                                                        cd_statusflag = 0 
                                                    }
                                                })





                                            }
                    
                    

                                            if(item.tp_order){
                                                cd_orderstatus=0
                                                cd_statusflag = 2
                                            }

                                                if(item.cd_orderstatus===2){
                                                    cd_orderstatus=2
                                                    cd_statusflag = 3


                                                    if(item.ic_canwithdraw){
                                                        cd_orderstatus=2
                                                        cd_statusflag = 4

                                                    }
        
                                                    if(item.ic_iswithdrawing){
                                                        cd_orderstatus=2
                                                        cd_statusflag = 0

                                                    }
        
                                                    if(item.ic_complete || item.ic_orderpaid || item.ic_ordercomplete){
                                                        cd_orderstatus=2
                                                        cd_statusflag = 1 
                                                    }

                                                    if(item.tp_order ){
                                                        cd_statusflag = 2

                                                        if(item.ic_userreviewed){
                                                        cd_statusflag = 1
                                                        }
                                                    }
        
        
                                                }


                                if(item.id_researcher && item.id_researcher !== currentUser._id){
                                        cd_orderstatus=3
                                        cd_statusflag = 0
                                        item.cd_orderstatus=3

                                }
                        }
                    
                

                    let titem = {
                        id: item._id,
                        name: item.nm_ancestor,
                        iconflag: item.tp_order ? 2 : 1,
                        docType: item.id_documenttype.nm_documenttype!=='Outros'?'Certidão de '+item.id_documenttype.nm_documenttype:item.nm_otherdocument,
                        status: statusInfo[cd_orderstatus].status,
                        proposal: item.bids.length,
                        publicdate: formattedToday,
                        flag: item.cd_orderstatus,
                        value: thisvalue,
                        flagstatus: statusInfo[cd_orderstatus].flags[cd_statusflag],
                        updatetime: 'Utima atualização: ' + formattedUpdateDate,
                        createdAt: item.createdAt,
                        isImport:item.tp_order,
                    }
                    temp.push(titem);
                })
                
                setTdata(temp);
                setTdata1(Object.assign(temp));
            }
        })
        .catch((err) => {
            console.log(err)
            let temp = []
            setTdata(temp);
            setTdata1(Object.assign(temp));

                // toast.error(JSON.parse(err.request.response).message)
        });  

    }

    useEffect(() => {
        getData()
    }, [option])

    return (
        <RootStyle style={{ position: 'relative',  }}>
            <div className="flex mt-[20px]  mb-[20px]">
                <div>
                    <TitleText
                        className={(option ? (active + " ") :"") + hover}
                        onClick={() => selectOption(true)}>Meus trabalhos</TitleText>
                    {option ?
                    <div className="bg-[#17686E] w-[159px] h-[4px]"></div>
                    : ""}

                </div>
                <div>
                    <TitleText
                        className={(!option ? (active + " ") :"") + 'pl-[30px] '+ hover}
                        onClick={() => selectOption(false)}>Propostas</TitleText>
                    {!option ?
                    <div className="bg-[#17686E] w-[159px] h-[4px]"></div>
                    : ""}

                </div>
            </div>
            {
                option ?
                <>
                    {width > 900 && <DesktopTable key={1111} rows={tdata} />}
                    {width < 900 && <MobileTable key={1111} rows={tdata} />}
                </> :
                <>
                    {width > 900 && <DesktopTable key={2222} rows={tdata1}  />}
                    {width < 900 && <MobileTable key={2222} rows={tdata1} />}
                </> 
            }

        </RootStyle>
    )
}