/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import API from '../../redux/API';
import { toast } from 'react-toast';

import {
    Box, Divider,
} from '@mui/material';
import Item  from './member';
import VectorImg from '../../assets/image/Vector.png'
import { useSelector, useDispatch } from 'react-redux';
import { plPL } from '@mui/x-date-pickers';
import useWindowSize from '../../hooks/useWindowSize';
import { Api } from '@mui/icons-material';

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#17686E',
    border: '1px solid #17686E',
    borderRadius: '5px',
    backgroundColor: '#fff',
    fontSize: '14px',
    width: '179px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px'
    // [theme.breakpoints.up('sm')]: {
    //     fontSize: '15px',
    //     padding: '10px',
    // },
    // [theme.breakpoints.up('md')]: {
    //     fontSize: '17px',
    //     padding: '15px',
    // },
}));

export default (props) => {
    const {width} = useWindowSize()

    useEffect(()=>{
        API.post('order/getallplans',{nm_language:'BR'})
        .then((res)=>{
            if (res.data.allPlans) {
                const allPlans = res.data.allPlans
                allPlans.map((single)=>{
                    let aux = {}
                    aux.name=single.nm_plan
                    aux.text=single.plan_detail
                    aux.oldprice=single.vl_normal
                    aux.price=single.vl_current
                    aux.ic_plan=single.ic_plan
                    aux._id=single._id
                    if(single.ic_plan===1){
                        setdata(aux)
                    }
                    else if(single.ic_plan===2){
                        setdata1(aux)
                    }
                })
            }
        })
        .catch((err)=>{
            toast.error(err.message?err.message:'Erro ao buscar planos. Contate o suporte.')
        })
    },[])

    const [data,setdata] = useState({name:'',oldprice:0,price:0,ic_plan:1,text:[]})
    const [data1,setdata1] = useState({name:'',oldprice:0,price:0,ic_plan:2,text:[]})
    // const data = {
    //     name: "Especialista",
    //     oldprice: 69,
    //     price: 59,
    //     ic_plan: 1,
    //     text: ['<strong>2</strong> país como especialidade','<strong>4</strong> dias úteis para receber','<strong>15%</strong> de taxa de intermediação','Propostas <strong>até especialista</strong>','<strong>90</strong> propostas mensais','<strong>10</strong> pesquisas em execução',]
    // }
    // const data1 = {
    //     name: "Assessor",
    //     oldprice: 179,
    //     price: 159,
    //     ic_plan: 2,
    //     text: [
    //         '<strong>4</strong> país como especialidade',
    //         '<strong>2</strong> dias úteis para receber',
    //         '<strong>10%</strong> de taxa de intermediação',
    //         'Propostas de <strong>todos os níveis</strong>',
    //         '<strong>Ilimitadas</strong> propostas',
    //         '<strong>Ilimitados</strong> pesquisas em execução',
    //         '<div style="display:flex;gap:10px"><div><strong>Selo</strong> premium </div><img src="/static/media/diamondicon.296ef55027a0cfdfe204f5d3edac1ccd.svg" width="15px" height="auto"/></div>'
    //     ]
    // }
    const { currentUser } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    return (
        <div className='box-border bg-[#FFFFFF] bg-opacity-10 rounded-[5px] w-[100%] min-w-[200px]'>
            {
            currentUser.ic_currentplan != 2 &&
                <div className='flex justify-center'>
                    <div className='h-[28px] font-[Poppins] text-[20px] text-center font-semibold leading-[21px] text-[#BA483A] pt-[10px]'>
                        Upgrade&nbsp;
                    </div>
                    <div className='h-[28px] font-[Poppins] text-[20px] text-center leading-[21px] text-[#313131] pt-[10px]'>
                    para seu trabalho!
                    </div>
                </div>
            }

            <div className='flex place-content-around'>
                {
                    currentUser.ic_currentplan == 0 && 
                    <div style={width>550? {display:'flex', } : {}}>
                        <Item data={data} withDiamond={false}  setcheckout={props.setcheckout} setic_plan={props.setic_plan} setcheckoutData={props.setcheckoutData}></Item>
                        <Item data={data1} withDiamond={false}  setcheckout={props.setcheckout} setic_plan={props.setic_plan} setcheckoutData={props.setcheckoutData}></Item>
                    </div>
                }
                {
                    currentUser.ic_currentplan == 1 && 
                    <div style={{marginRight:'auto', marginLeft:'auto'}}>
                        <Item data={data1} withDiamond={false}  setcheckout={props.setcheckout} setic_plan={props.setic_plan} setcheckoutData={props.setcheckoutData}></Item>
                    </div>
                }
            </div>
        </div>
    )
}