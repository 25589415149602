// Importa o React
import React from 'react';
// Importa o ReactDOM
import ReactDOM from 'react-dom/client';
// Importa o arquivo CSS
import './index.css';
// Importa o componente principal (App)
import App from './App';
// Importa o reportWebVitals para medir o desempenho da aplicação
import reportWebVitals from './reportWebVitals';
// Importa a store do Redux
import store from './redux/store';
// Importa o Provider do Redux
import { Provider } from 'react-redux/es';

// Cria um elemento raiz para o React renderizar
const root = ReactDOM.createRoot(document.getElementById('root'));
// Renderiza a aplicação dentro do elemento raiz
root.render(
    // Descomente esta linha se quiser ativar o StrictMode do React
    // <React.StrictMode>

    // Adiciona o Provider para fornecer a store para toda a aplicação
    <Provider store = {store}>
        <App />
    </Provider>
    // </React.StrictMode>
);

    // Chama a função reportWebVitals para começar a medir o desempenho
    // Se quiser ver os resultados no console, basta passar console.log como argumento
    // Se quiser enviar os resultados para algum endpoint, basta passar a função desejada como argumento
    reportWebVitals();