const data = {
    currentOrder: null,
};

// O estado inicial do reducer é o objeto "data"
// O "state" é o estado atual do reducer, que é igual a "data" por padrão
// eslint-disable-next-line default-param-last
export default function orderReducer(state = data, action) {
    // Criamos uma cópia do estado atual
    const temp = { ...state };
    // Verificamos qual ação está sendo executada
    switch (action.type) {
        // Se a ação for "setCurrentOrder", atualizamos o estado com o valor da propriedade "data" da ação
        case "setCurrentOrder":
            temp.currentOrder = action.data;
            return temp;
        // Se a ação não for reconhecida, retornamos o estado atual
        default:
            return temp;
    }
}