/* eslint-disable import/no-anonymous-default-export */
import ShareLink from "../../components/ShareLink";

export default ({withButton, orderID}) => {
    return (
        <div className='flex flex-col items-center w-full justify-center mb-[80px]'>
            <div className='font-[Poppins] font-[600] leading-[148%] text-center text-[18px] text-[#313131]'>Convide um pesquisador para fazer parte das suas propostas</div>
            <ShareLink withButton={withButton} researcherList={[]} orderid={orderID}/>
        </div>

    )
}