/* eslint-disable import/no-anonymous-default-export */
import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import {styled} from '@mui/material/styles';

import SubmitModal from '../../components/modal/confirmDeleteAccount';
import {TextareaAutosize} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/auth.action'
import API from '../../redux/API';
import { useNavigate } from 'react-router-dom';

const HeaderBTNStyle = styled('button')(({theme}) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '315px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px'
}));

export default() => {
    const style = {
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] mb:mt-[15p" +
                "x] md:mt-[10px] mb-[10px] w-[272px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#3131" +
                "31]"
    }
    const { currentUser } = useSelector(state => state.auth);
    const { currentProfile } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = React.useState(false);
    const [option, setOption] = useState(0);
    const [comment, setComment] = useState('');
    const selectOption = (event) => {
        setOption(event.target.value);
    }
    const commentChange = (event) => {
        setComment(event.target.value);
    }
    //dynamic optionlist
    const [optionList, setOptionList] = useState([]);
    
    useLayoutEffect(() => {
        if(optionList.length === 0)
        {
            API.get(`user/getaccountcancelreason?language=${'pt'}`)
            .then((result) => {
                let temp = result.data.reasonList;
                temp.unshift({_id:0, nm_accountcancellationreason:'-- Escolha um motivo --'})
                setOptionList(temp)
            })
            .catch((err) => {
                console.error(err.request.response)
            });
        } 
    }, [])
    const setChangeSetting = async (data) => { 

        try{
          const result = await API.post('/user/setacountcanceled', data);
          if (result.data.status === "success") {
                dispatch(logout(navigate, '/home'))
          }
        }
        catch(err) {
            console.log(err);
        };
  
      }
    const cancelAccount = () => {
        const data ={
            id_accountcancellationtype:option,
            ds_accountcancelcomment:comment,
            ds_email: currentUser.ds_email,
            ic_profile: currentProfile
        }
        setChangeSetting(data);
    }
    return (
        <div className='mb:w-full md:w-[95%] float-right'>
            <SubmitModal openModal={openModal} setOpenModal={setOpenModal} confirmFunc={cancelAccount}/>
            <div className='w-[100%] flex mb:items-center md:items-start flex-col'>
                <div className='mb:w-[96%] md:w-full  bg-[#17686E] bg-opacity-[0.11]'>
                    <div className='md:ml-[15px] pt-[10px] md:mr-[17px] mb:ml-[6px] mb:mr-[6px]'>
                        <div
                            className="font-[Poppins] font-bold text-[14px] leading-[40px] text-[#464A53] whitespace-nowrap ">Tem certeza que deseja encerrar sua conta?</div>
                        <div className={style.content + " mb-[40px]"}>Se você fechar a sua conta, você
                            não será capaz de fazer login, nem de receber qualquer comunicação do site.
                            Fechar sua conta significa que você não terá acesso aos documentos que você está
                            buscando (os que você publicou e as buscas das quais você está participando
                            também).</div>

                    </div>
                </div>
                <div
                    className=" mb:w-[96%] md:w-fullmt-[20px] mb:ml-[0px] md:ml-[16.5px] mt-[15px]">
                    <div
                        className="font-[Poppins] font-bold text-[14px] leading-[40px] text-[#464A53] mb:text-center md:text-left">Preencha
                        o formulário abaixo com seus comentários. Eles vão nos ajudar a melhorar a
                        plataforma no futuro.</div>
                </div>
                <div className=' mb:w-[96%] md:w-full'>
                    <div
                        className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mt-[15px] mb-[3px]">Motivo do encerramento da conta
                        <span className='text-[#DE2B2B]'>*</span>
                    </div>
                    <div>
                        <Select
                            value={option}
                            onChange={selectOption}
                            displayEmpty="displayEmpty"
                            className="w-[100%] mb:h-[50px] md:h-[34px]"
                            inputProps={{
                                'aria-label' : 'Without label'
                            }}
                            MenuProps={{
                                sx: {
                                    "& .Mui-selected": {
                                        backgroundColor: "rgba(23, 104, 110, 0.4) !important"
                                    },
                                    "& .MuiMenuItem-root:hover": {
                                        backgroundColor: "rgba(23, 104, 110, 0.11)"
                                    },
                                    '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal'
                                    }
                                }
                            }}>
                            {
                                //dynamic
                                optionList.map((item, index) => {
                                    return (
                                        <MenuItem value={item._id}>
                                            <p
                                                className="whitespace-normal font-[Poppins] font-normal text-[14px] leading-[24px]">{item.nm_accountcancellationreason}</p>
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div
                        className="font-[Poppins] font-normal text-[14px] leading-[148%] text-[#313131] mt-[20px] mb-[3px]">Comentários
                        <span className='text-[#DE2B2B]'>*</span>
                    </div>
                    <div>
                        <TextareaAutosize
                            id="standard-multiline-static"
                            label="Multiline"
                            onChange={commentChange}
                            InputProps={{
                                disableUnderline: true
                            }}
                            defaultValue={comment}
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={comment}
                            className="pt-[9px] pl-[13px] resize-y !min-h-[160px] font-[Poppins] font-normal text-[14px] leading-[24px] text-[#313131] w-[100%] !border-solid !border-[#E2E4E5] !border-[1px] rounded-[7px]"/>
                    </div>
                    <div className="flex mb:justify-center md:justify-end mb-[16px]">
                        <HeaderBTNStyle
                            onClick={() => {
                                setOpenModal(true)
                            }}
                            disabled={!comment || option == '-- Escolha um motivo --'
                                ? true
                                : false}
                            sx={{
                                '&:disabled' : {
                                    backgroundColor: '#C6C6C6',
                                    cursor: 'not-allowed'
                                }
                            }}>Sim, quero fechar minha conta</HeaderBTNStyle>
                    </div>
                </div>
            </div>
        </div>
    )
}