import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useNavigate } from 'react-router-dom';
import FilterDropDown from '../DropDown/FilterDropDown.js'
import SearchInput from '../Input/IconInput'
import SearchIcon from '../../assets/icons/Search.svg'
import DownloadIcon from '../../assets/icons/download.svg'
import UsersIcon from '../../assets/icons/users.svg'
import MoreImg from '../../assets/image/More.png'
import UpImage from '../../assets/icons/Polygon_up.svg'
import { visuallyHidden } from '@mui/utils';
import TableSortLabel from '@mui/material/TableSortLabel';
import OutsideAlerter from '../../hooks/OutSideAlerter';
import {
    Box,
    Stack,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';

function MoreIcon(props) {
    return (
        <SvgIcon {...props}>
            {/* <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" /> */}
            <path fill="currentColor" d="M9.5 13a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0z"></path>
        </SvgIcon>
    );
}

function TablePaginationActions(props) {
    const theme = useTheme();

    const { count, page, rowsPerPage, onPageChange } = props;

    // const handleFirstPageButtonClick = (event) => {
    //     onPageChange(event, 0);
    // };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    // const handleLastPageButtonClick = (event) => {
    //     onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    // };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            {/* <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton> */}
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            {/* <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton> */}
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


// .sort((a, b) => (a.name < b.name ? -1 : 1))

// iconflag, name, docType, status, updatetime, flag, publicdate, proposal
const NameStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 14,
}));

const DocStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 14,
    color: '#A9A9A9',
}));

const DotBox = styled('div')(({ theme }) => ({
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    margin: '0px 5.5px'
}));

const StausStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 12,
}));

const UpdateStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: 10,
    color: '#A9A9A9'
}));

const PublicStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 12,
}));

const DateStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: 12,
}));


const ProposalStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: 12,
}));

const GrayShadow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    borderRadius: '10px',
}));

const ViewMoreStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 12,
    color: '#C6C6C6',
    '&:hover': {
        color: '#17686E',
        cursor: 'pointer'
    }
}));



export default function CustomPaginationActionsTable({ rows, showfilter, singleOrderWithdraw, setsingleOrderWithdraw }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [flag, setFlag] = React.useState(new Array(rows.length).fill(null))
    const navigate = useNavigate();
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgba(23, 104, 110, 0.11)',
            color: '#313131',
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: 14,
            padding: '15px 0px',
            textTransform: 'uppercase'
        },
        [`&.${tableCellClasses.body}`]: {
            padding: '11px 0px 10px'
        },
    }));
    const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgba(23, 104, 110, 0.11)',
            color: '#313131',
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: 14,
            textTransform: 'uppercase',
            marginLeft: '13px'
        },
        [`&.${tableCellClasses.body}`]: {
            padding: '11px 0px 10px'
        },
    }));
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Nome do antepassado');
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    const getComparator = (order, orderBy) => {
        return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }
    
    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
        return -1;
        }
        if (b[orderBy] > a[orderBy]) {
        return 1;
        }
        return 0;
    }
    return (
        <TableContainer component={Paper} className="overflow-visible !overflow-x-visible">
            <Table sx={{ minWidth: 500, border: '1px solid #E2E4E5', borderRadius: '5px' }} aria-label="custom pagination table">
                <TableHead>
                    {
                        showfilter && 
                        <Stack direction='row' sx={{ padding: '15px 0px 15px 18px' }} >
                            <FilterDropDown />
                            <Box sx={{ width: '20px' }} />
                            <SearchInput IconSrc={SearchIcon} holder={'Pesquise'} />
                        </Stack>
                    }

                    <TableRow>
                    <StyledTableCell align="center" sortDirection={orderBy === "name" ? order : false}>
                            <TableSortLabel
                                active={orderBy === "name"}
                                direction={orderBy === "name" ? order : 'asc'}
                                onClick={()=>handleRequestSort("name")}
                                //  IconComponent={()=> <Box component='img' src={SortIcon}/>}
                                // sx={{
                                //     '& .MuiTableSortLabel-iconDirectionDesc':{
                                //         background: `url(${DecIcon})  no-repeat !important`,
                                //         width: '16px',
                                //         height: '16px',
                                //         '& path':{
                                //             display: 'none'
                                //         }
                                //     },
                                //     '& .MuiTableSortLabel-iconDirectionAsc':{
                                //         width: '16px',
                                //         height: '16px',
                                //         background: `url(${AscIcon})  no-repeat !important`,
                                //         '& path':{
                                //             display: 'none'
                                //         }
                                //     },
                                //     '& .MuiTableSortLabel-icon':{
                                //         width: '16px',
                                //         height: '16px',
                                //         background: `url(${SortIcon}) no-repeat`,
                    
                                //         '& path':{
                                //             display: 'none'
                                //         }
                                //     }
                                // }}
                                // IconComponent={()=> {
                                // return <div className="w-[20px] h-[20px]" style={{backgroundImage: `url(${SortIcon})`}}></div>}}
                                >
                                CLIENTE
                                {orderBy === "name" ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>

                        </StyledTableCell>
                        <StyledTableCell align="left" sortDirection={orderBy === "updatedAt" ? order : false}>
                            <TableSortLabel
                                active={orderBy === "updatedAt"}
                                direction={orderBy === "updatedAt" ? order : 'asc'}
                                onClick={()=>handleRequestSort("updatedAt")}
                                >
                                DATA LIBERAÇÃO
                                {orderBy === "updatedAt" ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="left" sortDirection={orderBy === "status" ? order : false}>
                            <TableSortLabel
                                active={orderBy === "status"}
                                direction={orderBy === "status" ? order : 'asc'}
                                onClick={()=>handleRequestSort("status")}
                                >
                                STATUS
                                {orderBy === "status" ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="left" sortDirection={orderBy === "value" ? order : false}>
                            <TableSortLabel
                                active={orderBy === "value"}
                                direction={orderBy === "value" ? order : 'asc'}
                                onClick={()=>handleRequestSort("value")}
                                >
                                VALOR
                                {orderBy === "value" ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row, index) => (
                        <TableRow key={row.id}>
                            <StyledTableCell component="th" scope='row' style={{ width: 400 }}>
                                <Stack direction='row'>

                                    <Box className='ml-[16.8px]'>
                                        <NameStyle>{row.name}</NameStyle>
                                        <DocStyle>{row.docType}</DocStyle>
                                    </Box>
                                </Stack>
                            </StyledTableCell>
                            <StyledTableCell style={{ width: 260 }} align="left">
                                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                    <Box>
                                        <DateStyle>{row.dt_releasemoney}</DateStyle>
                                    </Box>
                                </Stack>
                            </StyledTableCell>
                            <StyledTableCell style={{ width: 260 }} align="left">
                                <Stack direction='row' alignItems='center'>
                                    <GrayShadow sx={{ pr: '15px' }}>
                                        <DotBox sx={{
                                            bgcolor: row.flag == 0 ? '#E2BD7B' : row.flag == 1 ? '#4A4AFF' : row.flag == 2 ? '#17686E' : '#BA483A'
                                        }} />
                                        <StausStyle sx={{
                                            color: row.flag == 0 ? '#E2BD7B' : row.flag == 1 ? '#4A4AFF' : row.flag == 2 ? '#17686E' : '#BA483A'
                                        }}>
                                            {row.status}
                                        </StausStyle>
                                    </GrayShadow>
                                </Stack>
                            </StyledTableCell>
                            <StyledTableCell style={{ width: 160 }} align="left">
                                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                    <Box>
                                        <PublicStyle>R${Number.parseFloat(row.value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "*").replace('.', ',').replace('*', '.')}</PublicStyle>
                                    </Box>
                                    <div className="relative">
                                        <Box component={'img'} src={MoreImg} className=" !h-[20px] mr-[37px] text-[#C6C6C6] hover:text-[#17686E] cursor-pointer pr-[10px] pl-[10px]" onClick={() => {const temp = [...flag];temp[index] = !temp[index]; setFlag(temp)}} />
                                        <div className='absolute right-[8px] top-[30px] z-10'>
                                        {
                                            flag[index] &&
                                            <OutsideAlerter event={() => {const temp = [...flag];temp[index] = false; setFlag(temp)}}>
                                                <div className="drop-shadow  xl:ml-[0px]">
                                                    <Box component={'img'} src={UpImage} className="ml-[198px] z-0"/>
                                                    <div className="bg-[#FFFFFF] rounded-[5px] w-[250px] mt-[-2px] z-10 flex flex-col justify-around">
                                                        <div className='cursor-pointer h-[40px] hover:text-[#17686E] hover:bg-[#17686E] hover:bg-opacity-10' onClick={() => {navigate(`/orderdetail_researcher/${row.id}`, {state:{initial: 1}})}}>
                                                        <div className='h-full flex justify-center items-center'>
                                                                <div className='w-full font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131] text-center hover:text-[#17686E] hover:font-semibold'>Ver Pedido</div>
                                                            </div>
                                                        </div>         

                                                    
                                                        <div className='cursor-pointer h-[40px] hover:text-[#17686E] hover:bg-[#17686E] hover:bg-opacity-10'>
                                                        <div className='h-full flex justify-center items-center' onClick={()=>{setsingleOrderWithdraw(row.allItemData)}}>
                                                                <div className='w-full font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131] text-center hover:text-[#17686E] hover:font-semibold'>Sacar este valor apenas</div>
                                                        </div>
                                                        </div>                        
                                                    </div>
                                                </div>
                                            </OutsideAlerter>
                                        }
                                        </div>
                                    </div>
                                </Stack>
                            </StyledTableCell>
                        </TableRow>
                    ))}

                </TableBody>
                <TableFooter sx={{ bgcolor: 'rgba(23, 104, 110, 0.11)', }} >
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={5}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage='Linhas por página:'
                            onPageChange={handleChangePage}
                            labelDisplayedRows={
                                ({ from, to, count }) => {
                                  return '' + from + '-' + to + ' de ' + count
                                }
                              }
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            sx={{ '.MuiTablePagination-selectLabel' : {fontFamily: 'Poppins', fontSize: '12px', fontWeight: 600}, '.MuiNativeSelect-select' : { fontWeight: 600}, '.MuiTablePagination-displayedRows' : {fontFamily: 'Poppins', fontSize: '12px', fontWeight: 600} }}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}
