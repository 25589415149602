import { styled } from '@mui/material/styles';

import DesktopTable from '../../components/Table/DesktopTable2.js'
import MobileTable from '../../components/Table/MobileTable2.js'
import useWindowSize from '../../hooks/useWindowSize'
import { useState } from 'react';
import API from '../../redux/API.js';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
    }
}));

const TitleText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '18px',
    color: '#313131',
    padding: '46px 0px 21px',
    [theme.breakpoints.up('md')]: {
        fontSize: '20px',
        padding: '39px 0px 18px'
    }
}));


export default function ExpertProfile({userFees, data,singleOrderWithdraw, setsingleOrderWithdraw ,tdata, setTdata}) {
    const { width } = useWindowSize()
    const { currentUser } = useSelector(state => state.auth);

    const statusInfo = [
        {
            status: 'Em aberto',
            flags:['Proposta enviada', 'Em negociação', 'Aguardando cliente']
        },
        {
            status: 'Em andamento',
            flags:['Pesquisando', 'Aguardando análise']
        },
        {
            status: 'Concluído',
            flags:['Pagamento em andamento', 'Concluído']
        },
        {
            status: 'Cancelado',
            flags:['Proposta recusada', 'Cancelado']
        }
    ]
    
    const padTo2Digits = (n) => {
        return n.toString().padStart(2, "0");
      }

    const formatDateWithoutHours =(date) =>{

        if (!date) {
            return "";
          }
          const formattedDate = new Date(date);
          if (isNaN(formattedDate.getTime())) {
            throw new Error("Invalid date");
          }
        
          const day = padTo2Digits(formattedDate.getDate());
          const month = padTo2Digits(formattedDate.getMonth() + 1);
          const year = formattedDate.getFullYear();
          const hours = padTo2Digits(formattedDate.getHours());
          const minutes = padTo2Digits(formattedDate.getMinutes());
        
          return formattedDate.toLocaleString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });
      }



    useEffect(() => {
        const data ={
            id_researcher:currentUser.id === undefined ? currentUser._id : currentUser.id,
            canWithdraw:true,
        }

        API.post(`/order/getorderwithresearcher`, data)
        .then((result) => {
            if (result.data.status === "success") {

                // alert(result.data.orders.length)

                let temp = []
                result.data.orders.map((item) => {
                    let date = new Date(item.createdAt);
                    const yy = date.getFullYear() % 100;
                    let mm = date.getMonth() + 1; // Months start at 0!
                    let dd = date.getDate();

                    if (dd < 10) dd = '0' + dd;
                    if (mm < 10) mm = '0' + mm;

                    const formattedToday = dd + '/' + mm + '/' + yy;

                    date = new Date(item.updatedAt);
                    const yy1 = date.getFullYear() % 100;
                    let mm1 = date.getMonth() + 1; // Months start at 0!
                    let dd1 = date.getDate();
                    let hour = date.getHours();
                    let minute = date.getMinutes()
                    if (dd1 < 10) dd1= '0' + dd1;
                    if (mm1 < 10) mm1 = '0' + mm1;
                    if (hour < 10) hour = '0' + hour;
                    if (minute < 10) minute = '0' + minute;
                    const formattedUpdateDate = dd1 + '/' + mm1 + '/' + yy1 + ' - '+hour + 'h'+minute;
                    let titem = {
                        id: item._id,
                        name: item.id_customer.nm_user,
                        iconflag: item.tp_order ? 2 : 1,
                        docType: 'Certidão de '+item.id_documenttype.nm_documenttype,
                        status: statusInfo[item.cd_orderstatus].status,
                        proposal: item.bids.length,
                        publicdate: formattedToday,
                        flag: item.cd_orderstatus,
                        value: userFees?(item.vl_budget) * ((userFees/-100)+1):0,
                        updatetime: 'Utima atualização: ' + formattedUpdateDate,
                        updatedAt: item.updatedAt,
                        dt_releasemoney:formatDateWithoutHours(item.dt_releasemoney),
                        allItemData:item,
                    }
                    temp.push(titem);
                })
                
                setTdata(temp);
            }
        })
        .catch((err) => {
            console.log(err)
            setTdata([])
                // toast.error(JSON.parse(err.request.response).message)
        });  
    }, [currentUser,userFees])

    useEffect(()=>{
        if (singleOrderWithdraw._id) {
            let aux = [...tdata]
            aux = aux.filter(single=>single.id===singleOrderWithdraw._id)
            setTdata(aux)
        }
    },[singleOrderWithdraw])

    return (
        <RootStyle>
            {width > 900 && <DesktopTable rows={tdata} showfilter={false} singleOrderWithdraw={singleOrderWithdraw} setsingleOrderWithdraw={setsingleOrderWithdraw}/>}
            {width < 900 && <MobileTable rows={tdata} showfilter={false} singleOrderWithdraw={singleOrderWithdraw} setsingleOrderWithdraw={setsingleOrderWithdraw}/>}
        </RootStyle>
    )
}