const {REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV,REACT_APP_STRIPE_SECRET_KEY_DEV,REACT_APP_STRIPE_PUBLISHABLE_KEY_PROD,REACT_APP_STRIPE_SECRET_KEY_PROD,REACT_APP_AUTH_CLIENT_ID} = process.env

const DEV_MODE = false
const using_as_prod = false

// TO USE THE LOCALHOST = DEV_MODE (true) 
// TO USE THE MAIN API = DEV_MODE (false) + using_as_prod (true)
// TO USE THE DEV API = DEV_MODE (false) + using_as_prod (false)
export const API_BASE = DEV_MODE===true?'http://localhost:4000/api/v1':using_as_prod===true?'https://api.docs-tree.com/api/v1':'https://api-dev.docs-tree.com/api/v1';
export const IMAGE_BASE = DEV_MODE===true?'http://localhost:4000/images/':using_as_prod===true?'https://api.docs-tree.com/images/':'https://api-dev.docs-tree.com/images/';

export const STRIPE_PUBLISHABLE_KEY = DEV_MODE===true? REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV:using_as_prod===true?REACT_APP_STRIPE_PUBLISHABLE_KEY_PROD:REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV;
export const STRIPE_SECRET_KEY = DEV_MODE===true? REACT_APP_STRIPE_SECRET_KEY_DEV:using_as_prod===true?REACT_APP_STRIPE_SECRET_KEY_PROD:REACT_APP_STRIPE_SECRET_KEY_DEV;
