import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';

//Icons
import StarFilled from '../../assets/icons/starFilled.svg'
import StarEmptyGray from '../../assets/icons/starEmptyGray.svg'
import StarEmptyBlack from '../../assets/icons/starEmptyBlack.svg'

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#17686E',
    }
});

export default function CustomizedRating({ value, size, emptyIconType}) {
    return (
        <StyledRating
            name="customized-5"
            value={value}
            precision={0.1}
            max={5}
            readOnly
        />
    );
}