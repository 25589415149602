import React from "react";
import { styled } from '@mui/material/styles';

const RootStyle = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '10px auto',
    minHeight: '700px',
    flexDirection:'column',
    padding: '20px 20px 80px 20px',
}));

const MainTitle = styled ('h3')(({theme})=>({
    height: '52px',
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '32px',
    alignItems: 'center',
    textAlign: 'center',
    color: '#17686E',
    marginTop:'20px',
    marginBottom: '40px',
}))

const SimpleTitle = styled ('h4')(({theme})=>({
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '20px',
    textAlign: 'left',
    color: '#17686E',
    marginTop:'40px',
    marginBottom:'10px',
}))

const SimpleParagraph = styled ('p')(({theme})=>({
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '1.6em',
    color: '#313131',
    marginBottom:'10px',
    display:'flex'

}))

const SimpleLI = styled ('li')(({theme})=>({
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '1.6em',
    color: '#313131',
    marginLeft:'30px',
    marginBottom:'10px',

}))

const lastPrivacyPolicyChange = `10 de maio de 2023`

export default function PrivacyPolicy (){

    return (
        <RootStyle>
        <MainTitle>Política de Privacidade e Proteção de Dados</MainTitle>
        <SimpleParagraph>A sua privacidade é importante para nós. Nós respeitamos a sua privacidade em relação a quaisquer informações suas que possamos coletar no site da DocsTree, bem como em outros sites que possuímos e operamos. A DocsTree implementa em sua operação, as orientações, normas, controles e ações estabelecidas pelas legislações de proteção de dados onde atua, em especial a Lei Geral de Proteção de Dados - LGPD do Brasil. Esta política de privacidade e proteção de dados explicará como nossa organização usa os dados pessoais que coletamos de você quando você utiliza nossos serviços e aplicações para encontrar documentos.</SimpleParagraph>


        <SimpleParagraph> 
            Nós coletamos informações pessoais apenas quando realmente precisamos delas para fornecer um serviço a você. Fazemos isso por meios justos e legais, com o seu conhecimento e consentimento, e também informamos por que estamos coletando e como usaremos suas informações.
            Nós retemos as informações coletadas apenas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, os protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
        </SimpleParagraph>

        <SimpleParagraph>
            Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei. Além disso, nosso site pode conter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.
        </SimpleParagraph>

        <SimpleParagraph>
            Você é livre para recusar a nossa solicitação de informações pessoais, mas tenha em mente que talvez não possamos fornecer alguns dos serviços desejados se você optar por não fornecer tais informações.
            </SimpleParagraph>

        <SimpleParagraph>
            O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contacto connosco.
        </SimpleParagraph>

            <SimpleLI>O serviço Google AdSense que usamos para veicular publicidade usa um cookie DoubleClick para veicular anúncios mais relevantes em toda a Web e limitar o número de vezes que um determinado anúncio é exibido para você.</SimpleLI>
            <SimpleLI>Para mais informações sobre o Google AdSense, consulte as FAQs oficiais sobre privacidade do Google AdSense.</SimpleLI>
            <SimpleLI>Utilizamos anúncios para compensar os custos de funcionamento deste site e fornecer financiamento para futuros desenvolvimentos. Os cookies de publicidade comportamental usados ​​por este site foram projetados para garantir que você forneça os anúncios mais relevantes sempre que possível, rastreando anonimamente seus interesses e apresentando coisas semelhantes que possam ser do seu interesse.</SimpleLI>
            <SimpleLI>Vários parceiros anunciam em nosso nome e os cookies de rastreamento de afiliados simplesmente nos permitem ver se nossos clientes acessaram o site através de um dos sites de nossos parceiros, para que possamos creditá-los adequadamente e, quando aplicável, permitir que nossos parceiros afiliados ofereçam qualquer promoção que pode fornecê-lo para fazer uma compra.</SimpleLI>

<SimpleTitle>Como coletamos seus dados?</SimpleTitle>

        <SimpleParagraph>
            Coletamos informações diretamente no momento em que você se registra em nosso site, faz um pagamento, preenche um formulário ou navega no site. Além disso, coletamos dados indiretamente de empresas que fornecem serviços de análise, empresas que fornecem serviços de marketing, empresas de mídias sociais.
        </SimpleParagraph>

<SimpleTitle>Como usamos seus dados?</SimpleTitle>

        <SimpleParagraph>
            Usamos essas informações com as seguintes finalidades:
        </SimpleParagraph>
            <SimpleLI>Personalização da experiência do usuário: suas informações nos ajudam a entender melhor suas necessidades e oferecer uma experiência personalizada em nosso site;</SimpleLI>
            <SimpleLI>Melhoria do nosso site: com base nas informações e feedbacks que recebemos de você, continuamos nos esforçando para melhorar as ofertas do nosso site;</SimpleLI>
            <SimpleLI>Melhoria do atendimento ao cliente: suas informações nos ajudam a responder de forma mais eficaz às suas solicitações de atendimento ao cliente e necessidades de suporte;</SimpleLI>
            <SimpleLI>Processamento de transações: suas informações não serão vendidas, trocadas, transferidas ou fornecidas a terceiros sem o seu consentimento, a menos que seja necessário para a entrega de produtos ou serviços adquiridos;</SimpleLI>
            <SimpleLI>Administração de concursos, promoções, pesquisas ou outras características do site;</SimpleLI>
            <SimpleLI>Envio de e-mails periódicos: usamos seu endereço de e-mail para enviar informações ou atualizações relacionadas às suas solicitações, bem como notícias institucionais, atualizações e informações sobre produtos ou serviços adquiridos;</SimpleLI>
            <SimpleLI>Identificação do usuário: coletamos e processamos informações de identificação pessoal para fins de identificação do usuário e uso dos nossos serviços. Essas informações também podem ser usadas para fornecer suporte ao usuário, notificando-os sobre atualizações e melhorias, fornecendo informações sobre mudanças nos Termos de Serviço (incluindo mudanças nesta Política) e outras informações importantes;</SimpleLI>
            <SimpleLI>Criação e manutenção de um ambiente confiável: coletamos informações para verificar ou autenticar as informações ou identificações fornecidas por você, detectar e prevenir fraudes, spam, abusos, incidentes de segurança e outras atividades ilícitas ou perigosas;</SimpleLI>
            <SimpleLI>Publicidade personalizada e marketing: usamos dados baseados em seu perfil, comportamentos e preferências para fornecer ofertas baseadas em seus interesses, incluindo informações sobre ofertas, campanhas e serviços da Docstree e de nossos parceiros e afiliados;</SimpleLI>
            <SimpleLI>Contato com você: entramos em contato com você para notificá-lo sobre sua conta, informar sobre problemas com a sua conta, resolver disputas, coletar taxas ou cobranças, registrar sua opinião através de pesquisas ou questionários, enviar atualizações sobre nossa empresa ou contatar você de forma necessária para cumprir nosso Contrato de Usuário, leis nacionais aplicáveis e qualquer outro acordo que tivermos com você. Para esses propósitos, podemos entrar em contato por e-mail, telefone, mensagens de texto e portal;</SimpleLI>
            <SimpleLI>Coleta de feedback: podemos solicitar que você avalie nossos serviços e/ou produtos a fim de melhorá-los. Para a coleta de feedback, podemos utilizar uma empresa terceira, portanto, compartilharemos seus dados pessoais, como nome, endereço de e-mail e telefone, apenas para esse fim.</SimpleLI>
        <SimpleParagraph>
            Na Docstree, nos comprometemos a proteger seus dados pessoais de acordo com as leis aplicáveis e a garantir que suas informações sejam tratadas de forma justa e transparente.
        </SimpleParagraph>

<SimpleTitle>Como armazenamos e protegemos seus dados?</SimpleTitle>

    <SimpleParagraph>
        Levamos a segurança dos seus dados muito a sério e implementamos medidas de segurança apropriadas para proteger suas informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição não autorizada.
    </SimpleParagraph>
    <SimpleParagraph>
        Nós implementamos uma variedade de medidas de segurança para manter a segurança de suas informações pessoais quando você envia um pagamento, faz login, envia ou acessa suas informações pessoais.
    </SimpleParagraph>
    <SimpleParagraph>
        Oferecemos o uso de um servidor seguro. Todas as informações sensíveis/de crédito fornecido são transmitidas via tecnologia de Secure Socket Layer (SSL) e, então, encriptada em nossa Base de Dados para acesso apenas de indivíduos autorizados, com direitos especiais de acesso a nossos sistemas, a quem a manutenção da confidencialidade das informações é obrigatória.
    </SimpleParagraph>
    <SimpleParagraph>
        Também implementamos medidas de segurança física e técnica para proteger nossos sistemas de armazenamento de dados contra acesso não autorizado e roubo. Todos os dados são armazenados em servidores seguros, com acesso limitado a pessoal autorizado que precisa acessar essas informações para executar suas funções de trabalho.
    </SimpleParagraph>
    <SimpleParagraph>
        Todos os usuários podem ver e editar seus dados pessoais através da sua conta bem como encerrar sua conta a qualquer tempo. Em caso de encerramento, as informações de perfil não serão mais exibidas para outros usuários e visitantes da plataforma. Entretanto, permanecerão visíveis os pedidos públicos, sua descrição e anexos bem como os dados relativos aos pedidos executados entre Pesquisador e Cliente.
    </SimpleParagraph>

<SimpleTitle>Com quem compartilhamos seus dados?</SimpleTitle>

    <SimpleParagraph>
        Não compartilhamos seus dados com terceiros para fins de marketing sem sua autorização prévia. Podemos compartilhar seus dados com provedores de serviços terceirizados que trabalham em nosso nome para fornecer determinados serviços, como processamento de pagamentos, análise de dados, hospedagem de sites e armazenamento em nuvem. Também podemos compartilhar seus dados com autoridades legais ou regulatórias quando exigido por lei ou em resposta a uma solicitação legal válida. Quando compartilhamos seus dados, tomamos medidas para garantir que eles sejam tratados de acordo com esta Política de Privacidade e com as leis aplicáveis.
    </SimpleParagraph>

<SimpleTitle>Compromisso do Usuário</SimpleTitle>

    <SimpleParagraph>
        O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o DocsTree oferece no site e com caráter enunciativo, mas não limitativo:
    </SimpleParagraph>

    <SimpleLI>
        A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;
    </SimpleLI>
    <SimpleLI>
        B) Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, pix bet365 ou azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;
    </SimpleLI>
    <SimpleLI>
        C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do DocsTree, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.
    </SimpleLI>

<SimpleTitle>Mais informações</SimpleTitle>

    <SimpleParagraph>
        Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.
    </SimpleParagraph>

<SimpleTitle>Mudanças em nossa política de privacidade</SimpleTitle>

    <SimpleParagraph>
        Se decidirmos modificar nossas políticas de privacidade, publicaremos as alterações realizadas nesta página e/ou atualizaremos a data de modificação da Política de Privacidade e Proteção de Dados.
    </SimpleParagraph>
    <SimpleParagraph>As últimas modificações a essas políticas foram realizadas em {lastPrivacyPolicyChange}.</SimpleParagraph>

<SimpleTitle>Como entrarei em contato se tiver perguntas sobre privacidade?</SimpleTitle>

    <SimpleParagraph>
        Se você tiver alguma dúvida ou preocupação sobre como lidamos com seus dados pessoais ou sobre esta Política de Privacidade, entre em contato conosco usando as seguintes informações de contato:
    </SimpleParagraph>
    <SimpleLI>    
        Endereço de e-mail: privacy@docs-tree.com
    </SimpleLI>
    <SimpleParagraph>
        Responderemos a todas as perguntas e preocupações o mais rápido possível e faremos o possível para resolver qualquer problema que você possa ter em relação ao tratamento de seus dados pessoais.
    </SimpleParagraph>
        </RootStyle>
    )
}