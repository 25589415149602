import { styled } from '@mui/material/styles';
import * as React from 'react';
import {Avatar, Box, Stack, IconButton,} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Icon } from '@iconify/react';
import { IMAGE_BASE } from '../../config/constants';
// components
import PremiumCard from '../../components/card/PremiumCard'
import RatingStar from '../../components/RatingStar'
import CopyIcon from '../../assets/icons/copy.svg'
import LinkIcon from '../../assets/icons/link.svg'
import API from '../../redux/API';
// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    border: '1px solid #E2E4E5',
    borderRadius: '7px',
    padding: '23px 11px 14px 12px',
    margin: '5px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}));

const ContentStyle = styled('div')(({ theme }) => ({
    paddingLeft: '32px',
    width: '100%',
}));

const TitleStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #E2E4E5',
    borderRadius: '7px',
    paddingLeft: '11.5px',
    paddingRight: '21px',
}));

const NameText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '40px',
    color: '#313131',
}));

const RatingText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    color: '#313131',
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: '#313131',

        boxShadow: theme.shadows[1],
        fontSize: 10,
        fontFamily: 'Poppins',
        fontWeight: 400,
    },
}));

export default function DataCard({ profileData, currentUser,avgrating, showFlag, showCountryName, filterCountry }) {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        navigator.clipboard.writeText(currentUser._id?currentUser._id:currentUser.id);
        setOpen(true);
    };

    const getFirstLetter = (arr) =>{
        return arr[0][0] + "." + arr[1][0];
    }

    const [specialitylist, setSpecialitylist] = React.useState([])
    React.useLayoutEffect(() => {
       
        if(specialitylist.length === 0)
        {
            API.get(`user/getspecialitylist?language=${'pt'}`)
            .then((result) => {
                let temp = result.data.allspeciality;
                setSpecialitylist(temp)
            })
            .catch((err) => {
                console.error(err.request.response)
            });
        } 
    },[])

    const getSpeciality = (id) => {
        let temp;
        specialitylist.map((item) => {
            if(item._id == id) temp = item.nm_country_pt;
        })
        return temp;
    }
    const getcd_isoalpha2 = (id) => {
        let temp;
        specialitylist.map((item) => {
            if(item._id == id) temp = item.cd_isoalpha2;
        })
        return temp;
    }
    return (
        <RootStyle>
            <Stack flexDirection={'row'} alignItems={'center'} >
            <Box sx={{ width: 188, height: 188 }} className="rounded-[94px]">
                <Avatar
                    alt="Profile Picture"
                    src={IMAGE_BASE+`accounts/${currentUser._id?currentUser._id:currentUser.id}/`+currentUser.nm_researcheravatarfile}
                    sx={{ width: 188, height: 188, borderRadius: '50%' }}
                />
            </Box>
            </Stack>
            <ContentStyle>
                <TitleStyle>
                    <NameText>{currentUser.ic_researchertype ? getFirstLetter(currentUser.nm_user.split(' ')) : currentUser.nm_company}</NameText>
                    <PremiumCard  />
                </TitleStyle>
                <Stack flexDirection={'row'} alignItems={'center'} pt={'16px'}>
                <RatingStar value={avgrating} size={24} emptyIconType={2} />
                {avgrating &&
                    <RatingText>&nbsp;&nbsp;&nbsp;&nbsp;{avgrating?`${avgrating.toFixed(2)}/5`:'Nenhuma informação'}</RatingText>
                }
                </Stack>
                <Stack flexDirection={'row'} alignItems={'center'} mt={'10px'}>
                    <RatingText sx={{ fontWeight: 300 }}>Localização:&nbsp;</RatingText>
                    <RatingText sx={{ fontWeight: 600 }}>{currentUser?.id_country?getSpeciality(currentUser.id_country):''}&nbsp;&nbsp;</RatingText>
                    <Icon icon={
                        currentUser?.id_country?
                        showFlag(getcd_isoalpha2(currentUser.id_country), filterCountry)
                        :
                        ''                            
                    } width={19} />

                </Stack>
                <Stack flexDirection={'row'} alignItems={'center'} mt={'10px'}>
                    <RatingText sx={{ fontWeight: 300 }}>Especialidade: &nbsp;</RatingText>
                    <RatingText sx={{ fontWeight: 600 }}>{
                        currentUser.about_me?.specialities !== undefined && currentUser.about_me.specialities.map((item, index) => {
                            if(index !== currentUser.about_me.specialities.length - 1) return getSpeciality(item)+', '
                            return getSpeciality(item)
                        })
                    }</RatingText>
                </Stack>
                <Stack flexDirection={'row'} alignItems={'center'} mt={'13px'} sx={{ float: 'right' }}>
                    <RatingText sx={{ fontWeight: 300 }}>ID:&nbsp;</RatingText>
                    <RatingText sx={{ fontWeight: 300 }}>{currentUser.cd_researcher}</RatingText>
                    <LightTooltip title={<u>Nº de ID copiado!</u>} placement="top" open={open} onClose={handleClose}>
                        <IconButton aria-label="CopyID" onClick={handleOpen}>
                            <Box component={'img'} src={CopyIcon} width={12.5} />
                        </IconButton>
                    </LightTooltip >
                    {/* <IconButton aria-label="Link">
                        <Box component={'img'} src={LinkIcon} width={20} />
                    </IconButton> */}
                </Stack>
            </ContentStyle>
        </RootStyle>
    )
}