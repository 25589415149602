/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import {
    Box,
} from '@mui/material';
import { Markup } from 'interweave';
import DiamondIcon from '../../assets/icons/diamondicon.svg'
import CheckImg from '../../assets/image/check.svg'
import { useSelector } from 'react-redux';

import AssessorImg from '../../assets/image/upgrade_plan1.png'

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#BA483A',
    fontSize: '14px',
    width: '162px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px'
    // [theme.breakpoints.up('sm')]: {
    //     fontSize: '15px',
    //     padding: '10px',
    // },
    // [theme.breakpoints.up('md')]: {
    //     fontSize: '17px',
    //     padding: '15px',
    // },
}));

function statement(props) {
    return(
        <div className='flex items-center mb-[10px] ml-[10px]'>
            {props.text ? <Box component={'img'} src={CheckImg} className='w-[10px] h-[10px]'/> : ""}
            <div className="ml-[18px] text-[12px] font-[Poppins] h-[12px] whitespace-nowrap"><Markup content={props.text} /></div>
            {props.withDiamond? <Box component={'img'} src={DiamondIcon} className='mt-[3px] w-[12px] h-[9px]'/> : ""}
        </div>
    );
}

export default (props) => {
    const data = props.data;
    const withDiamond = false
    const { currentUser } = useSelector(state => state.auth);
    return (
        <div style={{display:'flex', flexDirection:'column', width:'100%', padding:'10px'}}>
            <div className="text-[16px] font-[Poppins] text-[#313131] text-center mt-[10px]">{data.name}</div>
            <div style={{display:'flex', flexDirection:'row', gap:'10px'}}>

                {data && data.oldprice && data.price && data.oldprice != data.price && data.oldprice>0 &&
                    <div style={{display:'flex', margin:'auto 0px auto auto', justifyContent:'center',    fontFamily: 'Poppins', fontWeight: 400, color: '#C6C6C6',fontSize: '12px',paddingTop: '5px',}}><del>{data.oldprice?`R$${data.oldprice/12}` : "R$-"}</del></div>
                }
                {data && data.price && Number(data.price)>0 ?
                    <div style={{display:'flex', flexDirection:'row', margin:'auto auto auto 0px', justifyContent:'center', alignItems:'baseline'}}  >
                        <div style={{fontFamily: 'Poppins',fontWeight: 500, color: '#313131',fontSize: '20px',whiteSpace: 'nowrap',}}>R$ {data.price/12}</div>
                        <div style={{display: 'flex', flexDirection: 'column',alignItems: 'center',}}>/Mês</div>
                    </div>
                    :
                    <></>
                }
            </div>
            <div className='mt-[0px] flex items-center justify-center h-[39px]'>
                {
                    data.oldprice != data.price &&  <span className="line-through text-[8px] font-[Poppins] text-[#C6C6C6]">R${data.oldprice}</span>
                }
                <span className="text-[13px] font-[Poppins] text-[#313131]">&nbsp;R$ {data.price}</span>
            </div>
            <div style={{marginBottom:'10px'}}>
            {data.text.map((item, index) => {
                if(index != 6)   return statement({text: item});
                else return statement({text: item, withDiamond, flag: currentUser.ic_currentplan == props.data.ic_plan});
            })}
            </div>

            {/* {JSON.stringify(props.data)} */}

            {/* <div className=' ' style={{display:'flex', justifyContent:'center'      , bottom:'0px', backgroundColor:'blue'}}> */}
                <HeaderBTNStyle onClick={() => {
                    props.setcheckoutData({  
                        bidname:props.data.name,
                        bidImage:AssessorImg,
                        normalprice:props.data.oldprice,
                        promoprice:props.data.price,
                        moreinfo:data.text,
                        ic_currentplan:props.data.ic_plan,
                        id_plan:props.data._id,
                    }); 
                    props.setcheckout(true)}
                    } 
                    disabled={currentUser.ic_currentplan == props.data.ic_plan} 
                    sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'},display:'flex', margin:'auto auto 0px auto', justifyContent:'center', alignItems:'center' }}
                    >Assinar</HeaderBTNStyle>
            {/* </div> */}

        </div>
    )
}