import useWindowSize from '../../../hooks/useWindowSize'
import { styled } from '@mui/material/styles';
import {
    Box,
    Stack,
} from '@mui/material';
import {useNavigate} from "react-router-dom"

// components
import ProposalButton from '../../buttons/ProposalButton';
import RatingStar from '../../../assets/icons/ratingstar.svg'
import Clock from '../../../assets/icons/clock.svg'
import Earth from '../../../assets/icons/earth.svg'
import Proposal from '../../../assets/icons/proposal.svg'
import Checked from '../../../assets/icons/checked.svg'

// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    border: '1px solid #E2E4E5',
    borderRadius: '7px',
    padding: '23px 11px 36px 12px',
    margin: '5px',
    overflow: 'hidden'
}));

const TitleText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    color: '#313131',
    whiteSpace: 'nowrap',
}));

const ItemText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '12px',
    color: '#313131',
    whiteSpace: 'nowrap',
}));

const ValueText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: '12px',
    color: '#17686E',
    whiteSpace: 'nowrap',
}));

const BodyStyle = styled('div')(({ theme }) => ({
    padding: '5px 0px 35px',
    [theme.breakpoints.up('md')]: {
        padding: '5px 0px 144px'
    }
}));

export default function DataCard({ card }) {

    const { width } = useWindowSize()
    const navigate = useNavigate();

    return (
        <RootStyle>
            <TitleText>Dados</TitleText>
            <BodyStyle>
                <Stack flexDirection={'row'} pt={'12px'}>
                    <Box component={'img'} src={RatingStar} />
                    <ItemText>&nbsp;Classificação:&nbsp;</ItemText>
                    <ValueText>{card.ratingNum?card.ratingNum.toFixed(2):'Sem avaliações até o momento'}</ValueText>
                </Stack>
                <Stack flexDirection={'row'} pt={'12px'}>
                    <Box component={'img'} src={Clock} />
                    <ItemText>&nbsp;Último acesso:&nbsp;</ItemText>
                    <ValueText>{card.lastLogIn?card.lastLogIn:'Sem dados no momento'}</ValueText>
                </Stack>
                <Stack flexDirection={'row'} pt={'12px'}>
                    <Box component={'img'} src={Earth} />
                    <ItemText>&nbsp;Registrado desde:&nbsp;</ItemText>
                    <ValueText>{card.registerSince?card.registerSince:'Sem dados no momento'}</ValueText>
                </Stack>
                <Stack flexDirection={'row'} pt={'12px'}>
                    <Box component={'img'} src={Proposal} />
                    <ItemText>&nbsp;Propostas realizadas:&nbsp;</ItemText>
                    <ValueText>{card.proposalMade?card.proposalMade:'0'}</ValueText>
                </Stack>
                <Stack flexDirection={'row'} pt={'12px'}>
                    <Box component={'img'} src={Checked} />
                    <ItemText>&nbsp;Propostas aceitas:&nbsp;</ItemText>
                    <ValueText>{card.acceptedProposl?card.acceptedProposl:'0'}</ValueText>
                </Stack>
            </BodyStyle>
            <Stack flexDirection={'row'} justifyContent={'center'}>
                <Box>
                    <ProposalButton type={1} fontsize={14} text={'Pesquisar pedidos'} event={()=>navigate('/publications') } />
                </Box>
            </Stack>
        </RootStyle>
    )
}