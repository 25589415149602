// import React ,{useSelector} from "react"








exports.createRedirect=(data)=>{
    const options = JSON.stringify(data)
    localStorage.setItem('redirect',options)
    const needRedirect = localStorage.getItem('redirect')
    if (needRedirect) {
        return true
    }
    return false
}

exports.checkNeedRedirect =()=>{
    const needRedirect = localStorage.redirect
    return needRedirect
}
exports.getNeedRedirect =()=>{
    const needRedirect = localStorage.getItem('redirect')
    return JSON.parse(needRedirect)
}


