/* eslint-disable import/no-anonymous-default-export */
// Importa o componente `styled` do pacote `@mui/material/styles`
import { styled } from '@mui/material/styles';
// Importa os componentes `useNavigate` e `Navigate` do pacote `react-router-dom`
import { useNavigate, Navigate } from 'react-router-dom';
// Importa o componente `useState` do pacote React
import { useState } from 'react';
import { setUser } from '../../redux/actions/auth.action';
// Importa o ícone `Sms_icon` do caminho `'../../assets/icons/sms_icon.svg'`
import Sms_icon from '../../assets/icons/sms_icon.svg';
// Importa o ícone `Email_icon` do caminho `'../../assets/icons/email_icon.svg'`
import Email_icon from '../../assets/icons/email_icon.svg';
// Importa o ícone `Arrowright_icon` do caminho `'../../assets/icons/arrow_right.svg'`
import Arrowright_icon from '../../assets/icons/arrow_right.svg';
// Importa o ícone `User_icon` do caminho `'../../assets/icons/usericon2.svg'`
import User_icon from '../../assets/icons/usericon2.svg'
// Importa o componente `useEffect` do pacote React
import { useEffect } from 'react';
import { setTempUser } from '../../redux/actions/auth.action';
// Importa o arquivo `API` do caminho `'../../redux/API'`
import  API from '../../redux/API';
// Importa o componente `Box` do pacote `@mui/material`
import { Box } from '@mui/material';
// Importa o componente `useRef` do pacote React
import { useRef } from 'react';
// Importa o componente `useSelector` do pacote `react-redux`
import { useSelector } from 'react-redux';
// Importa o componente `toast` do pacote `react-toast`
import { toast } from 'react-toast';

import {login, setCurrentProfile} from '../../redux/actions/auth.action'
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import Loading from '../../components/loading/Loading';


// Declara uma constante `HeaderBTNStyle` que é um botão estilizado utilizando o componente `styled`
const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '315px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const Timelimit = 120;

export default ({pageNum, is2Step, })=>{

    // Importação do hook useNavigate da biblioteca react-router-dom
    const navigate = useNavigate();
    const {currentUser} = useSelector(state => state.auth);

    const dispatch = useDispatch();

    // Inicialização dos estados 'minutes' e 'time' com o valor de Timelimit dividido por 60
    const [minutes, setMinutes] = useState(Timelimit/60);
    const [time, setTime] = useState(Timelimit);
    
    
    const [loading, setLoading] = useState(false);

    // Inicialização do estado 'seconds' com o valor 0
    const [seconds, setSeconds] = useState(0);

    // Inicialização do estado 'page' com o valor de pageNum
    const [page, setPage] = useState(pageNum);

    // Inicialização do estado 'enableResend' com o valor true
    const [enableResend, setEnableResend] = useState(true);

    // Variável para controlar o intervalo de tempo
    var interval;

    // Criação de referências para inputs
    const input1 = useRef();
    const input2 = useRef();
    const input3 = useRef();
    const input4 = useRef();
    const input5 = useRef();
    const input6 = useRef();

    // Importação da informação do usuário temporário do estado do Redux
    const {tempUser} = useSelector(state => state.auth);
    // Caso o usuário temporário não exista, o usuário é redirecionado para a página inicial '/'
    
    useEffect(()=>{
        if(!currentUser && !tempUser){
            return navigate('/')
        }
        else if(currentUser){
            dispatch(setTempUser(currentUser.ds_email))

        }
    },[currentUser])

    // Inicialização do estado 'timerPause' com o valor false
    const [timerPause, setTimerPause] = useState(false);
    
    // Função que retorna o tempo atual
    const getTime = () => {
        
        //console.log(time)
        if(timerPause) return;
        
        setMinutes(Math.floor(((time - 1) / 60) % 60));
        setSeconds(Math.floor((time - 1) % 60));
        setTime(time - 1);
      };
    
    // Efeito que atualiza o estado 'page' com o valor de pageNum
    useEffect(() => {
        setPage(pageNum);
    }, [pageNum])

    // Efeito que verifica se o tempo chegou a 0 e, caso sim, atualiza os estados 'time', 'timerPause' e 'enableResend'
    useEffect(() => {
        if(time === 0){
            setTime(Timelimit);
            setTimerPause(true);
            setEnableResend(false);
        }
    }, [time])
    useEffect(() => {
        if(page !== 1)
        {
             interval = setInterval(() => getTime(), 1000);
        
            return () => clearInterval(interval);
        }
      }, [page, time, timerPause]);

    const [errorMsg1, setErrorMsg1] = useState(false);
    const [errorMsg2, setErrorMsg2] = useState(false);



    
    // State para controlar o valor do email digitado pelo usuário
    const [email, setEmail] = useState('');
    
    // State para controlar a visibilidade do loading
    const [loadingOpen, setLoadingOpen] = useState(false);

    // logar após validar
        const handleLogin = () => {
            // Definição dos parâmetros da requisição de login
            let requestParams = {
                ds_email: tempUser,
                firstAccess: true
            };
            
            // Alteração do estado do loading para verdadeiro
            setLoadingOpen(true);

            // Disparo da ação de login com os parâmetros de requisição e informações para controlar o loading e a navegação
            dispatch(login(requestParams, setLoadingOpen, navigate))
        }


    // Debounce the handleLogin function
    const debouncedHandleLogin = _.debounce(handleLogin, 500);


    // Função que confirma o código de verificação
    const confirmVerificationCode = () =>{

        // Cria uma string com o valor dos inputs
        let code = '';
        code += input1.current.value;
        code += input2.current.value;
        code += input3.current.value;
        code += input4.current.value;
        code += input5.current.value;
        code += input6.current.value;
        // Escolhe a URL a ser usada, dependendo da página atual
        let url = '';
        if(!currentUser){
            //console.log('pageNum === ',pageNum);
            //console.log('NO currentUser');
            if(pageNum === 1) {url = 'auth/setUpPassword';}
            if(pageNum === 3) {url = 'auth/verification';}
        }
        else if(currentUser.ic_securitymode){
            //console.log('currentUser.ic_securitymode');
            url = 'user/verify2Step'
        }
        else if(!currentUser.ic_emailverified){
            //console.log('NO currentUser.ic_emailverified');
            url = 'auth/verification'
        }
        //console.log('url === ', url);
        // Faz uma requisição para a API
        API.post(url, {
            email: tempUser,
            code
        })
        .then((result) => {
            //console.log(`response OK from server`);
            // Se a requisição foi bem-sucedida
            if (result.data.status === "success") {
                // Mostra uma mensagem de sucesso, dependendo da página atual
                
                if(!currentUser){
                    //console.log(`NO currentUser`);
                    if(result.data.user){
                        //console.log(`dispatch(setUser(result.data.user))`);
                        dispatch(setUser(result.data.user));
                        //console.log('OK === dispatch(setUser(result.data.user))');
                    }
                    if(pageNum === 1){
                        toast.success('A senha foi redefinida com sucesso.\nVocê pode confirmar sua nova senha em sua caixa de entrada de e-mail.');
                        //console.log(`pageNum === 1`);
                        //console.log(`debouncedHandleLogin()`);
                        return debouncedHandleLogin()
                        //console.log(`OK === debouncedHandleLogin()`);
                        // return navigate('/account')
                    }
                    else if(pageNum === 3){
                        toast.success('Inscrito com sucesso. Agora você pode entrar no Docstree.');
                        //console.log(`pageNum === 3`);
                        //console.log(`debouncedHandleLogin()`);
                        return debouncedHandleLogin()
                        //console.log(`OK === debouncedHandleLogin()`);
                        // return navigate('/account')
                    }
                    else{
                        //console.log('pageNum !== 1 e 3');
                        return navigate('/login')
                    }                    
                }
                else if(currentUser.ic_securitymode){
                    //console.log('currentUser.ic_securitymode');
                    if(result.data.user){
                        //console.log('dispatch(setUser(result.data.user))');
                        dispatch(setUser(result.data.user));
                        //console.log('OK === dispatch(setUser(result.data.user))');
                    }
                    toast.success('Autenticação verificada.');
                    localStorage.setItem("is2step", 'true');
                    //console.log('OK === localStorage.setItem("is2step", "true")');
                    return navigate('/account')
                }
                if(result.data.user){
                    //console.log('currentUser, but NO currentUser.ic_securitymode ');
                    //console.log('dispatch(setUser(result.data.user))');
                    dispatch(setUser(result.data.user));
                    //console.log('OK === dispatch(setUser(result.data.user))');
                }
                    toast.success('Autenticação verificada.');
                    return navigate('/account')
           }
        })
        .catch((err) => {
            //console.log('RESPONSE === NOT OK');
            //console.log(err);
            // Se houve um erro na requisição
            // Atualiza as mensagens de erro            
            if(JSON.parse(err.request.response).message === 'Incorrect Code.')           {setErrorMsg1(true); setErrorMsg2(false);}
            if(JSON.parse(err.request.response).message === 'Expired Code.')             {setErrorMsg1(false);setErrorMsg2(true);}
            
            // Modifica a borda dos inputs
            input1.current.style.border = '1px solid #DE2B2B';
            input2.current.style.border = '1px solid #DE2B2B';
            input3.current.style.border = '1px solid #DE2B2B';
            input4.current.style.border = '1px solid #DE2B2B';
            input5.current.style.border = '1px solid #DE2B2B';
            input6.current.style.border = '1px solid #DE2B2B';

        });
    }

    // Função para reenviar o código de verificação
    const resendVerficationCode = () => {
        setLoading(true)
        // Habilitar a opção de reenviar o código
        setEnableResend(true); 
        // Configurar o tempo de volta ao limite
        setTime(Timelimit);
        // Retomar o tempo
        setTimerPause(false);
        API.post('auth/resend', {
            ds_email: tempUser,
        }).then(()=>{
            setLoading(false)
        }).catch(()=>{
            setLoading(false)
        })


    }

    // Função para mudar para o modo de verificação por email
    const withEmail = () => {
        setLoading(true)
        // Mudar para a página de verificação por email
        // Enviar o email para redefinir a senha com a API
        if (!is2Step) {
            API.post('auth/forgotpassword', { 
                ds_email: tempUser,
            })
            .then((res)=>{
                setPage(3);
                setLoading(false)
            })
            .catch((err)=>{
                toast.error('Erro ao prosseguir. Contate o suporte')
                //console.log(err); 
                setLoading(false)
            })
        }
        else if(is2Step){
            API.post('user/set2step', {ds_email: tempUser,})
            .then((res)=>{
                if(res.data.status==='success'){
                    toast.success(res.data.message)
                    setPage(3);
                    setLoading(false)
                }
            })
            .catch((err)=>{
                toast.error('Erro ao prosseguir. Contate o suporte')
                //console.log(err); 
                setLoading(false)
            })
        }
    }

    const handlePaste = e => {
        //console.log('pasting code');
      
        if (e.currentTarget.tagName === 'INPUT') {
          e.preventDefault();
          let paste = '';
      
          if (e.clipboardData && e.clipboardData.getData) {
            // For modern browsers
            paste = e.clipboardData.getData('text/plain');
          } else if (window.clipboardData && window.clipboardData.getData) {
            // For older IE browsers
            paste = window.clipboardData.getData('Text');
          }
      
          const numbers = paste.match(/\d/g);
      
          if (numbers && numbers.length === 6) {
            input1.current.value = numbers[0];
            input2.current.value = numbers[1];
            input3.current.value = numbers[2];
            input4.current.value = numbers[3];
            input5.current.value = numbers[4];
            input6.current.value = numbers[5];
          }
        }
      };
      



      //função que move para o próximo campo de entrada se o valor da entrada corrente for maior ou igual a 1
    //move para o campo anterior se o valor da entrada corrente for igual a 0 (usuário pressionou backspace)    
    const nextstep = async e => {
        //console.log("nextstep function has been called");

        e.preventDefault();
      
        if (e.key === 'v' && (e.metaKey || e.ctrlKey)) {
          let paste = await navigator.clipboard.readText()
            
          //console.log(paste);

            input1.current.value = paste[0];
            input2.current.value = paste[1];
            input3.current.value = paste[2];
            input4.current.value = paste[3];
            input5.current.value = paste[4];
            input6.current.value = paste[5];

        } else if ((e.key >= 0 && e.key <= 9) || e.key === 'Backspace') {
          if (e.key === 'Backspace') {
            e.target.value = '';
          } else {
            e.target.value = e.key;
          }
      
          var target = e.srcElement || e.target;
          var myLength = target.value.length;
      
          if (myLength >= 1) {
            var next = target.nextElementSibling;
            while (next) {
              if (next.tagName.toLowerCase() === 'input') {
                next.focus();
                break;
              }
              next = next.nextElementSibling;
            }
          } else if (myLength === 0) {
            var previous = target.previousElementSibling;
            while (previous) {
              if (previous.tagName.toLowerCase() === 'input') {
                previous.focus();
                break;
              }
              previous = previous.previousElementSibling;
            }
          }
        }
      };
      


    return(
        <div className='mb:w-full sm:w-[615px] rounded-[7px] shadow-[-2px_3px_50px_rgba(0,0,0,0.15)] flex flex-col items-center'>
            {loading && <Loading nameToShow="Carregando"/>}
            <div className='mt-[40px] mb-[40px]'>
                {
                    page === 1 &&
                    <div>
                        <div className='font-[Poppins] font-semibold text-[20px] leading-[24px] text-[#313131]  ml-[32px] flex justify-start'>
                            <div >Valide que esta é a sua conta </div>
                        </div>
                        <div className='font-[Poppins] font-light text-[14px] leading-[24px] text-[#313131] mt-[5px] ml-[32px] flex justify-start'>
                            <div >Escolha como quer receber o código de validação.</div>
                        </div>
                    </div>
                }
                {
                    page === 2 &&
                    <div>
                        <div className='font-[Poppins] font-semibold text-[20px] leading-[24px] text-[#313131]  ml-[32px] flex justify-start'>
                            <div >Informe o código de verificação</div>
                        </div>
                        <div className='font-[Poppins] font-light text-[14px] leading-[24px] text-[#313131] mt-[5px] ml-[32px] flex justify-start'>
                            <div >Você recebeu um número de 6 dígitos por SMS.</div>
                        </div>
                        
                    </div>
                }
                                {
                    page === 3 &&
                    <div>
                        <div className='font-[Poppins] font-semibold text-[20px] leading-[24px] text-[#313131]  mb:ml-[30px] sm:ml-[80px] flex justify-start w-[80%]'>
                            <div >Digite o código que enviamos por e-mail</div>
                        </div>
                        <div className='font-[Poppins] font-light text-[14px] leading-[24px] text-[#313131] mt-[30px] mb:ml-[30px] sm:ml-[80px] flex justify-start mb:w-[80%] sm:w-[70%]'>
                            <div >Enviamos um código no seu e-mail para que possa acessar sua conta. Caso não o encontre, verifique sua caixa de spam.</div>
                        </div>
                        <div className='w-full flex justify-center'>
                            <div className='w-fit border-solid border-[1px] border-[#C6C6C6] rounded-[15px] flex h-[30px] justify-center items-center mt-[30px]'>
                                <Box component="img" src={User_icon} alt="usuario-icone" width='10px' height='12px' className='ml-[10px]'></Box>
                                <div className='font-[Poppins] font-light text-[12px] leading-[18px] text-[#313131] ml-[5px] mr-[25px]'>{tempUser}</div>
                            </div>
                        </div>

                    </div>
                }
                {
                    page !== 1 && 
                    <>
                        <div className='w-full flex justify-center'>
                            <div className='mb:w-[280px] sm:w-[460px] mb:mt-[30px] sm:mt-[60px]'>                                
                                <input type='text' ref={input1} onKeyDown={nextstep} onPaste={handlePaste} className='text-center rounded-[5px] mr-[20px] mb:w-[30px] mb:h-[40px] sm:w-[60px] sm:h-[70px] !text-[25px] !text-[#17686E] leading-[38px] border-solid border-[1px] border-[#17686E] font-semibold'/>
                                <input type='text' ref={input2} onKeyDown={nextstep} onPaste={handlePaste} className='text-center rounded-[5px] mr-[20px] mb:w-[30px] mb:h-[40px] sm:w-[60px] sm:h-[70px] !text-[25px] !text-[#17686E] leading-[38px] border-solid border-[1px] border-[#17686E] font-semibold'/>
                                <input type='text' ref={input3} onKeyDown={nextstep} onPaste={handlePaste} className='text-center rounded-[5px] mr-[20px] mb:w-[30px] mb:h-[40px] sm:w-[60px] sm:h-[70px] !text-[25px] !text-[#17686E] leading-[38px] border-solid border-[1px] border-[#17686E] font-semibold'/>
                                <input type='text' ref={input4} onKeyDown={nextstep} onPaste={handlePaste} className='text-center rounded-[5px] mr-[20px] mb:w-[30px] mb:h-[40px] sm:w-[60px] sm:h-[70px] !text-[25px] !text-[#17686E] leading-[38px] border-solid border-[1px] border-[#17686E] font-semibold'/>
                                <input type='text' ref={input5} onKeyDown={nextstep} onPaste={handlePaste} className='text-center rounded-[5px] mr-[20px] mb:w-[30px] mb:h-[40px] sm:w-[60px] sm:h-[70px] !text-[25px] !text-[#17686E] leading-[38px] border-solid border-[1px] border-[#17686E] font-semibold'/>
                                <input type='text' ref={input6} onKeyDown={nextstep} onPaste={handlePaste} className='text-center rounded-[5px] mr-[0px] mb:w-[30px] mb:h-[40px] sm:w-[60px] sm:h-[70px] !text-[25px] !text-[#17686E] leading-[38px] border-solid border-[1px] border-[#17686E] font-semibold'/>                                                                                                
                            </div>
                        </div>
                        {
                            errorMsg1 && 
                        <div className='w-full flex justify-center'>
                            <div className='mt-[20px] font-[Poppins] font-semibold text-[14px] leading-[24px] text-[#DE2B2B]'>O código está incorreto.</div>
                        </div>
                        }
                        {
                            errorMsg2 && 
                        <div className='w-full flex justify-center'>
                            <div className='mt-[20px] font-[Poppins] font-semibold text-[14px] leading-[24px] text-[#DE2B2B]'>O código foi expirado, reenvie novamente.</div>
                        </div>
                        }
                    </>
                }
                {
                    page === 1 &&
                        <div>
                            {/* <div className="mt-[55px] w-full flex-row justify-center flex h-[60px]">
                                <div className='mb:w-[85%] sm:w-[420px] flex'>
                                    <div className="bg-[#17686E] bg-opacity-[0.11] w-[60px] h-[60px] flex items-center justify-center rounded-[30px] mr-[20px]">
                                        <Box component='img' src={Sms_icon} width="25px" height='25px'></Box>
                                    </div>
                                    <div className='w-fit flex flex-col justify-between'>
                                        <div className="flex text-[14px] leading-[24px] font-normal font-[Poppins] text-[#464A53]">SMS</div>
                                        <div className="flex text-[14px] leading-[24px] font-normal font-[Poppins] text-[#464A53]">No celular terminado em 1111</div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center cursor-pointer w-[20px]" onClick={()=>setPage(2)}>
                                    <Box component='img' src={Arrowright_icon} width="10px" height='6.85px'></Box>
                                </div>
                            </div> */}
                            <div className="mt-[55px] w-full flex-row justify-center flex h-[60px] cursor-pointer" onClick={withEmail}>
                                <div className='mb:w-[85%] sm:w-[420px] flex'>
                                    <div className="bg-[#17686E] bg-opacity-[0.11] w-[60px] h-[60px] flex items-center justify-center rounded-[30px] mr-[20px]">
                                        <Box component='img' src={Email_icon} width="25px" height='25px'></Box>
                                    </div>
                                    <div className='w-fit flex flex-col justify-between'>
                                        <div className="flex text-[14px] leading-[24px] font-normal font-[Poppins] text-[#464A53]">E-mail</div>
                                        <div className="flex text-[14px] leading-[24px] font-normal font-[Poppins] text-[#464A53]">{tempUser}</div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center cursor-pointer w-[20px]" >
                                    <Box component='img' src={Arrowright_icon} width="10px" height='6.85px'></Box>
                                </div>
                            </div>
                        </div>
                }
                {
                    (page === 2 || page === 3) && 
                    <div clasName='w-full'>
                        <div className='mb:mt-[25px] sm:mt-[60px] flex flex-col items-center'>
                        <HeaderBTNStyle
                                onClick={confirmVerificationCode}
                                disabled={input6.current && !input6.current.value}
                                sx={{
                                '&:disabled' : {
                                    backgroundColor: '#C6C6C6',
                                    color: '#FFFFFF',
                                    border: '1px solid #C6C6C6',
                                    cursor: 'not-allowed'
                                }}}
                                className="mb:!w-[180px] sm:!w-[315px]"
                            >
                            Continuar
                        </HeaderBTNStyle> 
                        <HeaderBTNStyle
                                onClick={resendVerficationCode}
                                disabled={enableResend}
                                sx={{
                                '&:disabled' : {
                                    backgroundColor: '#C6C6C6',
                                    color: '#FFFFFF',
                                    border: '1px solid #C6C6C6',
                                    cursor: 'not-allowed'
                                }
                            }}>
                            Reenviar código 
            
                            { enableResend && 'em '+minutes.toLocaleString('en-US', { minimumIntegerDigits: 2,  useGrouping: false})+':'+seconds.toLocaleString('en-US', { minimumIntegerDigits: 2,  useGrouping: false})}   
                        </HeaderBTNStyle> 
                        </div>


                    </div>   
                }
                
            </div>

        </div>
    )
}