/* eslint-disable import/no-anonymous-default-export */



import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux'


import GmailIcon from '../../assets/icons/gmailicon.svg'
import OutlookIcon from '../../assets/icons/outlookicon.svg'
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CopyIcon from '../../assets/icons/copyicon.svg'
import { Box } from '@mui/material';
import {SocialIcon} from 'react-social-icons'
import {WhatsappShareButton, WhatsappIcon} from 'react-share';
import {FacebookShareButton, FacebookIcon} from 'react-share';
import {TwitterShareButton, TwitterIcon} from 'react-share';
import {TelegramShareButton, TelegramIcon} from 'react-share';
import {FacebookMessengerShareButton, FacebookMessengerIcon} from 'react-share';
import {EmailShareButton } from 'react-share';
import { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import API from '../../redux/API';
import { toast } from 'react-toast';

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

export default  ({researcherList, setResearchList, submitClientID,  orderid}) => {
    const {currentUser} = useSelector(state => state.auth);

    const [linkurl, setLinkurl] = useState('')

    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] whitespace-nowrap mb-[5px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px]"
    }
    const alterNamekeyPress = (e) =>{
        if(e.keyCode == 13){
            e.preventDefault();
            //console.log(inputField.current.getElementsByClassName('MuiAutocomplete-input')[0].value);
            const temp = [...experts]; 
            if(temp.indexOf(e.target.defaultValue) == -1) temp.push(e.target.defaultValue); 
            setExperts(temp); 
            inputField.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
        }
    }
    const inputField = useRef(null);

    useEffect(()=>{
        if(researcherList.length === 0)
        {
            API.get(`auth/getClientsID`)
            .then((result) => {
                setResearchList(result.data.client)
            })
            .catch((err) => {
                console.error(err.request.response)
            });
        } 
    }, []);

    const [orderID,setOrderID]=useState(orderid)

    useEffect(()=>{
        if(!orderID){
            API.post('order/createlinkforlastimportedorder', {
                    id_researcher:currentUser.id?currentUser.id:currentUser._id,
                }
            )
            .then((res)=>{
                if(res.data.status==='success'){
                    setOrderID(res.data.lastOrder._id)
                    setLinkurl(`docs-tree.com/order/${res.data.lastOrder._id?res.data.lastOrder._id:res.data.lastOrder.id?res.data.lastOrder.id:''}`)
                }
            })
            .catch((err)=>{
                console.log(err);
                toast.error(err)
            })
        }
        else if(orderID){
            setLinkurl(`docs-tree.com/order/${orderID}`)
        }
    },[orderid,orderID])

    const [experts, setExperts] = useState([]);
    const [expert, setExpert] = useState('');
    const deleteExpertName = (index) => {
        let temp = [...experts];
        temp.splice(index,1);
        setExperts(temp);
    }
    return(
        <div className='max-w-[523px] w-[100%] rounded-[7px] shadow-[-2px_3px_50px_rgba(0,0,0,0.15)] mt-[40px] z-10 pb-[20px]'>
        <div className=''>
            <div className='mt-[29px] ml-[27px] font-[Poppins] font-bold text-[14px] leading-[148%] text-[#313131] '>Compartilhar</div>
            <div className="flex flex-col items-center ">
                <div className='flex w-[70%] justify-between  mt-[27px]'>
                    <div className="flex flex-col items-center">
                    <WhatsappShareButton url={linkurl} className='w-[50px] h-[50px]'>
                        <WhatsappIcon size={50} round></WhatsappIcon>
                    </WhatsappShareButton>
                    {/* <Box component={'img'} src={WhatsAppIcon} className='w-[50px] h-[50px] '/> */}
                    <div className='mt-[11px]  font-[Poppins] font-normal text-[10px] leading-[148%] text-[#313131]'>WhatsApp</div>
                    </div>
                    <div className="flex flex-col items-center">
                    <FacebookShareButton url={linkurl}   className='w-[50px] h-[50px]'>
                        <FacebookIcon size={50} round></FacebookIcon>
                    </FacebookShareButton>     
                    <div className='mt-[11px]  font-[Poppins] font-normal text-[10px] leading-[148%] text-[#313131]'>Facebook</div>
                    </div>
                    <div className="flex flex-col items-center">
                    <TwitterShareButton  url={linkurl}  className='w-[50px] h-[50px]'>
                        <TwitterIcon size={50} round></TwitterIcon>
                    </TwitterShareButton>  
                    <div className='mt-[11px]  font-[Poppins] font-normal text-[10px] leading-[148%] text-[#313131]'>Twitter</div>
                    </div>
                    <div className="flex flex-col items-center">
                    {/* <Box component={'img'} src={InstagramIcon} className='w-[50px] h-[50px]  cursor-pointer'/> */}
                    <SocialIcon url={linkurl} network="instagram" className="w-[50px] h-[50px]"></SocialIcon>

                    <div className='mt-[11px]  font-[Poppins] font-normal text-[10px] leading-[148%] text-[#313131]'>Instagram</div>
                    </div>
                </div>
                <div className='flex w-[70%] justify-between mt-[27px]'>
                    <div className="flex flex-col items-center">
                    <TelegramShareButton  url={linkurl}  className='w-[50px] h-[50px]'>
                        <TelegramIcon size={50} round></TelegramIcon>
                    </TelegramShareButton>    
                    <div className='mt-[11px]  font-[Poppins] font-normal text-[10px] leading-[148%] text-[#313131]'>Telegram</div>
                    </div>
                    <div className="flex flex-col items-center">
                    <FacebookMessengerShareButton  url={linkurl}  className='w-[50px] h-[50px]'>
                        <FacebookMessengerIcon size={50} round></FacebookMessengerIcon>
                    </FacebookMessengerShareButton>    
                    <div className='mt-[11px]  font-[Poppins] font-normal text-[10px] leading-[148%] text-[#313131]'>Messenger</div>
                    </div>
                    <div className="flex flex-col items-center">
                    {/* <SocialIcon url="https://mail.google.com" disabled className="w-[50px] h-[50px]"/> */}
                    <a href="https://mail.google.com" target='_blank'>
                    <Box component={'img'} src={GmailIcon} className='w-[50px] h-[50px] cursor-pointer'/>
                    </a>
                    <div className='mt-[11px]  font-[Poppins] font-normal text-[10px] leading-[148%] text-[#313131]'>Gmail</div>
                    </div>
                    <div className="flex flex-col items-center">
                    {/* <SocialIcon url="https://outlook.live.com/owa/" className="w-[50px] h-[50px]"/> */}
                    <EmailShareButton             
                        url={linkurl}
                        subject={linkurl}
                        body="body">
                        <Box component={'img'} src={OutlookIcon} className='w-[50px] h-[50px]  cursor-pointer'/>
                    </EmailShareButton>
                    {/* <a href="https://outlook.live.com" target='_blank'>
                    <Box component={'img'} src={OutlookIcon} className='w-[50px] h-[50px]  cursor-pointer'/></a> */}
                    <div className='mt-[11px]  font-[Poppins] font-normal text-[10px] leading-[148%] text-[#313131]'>Outlook</div>
                    </div>
                </div>
            </div>
        </div>
        <div className='mt-[29px] ml-[27px]'>
            <div className='mb-[5px] font-[Poppins] font-bold text-[14px] leading-[148%] text-[#313131] '>Link</div>
            <TextField
            className='w-[90%] '
            sx={{'& .MuiInputBase-input': {fontFamily: 'Poppins',fontSize: '12px'} ,'& .MuiInputBase-input::placeholder': {color: '#313131', opacity: 1}}}
            value={linkurl}
            
            // onChange={(e) => setLinkurl(e.target.value)}
            InputProps={{
                className: "h-[40px] font-[Poppins] font-normal text-[12px] leading-[148%] text-[#313131] ",
                endAdornment: (
                <InputAdornment position="end" className="cursor-pointer" >

                    <IconButton onClick={()=> navigator.clipboard.writeText(linkurl)}>

                        <Box component={'img'} src={CopyIcon} className='w-[12.5px] h-[15px]'/>
                    </IconButton>
                </InputAdornment>
                ),
            }}
            />
        </div>
        <div className='mt-[29px] ml-[27px]'>
            {/* <div className='mb-[5px] font-[Poppins] font-bold text-[14px] leading-[148%] text-[#313131] '>Buscar cliente </div>
            <div className='mb-[5px] font-[Poppins] font-normal text-[12px] leading-[148%] text-[#313131] '>Apenas clientes já cadastrados na plataforma.</div>
            <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    className='w-[90%] '
                    ref={inputField}
                    value={expert}

                    onKeyDown={alterNamekeyPress}
                    sx={{
                        '& .MuiAutocomplete-inputRoot': {height: '40px', padding: '0px !important' ,paddingLeft: '10px !important', fontFamily: 'Poppins',fontSize: '12px'},
                    }}
                    ListboxProps= {{
                        sx: {
                            "& :hover": { backgroundColor: "rgba(23, 104, 110, 0.11) !important"},
                            "& [aria-selected='true']": { backgroundColor: "rgba(23, 104, 110, 0.4) !important" }
                        }
                    }}
                    onChange={(e) => {
                        if(e.target.tagName != 'LI') return;
                        const temp = [...experts]; 
                        if(temp.indexOf(e.target.innerHTML) == -1) temp.push(e.target.innerHTML); 
                        setExperts(temp); 
                        inputField.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
                    }}
                    options={researcherList}
                    renderInput={(params) => <TextField    {...params} sx={{'& .MuiInputBase-input::placeholder': {color: '#313131', opacity: 1}}} placeholder='Busque por ID ou nome completo.' />}
            />
            <div className='flex mt-[10px] flex-wrap'>
                {experts.map((item, index) => {
                    return(
                    <div className="mb-[10px] min-w-[145px] flex justify-between items-center mr-[7px] pt-[5px] pb-[5px] pl-[15px] pr-[15px] w-fit rounded-[15px] border-solid border-[1px] border-[#C6C6C6] font-[Poppins] font-normal text-[12px] leading-[18px]">
                        <div>{item}</div>
                        <IconButton
                            className='!ml-[40px]'
                            aria-label="close"
                            onClick={() => deleteExpertName(index)}
                            sx={{
                                color: (theme) => theme.palette.black,
                                width: '9px',
                                height: '9px'
                            }}
                        >
                            <CloseIcon className="!text-[12px]"/>
                        </IconButton>
                    </div>
                )})}
            </div> */}
            {/* <div className="flex justify-center ml-[-27px]">
                <HeaderBTNStyle onClick={submitClientID} disabled={experts.length === 0} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor:'not-allowed'}}}>Enviar</HeaderBTNStyle>
            </div> */}
        </div>
        </div>

    )
}
