/* eslint-disable import/no-anonymous-default-export */
import React, {useState, useEffect, useRef, useLayoutEffect } from 'react';
import API from '../../redux/API';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/actions/auth.action';
import MySwitch from '../Switcher';

import Loading from '../loading/Loading';

export default () => {
    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] mb:mt-[15px] md:mt-[10px] mb-[10px] mb:ml-[6px] md:ml-[10px] w-[272px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px]"
    }
    const { currentUser } = useSelector(state => state.auth);

    
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [ic_getnewdailyorders, setic_getnewdailyorders] = useState(true);
    const [ic_websitenews, setic_websitenews] = useState(true);
    const [ic_whengetnewmessage, setic_whengetnewmessage] = useState(true);
    const [ic_orderinvites, setic_orderinvites] = useState(true);
    const [ic_whenbidisaccepted, setic_whenbidisaccepted] = useState(true);
    const [ic_whengetnewbid, setic_whengetnewbid] = useState(true);
    useLayoutEffect(()=>{
        if(currentUser){
          setic_getnewdailyorders(currentUser.ic_getnewdailyorders === undefined ? true : currentUser.ic_getnewdailyorders)
          setic_websitenews(currentUser.ic_websitenews === undefined ? true : currentUser.ic_websitenews)
          setic_whengetnewmessage(currentUser.ic_whengetnewmessage === undefined ? true : currentUser.ic_whengetnewmessage)
          setic_orderinvites(currentUser.ic_orderinvites === undefined ? true : currentUser.ic_orderinvites)
          setic_whenbidisaccepted(currentUser.ic_whenbidisaccepted === undefined ? true : currentUser.ic_whenbidisaccepted)
          setic_whengetnewbid(currentUser.ic_whengetnewbid === undefined ? true : currentUser.ic_whengetnewbid)
        }
    }, [currentUser])

    const setChangeSetting = async (data) => { 
      setLoading(true)

      try{
        const result = await API.post('/user/setnotificatoinsetting', data);
        if (result.data.status === "success") {

             dispatch(setUser(result.data.user))
             setTimeout(() => {
               setLoading(false)
             }, 1000);

        }
      }
      catch(err) {
          console.log(err);
          setTimeout(() => {
            setLoading(false)
          }, 1000);

      };

    }
    const { currentProfile } = useSelector(state => state.auth);

    const handleChange1 = (e) => {
      //console.log(e.target.checked);
      const data ={
        ic_getnewdailyorders:e.target.checked,
        ic_websitenews,
        ic_whengetnewmessage,
        ic_orderinvites,
        ic_whenbidisaccepted,
        ic_whengetnewbid,
        ds_email: currentUser.ds_email,
        ic_profile: currentProfile
    }
    setChangeSetting(data);
    setic_getnewdailyorders(e.target.checked);
      
    }
    const handleChange2 = (e) => {
      setic_websitenews(e.target.checked);
      const data ={
        ic_getnewdailyorders,
        ic_websitenews:!ic_websitenews,
        ic_whengetnewmessage,
        ic_orderinvites,
        ic_whenbidisaccepted,
        ic_whengetnewbid,
        ds_email: currentUser.ds_email,
        ic_profile: currentProfile
    }
      setChangeSetting(data);
    }
    const handleChange3 = (e) => {
      setic_whengetnewmessage(e.target.checked);
      const data ={
        ic_getnewdailyorders,
        ic_websitenews,
        ic_whengetnewmessage:!ic_whengetnewmessage,
        ic_orderinvites,
        ic_whenbidisaccepted,
        ic_whengetnewbid,
        ds_email: currentUser.ds_email,
        ic_profile: currentProfile
    }
      setChangeSetting(data);
    }
    const handleChange4 = (e) => {
      setic_orderinvites(e.target.checked);
      const data ={
        ic_getnewdailyorders,
        ic_websitenews,
        ic_whengetnewmessage,
        ic_orderinvites:!ic_orderinvites,
        ic_whenbidisaccepted,
        ic_whengetnewbid,
        ds_email: currentUser.ds_email,
        ic_profile: currentProfile
    }
      setChangeSetting(data);
    }
    const handleChange5 = (e) => {
      setic_whenbidisaccepted(e.target.checked);
      const data ={
        ic_getnewdailyorders,
        ic_websitenews,
        ic_whengetnewmessage,
        ic_orderinvites,
        ic_whenbidisaccepted:!ic_whenbidisaccepted,
        ic_whengetnewbid,
        ds_email: currentUser.ds_email,
        ic_profile: currentProfile
    }
      setChangeSetting(data);
    }
    const handleChange6 = (e) => {
      setic_whengetnewbid(e.target.checked);
      const data ={
        ic_getnewdailyorders,
        ic_websitenews,
        ic_whengetnewmessage,
        ic_orderinvites,
        ic_whenbidisaccepted,
        ic_whengetnewbid:!ic_whengetnewbid,
        ds_email: currentUser.ds_email,
        ic_profile: currentProfile
    }
      setChangeSetting(data);
    }
    return (
        <div className='mb:w-full md:w-[95%] float-right'>
          {loading && <Loading/>}
            <div className='w-[100%] '>
                <div className='w-full '>
                    <div className="font-[Poppins] font-bold text-[18px] leading-[40px] text-[#464A53] whitespace-nowrap mb-[5px] mb:ml-[6px] md:ml-[0px]">Geral</div>
                      
                    <div className='mb:w-full md:w-[400px] mb:mb-[30px] md:mb-[160px]'>
                        <div className='flex items-center justify-between'>
                            <div className={style.title}>
                              Receber e-mails diários com as novas publicações de acordo com minha especiliadade
                            </div>
                            <div className="mb:mr-[15px] md:mr-[50px]">
                              <MySwitch checked={ic_getnewdailyorders} onChange={handleChange1}></MySwitch>
                            </div>
                        </div>

                        <div className='flex items-center justify-between'>
                            <div className={style.title}>
                             Receber as novidades da plataforma
                            </div>
                            <div className="mb:mr-[15px] md:mr-[50px]">
                              <MySwitch checked={ic_websitenews}  onChange={handleChange2}></MySwitch>
                            </div>
                        </div>

                        <div className='flex items-center justify-between'>
                            <div className={style.title}>
                            Quando enviam uma mensagem
                            </div>
                            <div className="mb:mr-[15px] md:mr-[50px]">
                              <MySwitch checked={ic_whengetnewmessage}  onChange={handleChange3}></MySwitch>
                            </div>
                        </div>

                        {currentProfile===1 &&
                        <div className='flex items-center justify-between'>
                            <div className={style.title}>
                            Quando me convidam para uma pesquisa
                            </div>
                            <div className="mb:mr-[15px] md:mr-[50px]">
                              <MySwitch checked={ic_orderinvites}  onChange={handleChange4}></MySwitch>
                            </div>
                        </div>
                        }
                        {currentProfile===1 &&

                        <div className='flex items-center justify-between'>
                            <div className={style.title}>
                            Quando uma das minhas propostas é aceita
                            </div>
                            <div className="mb:mr-[15px] md:mr-[50px]">
                              <MySwitch checked={ic_whenbidisaccepted}  onChange={handleChange5}></MySwitch>
                            </div>
                        </div>
                        }
                        {currentProfile===0 &&

                        <div className='flex items-center justify-between'>
                            <div className={style.title}>
                            Quando me enviam uma proposta
                            </div>
                            <div className="mb:mr-[15px] md:mr-[50px]">
                              <MySwitch checked={ic_whengetnewbid}  onChange={handleChange6}></MySwitch>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}