import { styled } from '@mui/material/styles';
// components
import Slide_part from './slidePart';
import How_does_it_works from './howDoesItworks';
import Our_plans from './ourPlans';


// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    fontFamily: 'Poppins',
    alignItems: 'center',
    margin: '10px auto',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
        // padding: '30px',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
        // padding: '10px'
    }
}));

export default function Researcher(props) {

    return (
        <RootStyle>
            <Slide_part />
            <ContainerStyle>
                <How_does_it_works />
            </ContainerStyle>
            <Our_plans />
        </RootStyle>
    )
}