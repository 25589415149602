import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import API from '../../redux/API';
import { toast } from 'react-toast';

import {
    Stack,
} from '@mui/material';
import Card_1 from '../../components/card/PlanCards/card1';
import Card_2 from '../../components/card/PlanCards/card2';
import Card_3 from '../../components/card/PlanCards/card3';
import Checkout from '../../components/Checkout';

import ImagetoShow1 from "../../assets/image/researcher_top2.png"

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
}));

const Text_style_1 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '40px',
    fontWeight: 400,
    lineHeight: '100%',
    color: '#313131',
    [theme.breakpoints.up('sm')]: {
        fontSize: '50px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '65px',
        padding: '',
    }
}));


const Text_header = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '33px 0px 60px',
    [theme.breakpoints.up('md')]: {
        padding: '30px 0px 84px',
    },
}));


const Card_wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: '0px 7% 60px 7%',
    [theme.breakpoints.up('1600')]: {
        padding: '0px 12% 60px 12%',
    },
    [theme.breakpoints.up('1900')]: {
        padding: '0px 15% 60px 15%',
    },
}));

const Card_inner = styled('div')(({ theme }) => ({
    padding: '20px',
    [theme.breakpoints.up('900')]: {
    },
}));

const Box_rectangle = styled('div')(({ theme }) => ({
    width: '567px',
    height: '569.28px',

    position: 'absolute',

    boxSizing: 'border-box',
    border: '1px solid #17686E',
    transform: 'rotate(31.33deg)',
    right: '-479px',
    zIndex: -1,


    [theme.breakpoints.up('900')]: {
        width: '656.52px',
        right: '-25%',
    },
}));

export default function Why_we_use_this_tool(props) {
    const {currentUser} = useSelector(state=>state.auth)
    const [openModal,setopenModal]=useState(false)
    const [cardContent,setCardContent]=useState({})
    const [checkoutData,setcheckoutData]=useState({})  
    const [isPay,setisPay] =useState(false) 
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(window.innerWidth <= 760); // Adjust the threshold as needed
  }, []);

  useEffect(()=>{
    API.post('order/getallplans',{nm_language:'BR'})
    .then((res)=>{
        if (res.data.allPlans) {
            const allPlans = res.data.allPlans
                var aux1 = []
            allPlans.map((single)=>{
                let aux = {}
                aux.title=single.nm_plan
                aux.benefits=single.plan_detail
                aux.normalPrice=single.vl_normal
                aux.currentPrice=single.vl_current
                aux.ic_currentplan=single.ic_plan
                aux.comment='Perfeito para quem está iniciando sua jornada de pesquisa e possui pouco histórico!'
                aux.isHighlighted=false
                aux.image=ImagetoShow1
                aux.id_plan=single._id    
                if(single.ic_plan===0){
                    aux1[0]=aux
                }
                else if(single.ic_plan===1){
                    aux1[1]=aux
                }
                else if(single.ic_plan===2){
                    aux1[2]=aux
                }
            })
            return setallPlans(aux1)
        }
    })
    .catch((err)=>{
        return toast.error(err.message?err.message:'Erro ao buscar planos. Contate o suporte.')
    })
},[])

// const [data,setdata] = useState({name:'',oldprice:0,price:0,ic_plan:1,text:[]})
// const [data1,setdata1] = useState({name:'',oldprice:0,price:0,ic_plan:2,text:[]})
const [allPlans,setallPlans] = useState([])


//   const allPlans = [
//     {
//         importNumber:5,
//         title:'Iniciante',
//         normalPrice:'',
//         currentPrice:'00.00',
//         benefits:['1 país como especialidade','7 dias úteis para receber','20% de taxa de intermediação','Propostas de iniciantes','30 propostas mensais','4 projetos em execução'],
//         isHighlighted:false,
//         comment:'Perfeito para quem está iniciando sua jornada de pesquisa e possui pouco histórico!',
//         image:ImagetoShow1,
//         ic_currentplan:0,
//     },
//     {
//         importNumber:5,
//         title:'Especialista',
//         normalPrice:'1068.00',
//         currentPrice:'828.00',
//         benefits:['2 país como especialidade','4 dias úteis para receber','15% de taxa de intermediação','Propostas até especialista','90 propostas mensais','10 projetos em execução'],
//         isHighlighted:false,
//         comment:'Perfeito para quem está iniciando sua jornada de pesquisa e possui pouco histórico!',
//         image:ImagetoShow1,
//         ic_currentplan:1,

//     },
//     {
//         importNumber:5,
//         title:'Assessor',
//         normalPrice:'2141.00',
//         currentPrice:'1908.00',
//         benefits:['4 país como especialidade','2 dias úteis para receber','10% de taxa de intermediação','Propostas de todos os níveis','Ilimitadas propostas mensais','Ilimitadas projetos em execução','Selo premium'],
//         isHighlighted:false,
//         comment:'Perfeito para quem está iniciando sua jornada de pesquisa e possui pouco histórico!',
//         image:ImagetoShow1,
//         ic_currentplan:2,

//     },

//   ]


    return (
        <RootStyle>

            {openModal && <Checkout openModal={openModal} setopenModal={setopenModal} type={'membership'} cardContent={cardContent} checkoutData={checkoutData} setisPay={setisPay} isPay={isPay}/>}

            <Text_header>
                <Stack flexDirection={'row'}>
                    <Text_style_1>Nossos</Text_style_1>
                    <Text_style_1 sx={{ color: '#17686E',}}>&nbsp;Planos</Text_style_1>
                </Stack>
            </Text_header>

            <Box_rectangle />

            <Card_wrapper>
                <Card_inner>
                    <Card_1 setopenModal={setopenModal}
                            setCardContent={setCardContent}
                            setcheckoutData={setcheckoutData}
                            single={allPlans[0]}
                            currentUser={currentUser}
                    />
                </Card_inner>
                <Card_inner>
                    <Card_2 setopenModal={setopenModal}
                            setCardContent={setCardContent}
                            setcheckoutData={setcheckoutData}
                            single={allPlans[1]}
                            currentUser={currentUser}
                            optionsToRedirect={props.optionsToRedirect}
                            isTryingBuyBeforeLogin={props.isTryingBuyBeforeLogin}
                    />
                </Card_inner>
                <Card_inner>
                    <Card_3 setopenModal={setopenModal}
                            setCardContent={setCardContent}
                            setcheckoutData={setcheckoutData}
                            single={allPlans[2]}
                            currentUser={currentUser}
                            optionsToRedirect={props.optionsToRedirect}
                            isTryingBuyBeforeLogin={props.isTryingBuyBeforeLogin}

                    />
                </Card_inner>
            </Card_wrapper>
        </RootStyle >
    )
}