import { styled } from '@mui/material/styles';
import useWindowSize from '../../hooks/useWindowSize'
import {
    Stack,
    Box,
} from '@mui/material';
import CardItem from '../../components/card/SmallCard/pubHeader';
import home_top1 from '../../assets/image/home_top1.png'
import home_top2 from '../../assets/image/home_top2.png';
import home_top3 from '../../assets/image/home_top3.png';
import { useNavigate } from 'react-router-dom';

// -------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('1200')]: {
        display: 'grid',
        gridTemplateColumns: '100% 638px'
    }
}));

const Box_left = styled('div')(({ theme }) => ({
    width: '100%',
    zIndex: 1,
    position: 'relative',
    // borderRight: '10px red dashed ',
    [theme.breakpoints.down('sm')]: {
        marginTop: '139px',
    },
}));

const Box_right = styled('div')(({ theme }) => ({
    width: '100%',
    position: 'absolute',
    height: '100%',
    top: 0,
    right: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionX: 'right',
    backgroundImage: `url(${home_top3})`,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
        display: 'block',
        backgroundImage: `url(${home_top3})`,
    },
    [theme.breakpoints.up('lg')]: {
        width: '750px',
    }
}));

const Text_style_1 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '25px',
    fontWeight: 275,
    lineHeight: '100%',
    [theme.breakpoints.up('sm')]: {
        fontSize: '50px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '75px',
    }
}));

const Text_style_2 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',    
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.up('sm')]: {
        //color: '#FFFF',
        textShadow: '0px 0px 2px white',
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: '477px',
    }
}));

const Box_header_text = styled('div')(({ theme }) => ({
    padding: '17px 0px 380px 18px',
    marginLeft: '0px',
    [theme.breakpoints.down('sm')]: {
        backgroundColor: 'white',
    },
    [theme.breakpoints.up('sm')]: {
        marginLeft: '0px',
        padding: '49px 0px 380px 60px',
    },
    [theme.breakpoints.up('lg')]: {
        padding: '49px 0px 380px 125px',
    }
}));

const Card_wrapper = styled('div')(({ theme }) => ({
    overflowX: 'scroll',
    position: 'absolute',
    display: 'flex',
    padding: '30px 20px',
    width: 'calc(100vw - 10px)',
    height:'100%',
    left: '5px',
    [theme.breakpoints.down('sm')]: {
        marginTop: '3%',
    },
    [theme.breakpoints.up('sm')]: {
        width: 'calc(100vw - 50px)',
        // padding: '50px 20px',
        left: '40px',
    },
    [theme.breakpoints.up('md')]: {
        width: 'calc(100vw - 50px)',
        // padding: '50px 20px',
        left: '45px',
    },
    [theme.breakpoints.up('lg')]: {
        width: 'calc(100vw - 110px)',
        left: '105px',
    }
}));

const Effect_line = styled('div')(({ theme }) => ({
    position: 'absolute',
    zIndex: 1,
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '1px',
        top: '131px',
        borderTop: 'dashed 10px white',
    },
    [theme.breakpoints.up('sm')]: {
        width: '100%',
        height: '1px',
        top: '710px',
        borderTop: 'dashed 10px white',
    },
    [theme.breakpoints.up('lg')]: {
        borderTop: 'none',
        width: '1px',
        height: '100%',
        top: '0px',
        right: '741px',
        borderRight: 'dashed 10px white',
    },
}));

////data_card

const card_info = [
    {
        id: 1,
        header: 'Acesso a solicitação e ao sistema de acompanhamento.',
        content: 'Deixe os documentos nas mãos dos especialistas - nossos especialistas cuidam de tudo que você precisa e enviam diretamente para você.',
        button_text: 'Solicitar documento',
    },
    {
        id: 2,
        header: 'Temos a plataforma para você trabalhar e se tornar um pesquisador, grátis.',
        content: 'Você pode se inscrever grátis e se tornar um pesquisador, ajudando nossos clientes a encontrarem seus documentos.',
        button_text: 'Seja pesquisador',
    },
]

export default function Slide_part() {
    const { width } = useWindowSize()
    const navigate = useNavigate()
    return (
        <RootStyle>
            {width < 600 && <Box component='img' src={home_top2} width={'100%'} height={'167px'} sx={{ position: 'absolute', transform: 'scale(1.1)', top: '0.2%'}} />}
            {width < 600 && <Box sx={{ backgroundColor: '#BA483A', width: '32px', height: '32px', position: 'absolute', top: '139px' }}></Box>}
            <Effect_line></Effect_line>
            <Box_left>
                <Box_header_text>
                    {width < 600 ? (
                        <Text_style_1>Encontrar documentos</Text_style_1>
                        ) : (
                        <>
                            <Text_style_1>Encontrar</Text_style_1>
                            <Text_style_1>documentos</Text_style_1>
                        </>
                    )}
                    <Text_style_1 sx={{ color: '#BA483A', mt: '20px' }}>AGORA É FÁCIL!</Text_style_1>
                    <Text_style_2 sx={{ mt: '29px', width: '100%', }}>Detalhe o tipo de documento que precisa e confie em nossos pesquisadores, que se encarregarão de todas as etapas para você.</Text_style_2>
                    <Text_style_2 sx={{ fontWeight: 600, marginTop:'20px' }}>Sua solicitação é grátis!</Text_style_2>

                    {width < 600 && <Box sx={{ backgroundColor: '#17686E', width: '35px', height: '25px', position: 'absolute', top: '45%', left: '13px' }}></Box>}
                    <Card_wrapper>
                        <Stack sx={{ flexDirection: 'row' }}>
                            {card_info.map((card, index) => (
                                <CardItem key={index} card={card} />
                            ))}
                        </Stack>
                    </Card_wrapper>
                </Box_header_text>

            </Box_left>
            <Box_right>
            </Box_right>
            {width > 1200 && <Box sx={{ backgroundColor: '#BA483A', width: '32px', height: '32px', position: 'absolute', top: '17px', zIndex: -1 }}></Box>}
            {width > 1200 && <Box sx={{ backgroundColor: '#17686E', width: '35px', height: '70px', position: 'absolute', top: '312px', zIndex: -1 }}></Box>}
            {width > 1200 && <Box sx={{ backgroundColor: '#BA483A', width: '30px', height: '32px', position: 'absolute', top: '726px', zIndex: -1 }}></Box>}
            {width > 1200 && <Box sx={{ backgroundColor: '#BA483A', width: '24px', height: '25px', position: 'absolute', top: '437px', left: '645px', zIndex: -1 }}></Box>}
            {width > 1200 && <Box sx={{ backgroundColor: '#BA483A', width: '31px', height: '32px', position: 'absolute', top: '715px', left: '593px', zIndex: -1 }}></Box>}
        </RootStyle>
    )
}