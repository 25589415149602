import React, {useState, useEffect, useLayoutEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowSize from '../../hooks/useWindowSize'
import PartyPopperIcon from '../../assets/icons/party-popper.gif'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { Box, TextField,InputAdornment, TextareaAutosize} from '@mui/material';
import SecurityIcon from '../../assets/icons/securityicon1.svg';
import PasswordIcon from '../../assets/icons/passwordicon1.svg';
import Tooltip from '@mui/material/Tooltip';
import InformationIcon from '../../assets/icons/informationicon.svg';
import WarningIcon from '../../assets/icons/Warning.svg'
import SuccessIcon from '../../assets/icons/successicon.gif'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import API from '../../redux/API';
import { toast } from 'react-toast';
import moment from 'moment';
import './style.css'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, padding: '0px 43px' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#17686E',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
    border: '1px solid #17686E'
}));


const HeaderText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 500,
    color: '#313131',
    textAlign: 'center',
    lineHeight: '100%',
    [theme.breakpoints.up('md')]: {
        textAlign: 'left'
    },
}));

const GridWrapper = styled('div')(({ theme }) => ({
    padding: '40px 0px 10px 0px',
    [theme.breakpoints.up('md')]: {
        padding: '15px 3px 10px 3px'
    },
}));

function padTo2Digits(n) {
    return n.toString().padStart(2, "0");
  }

function formatDate(date) {
    return (
      [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("/") 
    );
  }
  const containerStyle = {
    width:'100% ',
    display: 'flex',
    flexWrap: 'wrap',
  };

  const inputStyle = {
    width: 'calc(50% - 10px)',
    margin: '5px',
    padding: '10px',
    boxSizing: 'border-box',
  };
  const inputStyleMobile = {
    width: 'calc(100% - 10px)',
    margin: '5px auto 5px auto',
    padding: '10px',
    boxSizing: 'border-box',
  };

export default function Modal({ 
    openModal, 
    setOpenModal, 
    confirmFunc, 
    pageNum, 
    setShippingway, 
    shippingway,
    setTrackingcode, 
    trackingcode,
    setDeliveryDate,
    deliveryDate,
    setdeliveryDateToShow,
    orderID,
    researcherID,
    loading,
    setLoading,
    orderINFO,
    }) {

    const { width } = useWindowSize();
    const style = {
        content: "!w-[80%] !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px] !rounded-[7px]",
    }
    const dispatch = useDispatch();
    const [initialPage, setInitialPage] = useState(pageNum);
    const handleClose = () => {
        window.location.reload();
        setOpenModal(false);
    };
    const handlePreviousPage = () => {
        if(page === 2){
            window.location.reload();
        }
        else{
            setOpenModal(false);
        }
    }

    const convertDate = (inputFormat) => {
        const pad = (s) => { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear() % 100].join('/')
      }
    const handleNextPage = () => {
        if(page === 0){
            return setPage(page+1);
        }
        if(page === 2){
            return window.location.reload();
        }
        // else{
        //     setPage(page + 1)
        // }

        setLoading(true)
        const thisdata = {
            orderID,
            researcherID,
            shippingway,
            trackingcode,
            deliveryDate,
        }
        API.post('/order/senddoc_researcher', thisdata)
        .then((result)=>{
            if(result.data.status === 'success'){
                toast.success(result.data.message?result.data.message:'SUCESSO')
                setPage(page + 1)
            }
            setLoading(false)
        })
        .catch((err)=>{
            setLoading(false)
            toast.error(err.message?err.message:'ERROR')
        })
    }

    // const [shippingway, setShippingway1] = useState('');
    // const [trackingcode, setTrackingcode1] = useState('');
    // const [deliveryDate, setDeliveryDate1] = useState(null)

    const [datetoshow, setdatetoshow] = useState(null)
    const [address_to_delivery, setaddress_to_delivery] = useState({})

    // const [isValidDate, setIsValidDate] = useState(false);

    useLayoutEffect(() => {
        setInitialPage(pageNum);
        setPage(pageNum);
      }, [openModal]);
    const [page, setPage] = useState(openModal);        
  
  // set the deliveryDate variable with the onChange handler
  const handleDate = (e) => {
    const parsedDate = Date.parse(e);
    const today = new Date();
  
    if (e < today) {      
      setdeliveryDateToShow(null);
      setDeliveryDate(null);
      toast.error('Digite uma data superior à atual');
    } else if (!isNaN(parsedDate)) {
      const datetosend = new Date(e);
      setDeliveryDate(datetosend.toString());
      setdeliveryDateToShow(formatDate(datetosend));
    } else {
      setDeliveryDate(null);
    }
  }
  const isMobile = window.innerWidth < 700;

  useEffect(()=>{
    if (orderINFO.address_to_delivery) {
        var aux = {...orderINFO.address_to_delivery}
        return setaddress_to_delivery(aux)
    }
  },[orderINFO])

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openModal} sx={{
                    '.MuiPaper-root': {
                        maxWidth: '837px',
                        width: '100%',
                    }
                }}
            >
                <div className='min-h-[350px]'>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
                
                {page===0 &&
                    <DialogContent className='mb:mt-[30px] md:mt-[58px] !overflow-unset '>
                    <div className='flex flex-col items-center'>
                        <div className='w-[90%] min-h-[120px] flex md:flex-row mb:flex-col mb:items-center md:items-start'>
                            <div className='flex flex-col justify-center w-[100%]'>
                                <div className='font-[Poppins] font-medium mb-text-[18px] md:text-[22px] text-[#313131] leading-[100%] mb:text-center md:text-left'>
                                Informações de envio do documento
                                </div>
                                <div className='w-[95%] mt-[20px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                                <div className="w-[95%] mt-[18px] font-[Poppins] font-light text-[12pt] text-[#313131] leading-[120%] mb:text-center md:text-left">
                                    <div style={{fontWeight:'600', textAlign:'center', fontSize:'16pt', margin:'10px'}}>ATENÇÃO!</div>
                                    <div style={{textAlign:'center'}}>Este é o endereço informado pelo cliente para envio do documento.</div>

                                </div>
                                <div style={isMobile ? {width:'0%', display:'flex', flexDirection:'column'} : containerStyle}>
                                    <div className='' style={isMobile?{}:inputStyle}>
                                        <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                        Nome da rua:
                                        </div>
                                        <TextField
                                            id="shipping_way_document"
                                            placeholder="Rua..." 
                                            defaultValue={address_to_delivery.nm_street&&address_to_delivery.nm_street.length>0?address_to_delivery.nm_street:' - - - - - '}
                                            fullWidth
                                            sx={{'& .MuiOutlinedInput-root': {height: '33px'},
                                            '& fieldset': {
                                                border: 'none !important'
                                            }}}
                                            InputProps={{
                                                className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5]",
                                            }}
                                            disabled
                                            />
                                    </div>
                                    <div className='' style={isMobile ? {} :  inputStyle}>


                                        <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                        Complemento:
                                        </div>
                                        <TextField
                                            id="shipping_way_document"
                                            placeholder="Complemento"
                                            defaultValue={address_to_delivery.ds_streetcomplement&&address_to_delivery.ds_streetcomplement.length>0?address_to_delivery.ds_streetcomplement:' - - - - - '}
                                            // onChange={(e) => {setaddressto_ds_streetcomplement(e.target.value)}}
                                            fullWidth
                                            sx={{'& .MuiOutlinedInput-root': {height: '33px'},
                                            '& fieldset': {
                                                border: 'none !important'
                                            }}}
                                            InputProps={{
                                                className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5]",
                                            }}
                                            disabled
                                            />
                                    </div>
                                    <div className='' style={isMobile ? {} :  inputStyle}>
                                        <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                        Código postal:
                                        </div>
                                        <TextField
                                            id="shipping_way_document"
                                            placeholder="00000BR"
                                            value={address_to_delivery.cd_zip&&address_to_delivery.cd_zip.length>0?address_to_delivery.cd_zip:' - - - - - '}
                                            // onChange={(e) => {setaddressto_cd_zip(e.target.value)}}
                                            fullWidth
                                            sx={{'& .MuiOutlinedInput-root': {height: '33px'},
                                            '& fieldset': {
                                                border: 'none !important'
                                            }}}
                                            InputProps={{
                                                className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5]",
                                            }}
                                            disabled
                                            />
                                    </div>
                                    <div className='' style={isMobile ? {} :  inputStyle}>
                                        <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                        Estado/Província:
                                        </div>
                                        <TextField
                                            id="shipping_way_document"
                                            placeholder="Estado"
                                            value={address_to_delivery.nm_state&&address_to_delivery.nm_state.length>0?address_to_delivery.nm_state:' - - - - - '}
                                            // onChange={(e) => {setaddressto_nm_state(e.target.value)}}
                                            fullWidth
                                            sx={{'& .MuiOutlinedInput-root': {height: '33px'},
                                            '& fieldset': {
                                                border: 'none !important'
                                            }}}
                                            InputProps={{
                                                className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5]",
                                            }}
                                            disabled
                                            />
                                    </div>
                                    <div className='' style={isMobile ? {} :  inputStyle}>
                                        <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                        Cidade:
                                        </div>
                                        <TextField
                                            id="shipping_way_document"
                                            placeholder="Cidade"
                                            value={address_to_delivery.nm_city&&address_to_delivery.nm_city.length>0?address_to_delivery.nm_city:' - - - - - '}
                                            // onChange={(e) => {setaddressto_nm_city(e.target.value)}}
                                            fullWidth
                                            sx={{'& .MuiOutlinedInput-root': {height: '33px'},
                                            '& fieldset': {
                                                border: 'none !important'
                                            }}}
                                            InputProps={{
                                                className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5]",
                                            }}
                                            disabled
                                            />
                                    </div>
                                    <div className='' style={isMobile ? {} :  inputStyle}>
                                        <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                        País:
                                        </div>
                                        <TextField
                                            id="shipping_way_document"
                                            placeholder="USA"
                                            value={address_to_delivery.nm_county&&address_to_delivery.nm_county.length>0?address_to_delivery.nm_county:' - - - - - '}
                                            // onChange={(e) => {setaddressto_nm_county(e.target.value)}}
                                            fullWidth
                                            sx={{'& .MuiOutlinedInput-root': {height: '33px'},
                                            '& fieldset': {
                                                border: 'none !important'
                                            }}}
                                            InputProps={{
                                                className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5]",
                                            }}
                                            disabled
                                            />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                }
                {
                    page === 1 &&
                    <DialogContent className='mb:mt-[30px] md:mt-[58px] !overflow-unset '>
                        <div className='flex flex-col items-center'>
                            <div className='w-[90%] min-h-[120px] flex md:flex-row mb:flex-col mb:items-center md:items-start'>
                                <div className='flex flex-col justify-center w-[100%]'>
                                    <div className='font-[Poppins] font-medium mb-text-[18px] md:text-[22px] text-[#313131] leading-[100%] mb:text-center md:text-left'>
                                    Informações de envio do documento
                                    </div>
                                    <div className='w-[95%] mt-[20px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                                    <div className="w-[95%] mt-[18px] font-[Poppins] font-light text-[14px] text-[#313131] leading-[120%] mb:text-center md:text-left">
                                    Informe nos campos abaixo para o cliente poder acompanhar o envio.
                                    </div>
                                    <div className='w-[60%] flex mb:flex-col md:flex-row'>
                                        <div className=''>
                                            <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                            Forma de envio:
                                            </div>
                                            <TextField
                                                id="shipping_way_document"
                                                placeholder="Fedex"
                                                value={shippingway}
                                                onChange={(e) => {setShippingway(e.target.value)}}
                                                sx={{'& .MuiOutlinedInput-root': {height: '33px', width: '223px'},
                                                '& fieldset': {
                                                    border: 'none !important'
                                                }}}
                                                InputProps={{
                                                    className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5]",
                                                }}
                                                />
                                        </div>
                                        <div className='mb:ml-[0px] md:ml-[25px]'>
                                            <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                            Código de rastreio:
                                            </div>
                                            <TextField
                                                id="shipping_way_document"
                                                placeholder="00000BR"
                                                value={trackingcode}
                                                onChange={(e) => {setTrackingcode(e.target.value)}}
                                                sx={{'& .MuiOutlinedInput-root': {height: '33px', width: '223px'},
                                                '& fieldset': {
                                                    border: 'none !important'
                                                }}}
                                                InputProps={{
                                                    className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5]",
                                                }}
                                                />
                                        </div>
                                      
                                    </div>
                                    <div className='w-[60%] flex'>
                                        <div className=''>
                                            <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                            Data prevista de entrega:
                                            </div>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                InputAdornmentProps={{
                                                    style: {
                                                    display: 'none'
                                                    }
                                                }}
                                                inputFormat="DD/MM/YYYY"
                                                value={deliveryDate} 
                                                className='w-full'
                                                onChange={handleDate}
                                                InputProps={{
                                                    className: style.content
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: "dd/mm/aaaa"
                                                        }}
                                                        // {...params}
                                                        // inputProps={{
                                                        // ...params.inputProps,
                                                        // placeholder: "dd/mm/aaaa"
                                                        // }}
                                                        />
                                                    );
                                                }}
                                                disablePast
                                                minDate={new Date()}
                                            />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    
                }
                {
                    page === 2 &&
                    <DialogContent className='mb:mt-[50px] md:mt-[118px] !overflow-unset '>
                        <div className='flex flex-col items-center'>
                        <div className='w-[90%] min-h-[120px] flex md:flex-row mb:flex-col items-center'>
                            <div className="mb:mr-[0px] md:mr-[25px] !w-[117px]">
                                <Box component="img" src={SuccessIcon} alt="sucesso-icone" className='max-w-[117px] h-[117px]'/>
                                </div>
                                <div className='flex flex-col justify-center w-[90%]'>
                                    <div className='font-[Poppins] font-medium mb-text-[18px] md:text-[22px] text-[#313131] leading-[100%]  mb:text-center md:text-left'>
                                    Informações enviadas
                                    </div>
                                    <div className='w-[95%] mt-[20px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                                    <div className="w-[95%] mt-[18px] font-[Poppins] font-light text-[14px] text-[#313131] leading-[120%] mb:text-center md:text-left">
                                    Agora é só aguardar o cliente confirmar o recebimento do documento.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    
                }
                           
                </div>
                <div className='md:mt-[0px] mb:mt-[20px]'>
                {
                    page === 0 &&
                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'> 
                        <HeaderBTNStyle1 onClick={handlePreviousPage} className="mb:mr-[0px] md:mr-[26px]" sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Voltar</HeaderBTNStyle1>
                        <HeaderBTNStyle onClick={handleNextPage} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Enviar documento</HeaderBTNStyle>
                    </div>
                }
                {
                    page === 1 &&
                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'> 
                        <HeaderBTNStyle1 onClick={handlePreviousPage} className="mb:mr-[0px] md:mr-[26px]" sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Voltar</HeaderBTNStyle1>
                        <HeaderBTNStyle onClick={handleNextPage} disabled={ !deliveryDate || !shippingway || !trackingcode   } sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Enviar documento</HeaderBTNStyle>
                    </div>
                }
                {
                    page === 2 &&
                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'> 
                         <HeaderBTNStyle onClick={handlePreviousPage} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Voltar</HeaderBTNStyle>
                    </div>
                }
                </div>
                {/* <Stack flexDirection={ width > 640 ? 'row' : 'column'} justifyContent={'center'} className="items-center" >
                <HeaderBTNStyle onClick={handleConfirm}  disabled={(!verifyHuman || !email) ? true : false} sx={{'&:disabled': {backgroundColor: '#C6C6C6'}}}>Enviar</HeaderBTNStyle>
                </Stack> */}
            </BootstrapDialog>
        </div >
    );
}
