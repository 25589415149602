import { styled } from '@mui/material/styles';
import { useState } from 'react'
import {
    InputBase,
} from '@mui/material';

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%'
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    maxWidth: '177px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 8px 6px 12px',
    borderRadius: '7px',
    // border: '1px solid #E2E4E5'
}));

const TextDescription = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    color: '#313131',
}));

const InputWrapper = styled('div')(({ theme }) => ({
    width: '60%',
}));

const TextRed = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '8px',
    fontWeight: 500,
    color: '#AB212F',
}));


export default function AmountInput({ text, holder, expectNumber, redtext, setStateMoney, setStateDay, typeButton, setValue, price }) {
    const [validation, setValidation] = useState(false);
    const [border, setBorder] = useState('1px solid #E2E4E5');
    const [color, setColor] = useState('#313131');

    const valiFunc = (value, type) => {
        if (value < expectNumber) {
            setValidation(true);
            setBorder('1px solid #AB212F');
            setColor('#AB212F');
            if (typeButton == 1)
                setStateMoney('false');
            else
                setStateDay('false');
        }
        else {
            setValidation(false);
            setBorder('1px solid #E2E4E5 !important');
            setColor('#313131');
            if (typeButton == 1)
                setStateMoney('true');
            else
                setStateDay('true');
        }


    }

    const isNumber = (input)=> {
        return typeof input === 'number'      
    }

      

    return (
        <RootStyle>
            <ContainerStyle sx={{ border: { border } }}>
                <TextDescription sx={{ color: { color } }}>{text}</TextDescription>
                <InputWrapper>
                    <InputBase
                        sx={{ fontFamily: 'Poppins', fontSize: '14px', color: { color } }}
                        placeholder={holder}
                        fullWidth={true}
                        onChange={(e) => {
                            const value = e.target.value;
                              valiFunc(value, e.target.type);
                              setValue(value);
                        }}
                        type='number'

                        value={price > 0 ? price : null}
                    ></InputBase>
                </InputWrapper>
            </ContainerStyle>
            {validation && <TextRed>{redtext}</TextRed>}
        </RootStyle>
    )
}