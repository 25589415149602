import { styled } from '@mui/material/styles';
import useWindowSize from '../../hooks/useWindowSize'
import {
    Box,
} from '@mui/material';
import Pic1 from '../../assets/image/home_why_1.svg';
import Pic2 from '../../assets/image/home_why_2.svg';
import Pic3 from '../../assets/image/home_why_3.svg';

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    background: '#F3F3F3',
    marginTop: '20px',
    marginBottom: '80px',
    [theme.breakpoints.up('sm')]: {
        marginTop: '40px',
    },
    [theme.breakpoints.up('md')]: {
        marginTop: '60px',
    },
}));

const Text_style_1 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 700,
    color: '#313131',
    [theme.breakpoints.up('sm')]: {
        fontSize: '32px',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '32px',
    }
}));

const Text_header = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: '28px 0px 44px',
    [theme.breakpoints.up('sm')]: {
        padding: '30px 0px',
    },
}));

const Text_style_2 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#313131',
    fontSize: '12px',
    [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
    },
}));

const Text_style_last = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#313131',
    fontSize: '12px',
    fontWeight: 400,
    paddingTop: '10px',
    [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
    },
    [theme.breakpoints.down('900')]: {
        paddingBottom: '30px',
    },
}));

const Card_wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '0px',
    [theme.breakpoints.up('900')]: {
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '0px 151px 60px 149px',
    },
}));

const Card = styled('div')(({ theme }) => ({
    maxWidth: '328px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
}));


export default function Why_we_use_this_tool() {
    const { width } = useWindowSize()

    return (
        <RootStyle>
            <Text_header>
                <Text_style_1>Por que usar a ferramenta?</Text_style_1>
            </Text_header>
            <Card_wrapper>
                <Card>
                    {width < 900 && <Box component='img' src={Pic1} height={'66px'} paddingTop={'22px'} paddingBottom={'27px'} />}
                    {width >= 900 && <Box component='img' src={Pic1} height={'143px'} paddingTop={'24px'} paddingBottom={'52px'} />}
                    <Box sx={{ textAlign: 'center' }}>
                        <Text_style_2 sx={{ fontWeight: 700, pt: '0px', fontSize:'15px' , marginBottom:'-5px'}}>Clareza do passo a passo</Text_style_2>
                        <Text_style_2 sx={{ fontWeight: 400, pt: '10px' }}>Acompanhe passo a passo de todo o processo até o recebimento do seu documento.</Text_style_2>
                    </Box>
                </Card>
                <Card sx={{marginRight:'50px', marginLeft:'50px', }}>
                    {width < 900 && <Box component='img' src={Pic2} height={'97px'} paddingTop={'33px'} paddingBottom={'18px'} />}
                    {width >= 900 && <Box component='img' src={Pic2} height={'182px'} paddingTop={'3px'} paddingBottom={'33px'} />}
                    <Box sx={{ textAlign: 'center' }}>
                        <Text_style_2 sx={{ fontWeight: 700, pt: '0px', fontSize:'15px' , marginBottom:'-5px' }}>Apoio ao longo do processo</Text_style_2>
                        <Text_style_2 sx={{ fontWeight: 400, pt: '10px' }}>Nossa plataforma irá simplificar seu processo e te acompanharemos em cada atualização.</Text_style_2>
                    </Box>
                </Card>
                <Card>
                    {width < 900 && <Box component='img' src={Pic3} height={'80px'} paddingTop={'40px'} paddingBottom={'20px'} />}
                    {width >= 900 && <Box component='img' src={Pic3} height={'155px'} paddingTop={'16px'} paddingBottom={'47px'} />}
                    <Box sx={{ textAlign: 'center' }}>
                        <Text_style_2 sx={{ fontWeight: 700, pt: '0px', fontSize:'15px' , marginBottom:'-5px' }}>Segurança</Text_style_2>
                        <Text_style_last>Feche o serviço com muita segurança para você receber o documento e para o pesquisador seja pago.</Text_style_last>
                    </Box>
                </Card>
            </Card_wrapper>
        </RootStyle >
    )
}