/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-anonymous-default-export */
import { Rating, Box } from "@mui/material"
import MoreImg from '../../assets/image/More.png'
import { styled } from '@mui/material/styles';
import DollarIcon from '../../assets/icons/dollaricon.svg'
import ClockIcon from '../../assets/icons/clockicon.svg'
import OutsideAlerter from '../../hooks/OutSideAlerter';
import { useState , useEffect} from "react";
import UpImage from '../../assets/icons/Polygon_up.svg'
import AcceptProposal from '../../components/modal/AcceptProposal'
import DeclineProposal from '../../components/modal/DeclineProposal'
import { useNavigate } from "react-router-dom";
import DiamondIcon from '../../assets/icons/diamondicon.svg'
import DiamondIcon1  from '../../assets/icons/diamondicon1.svg'
import API from "../../redux/API";
import { toast } from "react-toast";
import { useLocation } from "react-router-dom";

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    border: '1px solid #17686E',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#17686E',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '220px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    fontWeight: 600,
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle2 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#464A53',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '220px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    fontWeight: 400,
    marginTop: '11px',
    lineHeight: '21px',
    marginLeft: '20px'
}));

export default (props) => {
    
    const navigate = useNavigate();
    const location = useLocation();    
    const [showProposalCtrl, setShowProposalCtrl] = useState(false);
    const {avatar, name, rating, description, level, price, deadline, negotiationRefuse, negotiationAccepted, researcherID, orderID, acceptedBID_ID, isCancelledByResearcher, existNegociation, ic_bidrejected, currentUser, dt_bid,price_negotiation} = props.data;
    const countryknowledge = props.data.countryknowledge && props.data.countryknowledge.length > 0 
        ? "Conhecimento: "+ (props.data.countryknowledge.join(', '))
        : "Conhecimento: -";
    const [result, setResult] = useState(0);
    const [openAcceptProposal, setOpenAcceptProposal] = useState(false);
    const confirmFunc = () => {

        // API PARA CONFIRMAR POPOSTA ESPECÍFICA
        API.post(`order/acceptproposal/`, {researcherID, orderID})
        .then((result) => {
          if(result.data.status === 'success'){
              //toast.success('sucesso')
              setResult(1);
              window.location.reload()

          }
          else{
              toast.error('Erro ao obter dados da ordem.')
          }
        })
        .catch((err) => {

          if(err.message === 'Request failed with status code 500'){
              toast.error('Você precisa estar logado corretamente para acessar')
          }else{
              toast.error(`Erro ao obter dados da ordem ||| ${err.message} `) 
          }

          navigate('/login')
          console.log(err);
      });

    } 

    const reasonList = [    
        '-- Selecione --',
        'Este valor não cabe no meu orçamento', 
        'Este valor não cabe no meu orçamento e negociação recusada', 
        'Não estou interessado neste pesquisador', 
        'Outros'
        ];
    
    const [reasonType, setReasonType] = useState('-- Selecione --');

    const confirmDecline = () => {
        // setResult(2);

        const data = {
            orderID:orderID,
            researcherID:researcherID,
            reasonType:reasonType,

        }
        API.post(`order/declinenegociation`, data)
        .then((result)=>{
            if(result.data.status === 'success'){
                toast.success(result.data.message?result.data.message:"Sucesso");
                window.location.reload();
            }
            else{
                toast.error(result.data.message)
            }
        })
        .catch((err)=>{
            toast.error(err)
        })

    }

    const [openDeclineProposal, setOpenDeclineProposal] = useState(false);
    const [page, setPage] = useState(1);
    const cancel = props.cancel
    
    // set order status
    useEffect(()=>{
        if(existNegociation){
            // setResult(3)
        }
        if(negotiationRefuse){
            setResult(0);
        }
        if(negotiationAccepted || (acceptedBID_ID && acceptedBID_ID === researcherID)){
            setResult(1);
        }
        if(acceptedBID_ID && acceptedBID_ID !== researcherID){
            setResult(2)
        }
        if(ic_bidrejected || isCancelledByResearcher || cancel){
            setResult(2);
        }

    },[props])


    return (
        <div className={'mt-[20px] w-full rounded-[5px] border-[2px] border-solid ' +(result === 0 ? 'border-[#C6C6C6]' : 'border-[#17686E]')}>
            <AcceptProposal openModal={openAcceptProposal} setOpenModal={setOpenAcceptProposal} data={props.data} confirmFunc={confirmFunc} pageNum={page} setResult={setResult}/>
            <DeclineProposal openModal={openDeclineProposal} setOpenModal={setOpenDeclineProposal} confirmFunc={confirmDecline} pageNum={1} reasonList={reasonList} setReasonType={setReasonType} reasonType={reasonType}/>
  
            <div className="!h-[50px] flex items-center justify-between mt-[20px] mb-[20px]">
                <div className='flex items-center ml-[17px] '>
                    <div className='mr-[18px]' style={{borderRadius:'200px', overflow:'hidden',maxHeight: '50px',maxWidth: '50px'}}>
                        <img src={avatar} style={{ height:'100%' }} />
                    </div>
                    <div className='font-[Poppins] font-[600] leading-[27px] text-[18px] text-[#000000]'>{name}</div>
                </div>
                <div className='mr-[27px] relative'>
                    <Box component='img' src={MoreImg} width='4.2px' height='20px' className='cursor-pointer' onClick={() => setShowProposalCtrl(true)}/>
                    <div className='absolute right-[-35px] top-[20px] z-10'>
                {
                    showProposalCtrl &&
                    <OutsideAlerter event={()=>setShowProposalCtrl(false)}>
                        <div className="drop-shadow  xl:ml-[0px]">
                            <Box component={'img'} src={UpImage} className="ml-[153px] z-0"/>
                            <div className="bg-[#FFFFFF] rounded-[5px] w-[200px] mt-[-2px] z-10 flex flex-col justify-around">
                            {
                                    result === 0 &&
                                    <>
                                        <div className='flex justify-center items-center cursor-pointer h-[40px]'>
                                            <div onClick={()=>{setOpenAcceptProposal(true); setPage(1);}} className='font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]'>Aceitar proposta</div>
                                        </div>
                                        <div className='flex justify-center items-center cursor-pointer h-[40px]'>
                                            <div className='font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]' onClick={()=>{setOpenDeclineProposal(true);}}>Recusar proposta</div>
                                        </div>         
                                        {
                                            negotiationRefuse ?
                                            <div  onClick={()=>{setOpenAcceptProposal(true); setPage(5);}} className='flex justify-center items-center cursor-pointer h-[40px]'>
                                            <div className='font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]'>Mostrar negociação recusada</div>
                                            </div> :
                                            <div  onClick={()=>{setOpenAcceptProposal(true); setPage(2);}} className='flex justify-center items-center cursor-pointer h-[40px]'>
                                                <div className='font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]'>Negociar proposta</div>
                                            </div> 
                                        }
                                    </>
                                }                               
                                <div className='flex justify-center items-center cursor-pointer h-[40px]'>
                                    <div className='font-[Poppins] font-light text-[12px] leading-[24px] text-[#313131]' onClick={()=>{navigate(`/expertprofile/${researcherID}`)}}>Ver Perfil</div>
                                </div>                        
                            </div>
                        </div>
                    </OutsideAlerter>
                }
                </div>
                </div>


            </div>
            <div className="!h-[62px] flex items-center justify-between mt-[20px] mb-[20px]">
                <div className="flex flex-col items-center justify-center ml-[10px]">
                    <div className='font-[Poppins] font-[400] leading-[21px] text-[14px] text-[#313131]'>Avaliação</div>
                    <div className="mt-[0px]">                    
                        <Rating name="half-rating" defaultValue={rating} precision={0.1} sx={{
                        '& .MuiRating-iconFilled': {
                            color: '#17686E',
                        }
                    }}/></div>
                    <div className='font-[Poppins] font-[400] leading-[100%] text-[14px] text-[#313131]'>{rating ? `${rating.toFixed(2)}/5` : 'Sem classificação'}</div>
                </div>
                <div className='w-[60%] mr-[10px]'>
                    <div className="w-full text-center font-[Poppins] font-[275] leading-[21px] text-[14px] text-[#000000] break-words">
                    {countryknowledge}
                        
                    </div>
                </div>

            </div>
            <div className="!h-[40px] flex items-center justify-center mt-[20px] mb-[20px]">
                <div className="flex flex-col items-center justify-evenly ml-[5%]">
                    <div  className='font-[Poppins] font-[400] leading-[21px] text-[14px] text-[#000000]'>Nível</div>
                    {/* <div  className='font-[Poppins] font-[600] leading-[21px] text-[14px] text-[#000000]'>{level}</div> */}
                    {
                    level === 'Assessor' && 
                        <div className="flex items-center">
                            <Box component="img" src={DiamondIcon} alt="diamante-icone" width='20px' height='20px' className="ml-[-27px]"></Box>
                            <div  className='ml-[7px] font-[Poppins] font-[600] leading-[21px] text-[14px] text-[#000000]'>{level}</div>
                        </div>
                     }

                    {
                    level === 'Especialista' &&
                        <div  className='font-[Poppins] font-[600] leading-[21px] text-[14px] text-[#000000]'>{level}</div>
                    }

                    { 
                    level === 'Iniciante' &&
                        <div  className='font-[Poppins] font-[600] leading-[21px] text-[14px] text-[#000000]'>{level}</div>
                    }
                </div>
                
            </div>
            <div className='ml-[17px] mr-[20px] fit border-solid border-[1px] border-[#C6C6C6] opacity-90'></div>
            <div className="flex flex-col">
                <div className='flex flex-col'>
                    <div className="font-[Poppins] font-[400] leading-[21px] text-[14px] text-[#000000] ml-[24px] mt-[15px]">Descrição da proposta:</div>
                    <div className='w-[90%] font-[Poppins] font-[400] leading-[24px] text-[12px] text-[#313131] text-left ml-[24px] mt-[15px]'>
                     {description}
                     </div>

                </div>
                <div className="flex flex-col items-center justify-evenly">
                    <div className="flex justify-around items-center w-[212px] h-[80px] bg-[#17686E] bg-opacity-[0.11] rounded-[5px] mt-[20px]">
                        <div><Box component='img' src={DollarIcon} width='25px' height='43px'></Box></div>
                        <div className="flex flex-col items-center justify-evenly h-[80%] w-[130px]">
                            <div className='font-[Poppins] font-[400] leading-[21px] text-[14px] text-[#313131]'>Valor proposto</div>
                            <div className='font-[Poppins] font-[600] leading-[100%] text-[18px] text-[#313131]'>R$ {Number.parseFloat(price).toFixed(2).replace('.', ',')}</div>
                        </div>
                    </div>
                    <div className="flex justify-around items-center w-[212px] h-[80px] bg-[#17686E] bg-opacity-[0.11] rounded-[5px] mt-[20px]">
                        <div><Box component='img' src={ClockIcon} width='33px' height='43px'></Box></div>
                        <div className="flex flex-col items-center justify-evenly h-[80%] w-[130px]">
                            <div className='font-[Poppins] font-[400] leading-[21px] text-[14px] text-[#313131]'>Prazo de Entrega</div>
                            <div className='font-[Poppins] font-[600] leading-[100%] text-[18px] text-[#313131]'>{deadline} dias</div>
                        </div>
                    </div>
                </div>
                <div className='mt-[20px] mb-[20px] flex flex-col items-center'>
                { cancel === false &&
                    <>
                        {result === 0 && <HeaderBTNStyle onClick={() => {setOpenAcceptProposal(true); setPage(1);}}>Aceitar proposta</HeaderBTNStyle>}
                        {result === 1 && <HeaderBTNStyle1 className="!cursor-default">Pesquisador escolhido</HeaderBTNStyle1>}
                        {result === 2 && <HeaderBTNStyle1 disabled sx={{'&:disabled': {backgroundColor: '#C6C6C6', color: '#FFFFFF', cursor: 'not-allowed'}}}>Proposta recusada</HeaderBTNStyle1>}
                        {result === 3 && <HeaderBTNStyle1 disabled sx={{'&:disabled': {backgroundColor: '#C6C6C6', color: '#FFFFFF', cursor: 'not-allowed'}}}>Em negociação</HeaderBTNStyle1>}
                        {negotiationRefuse && <HeaderBTNStyle2 onClick={() => {setOpenAcceptProposal(true); setPage(5);}}>Mostrar negociação recusada</HeaderBTNStyle2>}
                    </>
                }
                {cancel === true && <HeaderBTNStyle1 disabled sx={{'&:disabled': {backgroundColor: '#C6C6C6', color: '#FFFFFF', cursor: 'not-allowed'}}}>Ordem Cancelada</HeaderBTNStyle1>}
                        {/* {result === 0 && <HeaderBTNStyle onClick={() => {setResult(1)}}>Aceitar proposta</HeaderBTNStyle>}
                        {result === 1 && <HeaderBTNStyle1>Pesquisador escolhido</HeaderBTNStyle1>} */}
                </div>
            </div>
        </div>
    )
}