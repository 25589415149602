import axios from "axios";
import {API_BASE} from '../config/constants'
import { Dispatch } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "./actions/auth.action";
import { toast } from "react-toast";
const API = axios.create({
    baseURL: API_BASE,
    withToken: true,
    headers: {
        "Content-Type": "application/json",
    },
});

API.interceptors.request.use((config) => {
    if (config.withToken) {
        const token = localStorage.token || sessionStorage.token;
        if (token) {
            // eslint-disable-next-line no-param-reassign
            config.headers.common.Authorization = `Bearer ${
                localStorage.token || sessionStorage.token
            }`;
        }
    }

    return config;
});


// Add an interceptor to handle response errors
API.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 515) {
            // Execute your specific function here
            handleResponse515Error();
        }
        return Promise.reject(error);
    }
);

// Define the function to handle the 515 error
function handleResponse515Error() {
    // const dispatch = useDispatch();
    // const navigate = useNavigate();

    const cookieConfirm = localStorage.getItem("cookieConfirm");
    localStorage.clear();
    if (cookieConfirm) {
        localStorage.setItem("cookieConfirm", cookieConfirm);
    }
    window.location.href = '/login'
    return toast.info("Sessão expirada. Faça login novamente.")
}



export default API;
