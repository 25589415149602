/* eslint-disable import/no-anonymous-default-export */

import { styled } from '@mui/material/styles';
import React, {useState, useRef, useEffect} from 'react';
import {
    Box
} from '@mui/material';
import AttachmentIcon from '../../assets/icons/attachment_icon.svg'
import { makeStyles } from "@material-ui/styles";
import DownloadIcon from '../../assets/icons/download_icon.svg'
import DeleteIcon from '../../assets/icons/delete_icon.svg'
import FilePreview from "../OrderDetailResearcher/MyFilePreview";
import PlusIcon from '../../assets/icons/plusicon.svg'

import Loading from '../../components/loading/Loading';

import API from '../../redux/API';
import { toast } from 'react-toast';
import { API_BASE } from '../../config/constants';
import { IMAGE_BASE } from '../../config/constants';

const useStyles = makeStyles((theme) => ({
    root: {
      "&::-webkit-scrollbar": {
        height: '4px',
      },
      "&::-webkit-scrollbar-track": {
          borderRadius: '20px',
          backgroundColor: 'rgb(217,217,217,0.3)'
  
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7A7A7A",
        borderRadius: '20px',
      },
    },
    root1: {
      "&::-webkit-scrollbar": {
        width: '4px',
      },
      "&::-webkit-scrollbar-track": {
          borderRadius: '5px',
          backgroundColor: 'rgb(217,217,217,0.3)'
  
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7A7A7A",
        borderRadius: '5px',
      },
    },
  }));

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '0px auto',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
    }
}));

export default ({ds_comments, setds_comments, setHas_attachments, orderID, setProgress}) => {

    const [attachments, setAttachments] = useState([])
    const classes = useStyles();
    const [showDocCtrl, setShowDocCtrl] = useState(0);
    const preview = useRef([]);
    const [validateAlertMsg, setValidateAlertMsg] = useState(false)
    const [sentDocument, setSentDocument] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(()=>{
        if(attachments.length>0){
           return setHas_attachments(true)
        }
        setHas_attachments(false)
    },[attachments])

    const handleUploadFiles = files => {
        const uploaded = [...attachments];
        files.some((file) => {
            if(uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push({url: URL.createObjectURL(file), name: file.name});
            } else {
                window.alert("Você já carregou este arquivo, favor carregar outro arquivo com outro nome.");
                return true; // stop iterating files
            }
        })
        setAttachments(uploaded);
    }

    const confirmSendDocument = () => {
        setSentDocument(true);
    }    
    const commentChange = (event) => {
        setds_comments(event.target.value);
    }








    // to send file to Backend
    const handleFileEvent = (e) => {

        setLoading(true)

        const chosenFiles = Array.prototype.slice.call(e.target.files)
        

        const files = e.target.files;
        const formData = new FormData();
      
        for (let i = 0; i < chosenFiles.length; i++) {
            
            const singleFile = chosenFiles[i]
            // const nameLimited = singleFile.name.slice(0, 15)
            const utf8EncodedFilename = encodeURIComponent(singleFile.name); // Encode the filename in UTF-8
            const fileWithUtf8Filename = new File([singleFile], utf8EncodedFilename, { type: singleFile.type }); // Create a new File object with UTF-8 encoded filename
            formData.append("file", fileWithUtf8Filename);

        }
                      
        // Envie o FormData usando uma chamada de API
        API.post(`order/updatefiles_researcher/${orderID}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })


        .then((result)=>{
            setLoading(false)

            if(result.data.status === 'success'){
                toast.success(result.data.message?result.data.message:"Sucesso");
                getorderfilesinfo()
            }
            else{
                setLoading(false)
                toast.error(result.data.message)
                getorderfilesinfo()
            }

        })
        .catch((err)=>{
            setLoading(false)
            toast.error(err)
            console.log(err);
            getorderfilesinfo()
        })
    }

    const handleDeleteAttach = (item) => {
        setLoading(true)

        API.post(`/order/deleteattachment/${orderID}/${item}`)
        .then((result)=>{
            setLoading(false)

            if(result.data.status === 'success'){
                toast.success(result.data.message?result.data.message:"Sucesso");
                getorderfilesinfo()
            }
        })
        .catch((err)=>{
            setLoading(false)
            toast.error(err)
            getorderfilesinfo()
        })
    }



    const getorderfilesinfo = ()=>{
        API.post(`/order/getorderfilesinfo/`, {orderID:orderID})
        .then((result)=>{
            if(result.data.status === 'success'){
                // toast.success(result.data.message?result.data.message:"Sucesso");
                setAttachments(result.data.attack)
            }
        })
        .catch((err)=>{
            console.log(err)
        })
    }



    useEffect(()=>{
        if(orderID){
            return getorderfilesinfo()
        }
        setProgress(75)        
    },[orderID])





    return (
        <RootStyle>
            <ContainerStyle className = '!mt-[40px]'>
                <div className="font-[Poppins] font-medium text-[22px] leading-[100%] color-[#313131] mb-[25px]">Anexar documentos que você encontrou e que foi solicitado pelo cliente:</div>
                <div className='flex justify-center w-full mb-[80px]'>              
                    <div className="w-[92%] rounded-[7px] border-solid border-[0.5px] border-[#C6C6C6] mt-[20px] h-[220px] flex flex-row items-center justify-center">
                        {
                            attachments.length > 0 ? 
                            <Box  className={classes.root + " w-[96%] overflow-x-auto flex h-[175px]"}>
                                {
                                    attachments.map((item, index) => {
                                        const fileName = decodeURIComponent(item);
                                        return (
                                          <div key={item} className='mt-[0px] mr-[25px] flex-col flex items-center relative' 
                                            onMouseOver={()=>{setShowDocCtrl(index+1); preview.current[index].style.filter = 'blur(5px)'}} 
                                            onMouseOut={()=>{setShowDocCtrl(false); preview.current[index].style.filter = ''}}>
                                            {showDocCtrl == index+1 && 
                                              <>
                                                <a 
                                                href={      API_BASE + `/order/downloadattachments/${orderID}/${fileName}`} 
                                                download={  API_BASE + `/order/downloadattachments/${orderID}/${fileName}`} 
                                                className={"absolute z-10 cursor-pointer top-[30px]"}
                                                >
                                                  <Box component="img" src={DownloadIcon} alt="download-icone"/>
                                                </a>
                                                <Box onClick={()=>{handleDeleteAttach(item)}} component="img" src={DeleteIcon} alt="delete-icone" className="absolute z-10 cursor-pointer top-[70px]"/>
                                              </>
                                            }
                                            <div ref={el => preview.current[index] = el}>
                                              <FilePreview
                                                width='93px'
                                                height='119px'
                                                type={"file"}
                                                url={IMAGE_BASE + `orders/orders_files/${orderID}/${item}`}
                                                onError={(err) =>console.error(err)}
                                              />
                                            </div>
                                            <div className='font-[Poppins] font-light text-[9px] leading-[100%] text-[#ABAFB3] mt-[5px] w-[93px] text-center break-all' style={{ whiteSpace: 'normal', wordBreak: 'break-all', width: '93px' }}>{item}</div>
                                          </div>
                                        )
                                    })                                    
                                }

                                <div key={attachments.length} className='mt-[16px] mr-[25px] flex-col flex items-center relative' >
                                    <div className="mb-[0px]">
                                        <input id='fileUpload' type='file' multiple onChange={handleFileEvent} className='hidden' accept='.pdf, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*'/>
                                        <label htmlFor='fileUpload'>
                                            <a>
                                                <div className='w-[93px] h-[119px] border-dashed border-[0.5px] border-[#C6C6C6] flex items-center justify-center cursor-pointer' >
                                                    <Box component='img' src={PlusIcon} width="25px" height="25px"></Box>
                                                </div>
                                            </a>
                                        </label>
                                    </div>
                                    <div className='font-[Poppins] font-light text-[9px] leading-[100%] text-[#ABAFB3] mt-[3px]'></div>
                                </div>

                            </Box>
                        :
                        <div className='flex flex-col items-center'>
                            <input 
                                id='fileUpload' 
                                type='file' 
                                multiple 
                                onChange={handleFileEvent} 
                                className='hidden' 
                                accept='.pdf, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*'
                            />
                            <label htmlFor='fileUpload'>
                                <a>
                                <div className={`flex flex-col items-center cursor-pointer `} style={{ pointerEvents:  'auto' }}>
                                    <div className='font-[Poppins] font-bold text-[18px] leading-[100%] text-[#C6C6C6] mb-[12px]' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                     Anexar arquivo
                                    </div>
                                    <div>
                                        <Box component='img' src={AttachmentIcon} width="60px" height='65px' />
                                    </div>
                                </div>
                                </a>
                            </label>
                        </div>
                        }                                                
                    </div>
                </div>
            </ContainerStyle>
        </RootStyle>
    )
}