import { styled } from '@mui/material/styles';
import useWindowSize from '../../hooks/useWindowSize'
import {
    Box,
    Stack,
    Divider,
} from '@mui/material';
// components
import PencilIcon from '../../assets/icons/pencil.svg'
import RatingStar from '../../components/RatingStar'
import DetailCard from '../../components/card/DetailCard'
import { useDispatch } from 'react-redux';
import { setCurrentProfile } from '../../redux/actions/auth.action';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import API from '../../redux/API.js';
// icons
import MailBox from '../../assets/icons/mailBox.svg'
import OngoingBox from '../../assets/icons/ongoingBox.svg'
import CompleteBox from '../../assets/icons/completeBox.svg'
import { useSelector } from 'react-redux';
import { IMAGE_BASE } from '../../config/constants';

// -------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
}));

const InfoWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    border: '1px solid #E2E4E5',
    borderRadius: '5px',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '26px 0px 0px 0px',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: '12px 23px 9px 16px',
    }
}));

const RatingText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    color: '#313131',
    whiteSpace: 'nowrap',
    lineHeight: "147.9%",
    fontStyle: 'normal'
}));

const TotalText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: '12px',
    color: '#313131',
}));

const TotalNumberText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    color: '#313131',
}));

const TotalWrapper = styled('div')(({ theme }) => ({
    paddingTop: '38px',
    paddingLeft: '10px',
    [theme.breakpoints.up('md')]: {
        paddingTop: '20px',
        paddingLeft: '0px',
    }
}));

const ContactCard = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: '700px',
    paddingLeft: '5px',
    paddingTop: '0px',
    [theme.breakpoints.up('md')]: {
        paddingTop: '12px',
        paddingLeft: '0px',
        maxWidth: '290px',
    }
}));

const CardsWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    paddingBottom: '27px',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    }
}));

export default function ExpertProfile({ data }) {
    const { width } = useWindowSize()

    const [cards, setCards] = useState([
        {
            id: 1,
            title: 'Em Aberto',
            iconSrc: MailBox,
            number: 0,
        },
        {
            id: 2,
            title: 'Em Andamento',
            iconSrc: OngoingBox,
            number: 0,
        },
        {
            id: 3,
            title: 'Concluído',
            iconSrc: CompleteBox,
            number: 0,
        }
    ]);

    const { currentUser } = useSelector(state => state.auth);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [total, setTotal] = useState(0);
    useEffect(() => {
        API.get(`/order/getordercntwithclient?id_customer=${currentUser.id === undefined ? currentUser._id : currentUser.id}`)
        .then((result) => {
            if (result.data.status === "success") {
                let temp = cards;
                temp[0].number = result.data.opened;
                temp[1].number = result.data.progress;
                temp[2].number = result.data.concluded;
                setCards(temp);
                setTotal(result.data.total);
            }})
            .catch((err) => {
                console.log(err)
            });  
        }, [])

    return (
        <RootStyle>
            <InfoWrapper>
                <ContactCard>
                    <Stack flexDirection={'row'}>
                        <Box component={'img'} src={IMAGE_BASE+`accounts/${currentUser._id?currentUser._id:currentUser.id}/`+currentUser.nm_customeravatarfile} width={'41px'} height={'41px'} className="rounded-[20px]"/>
                        <Box pl={'14px'} width='100%'>
                            <Stack flexDirection={'row'} justifyContent={'space-between'} >
                                <RatingText>{currentUser.nm_user}</RatingText>
                                <Box component={'img'} src={PencilIcon} onClick={()=>{dispatch(setCurrentProfile(0)); navigate('/account')}} className="cursor-pointer w-[12.98px] h-[14px] mb:mr-[14px] lg:mr-[0px]" />
                            </Stack>
                        </Box>
                    </Stack>
                    <TotalWrapper>
                        <TotalText>Total Solicitações</TotalText>
                        <TotalNumberText>{total}</TotalNumberText>
                    </TotalWrapper>
                </ContactCard>
                {width > 900 && <Divider orientation="vertical" flexItem sx={{ marginLeft: '12px', marginRight: '34px' }} />}
                <CardsWrapper>
                    {cards.map((item, index) => (
                        <DetailCard key={index} data={item} />
                    ))}
                </CardsWrapper>
            </InfoWrapper>
        </RootStyle>
    )
}