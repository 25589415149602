import { styled } from '@mui/material/styles';
import {
    Box,
} from '@mui/material';

import useWindowSize from '../../../hooks/useWindowSize';
import {IMAGE_BASE} from '../../../config/constants'
import API from '../../../redux/API';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../redux/actions/notification.action';

import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',

    border: '1px solid #E2E4E5',
    borderRadius: '7px',
    padding: '5px',
    marginBottom: '10px',
    [theme.breakpoints.up('sm')]: {
        padding: '10px',
    },
    [theme.breakpoints.up('md')]: {
        padding: '20px',
    },
}));

const Ellipse = styled('div')(({ theme }) => ({
    position: 'absolute',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    top: '6px',
    left: '56px',
    [theme.breakpoints.up('md')]: {
        top: '12px',
        left: '85px',
    },
}));

const AvatarWrapper = styled('div')({
    position: 'relative',
});

const TextWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

const TitleStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 300,
    color: '#313131',
    [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '24px',
    },
}));

const DayStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 300,
    color: '#C6C6C6',
    [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '20px',
    },
}));

const DescriptionStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 300,
    color: '#C6C6C6',
    [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '20px',
    },
}));


export default function NotificationCard({ card }) {

    useEffect(()=>{
        console.log(card);
    },[card])

    const { width } = useWindowSize()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const getPassedday = (date) => {
        const current = new Date(new Date().toUTCString());
        let  offset = Math.floor((current-new Date(date))/1000/60/60/24);
        if(offset === 0) return 'hoje';
        else return `há ${offset} dia`;
    }
    const setViewed = () => {
        API.post(`user/setviewednotification`, {id_customer:card.id_customer, _id:card._id})
        .then((result) => {
            if (result.data.status === "success") {
                dispatch(setNotification(result.data.notifications));
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }
    return (
        <RootStyle className="hover:bg-[#17686E] hover:bg-opacity-10 cursor-pointer" 
            onClick={()=>{
                card.nm_redirectpage?
                    window.location.href = card.nm_redirectpage
                    :
                    navigate('/notification')
                setViewed()
            }}
        >
            <AvatarWrapper>
                { width > 900 && <Box component={'img'} src={IMAGE_BASE+card.nm_avatar}  className='w-[108px] h-[108px] rounded-[54px]'/> }
                { width <= 900 && <Box component={'img'} src={IMAGE_BASE+card.nm_avatar} className='w-[75px] h-[75px] rounded-[37px]'/> }
                <Ellipse sx={{ backgroundColor: card.ic_viewed === false ? '#FE4E6E' : 'none' }} />
            </AvatarWrapper>
            <Box width={'100%'} paddingLeft={'20px'}>
                <TextWrapper>
                    <TitleStyle>{ card.ds_title }</TitleStyle>
                    <DayStyle>{getPassedday(card.createdAt)}</DayStyle>
                </TextWrapper>
                <DescriptionStyle>{card.ds_content}</DescriptionStyle>
            </Box>
        </RootStyle>
    )
}