import { styled } from '@mui/material/styles';
import useWindowSize from '../../../hooks/useWindowSize'
import {
    Box,
    Stack,
} from '@mui/material';
// -------------------------------------------------------------
import Diamond from '../../../assets/icons/diamante.svg'
import { useSelector } from 'react-redux';
const RootStyle = styled('div')(({ theme }) => ({
}));

const TextStyle = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    color: '#000000',
}));

export default function Card() {
    const { width } = useWindowSize()
    const { currentUser } = useSelector(state => state.auth)
    return (
        <RootStyle className="flex">
            <Stack flexDirection={'row'}>
                {
                    currentUser.ic_currentplan == 2 &&  <Box component={'img'} src={Diamond} width={width >= 900 ? '35px' : '15px'} />
                }

                {width >= 900 &&
                    <Box>
                        <TextStyle>&nbsp;&nbsp;Nível</TextStyle>
                        <TextStyle sx={{ fontWeight: 600 }}>&nbsp;&nbsp;{currentUser.ic_currentplan == 0 ? 'Iniciante' : currentUser.ic_currentplan == 1 ? 'Especialista' : 'Assessor'}</TextStyle>
                    </Box>
                }
                {width < 900 &&
                    <Stack flexDirection={'row'} >
                        <TextStyle>&nbsp;Nível:</TextStyle>
                        <TextStyle sx={{ fontWeight: 600 }}>&nbsp;&nbsp;{currentUser.ic_currentplan == 0 ? 'Iniciante' : currentUser.ic_currentplan == 1 ? 'Especialista' : 'Assessor'}</TextStyle>
                    </Stack>
                }
            </Stack>
        </RootStyle>
    )
}