/* eslint-disable import/no-anonymous-default-export */

import { styled } from '@mui/material/styles';
import React, {useState, useEffect, useRef} from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
    Box
} from '@mui/material';
import StatusBar from './orderstatus'
import useWindowSize from '../../hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import Custom_Accordion from './moredetail';
import AttachmentIcon from '../../assets/icons/attachment_icon.svg'
import Proposals from './proposals'
import InviteResearcher from './InviteResearcher';
import FilePreview from "./MyFilePreview";
import { makeStyles } from "@material-ui/styles";
import ReportIcon from '../../assets/icons/report_icon.svg';
import DownloadIcon from '../../assets/icons/download_icon.svg'
import NotificationIcon from '../../assets/icons/notification_icon.svg'
import ConfirmAnalyze from '../../components/modal/ConfirmAnalyse'
import RequestIcon1 from '../../assets/icons/request_active.svg';
import ReceiveIcon1 from '../../assets/icons/receive_active.svg';
import ReceiveIcon2 from '../../assets/icons/receive_passive.svg';
import ReceiveIcon3 from '../../assets/icons/receive_inactive.svg';
import ResearchIcon1 from '../../assets/icons/research_active.svg';
import ResearchIcon2 from '../../assets/icons/research_passive.svg';
import ValidateIcon1 from '../../assets/icons/validate_active.svg';
import ValidateIcon2 from '../../assets/icons/validate_passive.svg';
import CompleteIcon1 from '../../assets/icons/complete_active.svg';
import CompleteIcon2 from '../../assets/icons/complete_passive.svg';
import DeliverIcon1 from '../../assets/icons/deliver_active.svg';
import DeliverIcon2 from '../../assets/icons/deliver_passive.svg';
import ConfirmIcon1 from '../../assets/icons/confirm_active.svg';
import ConfirmIcon2 from '../../assets/icons/confirm_passive.svg';
import ApostilleIcon1 from '../../assets/icons/apostille_active.svg';
import ApostilleIcon2 from '../../assets/icons/apostille_passive.svg';
import ConfirmAddressToSend from '../../components/modal/ConfirmAddressToSend'
import Checkout from '../../components/Checkout';
import CancelOrder from '../../components/modal/CancelOrder'
import ReportProblem from '../../components/modal/ReportProblem';

import checkoutImage from '../../assets/image/publications_image.png'

import API from '../../redux/API';
import { toast } from 'react-toast';
import { API_BASE } from '../../config/constants';
import { IMAGE_BASE } from '../../config/constants';



import { width } from '@mui/system';

import Loading from '../../components/loading/Loading';
    
  

const useStyles = makeStyles((theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      height: '4px',
    },
    "&::-webkit-scrollbar-track": {
        borderRadius: '20px',
        backgroundColor: 'rgb(217,217,217,0.3)'

    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#7A7A7A",
      borderRadius: '20px',
    },
  },
}));

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '0px auto',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
    }
}));

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    fontWeight: 600,
    marginTop: '11px',
    lineHeight: '21px',
}));

const MakeProposalBTNStyleDISABLED = styled('button')(({ theme }) => ({
    color: '#C6C6C6',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',  
    lineHeight: 'normal',
    backgroundColor: '#fff',
    border: '1px solid #C6C6C6',
    borderRadius: '5px',
    padding: '10px',
    cursor: 'not-allowed',
    width:'180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center'
}));

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#17686E',
    borderRadius: '5px',
    border: '1px solid #17686E',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const TitleText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '100%',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    marginBottom: '5px',

}));





export default (props) => {

    const [isImported,setisImported]=useState(false)
    const [researcherINFO,setresearcherINFO]=useState({})
 
    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 0) {
            loading(true);
          } else {
            loading(false);
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    

    const {currentUser} = useSelector(state => state.auth);


    const padTo2Digits = (n) => {
        return n.toString().padStart(2, "0");
      }
      
    const formatDate = (date) => {
        if (!date) {
          return "";
        }
        const formattedDate = new Date(date);
        if (isNaN(formattedDate.getTime())) {
          throw new Error("Invalid date");
        }
      
        const day = padTo2Digits(formattedDate.getDate());
        const month = padTo2Digits(formattedDate.getMonth() + 1);
        const year = formattedDate.getFullYear();
        const hours = padTo2Digits(formattedDate.getHours());
        const minutes = padTo2Digits(formattedDate.getMinutes());
      
        return formattedDate.toLocaleString('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });
      }
          
      const formatDateWithoutHours =(date) =>{

        if (!date) {
            return "";
          }
          const formattedDate = new Date(date);
          if (isNaN(formattedDate.getTime())) {
            throw new Error("Invalid date");
          }
        
          const day = padTo2Digits(formattedDate.getDate());
          const month = padTo2Digits(formattedDate.getMonth() + 1);
          const year = formattedDate.getFullYear();
          const hours = padTo2Digits(formattedDate.getHours());
          const minutes = padTo2Digits(formattedDate.getMinutes());
        
          return formattedDate.toLocaleString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });
      }
    




    const active = "!text-[#17686E] !font-semibold border-b-[4px] border-solid border-[#17686E]";
    const hover = "hover:text-[#17686e] ";
    const [awardOption, setAwardOption] = useState(true);
    const selectAwardOption = (value) => {
        setAwardOption(value);
    }

    const navigate = useNavigate();
  
 
    const [orderstatusList, setOrderStatusList] = useState([
        {
            icon1: RequestIcon1,
            icon2: null,
            icon3: null,
            text: 'Pedido publicado',
            time: '',
            status: 1,
        },
        {
            icon1: ReceiveIcon1,
            icon2: ReceiveIcon2,
            icon3: ReceiveIcon3,
            text: 'Proposta recebida',
            time: '',
            status: 1,
        },
        {
            icon1: ResearchIcon1,
            icon2: ResearchIcon2,
            icon3: null,
            text: 'Pesquisa realizada',
            time: '',
            status: 1,
        },
        {
            icon1: ValidateIcon1,
            icon2: ValidateIcon2,
            icon3: null,
            text: 'Validar trabalho',
            time: '',
            status: 1,
        },
        {
            icon1: CompleteIcon1,
            icon2: CompleteIcon2,
            icon3: null,
            text: 'Pedido concluído',
            time: '',
            status: 1,
        },
    ]);


    const [orderstatusList1, setOrderStatusList1] = useState([
        {
            icon1: RequestIcon1,
            icon2: null,
            icon3: null,
            text: 'Pedido publicado',
            time: '',
            status: 1,
        },
        {
            icon1: ReceiveIcon1,
            icon2: ReceiveIcon2,
            icon3: ReceiveIcon3,
            text: 'Proposta recebida',
            time: '',
            status: 1,
        },
        {
            icon1: ResearchIcon1,
            icon2: ResearchIcon2,
            icon3: null,
            text: 'Pesquisa realizada',
            time: '',
            status: 1,
        },
        {
            icon1: ValidateIcon1,
            icon2: ValidateIcon2,
            icon3: null,
            text: 'Documentos validados',
            time: '',
            status: 1,
        },
        {
            icon1: DeliverIcon1,
            icon2: DeliverIcon2,
            icon3: null,
            text: 'Documento enviado ',
            time: '',
            status: 1,
        },
        {
            icon1: ConfirmIcon1,
            icon2: ConfirmIcon2,
            icon3: null,
            text: 'Confirmar recebimento ',
            time: '',
            status: 1,
        },
        {
            icon1: CompleteIcon1,
            icon2: CompleteIcon2,
            icon3: null,
            text: 'Pedido concluído',
            time: '',
            status: 1,
        },
    ]);

    const [orderstatusList2, setOrderStatusList2] = useState([
        {
            icon1: RequestIcon1,
            icon2: null,
            icon3: null,
            text: 'Pedido publicado',
            time: '',
            status: 1,
        },
        {
            icon1: ReceiveIcon1,
            icon2: ReceiveIcon2,
            icon3: ReceiveIcon3,
            text: 'Proposta recebida',
            time: '',
            status: 1,
        },
        {
            icon1: ResearchIcon1,
            icon2: ResearchIcon2,
            icon3: null,
            text: 'Pesquisa realizada',
            time: '',
            status: 1,
        },
        {
            icon1: ValidateIcon1,
            icon2: ValidateIcon2,
            icon3: null,
            text: 'Validar trabalho',
            time: '',
            status: 1,
        },
        {
            icon1: ApostilleIcon1,
            icon2: ApostilleIcon2,
            icon3: null,
            text: 'Validar trabalho',
            time: '',
            status: 1,
        },
        {
            icon1: DeliverIcon1,
            icon2: DeliverIcon2,
            icon3: null,
            text: 'Documento enviado ',
            time: '',
            status: 1,
        },
        {
            icon1: ConfirmIcon1,
            icon2: ConfirmIcon2,
            icon3: null,
            text: 'Confirmar recebimento ',
            time: '',
            status: 1,
        },
        {
            icon1: CompleteIcon1,
            icon2: CompleteIcon2,
            icon3: null,
            text: 'Pedido concluído',
            time: '',
            status: 1,
        },
    ]);

    const statusList = [
        'Em aberto',
        'Em andamento',
        'Concluído',
        'Cancelado'
    ]
    const [statusNum, setStatusNum] = useState(0);

    const { width } = useWindowSize()
    const classes = useStyles();
    const [showDocCtrl, setShowDocCtrl] = useState(0);
    const preview = useRef([]);
    const [openConfirmAnalyze, setOpenConfirmAnalyze] = React.useState(false);
    const [showConfirmBtn, setShowConfirmBtn] = useState(false);
    const [openReportProblem, setOpenReportProblem] = useState(false); 
    const [confirmAddressToSend, setConfirmAddressToSend] = useState(false); 
    const [addressto_nm_street, setaddressto_nm_street] = useState(''); 
    const [addressto_ds_streetcomplement, setaddressto_ds_streetcomplement] = useState(''); 
    const [addressto_nm_state, setaddressto_nm_state] = useState(''); 
    const [addressto_nm_city, setaddressto_nm_city] = useState(''); 
    const [addressto_nm_county, setaddressto_nm_county] = useState(''); 
    const [addressto_cd_zip, setaddressto_cd_zip] = useState(''); 
    const limit = 10;
    const [showConfirmReceipt, setShowConfirmReceipt] = useState(0);
    
    
    
    const confirmPhysicalDocFlow = () => {
        setLoading(true)
        const data = {
            orderID:orderID,
            userID:currentUser.id?currentUser.id:currentUser._id
        }
        if(needValidationStamped && !isSent){
            API.post(`order/confirmstampeddoc`, data)
            .then((result)=>{
                setLoading(false)

                if(result.data.status === 'success'){
                    toast.success(result.data.message?result.data.message:"Sucesso");
                    orderdetail();
                }
                else{
                    toast.error(result.data.message)
                    orderdetail();
                }
            })
            .catch((err)=>{
                toast.error(err)
                orderdetail();
            })
        }
        if(isSent){
            API.post(`order/confirmphysicaldoc`, data)
            .then((result)=>{
                setLoading(false)

                if(result.data.status === 'success'){
                    toast.success(result.data.message?result.data.message:"Sucesso");
                    orderdetail();
                }
                else{
                    toast.error(result.data.message)
                    orderdetail();
                }
            })
            .catch((err)=>{
                toast.error(err)
                orderdetail();
            })
        }

    }



    const [openCancelOrder, setOpenCancelOrder] = useState(false);
    

    const confirmsenddoc = ()=>{

    }
    
    
    const confirmAnalyse = () => {
        setLoading(true)
        const data = {
            orderID:orderID
        }
        API.post(`order/confirmdigitaldoc`, data)
        .then((result)=>{
            setLoading(false)

            if(result.data.status === 'success'){
                toast.success(result.data.message?result.data.message:"Sucesso");
                orderdetail();
            }
            else{
                toast.error(result.data.message)
                orderdetail();

            }
        })
        .catch((err)=>{
            setLoading(false)

            toast.error(err)
            orderdetail();

        })



        // let temp = [...orderstatusList];
        // temp[4].status = 0;
        // setOrderStatusList(temp);
        // setShowConfirmBtn(true);
    }





    const [cancel, setCancel] = useState(false);
    const confirmCancelOrder = () => {
        let temp = [...orderstatusList];
        temp[1].status = 2;
        temp[1].text = 'Proposta cancelada'
        temp[1].time = dateCancel

        
        // temp[2].status = 1;
        // temp[3].status = 1;
        // temp[4].status = 1;
        setCancel(true)
        setOrderStatusList(temp);

        confirmCancelOrder1()
        confirmCancelOrder2()
    }
    const confirmCancelOrder1 = () => {
        let temp = [...orderstatusList1];
        temp[1].status = 2;
        temp[1].text = 'Proposta cancelada'
        temp[1].time = dateCancel

        
        // temp[2].status = 1;
        // temp[3].status = 1;
        // temp[4].status = 1;
        setCancel(true)
        setOrderStatusList1(temp);
    }
    const confirmCancelOrder2 = () => {
        let temp = [...orderstatusList2];
        temp[1].status = 2;
        temp[1].text = 'Proposta cancelada'
        temp[1].time = dateCancel

        
        // temp[2].status = 1;
        // temp[3].status = 1;
        // temp[4].status = 1;
        setCancel(true)
        setOrderStatusList2(temp);
    }

    const proposalView = useRef(null);
    const location = useLocation();
    useEffect(() => {
        if(location.state && location.state.initial !== undefined){
            if(location.state.initial === 0)  {
                setAwardOption(true);
                proposalView.current.scrollIntoView()
            }
            else  if(location.state.initial === 1)  {
                setAwardOption(true);
                window.scrollTo(0,0);
            }
            else  if(location.state.initial === 2)  {
                setAwardOption(false);
                proposalView.current.scrollIntoView()
            }
            else  if(location.state.initial === 3)  {
                setAwardOption(false);
                proposalView.current.scrollIntoView()
            }
        }
    }, [location])
 

    const [loading, setLoading] = useState(true)
    const [orderID, setOrderID] = useState(null)
    const [orderINFO, setOrderINFO] = useState({})
    const [customer, setCustomer] = useState({})
    const [document, setDocument] = useState({})

    const [datePublication, setDatePublication] = useState(null)
    const [dateFirstBid, setDateFirstBid] = useState(null)
    const [dateStartWork, setDateStartWork] = useState(null)
    const [dateSearch, setDateSearch] = useState(null)
    const [dateCancel, setDateCancel] = useState(null)
    const [ic_canstartwork, setic_canstartwork] = useState(false);


    const [paymentMethod,setPaymentMethod] = useState(null)
    
    const [bids, setBids] = useState([])


    const [anyAcceptedBID, setAnyAcceptedBID] = useState(false)
    const [acceptedBID, setAcceptedBID] = useState({})


    
  const [flag, setFlag] = React.useState(false)
  //   const [country, setCountry] = useState('BR');
    const [country, setCountry] = useState('');
  
    const [panelName, setPanelName] = useState("Mais detalhes")
    const documentTypeList = ['Nascimento/Batismo', 'Casamento', 'Óbito', 'Imigração', 'Outros'];
    const [documentType, setDocumentType] = useState('');
    const [nm_documentlocation, setNm_documentlocation] = useState('')
    const [ds_familysearchprofile, setDs_familysearchprofile] = useState('')
    
  
    const [fullName, setFullName] = useState('');
  
    const [alterName, setAlterName] = useState([{}]);
  
  //   const [alterTName, setAlterTName] = useState("Maria José da Silva\nMaria José\nMaria Jose\n");
    const [alterTName, setAlterTName] = useState("");
  
    const [isPublic, setPublic] = useState(true);
    const [comment, setComment] = useState('ABCDE ABCDE ABCDE ...');
    const commentChange = (event) => {
        setComment(event.target.value);
    }
    const [life_event_dates, setlife_event_dates] = useState({
        baptism: {
            dt_baptism: null,
            dt_baptismalternativefrom: null,
            dt_baptismalternativeto: null,
            ds_baptismlocation: null,
        },
        birth: {
            dt_birth: null,
            dt_birthalternativefrom: null,
            dt_birthalternativeto: null,
            ds_birthlocation: null,
        },
        marriage: {
            dt_marriage: null,
            dt_marriagealternativefrom: null,
            dt_marriagealternativeto: null,
            ds_marriagelocation: null,
        },
        immigration: {
            dt_immigration: null,
            dt_immigrationalternativefrom: null,
            dt_immigrationalternativeto: null,
            ds_immigrationlocation: null,
        },
        death: {
            dt_death: null,
            dt_deathalternativefrom: null,
            dt_deathalternativeto: null,
            ds_deathlocation: null,
        },
    });


    
    const [family_members, setfamily_members] = useState({
        nm_spouse: '',
        nm_father: '',
        nm_mother: '',
        nm_otherperson: '',
        ds_otherpersonfamiliarity: '',
    });
    

    
    const [acceptanceDate, setacceptanceDate] = useState(null)
    const [acceptNegotiation, setAcceptNegotiation] = useState(false);

    const [proposalDate, setproposalDate] = useState(null)
    const [startwork, setStartWork] = useState(false)
    const [startworkDate, setstartworkDate] = useState(null)
    
    const [dt_bidcanceled, setdt_bidcanceled] = useState(null)
    const [ic_bidcanceled, setic_bidcanceled] = useState(false)
    const [ic_ignorenegotiation, setic_ignorenegotiation] = useState(false)

    const [needValidation, setneedValidation] = useState(false)
    const [needValidationDate, setneedValidationDate] = useState(null)
    const [needValidationStamped, setneedValidationStamped] = useState(false)
    const [needValidationStampedDATE, setneedValidationStampedDATE] = useState(null)

    const [researcherName, setresearcherName] = useState('')

    const [existNegociation, setexistNegociation] = useState(false)
    const [negociationHistory, setnegociationHistory] = useState([])
    const [dt_startnegotiation, setdt_startnegotiation] = useState(null)

    const [atack, setatack] = useState(false)

    const [attachments, setAttachments] = useState([])
    const [needReSend, setneedReSend] = useState(false)
    const [needReSendDate, setneedReSendDate] = useState(null)

    const [valueNegotiation, setvalueNegotiation] = useState(null)

    const [isValidatedDIGITAL, setIsValidatedDIGITAL] =useState(false)
    const [isValidatedDateDIGITAL, setIsValidatedDateDIGITAL] =useState(null)

    const [isValidatedSTAMPED, setisValidatedSTAMPED] = useState(false)
    const [isValidatedSTAMPED_DATE, setisValidatedSTAMPED_DATE] = useState(null)
    

    
    const [isDigital, setisDigital] = useState(false)
    const [isPhysical, setisPhysical] = useState(false)
    const [isStamped, setisStamped ] = useState(false)

    
    const [isSent, setIsSent ] = useState(false)
    const [isSentDate, setIsSentDate ] = useState(null)
    const [isSentInfo, setIsSentInfo ] = useState({})
    const [receiptDateForecast, setreceiptDateForecast ] = useState(null)
    
    
    const [isArrived, setisArrived ] = useState(false)
    const [isArrivedDate, setisArrivedDate ] = useState(null)
    
    const [idResearcherSelected, setidResearcherSelected ] = useState(null)
    const [nameResearcherSelected, setnameResearcherSelected ] = useState('')
    

    
    const [isReceived, setisReceived ] = useState('Á caminho')
    
    const [hasReviewForThisOrder, sethasReviewForThisOrder ] = useState(false)


    const orderdetail = ()=>{
        const path = location.pathname;
        const id = path.split('/')[2];
        
        setOrderID(id)

        const data = {
            orderID:id,
            userID: currentUser.id?currentUser.id:currentUser._id
        }


        API.post(`order/orderdetail`, data)
        .then((result) => {
            if(result.data.status === 'success'){
                //toast.success('sucesso')
                setOrderINFO(result.data.order)
                setCustomer(result.data.customer)
                setDocument(result.data.document)
                setDatePublication(formatDate(result.data.datePublication))
                setPaymentMethod(result.data.paymentMethod)
                
                if(result.data.researcherINFO){
                    setresearcherINFO(result.data.researcherINFO)
                }
                if (result.data.order.tp_order) {
                    setisImported(true)
                    result.data.researcherINFO.reviews.map((singleReview)=>{
                        if (singleReview.id_order.toString() === result.data.order._id.toString()) {
                            sethasReviewForThisOrder(true)
                        }
                    })
                }
                
                if(result.data.isStamped){
                    setisStamped(result.data.isStamped)
                }
                else if(result.data.isPhysical){
                    setisPhysical(result.data.isPhysical)
                }
                else if(result.data.isDigital){
                    setisDigital(result.data.isDigital)
                }

                if(result.data.orderStatus.setAcceptNegotiation){
                    setAcceptNegotiation(result.data.orderStatus.setAcceptNegotiation)
                    setacceptanceDate(formatDate(result.data.orderStatus.acceptanceDate))
                }
                if(result.data.orderStatus.ic_bidcanceled){
                    setic_bidcanceled(result.data.orderStatus.ic_bidcanceled)
                    setdt_bidcanceled(formatDate(result.data.orderStatus.dt_bidcanceled))
                }
                // if(result.data.orderStatus.ic_ignorenegotiation){
                //     setic_ignorenegotiation(result.data.orderStatus.ic_ignorenegotiation)
                //     setic_bidcanceled(false)
                // }
                if(result.data.existsStart === true){
                    setstartworkDate(formatDate(result.data.startworkDate))
                    setStartWork(result.data.existsStart)
                }
                if(result.data.existsValidate === true){
                    setneedValidationDate(formatDate(result.data.validationDate))
                    setneedValidation(result.data.existsValidate)
                }
                if(result.data.needValidationStamped){
                    setneedValidationStamped(result.data.needValidationStamped)
                    setneedValidationStampedDATE(formatDate(result.data.needValidationStampedDATE))
                }

                setCountry(result.data.order.nm_documentcountry)
                setNm_documentlocation(result.data.order.nm_documentlocation)

                        // show document type
                        if(result.data.order.id_documenttype === '639593ac18b3d1cf0751efff'){
                            setDocumentType('Nascimento/Batismo')
                        }
                        else if(result.data.order.id_documenttype === '6395944018b3d1cf0751f000'){
                            setDocumentType('Casamento')
                        }
                        else if(result.data.order.id_documenttype === '6395946118b3d1cf0751f002'){
                            setDocumentType('Óbito')
                        }
                        else if(result.data.order.id_documenttype === '6395947a18b3d1cf0751f003'){
                            setDocumentType('Imigração')
                        }
                        else if(result.data.order.id_documenttype === '6395949d18b3d1cf0751f005'){
                            setDocumentType('Outros')
                        }

                        setDs_familysearchprofile(result.data.order.ds_familysearchprofile)
                        setFullName(result.data.order.nm_ancestor)
                        setfamily_members(result.data.order.family_members)
                        setlife_event_dates(result.data.order.life_event_dates)
                        setAlterName(result.data.order.nm_ancestoralternative)
                        setComment(result.data.order.ds_comments)
                        setAlterTName(alterName.join("\n"))
                        setStatusNum(result.data.cd_orderstatus)
                        setAnyAcceptedBID(result.data.anyAcceptedBID)

                        if(result.data.cd_orderstatus === 3){
                            confirmCancelOrder()
                            confirmCancelOrder1()
                            confirmCancelOrder2()
                        }
                            // exists accepted Bid
                        setAnyAcceptedBID(result.data.anyAcceptedBID)
                        if(result.data.anyAcceptedBID){
                            setAcceptedBID(result.data.acceptedBid)
                            setresearcherName(result.data.orderStatus.researcherName)
                            setidResearcherSelected(result.data.acceptedBid.id_researcher)
                        }
                        if(result.data.bids){
                            setDateFirstBid(formatDate(result.data.dateFirstBid))
                            setBids(result.data.bids)
                        }
                        if(result.data.existNegociation){
                            setexistNegociation(result.data.existNegociation)
                            // search all negociations, and match with this user
                            result.data.negociationHistory.map((singleBidNegociation)=>{
                                singleBidNegociation.map((singleComment)=>{
                                    if(singleComment.id_usercomment === currentUser.id){
                                        setnegociationHistory(singleBidNegociation)
                                    }
                                })
                                setdt_startnegotiation(formatDate(result.data.dt_startnegotiation))
                            })
                        }
                        if(result.data.orderStatus.ic_canstartwork){
                            setic_canstartwork(result.data.orderStatus.ic_canstartwork)
                            
                        }


                        // any attackment?
                            if(result.data.orderStatus.attack){
                                setatack(true)
                                let allAttacks = result.data.orderStatus.attack
                                // setAttachments((result.data.orderStatus.attack).map(item => IMAGE_BASE + `orders/orders_files/${orderID}/${item}`));
                                setAttachments(result.data.orderStatus.attack)
                            }
                            if(result.data.orderStatus.needReSend){
                                setneedReSend(result.data.orderStatus.needReSend)
                                setneedReSendDate(formatDate(result.data.orderStatus.needReSendDate))
                            }


                            if(result.data.orderStatus.isvalidatedDIGITAL){
                                setIsValidatedDIGITAL(result.data.orderStatus.isvalidatedDIGITAL)
                                setIsValidatedDateDIGITAL(formatDate(result.data.orderStatus.isvalidatedDateDIGITAL))
                            }
                            if(result.data.isValidatedSTAMPED){
                                setisValidatedSTAMPED(result.data.isValidatedSTAMPED)
                                setisValidatedSTAMPED_DATE(formatDate(result.data.isValidatedSTAMPED_DATE))
                            }
            
                            if(result.data.isSent){
                                setIsSent(result.data.isSent)
                                setIsSentDate(formatDate(result.data.isSentDate))
                                setIsSentInfo(result.data.sentInfo)
                                setreceiptDateForecast(formatDateWithoutHours(result.data.receiptDateForecast))
                            }

                            if(result.data.isArrived){
                                setisArrived(result.data.isArrived)
                                setisArrivedDate(formatDate(result.data.isArrivedDate))
                                setisReceived(`Entregue em ${formatDateWithoutHours(result.data.isArrivedDate)}`)
                            }

                            if(result.data.hasReviewForThisOrder){
                                sethasReviewForThisOrder(true)
                            }


                setLoading(false)
            }
            else if(result.data.status === 'fail-auth'){
                toast.error(result.data.message)
                navigate(`/order/${id}`)
            }
            else{
                toast.error('Erro ao obter dados da ordem.')
            }
          })
          .catch((err) => {

            if(err.message === 'Request failed with status code 500'){
                toast.error('Você precisa estar logado corretamente para acessar')
            }else{
                toast.error(`Erro ao obter dados da ordem ||| ${err.message} `) 
            }

            navigate('/login')
            console.log(err);
        });

    }


    useEffect(()=>{
        orderdetail()
    }, [location])


    
    useEffect(()=>{
        
        // auth if is a valid customer
        // if(customer._id){
        //     if(customer._id !==  currentUser._id){
        //         navigate('/publications')
        //     }
        // }
        // if(customer.id){
        //     if(customer.id !== currentUser.id){
        //         navigate('/publications')
        //     }
        // }

    },[customer])
    
    useEffect(()=>{


        

        // exists info - set pedido publicado
        if(orderINFO._id ){
            let newOrderStatusList = [...orderstatusList]; // fazer uma cópia do array
            let newOrderStatusList1 = [...orderstatusList1]; // fazer uma cópia do array
            let newOrderStatusList2 = [...orderstatusList2]; // fazer uma cópia do array
            
            newOrderStatusList[0].time = datePublication; // modificar o valor de "time" do primeiro objeto
            newOrderStatusList[0].status = 0; // modificar o status do primeiro objeto

            newOrderStatusList1[0].time = datePublication; // modificar o valor de "time" do primeiro objeto
            newOrderStatusList1[0].status = 0; // modificar o status do primeiro objeto

            newOrderStatusList2[0].time = datePublication; // modificar o valor de "time" do primeiro objeto
            newOrderStatusList2[0].status = 0; // modificar o status do primeiro objeto
            
            setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
            setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
            setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado

            // any bid?
            if(bids.length>0){
                newOrderStatusList[1].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[1].time = dateFirstBid; // modificar o status do primeiro objeto

                newOrderStatusList1[1].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[1].time = dateFirstBid; // modificar o status do primeiro objeto

                newOrderStatusList2[1].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[1].time = dateFirstBid; // modificar o status do primeiro objeto

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
            }
            // change state for accepted Bid
            if(existNegociation){
                newOrderStatusList[0].time = dt_startnegotiation; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[0].text = 'Em Negociação';

                newOrderStatusList1[0].time = dt_startnegotiation; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[0].text = 'Em Negociação';

                newOrderStatusList2[0].time = dt_startnegotiation; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[0].text = 'Em Negociação';

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado

                negociationHistory.map((singlenegotiation, index, array)=>{
                    if(index === array.length - 1){
                        setComment(singlenegotiation.ds_comment)
                        setvalueNegotiation(singlenegotiation.vl_value)
                    }
                })
            }


            if(cancel){
                
                newOrderStatusList[1].text = 'Proposta cancelada';
                newOrderStatusList[1].status = 2;
                newOrderStatusList1[1].text = 'Proposta cancelada';
                newOrderStatusList1[1].status = 2;
                newOrderStatusList2[1].text = 'Proposta cancelada';
                newOrderStatusList2[1].status = 2;
                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado

                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
                return setStatusNum(3)

            }

            if(anyAcceptedBID ){
                newOrderStatusList[1].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[1].time = dateFirstBid; // modificar o status do primeiro objeto
                newOrderStatusList[1].text = 'Proposta aceita'; // modificar o status do primeiro objeto

                newOrderStatusList1[1].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[1].time = dateFirstBid; // modificar o status do primeiro objeto
                newOrderStatusList1[1].text = 'Proposta aceita'; // modificar o status do primeiro objeto

                newOrderStatusList2[1].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[1].time = dateFirstBid; // modificar o status do primeiro objeto
                newOrderStatusList2[1].text = 'Proposta aceita'; // modificar o status do primeiro objeto
                
                newOrderStatusList[2].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[2].text = 'Aguardando pesquisador'; // modificar o status do primeiro objeto

                newOrderStatusList1[2].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[2].text = 'Aguardando pesquisador'; // modificar o status do primeiro objeto

                newOrderStatusList2[2].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[2].text = 'Aguardando pesquisador'; // modificar o status do primeiro objeto

                setStatusNum(1)
                if(!ic_canstartwork){
                    newOrderStatusList[2].text = 'Aguardando pagamento';
                    newOrderStatusList1[2].text = 'Aguardando pagamento';
                    newOrderStatusList2[2].text = 'Aguardando pagamento';
                    setStatusNum(0)
                }
                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
            }



            if(startwork ){
                newOrderStatusList[2].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[2].time = startworkDate; // modificar o status do primeiro objeto
                newOrderStatusList[2].text = 'Pesquisador trabalhando'; // modificar o status do primeiro objeto

                newOrderStatusList1[2].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[2].time = startworkDate; // modificar o status do primeiro objeto
                newOrderStatusList1[2].text = 'Pesquisador trabalhando'; // modificar o status do primeiro objeto

                newOrderStatusList2[2].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[2].time = startworkDate; // modificar o status do primeiro objeto
                newOrderStatusList2[2].text = 'Pesquisador trabalhando'; // modificar o status do primeiro objeto

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
                setStatusNum(1)
            }
            if(needValidation ){
                newOrderStatusList[3].time = needValidationDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[3].text = 'Aguardando validação';

                newOrderStatusList1[3].time = needValidationDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[3].text = 'Aguardando validação';

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
            }
            if(isValidatedDIGITAL ){
                newOrderStatusList[3].time = isValidatedDateDIGITAL; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[3].text = 'Pedido validado';
                newOrderStatusList[4].time = isValidatedDateDIGITAL; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[4].status = 0; // modificar o status do primeiro objeto

                newOrderStatusList1[3].time = isValidatedDateDIGITAL; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[3].text = 'Pedido validado';
                
                newOrderStatusList2[3].time = isValidatedDateDIGITAL; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[3].text = 'Pedido validado';
                if(isDigital){
                    setStatusNum(2)
                }
                if(isStamped){
                    newOrderStatusList2[4].status = 0; 
                    newOrderStatusList2[4].text = 'Apostilando documento';
                }
                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
            }
            if(needValidationStamped ){

                newOrderStatusList[4].time = needValidationStampedDATE; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[4].text = 'Aguardando validação';

                newOrderStatusList1[4].time = needValidationStampedDATE; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[4].text = 'Aguardando validação';

                newOrderStatusList2[4].time = needValidationStampedDATE; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[4].text = 'Aguardando validação';

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado


            }
            if(isValidatedSTAMPED ){

                newOrderStatusList[4].time = isValidatedSTAMPED_DATE; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[4].text = 'Documento validado';

                newOrderStatusList1[4].time = isValidatedSTAMPED_DATE; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[4].text = 'Documento validado';

                newOrderStatusList2[4].time = isValidatedSTAMPED_DATE; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[4].text = 'Documento validado';

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado


            }


            if(isSent ){
                newOrderStatusList1[4].time = isSentDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[4].text = 'Pedido enviado';
                
                
                newOrderStatusList2[5].time = isSentDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[5].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[5].text = 'Pedido enviado';
                newOrderStatusList2[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[4].text = 'Documento apostilado';

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
            }
            if(isArrived ){
                newOrderStatusList1[5].time = isArrivedDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[5].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[5].text = 'Pedido entregue';
                newOrderStatusList1[6].time = isArrivedDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[6].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[6].text = 'Pedido concluído';
                
                newOrderStatusList2[6].time = isArrivedDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[6].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[6].text = 'Pedido entregue';
                newOrderStatusList2[7].time = isArrivedDate; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[7].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[7].text = 'Pedido concluído';

                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
                setStatusNum(2)
            }
            if(ic_bidcanceled){
                newOrderStatusList[0].time = dt_bidcanceled; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[0].text = 'Proposta cancelada';
                newOrderStatusList[1].time = dt_bidcanceled; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList[1].status = 2; // modificar o status do primeiro objeto
                newOrderStatusList[1].text = 'Proposta cancelada';
                newOrderStatusList[2].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList[3].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList[4].status = 1; // modificar o status do primeiro objeto

                newOrderStatusList1[0].time = dt_bidcanceled; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[0].text = 'Proposta cancelada';
                newOrderStatusList1[1].time = dt_bidcanceled; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList1[1].status = 2; // modificar o status do primeiro objeto
                newOrderStatusList1[1].text = 'Proposta cancelada';
                newOrderStatusList1[2].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList1[3].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList1[4].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList1[5].status = 1; // modificar o status do primeiro objeto

                newOrderStatusList2[0].time = dt_bidcanceled; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[0].text = 'Proposta cancelada';
                newOrderStatusList2[1].time = dt_bidcanceled; // modificar o valor de "time" do primeiro objeto
                newOrderStatusList2[1].status = 2; // modificar o status do primeiro objeto
                newOrderStatusList2[1].text = 'Proposta cancelada';
                newOrderStatusList2[2].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList2[3].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList2[4].status = 1; // modificar o status do primeiro objeto
                newOrderStatusList2[5].status = 1; // modificar o status do primeiro objeto


                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
                setStatusNum(3)
            }


            if(isImported){
                newOrderStatusList[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[1].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[2].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList[4].status = 0; // modificar o status do primeiro objeto

                newOrderStatusList1[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[1].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[2].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[5].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList1[6].status = 0; // modificar o status do primeiro objeto

                newOrderStatusList2[0].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[1].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[2].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[3].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[4].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[5].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[6].status = 0; // modificar o status do primeiro objeto
                newOrderStatusList2[7].status = 0; // modificar o status do primeiro objeto


                setOrderStatusList(newOrderStatusList); // atualizar o estado com o novo array modificado
                setOrderStatusList1(newOrderStatusList1); // atualizar o estado com o novo array modificado
                setOrderStatusList2(newOrderStatusList2); // atualizar o estado com o novo array modificado
                setStatusNum(2)

            }

            
            if(statusNum === 3){
                confirmCancelOrder()
            }



        }

    },[orderINFO])      

    const [openModal,setopenModal]=useState(false)
    const [cardContent,setCardContent]=useState({})
    const [checkoutData,setcheckoutData]=useState({})
    const [value, setValue] = useState(0)

    const [problemComment, setproblemComment] = useState('');
    const [problemDoc, setproblemDoc] = useState(['Nome do arquivo ou o tipo do documento...']);
    const [problemPage, setproblemPage] = useState();

    useEffect(()=>{
    },[problemComment, problemDoc, ])
    const reportProblem = async()=>{
        setLoading(true)
        const data = {
            orderID,
            problemDoc,
            problemComment
        }
        API.post(`order/reportproblem_customer`, data)
        .then((result)=>{
            setLoading(false)
            if(result.data.status === 'success'){
                toast.success(result.data.message?result.data.message:"Sucesso");
                setproblemPage(problemPage + 1);
                // orderdetail();
            }
            else{
                toast.error(result.data.message)
                orderdetail();

            }
        })
        .catch((err)=>{
            setLoading(false)
            toast.error(err)
            orderdetail();

        })



    }





    const confirmStartWork = () => {
        setCardContent({
            // importNumber:5,
            title:'Iniciante',
            normalPrice:'69',
            currentPrice:'59',
            benefits:['2 país como especialidade','4 dias úteis para receber','15% de taxa de intermediação','Propostas até especialista','90 propostas mensais','10 projetos em execução'],
            isHighlighted:false,
            comment:'Perfeito para quem está iniciando sua jornada de pesquisa e possui pouco histórico!',
            image:checkoutImage,
        })
        setcheckoutData({
            bidname:`Iniciar pesquisa`,
            bidImage:checkoutImage,
            normalprice:'',
            promoprice:orderINFO.vl_budget,
            moreinfo:[`Você aceitará a proposta do pesquisador. Após isso, ele poderá começar a pesquisa, conforme solicitado.`],
        })
        setopenModal(true);

    } 


    return (
       <RootStyle>

                {loading === true && 
                    <>
                        <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            zIndex: 9998, // certifique-se de que este valor é maior do que qualquer outro elemento na página
                        }}
                        >
                        <Loading />
                    </div>
                    </>
                }



             <ConfirmAnalyze openModal={openConfirmAnalyze} setOpenModal={setOpenConfirmAnalyze} confirmFunc={confirmAnalyse} reportFunc={setOpenReportProblem} isDigital={isDigital} isPhysical={isPhysical} isStamped={isStamped}/>
             <CancelOrder openModal={openCancelOrder} setOpenModal={setOpenCancelOrder} confirmFunc={confirmCancelOrder} pageNum={1} orderINFO={orderINFO}/>
             <ReportProblem openModal={openReportProblem} setOpenModal={setOpenReportProblem} data={attachments.map((item => {return item.split('/').slice(-1)}))} pageNum={1} isDigital={isDigital} isPhysical={isPhysical} isStamped={isStamped} orderINFO={orderINFO} reportProblem={reportProblem} setproblemComment={setproblemComment} problemComment={problemComment} setproblemDoc={setproblemDoc} problemDoc={problemDoc} setproblemPage={setproblemPage} problemPage={problemPage}/>
           
             <ConfirmAddressToSend 
                openModal={confirmAddressToSend} 
                setOpenModal={setConfirmAddressToSend} 
                pageNum={1} 

                confirmFunc={confirmsenddoc} 

                addressto_nm_street={addressto_nm_street}
                setaddressto_nm_street={setaddressto_nm_street}
                addressto_ds_streetcomplement={addressto_ds_streetcomplement}
                setaddressto_ds_streetcomplement={setaddressto_ds_streetcomplement}
                addressto_nm_state={addressto_nm_state}
                setaddressto_nm_state={setaddressto_nm_state}
                addressto_nm_city={addressto_nm_city}
                setaddressto_nm_city={setaddressto_nm_city}
                addressto_nm_county={addressto_nm_county}
                setaddressto_nm_county={setaddressto_nm_county}
                addressto_cd_zip={addressto_cd_zip}
                setaddressto_cd_zip={setaddressto_cd_zip}

                orderID={orderID} 
                currentUserID={currentUser.id? currentUser.id : currentUser._id}

                loading={loading}
                setLoading={setLoading}
            />


            <ContainerStyle className = '!mt-[40px]'>
                <div className='flex justify-between !mb-[20px]'>
                    <div className="font-[Poppins] font-semibold text-[14px] leading-[21px] text-[#313131]">Cliente</div>
                    <div className="font-[Poppins] font-semibold text-[14px] leading-[21px] text-[#313131]" >Pesquisador</div>     
                </div>
                {!isImported &&
                <div className='flex justify-between  !mb-[30px]'>
                    <div className="font-[Poppins] font-normal text-[14px] leading-[21px] underline text-[#7E7E7E]">{customer.nm_user}</div>
                    <div className="flex">
                    {idResearcherSelected &&
                        <>
                            <Link to={`/update_customer/${orderID}`} className="mr-[20px] "><Box component="img" src={NotificationIcon} alt="notificacao-icone" width='20px' height='20px' style={{animation: "stake 5s infinite"}}/></Link>    
                            <Link to={`/expertprofile/${idResearcherSelected}`} className="font-[Poppins] font-normal text-[14px] leading-[21px] underline text-[#7E7E7E] ">{researcherName}</Link>    
                        </>
                    }
                    </div>  
                </div>
                }
                {isImported &&
                    <div className='flex justify-between  !mb-[30px]'>
                        <div className="font-[Poppins] font-normal text-[14px] leading-[21px] underline text-[#7E7E7E]">{customer.nm_user}</div>
                        <div className="flex">
                                <Link to={`/expertprofile/${researcherINFO._id}`} className="font-[Poppins] font-normal text-[14px] leading-[21px] underline text-[#7E7E7E] ">{researcherINFO.nm_user}</Link>    
                        </div>  
                    </div>
                
                }
                <div className='border-solid border-[1px] bg-[#C6C6C6] w-[100%] opacity-91 h-[1px]'></div>
                <div className="font-[Poppins] font-medium text-[22px] leading-[100%] text-[#313131] mt-[20px]">Informações do documento</div>
                <div className="rounded-[7px] border-solid border-[1px] border-[#C6C6C6] mt-[20px]">
                    <div className='w-[100%] mb:block xl:flex mt-[18px] ml-[18px] mr-[20px] mb:mb-[30px] xl:mb-[10px] text-left'>
                        <div className='mb:w-full xl:w-[20%] mb:mt-[15px] xl:mt-[0px]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Nome completo:</div>
                            <div className='font-[Poppins] font-light text-[14px] leading-[21px] text-[#7E7E7E] flex items-center h-[42px]'>{orderINFO.nm_ancestor}</div>
                        </div>
                        <div className='mb:w-full xl:w-[12%] mt-[15px] xl:mt-[0px] font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Pedido</div>
                            <div className='font-[Poppins] font-light text-[14px] leading-[21px] text-[#7E7E7E] flex items-center h-[42px]'>{orderINFO.cd_order}</div>
                        </div>
                        <div className='mb:w-full xl:w-[20%] mt-[15px] xl:mt-[0px] font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Tipo de documento </div>
                            <div className='font-[Poppins] font-light text-[14px] leading-[21px] text-[#7E7E7E] flex items-center h-[42px]'>{document.nm_documenttype}</div>
                        </div>
                        <div className='mb:w-full xl:w-[15%] mt-[15px] xl:mt-[0px] font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Status</div>
                            <div className='flex items-center h-[42px]'>
                                <div className='flex items-center h-[20px] bg-[#F5F5F5] w-[162px] whitespace-nowrap rounded-[10px] '>
                                    {/* <Box component={'img'} src={Ellipse} className='w-[6.65px] h-[6px] bg-[#4A4AFF] ml-[5.5px] mr-[5.5px] rounded-[50%]'/> */}
                                {
                                loading === true &&
                                    <>
                                        {
                                            statusNum === 0 &&
                                            <div className='flex items-center h-[20px] bg-[#F5F5F5] w-[162px] whitespace-nowrap rounded-[10px] '>
                                                <div className="w-[6px] h-[6px] rounded-[5px] bg-[#E2BD7B] ml-[5.5px] mr-[5.5px]"></div>
                                                <div className="font-[Poppins] font-medium text-[12px] leading-[15px] text-[#E2BD7B] w-[95px]">Carregando...</div>
                                            </div>
                                        }
                                    </>
                                }
                                {
                                loading === false &&
                                    <>
                                        {
                                            statusNum === 0 &&
                                            <div className='flex items-center h-[20px] bg-[#F5F5F5] w-[162px] whitespace-nowrap rounded-[10px] '>
                                                <div className="w-[6px] h-[6px] rounded-[5px] bg-[#E2BD7B] ml-[5.5px] mr-[5.5px]"></div>
                                                <div className="font-[Poppins] font-medium text-[12px] leading-[15px] text-[#E2BD7B] w-[95px]">{statusList[statusNum]}</div>
                                            </div>
                                        }
                                        {
                                            statusNum === 1 &&
                                            <div className='flex items-center h-[20px] bg-[#F5F5F5] w-[162px] whitespace-nowrap rounded-[10px] '>
                                                <div className="w-[6px] h-[6px] rounded-[5px] bg-[#4A4AFF] ml-[5.5px] mr-[5.5px]"></div>
                                                <div className="font-[Poppins] font-medium text-[12px] leading-[15px] text-[#4A4AFF] w-[95px]">{statusList[statusNum]}</div>
                                            </div>
                                        }
                                        {
                                            statusNum === 2 &&
                                            <div className='flex items-center h-[20px] bg-[#F5F5F5] w-[162px] whitespace-nowrap rounded-[10px] '>
                                                <div className="w-[6px] h-[6px] rounded-[5px] bg-[#17686E] ml-[5.5px] mr-[5.5px]"></div>
                                                <div className="font-[Poppins] font-medium text-[12px] leading-[15px] text-[#17686E] w-[95px]">{statusList[statusNum]}</div>
                                            </div>
                                        }
                                        {
                                            statusNum === 3 &&
                                            <div className='flex items-center h-[20px] bg-[#F5F5F5] w-[162px] whitespace-nowrap rounded-[10px] '>
                                                <div className="w-[6px] h-[6px] rounded-[5px] bg-[#BA483A] ml-[5.5px] mr-[5.5px]"></div>
                                                <div className="font-[Poppins] font-medium text-[12px] leading-[15px] text-[#BA483A] w-[95px]">{statusList[statusNum]}</div>
                                            </div>
                                        }
                                    </>
                                }
                                </div>
                            </div>
                        </div>
                        <div className='mb:w-full xl:w-[12%] mt-[15px] xl:mt-[0px] font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Data</div>
                            <div className='font-[Poppins] font-light text-[14px] leading-[21px] text-[#7E7E7E] flex items-center h-[42px]'>{datePublication}</div>
                        </div>
                        {!isImported &&
                        <div className='mb:w-full xl:w-[10%] mt-[15px] xl:mt-[0px] font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>
                            <div className='font-[Poppins] font-medium text-[16px] leading-[100%] text-[#313131]'>Pagamento</div>
                            <div className='font-[Poppins] font-light text-[14px] leading-[21px] text-[#7E7E7E] flex items-center h-[42px]'>
                                {/* {paymentMethod}  */}
                                {
                                    anyAcceptedBID === false? 
                                    '-'
                                    : 
                                        `R$ ${Number.parseFloat(acceptedBID.vl_researcherbid).toFixed(2).replace('.', ',')}`
                                } 
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <div className='w-[100%] flex justify-center'>
                    <div className='w-[96%]'>
                        <Custom_Accordion 
                        data={orderINFO}
                        flag={flag}
                        country={country} 
                        panelName={panelName} 
                        documentTypeList={documentTypeList}
                        documentType={documentType}
                        nm_documentlocation ={nm_documentlocation}
                        ds_familysearchprofile={ds_familysearchprofile}
                        fullName={fullName}
                        alterName={alterName}
                        alterTName={alterTName}
                        isPublic={isPublic}
                        comment={comment}
                        life_event_dates={life_event_dates}
                        setPanelName={setPanelName}
                        setFlag={setFlag}
                        setFullName={setFullName}
                        setlife_event_dates={setlife_event_dates}
                        setCountry={setCountry}
                        commentChange={commentChange}
                        family_members={family_members}
                        loading={loading}
                        />
                    </div>
                </div>





                <div className="font-[Poppins] font-medium text-[22px] leading-[100%] text-[#313131] mt-[20px]">Status do Pedido</div>
                {isDigital &&
                    <>
                        <StatusBar orderstatusList={orderstatusList} cancel={cancel}/>
                    </>
                }
                {isPhysical &&
                    <>
                        <StatusBar orderstatusList={orderstatusList1} cancel={cancel}/>
                    </>
                }
                {isStamped &&
                    <>
                        <StatusBar orderstatusList={orderstatusList2} cancel={cancel}/>
                    </>
                }

                {openModal && <Checkout openModal={openModal} setopenModal={setopenModal} type={'sendnegociation'} cardContent={cardContent} checkoutData={checkoutData} researcherID={researcherINFO._id} orderID={orderID}/>}

                {acceptNegotiation && !ic_canstartwork && 

                <div className='w-full flex justify-between mt-[32px] min-h-[70px] h-fit items-center mb:flex-col md:flex-row'>
                <div className="bg-[#D9D9D9] bg-opacity-20 flex items-center justify-start w-[80%]">
                    <div className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px] pb-[18px]">Após o pagamento do valor acordado, o pesquisador poderá iniciar o trabalho. O valor somente será liberado após a conclusão da pesquisa.</div>
                </div>
                <div className="flex flex-col">
                    <HeaderBTNStyle1 onClick={() => {confirmStartWork()}}>Iniciar pesquisa</HeaderBTNStyle1>
                    <div className='flex justify-center mt-[10px]'>
                    <span className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#313131]'>Reportar problema,</span>&nbsp;
                    <span onClick={()=> {setOpenReportProblem(true)}} className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#4A4AFF] underline cursor-pointer'>entre aqui.</span>&nbsp;
                    </div>
                </div>
                </div>

                }
                {isValidatedDIGITAL && !isDigital && !isSent && !orderINFO.address_to_delivery && 

                <div className='w-full flex justify-between mt-[32px] min-h-[70px] h-fit items-center mb:flex-col md:flex-row'>
                <div className="bg-[#D9D9D9] bg-opacity-20 flex items-center justify-start w-[80%]">
                    <div className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px] pb-[18px]">Por favor, informe o endereço em que o documento deverá ser entregue.</div>
                </div>
                <div className="flex flex-col">
                    <HeaderBTNStyle1 onClick={() => {setConfirmAddressToSend(true)}}>Adicionar endereço</HeaderBTNStyle1>
                    <div className='flex justify-center mt-[10px]'>
                    <span className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#313131]'>Reportar problema,</span>&nbsp;
                    <span onClick={()=> {setOpenReportProblem(true)}} className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#4A4AFF] underline cursor-pointer'>entre aqui.</span>&nbsp;
                    </div>
                </div>
                </div>

                }

                <div className='w-full flex justify-end mt-[32px]' style={{gap:'10px'}}>
                    {   !isValidatedDIGITAL &&         cancel &&
                        <HeaderBTNStyle className="!cursor-not-allowed" disabled sx={{'&:disabled': {backgroundColor: '#FFFFFF', borderColor: '#C6C6C6', color:'#C6C6C6', cursor: 'not-allowed'}}} onClick={()=>{setOpenCancelOrder(true)}}>Pedido cancelado</HeaderBTNStyle> 
                    }
                    {   !isValidatedDIGITAL &&         !cancel && !needValidation && attachments.length === 0 &&
                        <HeaderBTNStyle onClick={()=>{setOpenCancelOrder(true)}}>Cancelar pedido</HeaderBTNStyle>
                    }

                </div>

        




                {
                    needValidation && !isValidatedDIGITAL && !cancel &&

                        isDigital

                    &&

                    <div className='w-full flex justify-between mt-[32px] min-h-[70px] h-fit items-center mb:flex-col md:flex-row'>
                    <div className="bg-[#D9D9D9] bg-opacity-20 flex items-center justify-start w-[80%]">
                        <div className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px] pb-[18px]">Caso não confirme a análise em {limit} dias corridos, a cofirmação será feita automaticamente, liberando o pagamento para seu pesquisador.</div>
                    </div>
                    <div className="flex flex-col">
                        <HeaderBTNStyle1 onClick={() => {setOpenConfirmAnalyze(true)}}>Confirmar análise</HeaderBTNStyle1>
                        <div className='flex justify-center mt-[10px]'>
                        <span className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#313131]'>Reportar problema,</span>&nbsp;
                        <span onClick={()=> {setOpenReportProblem(true)}} className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#4A4AFF] underline cursor-pointer'>entre aqui.</span>&nbsp;
                        </div>
                    </div>
                </div>
                }
                {isImported &&
                    <div className='w-full flex justify-end mt-[32px]'>
                        {hasReviewForThisOrder?
                            <MakeProposalBTNStyleDISABLED disabled>Pedido já avaliado</MakeProposalBTNStyleDISABLED>
                            :
                            <HeaderBTNStyle1 onClick={()=>{navigate(`/feedback/researcher/${researcherINFO._id}/${orderID}`)}}>Avaliar pesquisador</HeaderBTNStyle1>
                        }
                    </div>
                }
                {   isDigital &&
                    isValidatedDIGITAL && !hasReviewForThisOrder &&
                    <div className='w-full flex justify-end mt-[32px]'>
                        <HeaderBTNStyle1 onClick={()=>{navigate(`/feedback/researcher/${idResearcherSelected}/${orderID}`)}}>Avaliar pesquisador</HeaderBTNStyle1>
                    </div>
                }
                {   isPhysical &&
                    isArrived && !hasReviewForThisOrder &&
                    <div className='w-full flex justify-end mt-[32px]'>
                        <HeaderBTNStyle1 onClick={()=>{navigate(`/feedback/researcher/${idResearcherSelected}/${orderID}`)}}>Avaliar pesquisador</HeaderBTNStyle1>
                    </div>
                }
                {   isStamped &&
                    isArrived && !hasReviewForThisOrder &&
                    <div className='w-full flex justify-end mt-[32px]'>
                        <HeaderBTNStyle1 onClick={()=>{navigate(`/feedback/researcher/${idResearcherSelected}/${orderID}`)}}>Avaliar pesquisador</HeaderBTNStyle1>
                    </div>
                }

                {/* <StatusBar orderstatusList={orderstatusList1}/> */}

                {!cancel  && needValidation && !isValidatedDIGITAL &&  
                
                    isPhysical &&

                    <div className='w-full flex justify-between mt-[32px] min-h-[70px] h-fit items-center mb:flex-col md:flex-row'>
                        <div className="bg-[#D9D9D9] bg-opacity-20 flex flex-col items-start justify-start w-[80%] min-h-[93px]">
                            <div className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px]">Caso não confirme a análise em {limit} dias corridos, a cofirmação será feita automaticamente, liberando para o pesquisador enviar o documento fisicamente.</div>
                            <div className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-light pl-[21px] pr-[21px] pt-[18px] pb-[18px]">O pagamento será após a confirmação de recebimento físico.</div>
                        </div>
                        <div className="flex flex-col">
                                <HeaderBTNStyle1 onClick={() => {setOpenConfirmAnalyze(true)}}>Confirmar análise</HeaderBTNStyle1>
                            <div className='flex justify-center mt-[10px]'>
                            <span className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#313131]'>Reportar problema,</span>&nbsp;
                            <span onClick={()=> {setOpenReportProblem(true)}} className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#4A4AFF] underline cursor-pointer'>entre aqui.</span>&nbsp;
                            </div>
                        </div>
                    </div>
                }
                {!cancel  && needValidation && isValidatedDIGITAL &&  needValidationStamped && !isValidatedSTAMPED &&
                
                    isPhysical &&

                    <div className='w-full flex justify-between mt-[32px] min-h-[70px] h-fit items-center mb:flex-col md:flex-row'>
                        <div className="bg-[#D9D9D9] bg-opacity-20 flex flex-col items-start justify-start w-[80%] min-h-[93px]">
                            <div className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px]">Caso não confirme a análise em {limit} dias corridos, a cofirmação será feita automaticamente, liberando para o pesquisador enviar o documento fisicamente.</div>
                            <div className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-light pl-[21px] pr-[21px] pt-[18px] pb-[18px]">O pagamento será após a confirmação de recebimento físico.</div>
                        </div>
                        <div className="flex flex-col">
                            <HeaderBTNStyle1 onClick={confirmPhysicalDocFlow}>Confirmar análise</HeaderBTNStyle1>
                            <div className='flex justify-center mt-[10px]'>
                            <span className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#313131]'>Reportar problema,</span>&nbsp;
                            <span onClick={()=> {setOpenReportProblem(true)}} className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#4A4AFF] underline cursor-pointer'>entre aqui.</span>&nbsp;
                            </div>
                        </div>
                    </div>
                }


                {/* <StatusBar orderstatusList={orderstatusList2}/> */}

                {/* {!cancel  &&  !isValidatedDIGITAL &&       */}
                {!cancel  && needValidation && !isValidatedDIGITAL &&  
                
                    isStamped &&
                    <div className='w-full flex justify-between mt-[32px] min-h-[70px] h-fit items-center mb:flex-col md:flex-row'>
                        <div className="bg-[#D9D9D9] bg-opacity-20 flex flex-col items-start justify-start w-[80%] min-h-[93px]">
                            <div className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px]">Caso não confirme a análise em {limit} dias corridos, a cofirmação será feita automaticamente, liberando para o pesquisador enviar o documento fisicamente.</div>
                            <div className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-light pl-[21px] pr-[21px] pt-[18px] pb-[18px]">O pagamento será após sua confirmação de recebimento físico.</div>
                            
                        </div>
                        <div className="flex flex-col">
                            <HeaderBTNStyle1 onClick={() => {setOpenConfirmAnalyze(true)}}>Confirmar análise</HeaderBTNStyle1>
                            <div className='flex justify-center mt-[10px]'>
                            <span className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#313131]'>Reportar problema,</span>&nbsp;
                            <span onClick={()=> {setOpenReportProblem(true)}} className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#4A4AFF] underline cursor-pointer'>entre aqui.</span>&nbsp;
                            </div>
                        </div>
                    </div>
                }
                
                {!cancel  && needValidation && isValidatedDIGITAL &&!isValidatedSTAMPED && needValidationStamped &&
                
                    isStamped &&
                    <div className='w-full flex justify-between mt-[32px] min-h-[70px] h-fit items-center mb:flex-col md:flex-row'>
                        <div className="bg-[#D9D9D9] bg-opacity-20 flex flex-col items-start justify-start w-[80%] min-h-[93px]">
                            <div className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px]">Caso não confirme a análise em {limit} dias corridos, a cofirmação será feita automaticamente, liberando para o pesquisador enviar o documento fisicamente.</div>
                            {/* <div className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-light pl-[21px] pr-[21px] pt-[18px] pb-[18px]">O pagamento será após sua confirmação de recebimento físico.</div> */}
                            
                        </div>
                        <div className="flex flex-col">
                            <HeaderBTNStyle1 onClick={confirmPhysicalDocFlow}>Confirmar análise</HeaderBTNStyle1>
                            <div className='flex justify-center mt-[10px]'>
                            <span className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#313131]'>Reportar problema,</span>&nbsp;
                            <span onClick={()=> {setOpenReportProblem(true)}} className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#4A4AFF] underline cursor-pointer'>entre aqui.</span>&nbsp;
                            </div>

                            
                        </div>
                    </div>
                }
                
                {
                    isSent &&
                    <div className='w-full flex justify-between mt-[32px] min-h-[70px] h-fit items-center mb:flex-col md:flex-row'>
                        <div className="bg-[#D9D9D9] bg-opacity-20 flex flex-col items-start justify-start w-[80%]  min-h-[93px]">
                            {/* <div className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-bold pl-[21px] pr-[21px] pt-[18px]">Caso não confirme a análise em 10 dias corridos, a cofirmação será feita automaticamente, liberando para o pesquisador enviar o documento fisicamente.</div>
                            <div className="text-[#BA483A] font-[Poppins] text-[12px] leading-[18px] font-light pl-[21px] pr-[21px] pt-[18px] pb-[18px]">O pagamento será após sua confirmação de recebimento físico.</div> */}
                            <div className="flex mt-[18px] mb:ml-[15px] md:ml-[32px] justify-between mb:w-[90%] md:w-[75%] mb:mb-[18px] md:mb-[39px] md:flex-row mb:flex-col">
                            <div className='flex mb:flex-row md:flex-col justify-between'>
                                <div className='font-[Poppins] text-[12px] leading-[25px] font-normal'>Forma de envio:</div>
                                <div className='font-[Poppins] text-[12px] leading-[25px] font-semibold'>{isSentInfo.cd_shippingway}</div>    
                            </div>          
                            <div className='flex mb:flex-row md:flex-col justify-between'>
                                <div className='font-[Poppins] text-[12px] leading-[25px] font-normal'>Código de rastreio:</div>
                                <div className='font-[Poppins] text-[12px] leading-[25px] font-semibold'>{isSentInfo.cd_trackingcode}</div>    
                            </div>   
                            <div className='flex mb:flex-row md:flex-col justify-between'>
                                <div className='font-[Poppins] text-[12px] leading-[25px] font-normal'>Data prevista de entrega:</div>
                                <div className='font-[Poppins] text-[12px] leading-[25px] font-semibold'>{receiptDateForecast? receiptDateForecast : ''}</div>    
                            </div>   
                            <div className='flex mb:flex-row md:flex-col justify-between'>
                                <div className='font-[Poppins] text-[12px] leading-[25px] font-normal'>Status</div>
                                <div className='font-[Poppins] text-[12px] leading-[25px] font-semibold text-[#17686E]'>{isReceived}</div>    
                            </div>   
                            </div>
                            {!isArrived &&
                                <div className='font-[Poppins] mb:ml-[15px] md:ml-[20px] text-[14px] leading-[21px] font-normal text-[#BA483A]' style={{marginTop:'-35px'}}>
                                Caso não confirme o recebimento em {limit} dias corridos após a data prevista, a cofirmação será feita automaticamente, liberando o pagamento para seu pesquisador.
                                </div>
                            }

                  
                        </div>

                        {!isArrived &&
                        <div className="">
                            <HeaderBTNStyle1 onClick={confirmPhysicalDocFlow}>Confirmar recebimento</HeaderBTNStyle1>
                            <div className='flex justify-center mt-[10px]'>
                                <span className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#313131]'>Reportar problema,</span>&nbsp;
                                <span onClick={()=> {setOpenReportProblem(true)}} className='font-[Poppins] font-normal text-[10px] leading-[100%] text-[#4A4AFF] underline cursor-pointer'>entre aqui.</span>&nbsp;
                            </div>
                        </div>
                        }
                    </div>
                }


                {
                    attachments.length === 0  &&
                    <>
                <div className="font-[Poppins] font-medium text-[22px] leading-[100%] text-[#313131] mt-[20px] ">Documento soliticado:</div>
                

                <div className='flex justify-center w-full'>
                    <div className="w-[92%] rounded-[7px] border-solid border-[0.5px] border-[#C6C6C6] mt-[20px] h-[186px] flex flex-row items-center justify-center">
                        <div className='flex flex-col items-center'>
                            <div className='font-[Poppins] font-bold text-[18px] leading-[100%] text-[#C6C6C6] mb-[12px]'>Aguardando anexo</div>
                            <div><Box component='img' src={AttachmentIcon} width="60px" height='65px'></Box></div>
                        </div>
                    </div>
                </div>
                </>
                }

                {
                    isImported && attachments&&attachments.length > 0 &&

                    <>
                    <div className="font-[Poppins] font-medium text-[22px] leading-[100%] text-[#313131] mt-[20px] ">Documento soliticado:</div>
                    <div className='flex justify-center w-full'>
                        <div className="w-[92%] rounded-[7px] border-solid border-[0.5px] border-[#C6C6C6] mt-[20px] h-[220px] flex flex-row items-center justify-center">
                            <Box  className={classes.root + " w-[96%] overflow-x-auto flex h-[175px]"}>
                            {
                                orderID && 
    
                                attachments.map((item, index) => {
    
                                    const fileName = encodeURIComponent(item);
    
                                    return (
                                        <div key={index} className='mt-[0px] mr-[25px] flex-col flex items-center relative' 
                                        onMouseOver={()=>{setShowDocCtrl(index+1); preview.current[index].style.filter = 'blur(5px)'}} onMouseOut={()=>{setShowDocCtrl(false); preview.current[index].style.filter = ''}}>
                                            {showDocCtrl === index+1 && 
                                                <>
                                                <a 
                                                href={      API_BASE + `/order/downloadattachments/${orderID}/${fileName}`} 
                                                download={  API_BASE + `/order/downloadattachments/${orderID}/${fileName}`} 
                                                className="absolute z-10 cursor-pointer top-[25px]"                                            >
                                                <Box component="img" src={DownloadIcon} />
                                                </a>
                                                {!isImported &&
                                                    <Box onClick={()=> {setOpenReportProblem(true)}} component="img" src={ReportIcon} alt="alerta-icone" className="absolute z-10 cursor-pointer top-[70px]"/>
                                                }
                                                </>                                       
                                            }
                                                <div ref={el => preview.current[index] = el}>
                                                  <FilePreview
                                                    width='93px'
                                                    height='119px'
                                                    type={"file"}
                                                    url={IMAGE_BASE + `orders/orders_files/${orderID}/${item}`}
                                                    onError={(err) =>console.error(err)}
                                                  />
                                                </div>
                                                <div className='font-[Poppins] font-light text-[9px] leading-[100%] text-[#ABAFB3] mt-[5px] w-[93px] text-center break-all' style={{ whiteSpace: 'normal', wordBreak: 'break-all', width: '93px' }}>{item.split('/').slice(-1)}</div>
                                        </div>
                                    )
                                })
                            }
                            </Box>                        
                        </div>
                    </div>
                       
                       </>
    
                }

                {
                   attachments&&attachments.length > 0 && !isImported &&
                   
                   <>
                   {
                   isDigital?
                   <>

                    {!needValidation?
                    <>
                    <div className="font-[Poppins] font-medium text-[22px] leading-[100%] text-[#313131] mt-[20px] ">Documento soliticado:</div>
                    
    
                    <div className='flex justify-center w-full'>
                        <div className="w-[92%] rounded-[7px] border-solid border-[0.5px] border-[#C6C6C6] mt-[20px] h-[186px] flex flex-row items-center justify-center">
                            <div className='flex flex-col items-center'>
                                <div className='font-[Poppins] font-bold text-[18px] leading-[100%] text-[#C6C6C6] mb-[12px]'>Aguardando anexo</div>
                                <div><Box component='img' src={AttachmentIcon} width="60px" height='65px'></Box></div>
                            </div>
                        </div>
                    </div>
                    </>
    

                    :


                <>
                <div className="font-[Poppins] font-medium text-[22px] leading-[100%] text-[#313131] mt-[20px] ">Documento soliticado:</div>
                <div className='flex justify-center w-full'>
                    <div className="w-[92%] rounded-[7px] border-solid border-[0.5px] border-[#C6C6C6] mt-[20px] h-[220px] flex flex-row items-center justify-center">
                        <Box  className={classes.root + " w-[96%] overflow-x-auto flex h-[175px]"}>
                        {
                            orderID && 

                            attachments.map((item, index) => {

                                const fileName = encodeURIComponent(item);

                                return (
                                    <div key={index} className='mt-[0px] mr-[25px] flex-col flex items-center relative' 
                                    onMouseOver={()=>{setShowDocCtrl(index+1); preview.current[index].style.filter = 'blur(5px)'}} onMouseOut={()=>{setShowDocCtrl(false); preview.current[index].style.filter = ''}}>
                                        {showDocCtrl === index+1 && 
                                            <>
                                            <a 
                                            href={      API_BASE + `/order/downloadattachments/${orderID}/${fileName}`} 
                                            download={  API_BASE + `/order/downloadattachments/${orderID}/${fileName}`} 
                                            className="absolute z-10 cursor-pointer top-[25px]">
                                            <Box component="img" src={DownloadIcon} alt="download-icone"/>
                                            </a>
                                            {!isImported &&
                                                <Box onClick={()=> {setOpenReportProblem(true)}} component="img" src={ReportIcon} alt="alerta-icone" className="absolute z-10 cursor-pointer top-[70px]"/>
                                            }
                                            </>                                       
                                        }
                                            <div ref={el => preview.current[index] = el}>
                                              <FilePreview
                                                width='93px'
                                                height='119px'
                                                type={"file"}
                                                url={IMAGE_BASE + `orders/orders_files/${orderID}/${item}`}
                                                onError={(err) =>console.error(err)}
                                              />
                                            </div>
                                            <div className='font-[Poppins] font-light text-[9px] leading-[100%] text-[#ABAFB3] mt-[5px] w-[93px] text-center break-all' style={{ whiteSpace: 'normal', wordBreak: 'break-all', width: '93px' }}>{item.split('/').slice(-1)}</div>
                                    </div>
                                )
                            })
                        }
                        </Box>                        
                    </div>
                </div>
                   
                   </>
                   }
                   </>
                    :
                   isPhysical?
                   <>

                   {!needValidation?
                   <>
                   <div className="font-[Poppins] font-medium text-[22px] leading-[100%] text-[#313131] mt-[20px] ">Documento soliticado:</div>
                   
   
                   <div className='flex justify-center w-full'>
                       <div className="w-[92%] rounded-[7px] border-solid border-[0.5px] border-[#C6C6C6] mt-[20px] h-[186px] flex flex-row items-center justify-center">
                           <div className='flex flex-col items-center'>
                               <div className='font-[Poppins] font-bold text-[18px] leading-[100%] text-[#C6C6C6] mb-[12px]'>Aguardando anexo</div>
                               <div><Box component='img' src={AttachmentIcon} width="60px" height='65px'></Box></div>
                           </div>
                       </div>
                   </div>
                   </>
   

                   :


               <>
               <div className="font-[Poppins] font-medium text-[22px] leading-[100%] text-[#313131] mt-[20px] ">Documento soliticado:</div>
               <div className='flex justify-center w-full'>
                   <div className="w-[92%] rounded-[7px] border-solid border-[0.5px] border-[#C6C6C6] mt-[20px] h-[220px] flex flex-row items-center justify-center">
                       <Box  className={classes.root + " w-[96%] overflow-x-auto flex h-[175px]"}>
                       {
                           orderID && 

                           attachments.map((item, index) => {

                               const fileName = encodeURIComponent(item);

                               return (
                                   <div key={index} className='mt-[0px] mr-[25px] flex-col flex items-center relative' 
                                   onMouseOver={()=>{setShowDocCtrl(index+1); preview.current[index].style.filter = 'blur(5px)'}} onMouseOut={()=>{setShowDocCtrl(false); preview.current[index].style.filter = ''}}>
                                       {showDocCtrl === index+1 && 
                                           <>
                                           <a 
                                           href={      API_BASE + `/order/downloadattachments/${orderID}/${fileName}`} 
                                           download={  API_BASE + `/order/downloadattachments/${orderID}/${fileName}`} 
                                           className="absolute z-10 cursor-pointer top-[25px]">
                                           <Box component="img" src={DownloadIcon} alt="download-icone"/>
                                           </a>
                                           {!isImported &&
                                               <Box onClick={()=> {setOpenReportProblem(true)}} component="img" src={ReportIcon} alt="alerta-icone" className="absolute z-10 cursor-pointer top-[70px]"/>
                                           }
                                           </>                                       
                                       }
                                           <div ref={el => preview.current[index] = el}>
                                             <FilePreview
                                               width='93px'
                                               height='119px'
                                               type={"file"}
                                               url={IMAGE_BASE + `orders/orders_files/${orderID}/${item}`}
                                               onError={(err) =>console.error(err)}
                                             />
                                           </div>
                                           <div className='font-[Poppins] font-light text-[9px] leading-[100%] text-[#ABAFB3] mt-[5px] w-[93px] text-center break-all' style={{ whiteSpace: 'normal', wordBreak: 'break-all', width: '93px' }}>{item.split('/').slice(-1)}</div>
                                   </div>
                               )
                           })
                       }
                       </Box>                        
                   </div>
               </div>
                  
                  </>
                  }
                  </>
                       :
                        isStamped?
                        <>

                        {!needValidation?
                        <>
                        <div className="font-[Poppins] font-medium text-[22px] leading-[100%] text-[#313131] mt-[20px] ">Documento soliticado:</div>
                        
        
                        <div className='flex justify-center w-full'>
                            <div className="w-[92%] rounded-[7px] border-solid border-[0.5px] border-[#C6C6C6] mt-[20px] h-[186px] flex flex-row items-center justify-center">
                                <div className='flex flex-col items-center'>
                                    <div className='font-[Poppins] font-bold text-[18px] leading-[100%] text-[#C6C6C6] mb-[12px]'>Aguardando anexo</div>
                                    <div><Box component='img' src={AttachmentIcon} width="60px" height='65px'></Box></div>
                                </div>
                            </div>
                        </div>
                        </>
        
     
                        :
     
     
                    <>
                    <div className="font-[Poppins] font-medium text-[22px] leading-[100%] text-[#313131] mt-[20px] ">Documento soliticado:</div>
                    <div className='flex justify-center w-full'>
                        <div className="w-[92%] rounded-[7px] border-solid border-[0.5px] border-[#C6C6C6] mt-[20px] h-[220px] flex flex-row items-center justify-center">
                            <Box  className={classes.root + " w-[96%] overflow-x-auto flex h-[175px]"}>
                            {
                                orderID && 
     
                                attachments.map((item, index) => {
     
                                    const fileName = encodeURIComponent(item);
     
                                    return (
                                        <div key={index} className='mt-[0px] mr-[25px] flex-col flex items-center relative' 
                                        onMouseOver={()=>{setShowDocCtrl(index+1); preview.current[index].style.filter = 'blur(5px)'}} onMouseOut={()=>{setShowDocCtrl(false); preview.current[index].style.filter = ''}}>
                                            {showDocCtrl === index+1 && 
                                                <>
                                                <a 
                                                href={      API_BASE + `/order/downloadattachments/${orderID}/${fileName}`} 
                                                download={  API_BASE + `/order/downloadattachments/${orderID}/${fileName}`} 
                                                className="absolute z-10 cursor-pointer top-[25px]">
                                                <Box component="img" src={DownloadIcon} alt="download-icone"/>
                                                </a>
                                                {!isImported &&
                                                    <Box onClick={()=> {setOpenReportProblem(true)}} component="img" src={ReportIcon} alt="alerta-icone" className="absolute z-10 cursor-pointer top-[70px]"/>
                                                }
                                                </>                                       
                                            }
                                                <div ref={el => preview.current[index] = el}>
                                                  <FilePreview
                                                    width='93px'
                                                    height='119px'
                                                    type={"file"}
                                                    url={IMAGE_BASE + `orders/orders_files/${orderID}/${item}`}
                                                    onError={(err) =>console.error(err)}
                                                  />
                                                </div>
                                                <div className='font-[Poppins] font-light text-[9px] leading-[100%] text-[#ABAFB3] mt-[5px] w-[93px] text-center break-all' style={{ whiteSpace: 'normal', wordBreak: 'break-all', width: '93px' }}>{item.split('/').slice(-1)}</div>
                                        </div>
                                    )
                                })
                            }
                            </Box>                        
                        </div>
                    </div>
                       
                       </>
                       }
                       </>
                                 :<></>        
                }
                </>
                }
                {isImported &&
                <div style={{margin:"50px"}}></div>
                }
                {!isImported &&
                <>
                <div className="flex mt-[20px]  mb-[20px]">
                    <div>
                        <TitleText
                            className={(awardOption ? (active + " ") :"") + hover}
                            onClick={() => selectAwardOption(true)}>Propostas</TitleText>

                    </div>
                    {!acceptedBID &&
                    <div>
                        <TitleText
                            className={(!awardOption ? (active + " ") :"") + 'ml-[30px] '+ hover}
                            onClick={() => selectAwardOption(false)}>Convidar pesquisador</TitleText>
                    </div>
                    }
                </div>


                <div ref={proposalView}>
                {
                    awardOption ? 
                    <Proposals 
                    bids={bids}
                    
                    cancel={cancel}
                    
                    acceptedBID={acceptedBID}
                    anyAcceptedBID={anyAcceptedBID}
                    currentUser={currentUser}
                    
                    /> 
                    : 
                    <>
                    { !acceptedBID?
                        <InviteResearcher withButton={true} orderID={orderID}/>
                    :<></>
                    }
                    </>
                }
                </div>
                </>
                }
          </ContainerStyle>
       </RootStyle>
    )
}