import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import * as ReactLoadingg from 'react-loadingg';

import {
    Grid,
    Box,
    Stack,
    TextareaAutosize,
    Divider,
} from '@mui/material';
import ProposalButton from '../buttons/ProposalButton';
import AmountInput from '../Input/AmountInput.js';
import AmountDaysInput from '../Input/AmountDaysInput';
import InformationIcon from '../../assets/icons/Information.svg'
import WarningIcon from '../../assets/icons/Warning.svg'
import SuccessIcon from '../../assets/icons/Success.svg'

import { useState } from 'react';
import API from '../../redux/API';
import { toast } from 'react-toast';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


function Loading() {

    const loadingStyles = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 99999,
        };
    const textLoadingStyles = {
        position: 'fixed',
        top: '55%',
        left: '50%', 
        transform: 'translate(-50%, -50%)',


        textAlign: 'center',

        fontSize: '18px', 
        fontWeight: 'bold', 
        color: '#17686E' ,
        zIndex: 9999,

        };
      return (
      <>
                <ReactLoadingg.WaveLoading  size='large' color="#17686E" style={loadingStyles}>
                </ReactLoadingg.WaveLoading>
                  <div style={textLoadingStyles}>Carregando...</div>
        </>
      )
    }


function BootstrapDialogTitle(props) {

    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, padding: '0px 43px' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const HeaderText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 500,
    color: '#313131',
    [theme.breakpoints.up('md')]: {
    },
}));

const DescriptionText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 300,
    color: '#313131',
    [theme.breakpoints.up('md')]: {
    },
}));

const DescriptionTextBold = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 600,
    color: '#313131',
    [theme.breakpoints.up('md')]: {
    },
}));

const Text_span = styled('span')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 500,
    color: '#DE2B2B',
}));

const GridWrapper = styled('div')(({ theme }) => ({
    padding: '40px 0px 35px 0px',
    [theme.breakpoints.up('sm')]: {
        padding: '70px 0px 95px 0px'
    },
    [theme.breakpoints.up('md')]: {
        padding: '90px 35px 115px 3px'
    },
}));



export default function Modal({ openModal, setOpenModal, orderID, setcanClick}) {
const navigate = useNavigate()
const {width} = useWindowSize();

    const [page, setPage] = useState(1);
    const [stateMoney, setStateMoney] = useState('false');
    const [stateDay, setStateDay] = useState('false');

    const handleClose = () => {

        setOpenModal(false);
        // window.location.reload()

    };

    const handleNext = () => { 

        setPage(page + 1);
    };

    const handleBack = () => {
        setPage(page - 1);
    };

    const [value, setValue] = useState(0)
    const [days, setDays] = useState(0)
    const [proposalComment, setProposalComment] = useState('')
    const { currentUser } = useSelector(state => state.auth);
    const { currentProfile } = useSelector(state => state.auth);

    const [loading,setLoading]=useState(false)



    const sendProposal = ()=>{
        if(currentUser){
            setLoading(true)

            const data = {
                
                nr_deadline: days, 
                orderID:orderID,
                
                id_researcher: currentUser.id?currentUser.id:currentUser._id,
                ds_bidcomments: proposalComment,        
                vl_researcherbid: value, 
                userID: currentUser.id?currentUser.id:currentUser._id,
                
                
            }            
        
        API.post('/order/sendProposal', data)
          .then((result) => {
            setLoading(false)
            if(result.data.status === 'success'){
               toast.success(result.data.message?result.data.message:"Sucesso");
               handleNext()
            }
            else{
                toast.error(result.data.message)
            }
            setcanClick(false)
        })
        .catch((err) => {
            setLoading(false)
            toast.error(JSON.parse(err.request.response).message)
            console.log(err);
            setcanClick(false)
        });
        }
    }


    // useEffect(() => {
    //     const handleScroll = () => {
    //       if (window.scrollY > 0) {
    //         setLoading(true);
    //       } else {
    //         setLoading(false);
    //       }
    //     };
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //       window.removeEventListener('scroll', handleScroll);
    //     };
    //   }, []);



    const location = useLocation();

    const handlenavigatemyorders =()=>{
        return navigate('/myorders', {state: {initial: 1}})
    }
    const handlenavigatepub =()=>{
        if (location.pathname === '/publications') {
            return handleClose()
        }
        return navigate('/publications')
    }

    

    return (
        <div>

{loading === true && 
                    <>
                        <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            zIndex: 9998, // certifique-se de que este valor é maior do que qualquer outro elemento na página
                        }}
                        >
                        <Loading />
                    </div>
                    </>
                }

            {page == 1 &&
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openModal} sx={{
                        '.MuiPaper-root': {
                            maxWidth: '837px',
                            width: '100%',
                        }
                    }}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        <HeaderText sx={{ pt: '30px' }}>Deseja enviar uma proposta?</HeaderText>
                        <Divider sx={{ margin: '20px 0px' }} />
                    </BootstrapDialogTitle>
                    <DialogContent sx={{ padding: '0px 43px !important' }}>
                        <DescriptionText>Você pode enviar uma proposta para seu cliente com o valor desejado.</DescriptionText>
                        <Grid container sx={{ pt: '20px' }}>
                            <Grid item xs={12} sm={12} md={5} style={{ marginBottom: '20px'}}>
                                <DescriptionTextBold>Valor da proposta <Text_span>&nbsp;*</Text_span></DescriptionTextBold>
                                <AmountInput
                                    text={'R$'}
                                    holder={'        0.00'}
                                    expectNumber={50}
                                    redtext={'Mínimo de proposta é R$ 50,00. Insira um valor maior.'}
                                    setStateMoney={setStateMoney}
                                    typeButton={1}
                                    setValue={setValue}
                                    price={value}
                                 />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <DescriptionTextBold>Previsão de entrega<Text_span>&nbsp;*</Text_span></DescriptionTextBold>
                                <AmountDaysInput 
                                    text={'Dias'}
                                    holder={'   00'}
                                    expectNumber={1}
                                    redtext={'Informe um dia válido. Mínimo de previsão de entrega é 1 dia.'}
                                    setStateDay={setStateDay}
                                    typeButton={2}
                                    setDays={setDays}
                                    days={days}
                                />
                            </Grid>
                        </Grid>
                        <Stack flexDirection={'row'} justifyContent={'space-between'} sx={{ pt: '40px' }}>
                            <DescriptionText>Deixe uma mensagem para o cliente (opcional)</DescriptionText>
                            <Box component="img" src={InformationIcon} alt="informacao-icone" width="17px" title="Deixe uma mensagem opcional para o cliente poder avaliar melhor a sua proposta." />
                        </Stack>
                        <TextareaAutosize
                            maxRows={2}
                            placeholder=""
                            style={{
                                fontFamily: 'Poppins',
                                color: '#313131',
                                marginTop: '3px',
                                padding:'10px',
                                width: '100%',
                                minHeight: '100px',
                                border: '1px solid #E2E4E5',
                                borderRadius: '7px',
                                resize: 'vertical',
                            }}
                            value={proposalComment}
                            onChange={(e)=>{setProposalComment(e.target.value)}}
                        />
                    </DialogContent>
                    <Stack flexDirection={'row'} justifyContent={'center'} paddingTop={'20px'} margin={'20px'}>
                        <ProposalButton type={2} fontsize={14} text={'Voltar'} event={handleClose} />
                        <Box sx={{ marginLeft: '25px' }} />
                        {(stateMoney=='true' && stateDay=='true') && <ProposalButton type={1} fontsize={14} text={'Enviar proposta'} event={handleNext} />}
                        {(stateMoney=='false' || stateDay=='false') && <ProposalButton  type={5} fontsize={14} text={'Enviar proposta'}/>}
                    </Stack>
                    <DialogActions></DialogActions>
                </BootstrapDialog>
            }
            {page > 1 && page < 4 &&
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openModal} sx={{
                        '.MuiPaper-root': {
                            maxWidth: '837px',
                            width: '100%',
                        }
                    }}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    </BootstrapDialogTitle>
                    <DialogContent sx={{ padding: '0px 43px !important' }}>
                        <GridWrapper>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={3}>
                                    <Stack alignItems={'center'}>
                                        {page == 2 &&
                                            <Box component="img" src={WarningIcon} alt="alerta-icone" width="113px" />
                                        }
                                        {page == 3 &&
                                            <Box component="img" src={SuccessIcon} alt="sucesso-icone" width="113px" />
                                        }
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    {page == 2 &&
                                        <HeaderText>Confirmação de envio da proposta</HeaderText>
                                    }
                                    {page == 3 &&
                                        <HeaderText>Proposta enviada com secesso!</HeaderText>
                                    }
                                    <Divider sx={{ marginTop: '19px', marginBottom: '18px'}}/>
                                    {page == 2 &&
                                        <DescriptionText>Se o cliente recusar sua proposta, o motivo da recusa estará nos detalhes do pedido. </DescriptionText>
                                    }
                                    {page == 3 &&
                                        <DescriptionText>Agora é só aguardar o cliente analisar a proposta.</DescriptionText>
                                    }
                                </Grid>
                            </Grid>
                        </GridWrapper>
                    </DialogContent>
                    <Stack flexDirection={'row'} justifyContent={'center'} paddingTop={'20px'} margin={'20px'}>
                        {page == 2 &&
                        <>
                            <div style={width>600 ? {display:'flex'} : { }}
                            >
                                <ProposalButton type={2} fontsize={14} text={'Voltar'} event={handleBack} />
                                <div style={{ margin: '15px' }} /> 
                                {/* <ProposalButton type={1} fontsize={14} text={'Buscar novos pedidos'} event={handleClose} /> */}
                                <ProposalButton type={1} fontsize={14} text={'Confirmar proposta'} 
                                
                                 event={()=>{
                                    sendProposal() 
                                    // handleNext
                                }}
                                 
                                />
                            </div>
                        </>
                        }
                        {page == 3 &&
                        <>
                            <div style={width>600 ? {display:'flex'} : { }}
                            >
                                    <ProposalButton  type={2} fontsize={14} text={'Minhas propostas'} event={handlenavigatemyorders} />
                                <div style={{ margin: '15px' }} /> 
                                {/* <ProposalButton type={1} fontsize={14} text={'Buscar novos pedidos'} event={handleClose} /> */}
                                    <ProposalButton type={1} fontsize={14} text={'Buscar novos pedidos'} event={handlenavigatepub} />
                            </div>
                        </>
                        }
                    </Stack>
                    <DialogActions></DialogActions>
                </BootstrapDialog>
            }
        </div >
    );
}
