import { styled } from '@mui/material/styles';
import {
    Box,
    Stack,
} from '@mui/material';
import checkitem from '../../../assets/icons/checkitem.svg';

const Card = styled('div')(({ theme }) => ({
    width: '280px',
    textAlign: 'center',
    background: 'white',
    border: '1px solid #D8D8D8',
    borderRadius: '5px',
    [theme.breakpoints.up('900')]: {
        width: '362px',
    },
}));

const Text_style_3 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    lineHeight: '100%',
    fontWeight: 400, color: '#17686E',
    fontSize: '20px',
    paddingTop: '21px',
    [theme.breakpoints.up('md')]: {
        fontSize: '30px', paddingTop: '24px',
    },
}));

const Text_style_4 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400, color: '#17686E',
    lineHeight: '130%',
    fontSize: '40px',
    [theme.breakpoints.up('md')]: {
        fontSize: '60px',
    },
}));

const Text_style_5 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    lineHeight: '100%',
    fontWeight: 600, color: '#17686E',
    fontSize: '12px',
    paddingLeft: '12px',
    [theme.breakpoints.up('md')]: {
        fontSize: '16px',
        paddingLeft: '16px',
    },
}));

const Text_style_6 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    lineHeight: '100%',
    fontWeight: 400, color: '#17686E',
    fontSize: '12px',
    [theme.breakpoints.up('md')]: {
        fontSize: '16px',
    },
}));


const Text_style_7 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    lineHeight: '100%',
    fontWeight: 400, color: '#17686E',
    fontSize: '12px', paddingLeft: '12px',
    [theme.breakpoints.up('md')]: {
        fontSize: '16px', paddingLeft: '16px',
    },
}));


const Text_style_8 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    lineHeight: '100%',
    fontWeight: 600, color: '#17686E',
    fontSize: '12px', paddingLeft: '3px',
    [theme.breakpoints.up('md')]: {
        fontSize: '16px', paddingLeft: '6px',
    },
}));

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    border: '1px solid #BA483A',
    borderRadius: '5px',
    backgroundColor: '#BA483A',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: '18px',
    padding: '10px 18px 11px 18px',
    margin: '55px 0px 29px',
    [theme.breakpoints.up('md')]: {
        padding: '15px 60px 17px 60px',
        margin: '55px 0px 29px',
    },
}));

const Sub_header = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    lineHeight: '100%',
    fontWeight: 400, color: '#C6C6C6',
    fontSize: '16px',
    paddingTop: '10px',
    [theme.breakpoints.up('md')]: {
        paddingTop: '20px'
    },
}));

const Sub_header_1 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400, color: '#17686E',
    fontSize: '12px',
    [theme.breakpoints.up('md')]: {
    },
}));

const Details_card = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '18px',
    [theme.breakpoints.up('md')]: {
        paddingTop: '33px',
    },
}));

export default function Card_2() {
    return (
        <Card>
            <Text_style_3>Especialista</Text_style_3>
            <Sub_header><del>R$89</del></Sub_header>
            <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'baseline'}>
                <Text_style_4>R$69</Text_style_4>
                <Sub_header_1>/Mês</Sub_header_1>
            </Stack>
            <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'baseline'}>
                <Sub_header_1 sx={{ color: '#C6C6C6' }}><del>R$ 1,068</del></Sub_header_1>
                <Sub_header_1 >&nbsp;R$ 828/ano</Sub_header_1>
            </Stack>

            <Details_card>
                <Stack flexDirection={'column'} alignItems={'left'}>
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Box component={'img'} src={checkitem} height={'12px'} />

                        <Text_style_5>2</Text_style_5>
                        <Text_style_6>&nbsp;país como especialidade</Text_style_6>
                    </Stack>
                    <Stack flexDirection={'row'} alignItems={'center'} sx={{ pt: '16px' }}>
                        <Box component={'img'} src={checkitem} height={'12px'} />

                        <Text_style_5>4</Text_style_5>
                        <Text_style_6>&nbsp;dias úteis para receber</Text_style_6>
                    </Stack>
                    <Stack flexDirection={'row'} alignItems={'center'} sx={{ pt: '16px' }}>
                        <Box component={'img'} src={checkitem} height={'12px'} />

                        <Text_style_5>15%</Text_style_5>
                        <Text_style_6>&nbsp;de taxa de intermediação  </Text_style_6>
                    </Stack>

                    {/* reverse */}
                    <Stack flexDirection={'row'} alignItems={'center'} sx={{ pt: '16px' }}>
                        <Box component={'img'} src={checkitem} height={'12px'} />

                        <Text_style_7>Propostas </Text_style_7>
                        <Text_style_8>até especialista</Text_style_8>
                    </Stack>

                    <Stack flexDirection={'row'} alignItems={'center'} sx={{ pt: '16px' }}>
                        <Box component={'img'} src={checkitem} height={'12px'} />

                        <Text_style_5>90</Text_style_5>
                        <Text_style_6>&nbsp;propostas mensais</Text_style_6>
                    </Stack>
                    <Stack flexDirection={'row'} alignItems={'center'} sx={{ pt: '16px' }}>
                        <Box component={'img'} src={checkitem} height={'12px'} />

                        <Text_style_5>10</Text_style_5>
                        <Text_style_6>&nbsp;pesquisas em execução</Text_style_6>
                    </Stack>
                </Stack>
            </Details_card>

            <HeaderBTNStyle>Assinar</HeaderBTNStyle>
        </Card >
    )
}