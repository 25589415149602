/* eslint-disable import/no-anonymous-default-export */
import React, {useState, useEffect, useRef, useLayoutEffect } from 'react';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Select, MenuItem } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import UploadIcon1 from '../../assets/icons/uploadicon1.svg'
import UploadIcon2 from '../../assets/icons/uploadicon2.svg'
import CameraIcon from '../../assets/icons/cameraicon.svg'
import UpImage from '../../assets/icons/Polygon_up.svg'
import RemoveIcon from '../../assets/icons/removeicon.svg'
import UpdateIcon from '../../assets/icons/updateicon.svg'
import OutsideAlerter from '../../hooks/OutSideAlerter';
import { toast } from 'react-toast';
import {
    Box,
    IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import API from '../../redux/API';
import { setCurrentProfile, setUser } from '../../redux/actions/auth.action';
import { useDispatch } from 'react-redux';
import {IMAGE_BASE} from '../../config/constants'
import { current } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const CancelBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#17686E',
    borderRadius: '5px',
    border: '1px solid #17686E',
    backgroundColor: '#FFF',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

export default(props) => {

    const navigate = useNavigate()

    const { currentUser } = useSelector(state => state.auth);
    const [birthDay, setBirthday] = React.useState(null);
    const [age, setAge] = useState(null)
    const [email, setEmail] = useState(currentUser.ds_email);
    const [isValidDate, setIsValidDate] = useState(true)
    const [phone, setPhone] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [removingimage, setremovingimage] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [name, setName] = useState(currentUser.nm_user);
    const [showPhotoCtrl, setShowPhotoCtrl] = useState(false);
    const [currency, setCurrency] = useState('Reais (BRL)');
    const [country, setCountry] = useState('BR');

    const genderList = [
        'Masculino',
        'Feminino',
        'Não especificado'
    ]
    const [gender, setGender] = useState(0);
    const dispatch = useDispatch();
    useLayoutEffect(() => {
        if(currentUser){
            setName(currentUser.nm_user);
            setEmail(currentUser.ds_email);
            setGender(currentUser.ds_gender === undefined ? 2 : currentUser.ds_gender);
            setBirthday(currentUser.dt_birth === undefined ? null : currentUser.dt_birth);
            setPhone(currentUser.ds_telephone === undefined ? '' : currentUser.ds_telephone);
            setCurrency(currentUser.nm_countrycurrency === undefined ? 'Reais (BRL)': currentUser.nm_countrycurrency);
            handleDateChange(currentUser.dt_birth === undefined ? null : currentUser.dt_birth)
            setImageUrl(currentUser.nm_customeravatarfile === undefined ? null :( `${IMAGE_BASE}accounts/${currentUser._id?currentUser._id:currentUser.id}/${currentUser.nm_customeravatarfile}`))
            setSelectedImage(currentUser.nm_customeravatarfile === undefined ? null : {name:currentUser.nm_customeravatarfile})
        }
    }, [currentUser]);

    const [checkChanged, setCheckChanged] = useState(false);
    const [lowDate, setLowDate] = useState(false);
    useEffect(()=>{
        if(name !== currentUser.nm_user) {
            setCheckChanged(true);
            return;
        }
        else{
            setCheckChanged(false);
        }

        if(email !== currentUser.ds_email) {
            setCheckChanged(true);
            return;
        }
        else{
            setCheckChanged(false);
        }

        if(gender !== currentUser.ds_gender) {
            setCheckChanged(true);
            return;
        }
        else{
            setCheckChanged(false);
        }

        if(phone !== currentUser.ds_telephone) {
            setCheckChanged(true);
            return;
        }
        else{
            setCheckChanged(false);
        }

        if(birthDay !== currentUser.dt_birth) {
            var thisBirthday = new Date(birthDay)
            var today = new Date()
            var limitAge = 18*(1000*60*60*24*365)//18 years
            if((today.getTime()-thisBirthday.getTime())<limitAge){
                setLowDate(true)
                setCheckChanged(false);
            }
            else if((today.getTime()-thisBirthday.getTime())>=limitAge){
                setLowDate(false)
                setCheckChanged(true);
            }
            //console.log(thisBirthday)
            return;
        }
        else{
            setCheckChanged(false);
        }

        if(currency !== currentUser.nm_countrycurrency) {
            setCheckChanged(true);
            return;
        }
        else{
            setCheckChanged(false);
        }

        if((selectedImage && selectedImage.name !== currentUser.nm_customeravatarfile)  || !selectedImage) {
            setCheckChanged(true);
            return;
        }
        else{
            setCheckChanged(false);
        }

        if(props.isProfile2 == true){
            setCheckChanged(true);
            return;
        }
        else{
            setCheckChanged(false);
        }

    }, [name, email, gender, birthDay, phone, currency, selectedImage,props.isProfile2])
    
    const { currentProfile } = useSelector(state => state.auth);
    
    const handleSave = () => {
        let formData = new FormData();
        formData.append("file", selectedImage);
        formData.append("removingimage", removingimage);
        formData.append("nm_user", name);
        formData.append("ds_gender", gender);
        formData.append("dt_birth", (new Date(birthDay)).toUTCString());
        formData.append("ds_telephone", phone);
        formData.append("nm_countrycurrency", currency);
        formData.append("ds_email", email);
        formData.append("ic_profile", currentProfile);
        formData.append("userID", currentUser._id?currentUser._id:currentUser.id);
        formData.append("country_code", country);
        API.post(`user/setpersonaldata/${currentUser._id ? currentUser._id : currentUser.id}`, formData, {
            headers: {
            "content-type": "multipart/form-data",
            },
        })
        .then((result) => {
            if (result.data.status === "success") {
                toast.success('Atualizado com sucesso.');
                dispatch(setUser(result.data.user));
                // window.location.reload()

            }
        })
        .catch((err) => {
            console.log(err);
        });
    }


    const handleCancel = ()=>{
        if(currentProfile !== currentUser.ic_profile){
            if(currentProfile===1){
                setCurrentProfile(0)
            }
            else if(currentProfile===0){
                setCurrentProfile(1)
            }
            window.location.reload()
        }
    }


    const handlePhoneChange = (e) => {
        var currentValue = e;
         setPhone(currentValue);
    }

    useEffect(()=>{
        var thisBirthday = new Date(birthDay)
        var today = new Date()
        var limitAge = 18*(1000*60*60*24*365)//18 years
        //console.log(`thisBirthday=${thisBirthday.getTime()}`);
        //console.log(`DIF=${(today.getTime()-thisBirthday.getTime())}`);
        //console.log(`limitAge=${limitAge}`);
        if((today.getTime()-thisBirthday.getTime())<limitAge){
            //console.log('lowDate');
            setLowDate(true)
            setIsValidDate(false);
        }
        else if((today.getTime()-thisBirthday.getTime())>=limitAge){
            setLowDate(false)
            setIsValidDate(true);
        }
        var age_now = Math.floor((today.getTime()-thisBirthday.getTime())/(1000*60*60*24*365))
        if(age_now>0){setAge(age_now)}
    },[birthDay])



    const handleDateChange = (newValue) => {
        setBirthday(newValue);
        if(newValue === null){
            // setAge('');
            return;
        }
        // setAge(0);
        // var today = new Date();
        // var birthDate = new Date(newValue);  // create a date object directly from `dob1` argument
        // var age_now = today.getFullYear() - birthDate.getFullYear();
        // var m = today.getMonth() - birthDate.getMonth();
        // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
        // {
        //     age_now--;
        // }
        // if(age_now < 18 || age_now > 100 || isNaN(age_now)){
        //     setAge('');
        //     setIsValidDate(false);
        // } 
        // else{
        //     setAge(age_now)
        //     setIsValidDate(true);
        // }      


    }
    const cameraToggle = () =>{
        setShowPhotoCtrl(!showPhotoCtrl);
    }
    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] whitespace-nowrap mb-[5px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px]"
    }
    const avatarBtn = useRef();
    useEffect(() => {
        if (selectedImage && selectedImage.name !== currentUser.nm_customeravatarfile) {
          setImageUrl(URL.createObjectURL(selectedImage));
        }
      }, [selectedImage]);

    useEffect(() => {
        if(props.submitEvent === true){
            avatarBtn.current.click();
            props.submitStateChange();
        }
        
    }, [props.submitEvent])

    const saveProfile = useRef(null);

    useEffect(() => {
        if (saveProfile.current) {
          const instance = tippy(saveProfile.current, {
            content: "Aperte a tecla <strong>'Salvar'</strong> para criar o seu novo perfil!",
            placement: 'left',
            allowHTML: true,
            delay: 0,
            interactive: true,
            hideOnClick: false,
            showOnCreate: true,
            popperOptions: {
              modifiers: {
                preventOverflow: {
                  boundariesElement: "window"
                }
              },
              styles: {
                popper: {
                  backgroundColor: "#17686E",
                  color: "white",
                  borderRadius: "5px",
                  padding: "5px 10px"
                }
              }
            }
          });
          
          instance.show();
          // add a listener to prevent the tooltip from being hidden on mouseleave
          saveProfile.current.addEventListener('mouseleave', () => {
            instance.show();
          });
        }
      }, []);


      useEffect(()=>{
        if(currentUser){
            API.post("user/checkcountrycode", {countryID:currentUser.id_country})
            .then((result) => {
                if (result.data.status === "success") {
                    setCountry(result.data.countrycode.cd_isoalpha2)

                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    },[currentUser])

    return (
        <>
        <div className="flex w-full justify-between mb:flex-col-reverse xl:!flex-row">
            <div className="xl:w-[62%] mb:[100%]">
                <div className='w-full flex mb-[25px]'>
                    <div className='w-full'>
                        <div className={style.title}>Nome completo</div>
                        <div>
                            <TextField
                                className="w-full"
                                value = {name}
                                onChange={(e) => {setName(e.target.value)}}
                                InputProps={{
                                    className: style.content
                                }}
                                hiddenLabel="hiddenLabel"
                                id="outlined-basic"
                                variant="outlined"/>
                        </div>
                    </div>
                </div>
                <div className='w-full flex mb-[25px]'>
                    <div className='w-full'>
                        <div className={style.title}>E-mail cadastrado</div>
                        <div>
                            <TextField
                                autoComplete="email"
                                type="email"
                                className="w-full"
                                value = {email}
                                InputProps={{
                                    className: style.content,
                                    type: "email",
                                    requried: "true"
                                }}
                                hiddenLabel="hiddenLabel"
                                id="outlined-basic"
                                variant="outlined"/>
                        </div>
                    </div>
                </div>
                <div className='w-full mb:block xl:flex  mb-[25px]'>
                    <div className='mb:w-full xl:w-[calc(60%+50px)] flex'>
                        <div className='w-[calc(50%+25px)] mr-[25px] mb:mb-[25px] xl:mb-[0px]'>
                            <div className={style.title} style={lowDate?{color:'red'}:{}}>Data de nascimento</div>
                            <div >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                    
                                        InputAdornmentProps={{
                                            style: {
                                                display: 'none',
                                            }
                                        }}
                                        inputFormat="DD/MM/YYYY"
                                        value={birthDay} 
                                        className='w-full'
                                        
                                        onChange={handleDateChange}
                                        InputProps={{
                                            className: style.content,
                                            sx: lowDate ? {
                                                border: '1px solid red', 
                                                borderColor: 'red !important', 
                                                color: 'red !important',
                                            } : {},
                                        }}
                                
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                        {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: "dd/mm/aaaa"
                                                    }}/>
                                            );
                                        }}/>
                                </LocalizationProvider>
                                {lowDate && <div style={{color:'red', fontSize:'8pt', fontFamily:'poppins', textAlign:'center' }}>Usuário menor de idade</div>}
                            </div>
                        </div>
                        <div className='w-[50%] mb:mr-[0px] xl:mr-[25px]'>
                            <div className={style.title} style={lowDate?{color:'red'}:{}}>Idade</div>
                            <div>
                                <TextField
                                    placeholder='__'
                                    value={age}
                                    className="w-full"
                                    InputProps={{
                                        className: style.content,
                                        readOnly: true,
                                        sx: lowDate ? {
                                            border: '1px solid red', 
                                            borderColor: 'red !important', 
                                            color: 'red !important',
                                        } : {},
                                    }}
                                    hiddenLabel="hiddenLabel"
                                    id="outlined-basic"
                                    variant="outlined"/>
                            </div>
                        </div>
                    </div>
                    
                    <div className='xl:w-[33%] mb:w-[50%]'>
                        <div className={style.title}>Sexo</div>
                        <div>
                            <Select
                                value={gender}
                                onChange={(e) => {
                                    setGender(e.target.value)
                                }}
                                displayEmpty="displayEmpty"
                                className="w-[100%] h-[40px]"
                                MenuProps={{
                                    sx:{
                                        "& .Mui-selected": {
                                            backgroundColor: "rgba(23, 104, 110, 0.4) !important"
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor: "rgba(23, 104, 110, 0.11)"
                                        },
                                        '& .MuiMenuItem-root': {
                                            whiteSpace: 'normal',
                                        },
                                    }
                                }} 
                                >
                                {
                                    //dynamic
                                    genderList.map((item, index) => {
                                        return (
                                            <MenuItem value={index}>
                                                <p
                                                    className="whitespace-nowrap font-[Poppins] font-normal text-[14px] leading-[24px]">{item}</p>
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                </div>
                <div className='w-full flex mb-[25px]'>
                    <div className='mb:w-[calc(50%+25px)] xl:w-[35%] mr-[25px]'>
                        <div className={style.title}>Telefone</div>
                        <div>
                             <PhoneInput
                                country={'br'}
                                value={phone}
                                inputStyle={
                                    {
                                        height: '40px',
                                        width: '100%',
                                        fontFamily: 'Poppins',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#313131'
                                    }
                                }
                                className={style.content}
                                placeholder="+55 (00) 000000000"
                                onChange={handlePhoneChange}
                            />
                        </div>
                    </div>
                    <div className='mb:w-[50%] xl:w-[30%] mb:mr-[0px] xl:mr-[25px]'>
                        <div className={style.title}>Moeda</div>
                        <div>
                            <TextField
                                className="w-full"
                                InputProps={{
                                    className: style.content,
                                    readOnly: true
                                }}
                                value = {currency}
                                hiddenLabel="hiddenLabel"
                                id="outlined-basic"
                                variant="outlined"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='xl:ml-[100px] mb:ml-[0px] flex mb:flex-col xl:flex-col xl:justify-start mb:items-center'>
                <div className='w-[200px] h-[250px] mb:text-center xl:text-left'>
                    <div className={style.title}>Foto do perfil</div>

                    <div className='relative'>
                        <input
                            accept="image/*"
                            className="hidden"
                            id="icon-button-file"
                            type="file"
                            ref={avatarBtn}
                            onChange={e => setSelectedImage(e.target.files[0])}/>
                        <label htmlFor="icon-button-file">
                            <IconButton color="primary" aria-label="upload picture" component="span">
                                {
                                    (imageUrl && selectedImage)
                                        ? (
                                            <Box
                                                component={'img'}
                                                src={imageUrl}
                                                alt={selectedImage.name}
                                                className='w-[186px] h-[186px] border-dashed rounded-[50%] border-[#E2E4E5] border-[1px] items-center flex justify-center'></Box>
                                        )
                                        : (
                                            <div
                                                className="w-[186px] h-[186px] border-dashed rounded-[50%] border-[#E2E4E5] border-[1px] items-center flex justify-center">
                                                <div >
                                                    <div className='relative flex justify-center cursor-pointer'>
                                                        <Box component={'img'} src={UploadIcon2} className='w-[67px] h-[42px]'/>
                                                        <Box
                                                            component={'img'}
                                                            src={UploadIcon1}
                                                            className='w-[25px] h-[42px] absolute top-[23%]'/>
                                                    </div>
                                                    <div
                                                        className="mt-[10px] font-[Poppins] text-[12px] leading-[12px] font-normal text-[#ABAFB3] text-center h-[50px] flex justify-center items-center">
                                                        <div>Arraste e solte sua<br/> imagem<br/>ou clique e selecione seu<br/>arquivo</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                }
                            </IconButton>
                        </label>
                        {
                            <div
                                className='absolute top-[82%] mb:left-[145px] xl:left-[145px] cursor-pointer'
                                onClick={cameraToggle}>
                                <Box component={'img'} src={CameraIcon} className="w-[30px] h-[30px]"/>
                            </div>
                        }

                    </div>
                    {
                        showPhotoCtrl && (
                            <OutsideAlerter event={()=>setShowPhotoCtrl(false)}>
                                <div className="drop-shadow  xl:ml-[0px]">
                                 <Box component={'img'} src={UpImage} className="ml-[153px] z-0"/>
                                 <div className="bg-[#FFFFFF] rounded-[5px] w-[200px] h-[58px] mt-[-2px] z-10 flex flex-col justify-around">
                                        <div
                                            className='flex items-center cursor-pointer'
                                            onClick={() => {
                                                avatarBtn
                                                    .current
                                                    .click()
                                            }}>
                                            <Box
                                                component={'img'}
                                                src={UpdateIcon}
                                                className='w-[12px] h-[12px] ml-[10px] mr-[7px]'/>
                                            <div
                                                className='font-[Poppins] font-normal text-[12px] leading-[148%] text-[#313131]'>Atualizar foto do perfil</div>
                                        </div>
                                        {
                                            imageUrl && selectedImage && <div
                                            className='flex items-center cursor-pointer '
                                            onClick={() => {
                                                setImageUrl(null);
                                                setSelectedImage(null);
                                                setremovingimage(true);
                                            }}>
                                            <Box
                                                component={'img'}
                                                src={RemoveIcon}
                                                className='w-[12px] h-[12px] ml-[10px] mr-[7px]'/>
                                            <div
                                                className='font-[Poppins] font-normal text-[12px] leading-[148%] text-[#313131]'>Remover foto</div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </OutsideAlerter>
                           
                        )
                    }
                </div>
            </div>


        </div>
        <div className="mb:mt-[0px] xl:mt-[60px] mb:text-center xl:float-right mb:mr-[0px] xl:mr-[-30px] mb-[16px] " style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <HeaderBTNStyle 
                disabled={!checkChanged}             
                onClick={handleSave}             
                sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}
                ref={saveProfile}
                style={{ marginRight: '20px' }}
            >
                Salvar
            </HeaderBTNStyle>
            
            {currentProfile !== currentUser.ic_profile && currentUser.ic_profile !== 2 &&
                <CancelBTNStyle
                    disabled={!checkChanged}
                    onClick={handleCancel}
                    sx={{'&:disabled': {backgroundColor: '#C6C6C6', borderColor: '#C6C6C6', color: '#FFF', cursor: 'not-allowed', }}}
                >
                    Cancelar
                </CancelBTNStyle>
            }
        </div>
    </>
    )
}