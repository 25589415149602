import React, {useState, useEffect, useLayoutEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowSize from '../../hooks/useWindowSize'
import PartyPopperIcon from '../../assets/icons/party-popper.gif'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { Box, TextField,InputAdornment, TextareaAutosize} from '@mui/material';
import SecurityIcon from '../../assets/icons/securityicon1.svg';
import PasswordIcon from '../../assets/icons/passwordicon1.svg';
import Tooltip from '@mui/material/Tooltip';
import InformationIcon from '../../assets/icons/informationicon.svg';
import WarningIcon from '../../assets/icons/Warning.svg'
import SuccessIcon from '../../assets/icons/successicon.gif'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import API from '../../redux/API';
import { toast } from 'react-toast';
import moment from 'moment';
import './style.css'


const containerStyle = {
    width:'100% ',
    display: 'flex',
    flexWrap: 'wrap',
  };

  const inputStyle = {
    width: 'calc(50% - 10px)',
    margin: '5px',
    padding: '10px',
    boxSizing: 'border-box',
  };
  const inputStyleMobile = {
    width: 'calc(100% - 10px)',
    margin: '5px auto 5px auto',
    padding: '10px',
    boxSizing: 'border-box',
  };
  
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, padding: '0px 43px' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#17686E',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
    border: '1px solid #17686E'
}));


const HeaderText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 500,
    color: '#313131',
    textAlign: 'center',
    lineHeight: '100%',
    [theme.breakpoints.up('md')]: {
        textAlign: 'left'
    },
}));

const GridWrapper = styled('div')(({ theme }) => ({
    padding: '40px 0px 10px 0px',
    [theme.breakpoints.up('md')]: {
        padding: '15px 3px 10px 3px'
    },
}));

function padTo2Digits(n) {
    return n.toString().padStart(2, "0");
  }

function formatDate(date) {
    return (
      [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("/") 
    );
  }

export default function Modal({ 

    openModal,
    setOpenModal,
    pageNum,

    confirmFunc,

    addressto_nm_street,
    setaddressto_nm_street,
    addressto_ds_streetcomplement,
    setaddressto_ds_streetcomplement,
    addressto_nm_state,
    setaddressto_nm_state,
    addressto_nm_city,
    setaddressto_nm_city,
    addressto_nm_county,
    setaddressto_nm_county,
    addressto_cd_zip,
    setaddressto_cd_zip,

    orderID,
    currentUserID,

    loading,
    setLoading,

    }) {

    const { width } = useWindowSize();
    const style = {
        content: "!w-[80%] !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px] !rounded-[7px]",
    }
    const dispatch = useDispatch();
    const [initialPage, setInitialPage] = useState(pageNum);
    const handleClose = () => {
        window.location.reload();
        setOpenModal(false);
    };
    const handlePreviousPage = () => {
        if(page === 2){
            window.location.reload();
        }
        else{
            setOpenModal(false);
        }
    }

    const convertDate = (inputFormat) => {
        const pad = (s) => { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear() % 100].join('/')
      }
    const handleNextPage = () => {
        if(page === 2){
            return setOpenModal(false);
            // confirmFunc();
            // setShippingway(shippingway);
            // setTrackingcode(trackingcode);
            // setDeliveryDate(convertDate(deliveryDate));
        }
        // else{
            // setPage(page + 1)
        // }

        setLoading(true)
        const thisdata = {
            orderID,
            currentUserID,
            addressto_nm_street,
            addressto_ds_streetcomplement,
            addressto_nm_state,
            addressto_nm_city,
            addressto_nm_county,
            addressto_cd_zip,
        }
        //console.log(thisdata);
        API.post('/order/setaddresstosend', thisdata)
        .then((result)=>{
            setLoading(false)
            if(result.data.status === 'success'){
                toast.success(result.data.message?result.data.message:"Sucesso")
                return setPage(page + 1)
            }
        })
        .catch((err)=>{
            setLoading(false)
            return toast.error(err.message?err.message:'Erro')
        })
    }

    // const [shippingway, setShippingway1] = useState('');
    // const [trackingcode, setTrackingcode1] = useState('');
    // const [deliveryDate, setDeliveryDate1] = useState(null)

    const [datetoshow, setdatetoshow] = useState(null)

    // const [isValidDate, setIsValidDate] = useState(false);

    useLayoutEffect(() => {
        setInitialPage(pageNum);
        setPage(pageNum);
      }, [openModal]);
    const [page, setPage] = useState(openModal);        
  
  // set the deliveryDate variable with the onChange handler
//   const handleDate = (e) => {
//     const parsedDate = Date.parse(e);
//     const today = new Date();
  
//     if (e < today) {      
//       setdeliveryDateToShow(null);
//       setDeliveryDate(null);
//       toast.error('Digite uma data superior à atual');
//     } else if (!isNaN(parsedDate)) {
//       const datetosend = new Date(e);
//       setDeliveryDate(datetosend.toString());
//       setdeliveryDateToShow(formatDate(datetosend));
//     } else {
//       setDeliveryDate(null);
//     }
//   }
const isMobile = window.innerWidth < 700;



    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openModal} sx={{
                    '.MuiPaper-root': {
                        maxWidth: '837px',
                        width: '100%',
                    }
                }}
            >
                <div className='min-h-[350px]'>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
                
                
                {
                    page === 1 &&
                    <DialogContent className='mb:mt-[30px] md:mt-[58px] !overflow-unset '>
                        <div className='flex flex-col items-center'>
                            <div className='w-[90%] min-h-[120px] flex md:flex-row mb:flex-col mb:items-center md:items-start'>
                                <div className='flex flex-col justify-center w-[100%]'>
                                    <div className='font-[Poppins] font-medium mb-text-[18px] md:text-[22px] text-[#313131] leading-[100%] mb:text-center md:text-left'>
                                    Informações de envio do documento
                                    </div>
                                    <div className='w-[95%] mt-[20px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                                    <div className="w-[95%] mt-[18px] font-[Poppins] font-light text-[14px] text-[#313131] leading-[120%] mb:text-center md:text-left">
                                    Informe nos campos abaixo para o cliente poder acompanhar o envio.
                                    </div>
                                    <div style={isMobile ? {width:'100%', display:'flex', flexDirection:'column'} : containerStyle}>
                                        <div className='' style={isMobile?{}:inputStyle}>
                                            <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                            Nome da rua:
                                            </div>
                                            <TextField
                                                id="shipping_way_document"
                                                placeholder="Rua..." 
                                                value={addressto_nm_street?addressto_nm_street:''}
                                                onChange={(e) => {setaddressto_nm_street(e.target.value)}}
                                                fullWidth
                                                sx={{'& .MuiOutlinedInput-root': {height: '33px'},
                                                '& fieldset': {
                                                    border: 'none !important'
                                                }}}
                                                InputProps={{
                                                    className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5]",
                                                }}
                                                />
                                        </div>
                                        <div className='' style={isMobile ? {} :  inputStyle}>


                                            <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                            Complemento:
                                            </div>
                                            <TextField
                                                id="shipping_way_document"
                                                placeholder="Complemento"
                                                value={addressto_ds_streetcomplement?addressto_ds_streetcomplement:''}
                                                onChange={(e) => {setaddressto_ds_streetcomplement(e.target.value)}}
                                                fullWidth
                                                sx={{'& .MuiOutlinedInput-root': {height: '33px'},
                                                '& fieldset': {
                                                    border: 'none !important'
                                                }}}
                                                InputProps={{
                                                    className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5]",
                                                }}
                                                />
                                        </div>
                                        <div className='' style={isMobile ? {} :  inputStyle}>
                                            <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                            Código postal:
                                            </div>
                                            <TextField
                                                id="shipping_way_document"
                                                placeholder="00000BR"
                                                value={addressto_cd_zip?addressto_cd_zip:''}
                                                onChange={(e) => {setaddressto_cd_zip(e.target.value)}}
                                                fullWidth
                                                sx={{'& .MuiOutlinedInput-root': {height: '33px'},
                                                '& fieldset': {
                                                    border: 'none !important'
                                                }}}
                                                InputProps={{
                                                    className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5]",
                                                }}
                                                />
                                        </div>
                                        <div className='' style={isMobile ? {} :  inputStyle}>
                                            <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                            Estado/Província:
                                            </div>
                                            <TextField
                                                id="shipping_way_document"
                                                placeholder="Estado"
                                                value={addressto_nm_state?addressto_nm_state:''}
                                                onChange={(e) => {setaddressto_nm_state(e.target.value)}}
                                                fullWidth
                                                sx={{'& .MuiOutlinedInput-root': {height: '33px'},
                                                '& fieldset': {
                                                    border: 'none !important'
                                                }}}
                                                InputProps={{
                                                    className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5]",
                                                }}
                                                />
                                        </div>
                                        <div className='' style={isMobile ? {} :  inputStyle}>
                                            <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                            Cidade:
                                            </div>
                                            <TextField
                                                id="shipping_way_document"
                                                placeholder="Cidade"
                                                value={addressto_nm_city?addressto_nm_city:''}
                                                onChange={(e) => {setaddressto_nm_city(e.target.value)}}
                                                fullWidth
                                                sx={{'& .MuiOutlinedInput-root': {height: '33px'},
                                                '& fieldset': {
                                                    border: 'none !important'
                                                }}}
                                                InputProps={{
                                                    className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5]",
                                                }}
                                                />
                                        </div>
                                        <div className='' style={isMobile ? {} :  inputStyle}>
                                            <div className='mt-[18px] font-[Poppins] font-medium text-[12px] text-[#313131] leading-[148%] mb-[5px]'>
                                            País:
                                            </div>
                                            <TextField
                                                id="shipping_way_document"
                                                placeholder="USA"
                                                value={addressto_nm_county?addressto_nm_county:''}
                                                onChange={(e) => {setaddressto_nm_county(e.target.value)}}
                                                fullWidth
                                                sx={{'& .MuiOutlinedInput-root': {height: '33px'},
                                                '& fieldset': {
                                                    border: 'none !important'
                                                }}}
                                                InputProps={{
                                                    className:"border-solid border-[1px] !rounded-[5px] border-[#E2E4E5]",
                                                }}
                                                />
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    
                }
                {
                    page === 2 &&
                    <DialogContent className='mb:mt-[50px] md:mt-[118px] !overflow-unset '>
                        <div className='flex flex-col items-center'>
                        <div className='w-[90%] min-h-[120px] flex md:flex-row mb:flex-col items-center'>
                            <div className="mb:mr-[0px] md:mr-[25px] !w-[117px]">
                                <Box component="img" src={SuccessIcon} alt="sucesso-icone" className='max-w-[117px] h-[117px]'/>
                                </div>
                                <div className='flex flex-col justify-center w-[90%]'>
                                    <div className='font-[Poppins] font-medium mb-text-[18px] md:text-[22px] text-[#313131] leading-[100%]  mb:text-center md:text-left'>
                                    Informações enviadas
                                    </div>
                                    <div className='w-[95%] mt-[20px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                                    <div className="w-[95%] mt-[18px] font-[Poppins] font-light text-[14px] text-[#313131] leading-[120%] mb:text-center md:text-left">
                                    Agora é só aguardar o pesquisador enviar o seu documento.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    
                }
                           
                </div>
                <div className='md:mt-[0px] mb:mt-[20px]'>
                {
                    page === 1 &&
                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'> 
                        <HeaderBTNStyle1 onClick={handlePreviousPage} className="mb:mr-[0px] md:mr-[26px]" sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Voltar</HeaderBTNStyle1>
                        <HeaderBTNStyle onClick={handleNextPage} disabled={ !addressto_nm_street || !addressto_nm_city || !addressto_nm_county || !addressto_nm_state ||!addressto_cd_zip   } sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Enviar dados</HeaderBTNStyle>
                    </div>
                }
                {
                    page === 2 &&
                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'> 
                         <HeaderBTNStyle onClick={handlePreviousPage} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Voltar</HeaderBTNStyle>
                    </div>
                }
                </div>
                {/* <Stack flexDirection={ width > 640 ? 'row' : 'column'} justifyContent={'center'} className="items-center" >
                <HeaderBTNStyle onClick={handleConfirm}  disabled={(!verifyHuman || !email) ? true : false} sx={{'&:disabled': {backgroundColor: '#C6C6C6'}}}>Enviar</HeaderBTNStyle>
                </Stack> */}
            </BootstrapDialog>
        </div >
    );
}
