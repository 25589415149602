import { useState } from 'react'
import { styled } from '@mui/material/styles';
import { Stack, TextField, Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/pt-br';
import DatePickerIcon from '../../assets/icons/datepickericon.svg';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';

// -------------------------------------------------------------
const FilterTitleStyle = styled('p')({
    color: '#313131',
    fontFamily: 'Poppins',
    fontSize: '14px',
    margin: '20px 0px 10px',
    fontWeight: 600,
});

const DateIcon =() => {
    return (
      <Icon  className="">
        <Box component={'img'} src={DatePickerIcon} className='w-[17px] h-[17px] ml-[3px] mt-[3px] flex justify-center items-center'/>
      </Icon>
    );
  }

// -------------------------------------------------------------
function PadTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  
  function FormatDate(dateString) {
    dateString = dateString.replace('[', '').replace(']', '');
    var dateObject = new Date(dateString);
    var formattedDate = [
      PadTo2Digits(dateObject.getDate()),
      PadTo2Digits(dateObject.getMonth() + 1),
      dateObject.getFullYear()
    ].join('/');
    return formattedDate;
  }
  
export default function Filter5(props) {

    const [value, setValue] = useState(null);

    var filter = props.filter
    var filterList = props.filterList
    var setFilterList = props.setFilterList

    const handleFilterList = (item) => {
        let aux =[...filterList]
        const filterType = 'date'
        var itemFormated = FormatDate(item.toString())
        let flag = filterList.some(obj=>obj.name===item)
        if (flag) {
            aux = filterList.filter((e) => e.name != item)
        } else {
            aux.push({name:itemFormated,type:'date'})
        }
        props.postFilters(itemFormated, filterType)
        setFilterList(aux);
    }


    return (
        <>
            <FilterTitleStyle>Data de Publicação</FilterTitleStyle>
            <Stack sx={{ mb: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-BR">
                <DatePicker
                    disableFuture
                    label={
                        <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, color: "#313131" }}>
                            Período de busca
                        </Typography>
                    }
                    components={{
                        OpenPickerIcon: DateIcon
                    }}
                    value={value}
                    onChange={(newValue) => {
                        setValue(newValue)
                        handleFilterList(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                    inputFormat="DD/MM/YYYY"
                    disabled={props.filtersBlock == true}
            
                />
                </LocalizationProvider>
            </Stack>
        </>
    )
}