import { styled } from '@mui/material/styles';
import { useNavigate, Navigate } from 'react-router-dom';
import Card from './card';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '80px',
  marginBottom: '80px',
}));

const Content = styled('div')(({ theme }) => ({
  width: '80%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
}));

const ZoomContainer = styled('div')({
  // Default state
  opacity: 0.6,
  transition: 'transform 0.3s, opacity 0.3s',

  // Hover state
  '&:hover': {
    transform: 'scale(1.1)',
    zIndex: 1,
    opacity: 1, // Keep opacity at 1 when hovering
  },
});

export default () => {
  const navigate = useNavigate();
  const [zoomedCard, setZoomedCard] = useState('client');
  const {currentUser} = useSelector(state => state.auth);
  if (currentUser){
      return <Navigate to='/'  />
  }
  const style= {
      subtitle: "font-[Poppins] mb:text-[22px] lg:text-[32px] text-[#313131] font-normal leading-[148%] mb-[65px]",
      noaccountTxt: "font-[Poppins] text-[12px] text-[#313131] font-normal leading-[148%]",
      registerTxt: "cursor-pointer font-[Poppins] text-[12px] text-[#313131] font-normal leading-[148%] underline",
  }

  return (
    <Container>
      <div className="w-full flex justify-center items-center">
        <div className="w-[80%] flex flex-col items-center">
          <div className={style.subtitle}>Como deseja se cadastrar?</div>
          <div className="w-full flex mb:items-center lg:justify-center mb:mb-[25px] lg:mb-[111px] mb:flex-col lg:flex-row" style={{gap:'50px'}}>
            <div className="mb:w-[320px] lg:w-[49%]" style={{maxWidth:'600px'}}>
              <ZoomContainer>
                <Card
                  type="client"
                  title1="Explore sua história familiar e descubra suas raízes com facilidade!"
                  content1="Embarque em uma jornada de descoberta para encontrar suas raízes familiares. Nossos pesquisadores estão prontos para ajudar você a encontrar documentos valiosos, permitindo que você se conecte com seu passado de maneira profunda e significativa."
                  title2="Você já é nosso cliente!"
                  content2="Cadastro já localizado no nosso sistema. Você pode fazer seu login ou recuperar sua senha para acessar sua conta."
                  onMouseEnter={() => setZoomedCard('client')}
                />
              </ZoomContainer>
            </div>            
            <div className="mb:w-[320px] lg:w-[49%]" style={{maxWidth:'600px'}}>
              <ZoomContainer>
                <Card
                  type="researcher"
                  title1="Conecte-se a clientes, ganhe dinheiro com suas descobertas!"
                  content1="Junte-se a um comunidade de pesquisadores e explore oportunidades de encontrar documentos. Ganhe dinheiro com suas descobertas e construa pontes entre pessoas e suas raízes familiares."
                  title2="Você já é nosso pesquisador!"
                  content2="Cadastro já localizado no nosso sistema. Você pode fazer seu login ou recuperar sua senha para acessar sua conta."
                  onMouseEnter={() => setZoomedCard('researcher')}
                />
              </ZoomContainer>
            </div>
          </div>
          <div className="flex justify-center">
            <div className={style.noaccountTxt}>Já tem uma conta? Faça login&nbsp;</div>
            <div className={style.registerTxt} onClick={() => navigate('/login')}>
              aqui
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};