import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import CorrectGreen from "../../assets/icons/correctGreen.svg"

import ImportImage from '../../assets/image/importplan_highlited.svg'

const SinglePlan = styled('div')(({ theme }) => ({
    display:'flex',
    flexDirection:'column',
    width:'100%',
    height:'100%',
    border:'1px solid',
    borderRadius:'10px',
    margin:'10px 10px 10px 10px',
    borderColor:'#17686E',
    overflow:'hidden',
    boxShadow:'0px 4px 6px rgba(23, 104, 110, 0.5)',
    transition: 'transform 0.3s', // Adding the transition here
    '&:hover': {
      transform: 'scale(1.1)', // Zoom effect on hover
      zIndex: '1',
    },
}));

const SingleBenefit = styled('div')(({ theme }) => ({
    display:'flex',
    justifyContent:'left',
    margin:'0px auto 5px auto'
}));

const Button = styled('div')(({ theme }) => ({
    display:'flex',
    justifyContent:'center',
    margin:'0px auto 20px auto',
    fontFamily: 'Poppins',
    fontWeight: 600,
    backgroundColor:'#17686E',
    width:'180px',
    height:'40px',
    borderRadius:'10px',
    color:'#fff',
    alignItems:'center',
    alignContent:'center',
    cursor:'pointer',
}));



export default function SingleCardPlan({title,normalPrice,currentPrice,benefits,isHighlighted,importNumber,comment,image, setCardContent, setopenModal,single,setcheckoutData,importID, }){

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
          setIsMobile(window.innerWidth <= 760); // Adjust the threshold as needed
    }, []);

    return(
        <>
        {
            !isMobile ?
            <SinglePlan style={isHighlighted?{ border:'3px solid', borderColor:'#BA483A', boxShadow:'0px 4px 6px rgba(186, 72, 58, 0.5)', }:{}}>
                <div  style={{display:'flex', width:'100%', height:isHighlighted?'150px':'80px', overflow:'hidden', border:'none',}}>
                    <img src={image} style={{display:'flex',width:'130%', height:'130%',  objectFit:'cover',margin:'0px'}}/>
                </div>
                    {isHighlighted &&
                        <div  style={{ width:'70px', display:'flex', backgroundColor:'#fff', padding:'5px 5px 5px 5px', borderRadius:'10px', color:'#fff', fontWeight:'normal', fontSize:'8px',position:'relative', bottom:'15px',  right:'-70%', alignContent:'center', alignItems:'center',justifyContent:'center'}} >
                            <div  style={{width:'70px', display:'flex', backgroundColor:'#17686E', borderRadius:'5px',padding:'5px 5px 5px 5px', color:'#fff', fontWeight:'bold', fontSize:'8px', alignContent:'center', alignItems:'center',justifyContent:'center'}}>POPULAR</div>
                        </div>
                    }

            <div style={{display:'flex',flexDirection:'column', justifyContent:'center', margin :isHighlighted?'-20px auto 20px  auto':'10px auto 20px  auto',alignContent:'center'}}>
                    <p style={{fontFamily:'poppins', margin:'auto auto auto auto ', fontSize:'35px', fontWeight:'800', }}>{importNumber}</p>
                    <p style={{fontFamily:'poppins', margin:'auto auto auto auto ', fontSize:'20px', fontWeight:'300', }}>Importações</p>
            </div>

            <div style={{display:'flex' , width:'80%', margin:'0px auto 10px auto', backgroundColor:'#C6C6C6', padding:'0.5px 0.5px 0.5px 0.5px'}}></div>

            <div style={{display:'flex', margin:'10px auto 10px auto'}}>
                <div style={{fontFamily:'poppins', color:'#C6C6C6',fontSize:'15px',textDecoration:'line-through', margin:'auto 10px auto auto'}}>R${normalPrice}</div>
                <div style={{fontFamily:'poppins',  fontSize:'30px', margin:'auto auto auto 0px'}}>R$ {currentPrice}</div>
            </div>

            <div style={{display:'flex' , width:'80%', margin:'0px auto 10px auto', backgroundColor:'#C6C6C6', padding:'0.5px 0.5px 0.5px 0.5px'}}></div>

            <div style={{fontFamily:'poppins', width:'70%', display:'flex', justifyContent:'center', alignContent:'center', margin:'0px auto 20px auto', textAlign:'center',}}>
                    {comment}
            </div>

            <div>
                <Button style={isHighlighted?{backgroundColor:'#BA483A'}:{}} onClick={()=>{setopenModal(true);setCardContent(single);setcheckoutData({
                      bidname:`${importNumber} Importações`,
                      bidImage:image,
                      normalprice:normalPrice,
                      promoprice:currentPrice,
                      moreinfo:[comment],                   
                    })}}>Comprar</Button>
            </div>



        </SinglePlan>
:
<SinglePlan style={isHighlighted?{ border:'3px solid',borderColor:'#BA483A',}:{}}>
<div  style={{display:'flex', width:'100%', height:isHighlighted?'150px':'80px', overflow:'hidden', border:'none',}}>
    <img src={image} style={{display:'flex',width:'130%', height:'130%',  objectFit:'cover',margin:'0px'}}/>
</div>
    {isHighlighted &&
        <div  style={{width:'70px', display:'flex', backgroundColor:'#fff', padding:'5px 5px 5px 5px', borderRadius:'10px', color:'#fff', fontWeight:'normal', fontSize:'8px',position:'relative', bottom:'15px',  right:'-70%', alignContent:'center', alignItems:'center',justifyContent:'center'}} >
            <div  style={{width:'70px', display:'flex', backgroundColor:'#17686E', borderRadius:'5px',padding:'5px 5px 5px 5px', color:'#fff', fontWeight:'bold', fontSize:'8px', alignContent:'center', alignItems:'center',justifyContent:'center'}}>POPULAR</div>
        </div>
    }

<div style={{display:'flex',flexDirection:'column', justifyContent:'center', margin :isHighlighted?'-20px auto 20px  auto':'10px auto 20px  auto',alignContent:'center'}}>
    <p style={{fontFamily:'poppins', margin:'auto auto auto auto ', fontSize:'35px', fontWeight:'800', }}>{importNumber}</p>
    <p style={{fontFamily:'poppins', margin:'auto auto auto auto ', fontSize:'20px', fontWeight:'300', }}>Importações</p>
</div>

<div style={{display:'flex' , width:'80%', margin:'0px auto 10px auto', backgroundColor:'#C6C6C6', padding:'0.5px 0.5px 0.5px 0.5px'}}></div>

<div style={{display:'flex', margin:'10px auto 10px auto'}}>
<div style={{fontFamily:'poppins', color:'#C6C6C6',fontSize:'15px',textDecoration:'line-through', margin:'auto 10px auto auto'}}>R${normalPrice}</div>
<div style={{fontFamily:'poppins',  fontSize:'30px', margin:'auto auto auto 0px'}}>R$ {currentPrice}</div>
</div>

<div style={{display:'flex' , width:'80%', margin:'0px auto 10px auto', backgroundColor:'#C6C6C6', padding:'0.5px 0.5px 0.5px 0.5px'}}></div>

<div style={{fontFamily:'poppins', width:'70%', display:'flex', justifyContent:'center', alignContent:'center', margin:'0px auto 20px auto', textAlign:'center',}}>
    {comment}
</div>

<div>
<Button style={isHighlighted?{backgroundColor:'#BA483A'}:{}} onClick={()=>{setopenModal(true);setCardContent(single);setcheckoutData({
                      bidname:`${importNumber} Importações`,
                      bidImage:image,
                      normalprice:normalPrice,
                      promoprice:currentPrice,
                      moreinfo:[comment],         
                      id_plan_import:importID,
                    })}}>Comprar</Button>
</div>



</SinglePlan>

        }
        </>
    )
}