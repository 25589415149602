import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  //maxWidth: '600px',
  padding: '20px',
  flexDirection: 'column',  
  alignItems: 'flex-start',
  gap: '20px',  
  borderRadius: '7px',
  boxShadow: '-2px 3px 50px rgba(0, 0, 0, 0.15)',  
}));

const Title = styled('div')(({ theme }) => ({
  fontFamily: 'Poppins',
  color: '#313131',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: '700',
  //lineHeight: '100%',
  margin: '0px 0px 0px',
  flexGrow: 1, // Permite que o título cresça verticalmente
}));

const Content = styled('div')(({ theme }) => ({
  fontFamily: 'Poppins',
  color: '#313131',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '148%',
  //margin: '0 20px',
  flexGrow: 1, // Permite que o conteúdo cresça verticalmente
}));

const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    //marginBottom: '20px',
    //marginTop: '20px',
  }));

const Button = styled('button')(({ theme }) => ({
  fontFamily: 'Poppins',
  color: '#FFFFFF',
  borderRadius: '5px',
  backgroundColor: '#17686E',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  width: '215px',
  height: '60px',
  textAlign: 'center',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  padding: '8px',
  marginTop: '11px',
  lineHeight: 'normal',
}));

const InvertedButton = styled(Button)(({ theme }) => ({
  color: '#17686E',
  backgroundColor: '#FFFFFF',
  border: '1px solid #17686E',
}));

export default (props) => {
  const navigate = useNavigate();
  const { type, title1, content1, title2, content2 } = props;
  const [clicked, setClicked] = useState(true);

  const handleButtonClick = () => {
    setClicked(false);
    navigate(`/register/${type}`);
  };

  return (
    <CardContainer>
      {clicked ? (
        <>
          <Title>{title1}</Title>
          <Content>{content1}</Content>
        </>
      ) : (
        <>
          <Title>{title2}</Title>
          <Content>{content2}</Content>
        </>
      )}
        <ButtonContainer>
            {type === 'client' ? (
            <Button disabled={!clicked} onClick={handleButtonClick}>
                Seja Cliente
            </Button>
            ) : (
            <Button disabled={!clicked} onClick={handleButtonClick}>
                Seja pesquisador
            </Button>
            )}
        </ButtonContainer>
    </CardContainer>
  );
};
