import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import useWindowSize from '../../hooks/useWindowSize'
import {
    Stack,
    Box,
} from '@mui/material';
import Pic1 from '../../assets/image/home_how_1.svg';
import Pic2 from '../../assets/image/home_how_2.svg';
import Pic3 from '../../assets/image/home_how_3.svg';
import Pic4 from '../../assets/image/home_how_4.svg';
import { useNavigate } from 'react-router-dom';

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const Text_style_1 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '26px',
    fontWeight: 400,
    lineHeight: '100%',
    color: '#313131',
    [theme.breakpoints.up('sm')]: {
        fontSize: '50px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '65px',
    }
}));

const Text_number = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: '90px',
    // top: '45px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
        right: '215px',
        // top: 'auto',
        // bottom: 'auto',
    },
}));

const Text_first = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '60px',
    fontWeight: 500,
    color: '#BA483A',
    [theme.breakpoints.up('md')]: {
        fontSize: '150px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '230px',
    }
}));

const Text_second = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '60px',
    fontWeight: 500,
    textShadow: '3px 0  #F3F3F3, -3px 0  #F3F3F3, 0 3px  #F3F3F3, 0 -3px  #F3F3F3, 1.5px 1.5px  #F3F3F3, -1.5px -1.5px  #F3F3F3, 1.5px -1.5px  #F3F3F3, -1.5px 1.5px  #F3F3F3',
    color: '#BA483A',
    [theme.breakpoints.up('md')]: {
        fontSize: '150px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '230px',
        textShadow: '5px 0  #F3F3F3, -5px 0  #F3F3F3, 0 5px  #F3F3F3, 0 -5px  #F3F3F3, 2.5px 2.5px  #F3F3F3, -2.5px -2.5px  #F3F3F3, 2.5px -2.5px  #F3F3F3, -2.5px 2.5px  #F3F3F3',
    }
}));

const Text_number_2 = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: '90px',
    top: '45px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
        right: '150px',
        top: '50px',
    },
}));

const Text_number_3 = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: '90px',
    // top: '20px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
        right: '192px',
        // top: '95px',
    },
}));

const Text_number_4 = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: '90px',
    top: '45px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
        right: '212px',
        top: '97px',
    },
}));

const Text_header = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: '37px 0px 11px',
    [theme.breakpoints.up('sm')]: {
        padding: '60px 0px 50px',
    },
    [theme.breakpoints.up('md')]: {
        padding: '120px 0px 50px',
    },
}));

const Text_style_2 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    color: '#313131',
    lineHeight: '150%',
    paddingLeft: '5px',
    paddingRight: '13px',
}));

const Text_style_3 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    color: '#313131',
    lineHeight: '150%',
    paddingLeft: '5px',
    paddingRight: '13px',
}));

const BTN_style_1 = styled('button')(({ theme }) => ({
    color: '#FFFFFF',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    backgroundColor: '#17686E',
    borderRadius: '5px',
    border: 'none',
    fontSize: '14px',
    padding: '10px 5px 10px 5px',
    float: 'left',
    marginTop: '15px',
    cursor: 'pointer',
    marginBottom: '41px',
    [theme.breakpoints.up('sm')]: {
        fontSize: '15px',
        padding: '12px 13px',
        float: 'right',
        marginTop: '25px',
        marginBottom: '20px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        padding: '15px 14px',
        float: 'right',
        marginTop: '25px',
        marginBottom: '0px',
    }
}));

const Grid_Wrapper_1 = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    background: '#F3F3F3',
    borderRadius: '5px',
    flexDirection: 'column',
    paddingTop: '23px',
    marginTop: '20px',
    [theme.breakpoints.up('sm')]: {
        paddingTop: '23px',
    },
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        paddingTop: '0px',
    }
}));

const Grid_item_1 = styled('div')(({ theme }) => ({
    // width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    position: 'relative',
    right: '35%',
    paddingTop:'auto',
    paddingBottom:'auto',
    [theme.breakpoints.up('md')]: {
        width: '46%',
        right: '26px',
        minHeight: '424px',
    },
    [theme.breakpoints.up('1259')]: {
        width: '40.6%',
        right: '36px',
        minHeight: '424px',
    }
}));

const Grid_item_2 = styled('div')(({ theme }) => ({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
    [theme.breakpoints.up('md')]: {
        width: '54%',
        paddingLeft: '5px',
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    [theme.breakpoints.up('1259')]: {
        width: '59.4%',
        paddingTop: '20px',
        paddingBottom: '20px',
    }
}));

const Grid_item_3 = styled('div')(({ theme }) => ({
    // width: '100%',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.up('md')]: {
        // paddingTop: '63px',
        width: '54%',
        paddingLeft: '45px',
    },
    [theme.breakpoints.up('lg')]: {
        width: '59.4%',
        paddingLeft: '45px',
        // paddingTop: '63px',
    }
}));

const Grid_item_4 = styled('div')(({ theme }) => ({
    width: '100%',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.up('md')]: {
        paddingTop: '63px',
        width: '50%',
        paddingLeft: '45px',
    },
    [theme.breakpoints.up('lg')]: {
        width: '59.4%',
    }
}));

const Grid_item_5 = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    position: 'relative',
    right: '35%',
    [theme.breakpoints.up('md')]: {
        width: '50%',
        right: '24px',
        minHeight: '424px',
    },
    [theme.breakpoints.up('1300')]: {
        width: '50%', 
        right: '34px',
        minHeight: '424px',
    }
}));

const Grid_item_6 = styled('div')(({ theme }) => ({
    width: '100%',
    // paddingLeft: '5px',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.up('md')]: {
        paddingTop: '63px',
        width: '50%',
    },
    [theme.breakpoints.up('1300')]: {
        width: '59.4%',
    }
}));

const Grid_Wrapper_2 = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    background: '#F3F3F3',
    flexDirection: 'column-reverse',
    paddingTop: '23px',
    marginTop: '20px',
    [theme.breakpoints.up('sm')]: {
        paddingTop: '23px',
    },
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        paddingTop: '0px',
    }
}));

export default function How_does_it_works() {
    
    const {currentUser} = useSelector(state => state.auth);
    const { width } = useWindowSize()
    const navigate = useNavigate();

    return (
        <RootStyle id='how'>
            <Text_header>
                <Text_style_1>Como</Text_style_1>
                <Text_style_1 sx={{ color: '#BA483A', fontWeight:'bold' }}>&nbsp;funciona</Text_style_1>
            </Text_header>

            {/* comment 1 */}

            <Grid_Wrapper_1>
                <Grid_item_1>
                    {width < 900 && <Box component='img' src={Pic1} height={'166px'} />}
                    {width >= 900 && <Box component='img' src={Pic1} height={'400px'} />}
                    <Text_number sx={{marginTop:'auto', marginBottom:'auto'}} >
                        <Text_first>1</Text_first>
                        <Text_second>°</Text_second>
                    </Text_number>
                </Grid_item_1>
                <Grid_item_2>
                    <Text_style_2 sx={{ fontSize: '28px', pt: '15px', fontWeight: 700,}}>Solicite seus documentos e receba propostas personalizadas</Text_style_2>
                    <Text_style_3 sx={{ pt: '15px' }}>Encontre especialistas na DocsTree que irão ajudá-lo a localizar seus próprios documentos e os documentos dos seus antepassados.</Text_style_3>
                    <Text_style_2 sx={{ pt: '20px' }}>Descreva o tipo de documento que você precisa</Text_style_2>
                    <Text_style_3 sx={{ pt: '15px' }}>Informe a origem do documento e forneça detalhes das informações que você já possui.</Text_style_3>
                    <Text_style_3 sx={{ pt: '3px' }}>É gratuito!</Text_style_3>
                    <Text_style_2 sx={{ pt: '20px' }}>Receba várias propostas em minutos</Text_style_2>
                    <Text_style_3 sx={{ pt: '15px' }}>Imediatamente, pesquisadores de todo o mundo irão ler sua solicitação e enviar propostas personalizadas.</Text_style_3>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}><BTN_style_1  onClick={()=>currentUser?navigate('/createorder'):navigate('/login')}>Solicitar documento</BTN_style_1></Box>
                </Grid_item_2>
            </Grid_Wrapper_1>
            <Stack direction={'row'} justifyContent={'center'}>
                <Box sx={{
                    width: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    height: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    borderTop: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderRight: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderLeft: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    borderBottom: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    boxSizing: 'border-box',
                }}></Box>
                <Box sx={{
                    width: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    height: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    borderTop: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderLeft: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderRight: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    borderBottom: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    boxSizing: 'border-box',
                }}></Box>
            </Stack>

            {/* comment 2 */}

            <Grid_Wrapper_2>
                <Grid_item_3 sx={{ }}>
                    <Text_style_2 sx={{ fontSize: '28px', fontWeight: 700, }}>Analise e escolha o pesquisador ideal para você</Text_style_2>
                    <Text_style_3 sx={{ pt: '30px' }}>Você tem o poder de decidir com qual pesquisador deseja trabalhar e o valor que está disposto a pagar.</Text_style_3>
                    <Text_style_2 sx={{ pt: '30px' }}>Avalie as propostas recebidas</Text_style_2>
                    <Text_style_3 sx={{ pt: '13px' }}>Cada proposta incluirá um valor e um prazo estimado para a busca do seu documento.</Text_style_3>
                    <Text_style_2 sx={{ pt: '30px' }}>Analise os perfis</Text_style_2>
                    <Text_style_3 sx={{ pt: '13px', pb: '38px' }}>Observe os perfis dos pesquisadores, suas qualificações e experiências anteriores para conhecê-los melhor.</Text_style_3>
                </Grid_item_3>
                <Grid_item_1 sx={{  }} >
                    {width < 900 && <Box component='img' src={Pic2} height={'166px'} />}
                    {width >= 900 && <Box component='img'  sx={{marginTop:'auto', marginBottom:'auto'}} src={Pic2} height={'272px'} />}
                    <Text_number_2>
                        <Text_first>2</Text_first>
                        <Text_second>°</Text_second>
                    </Text_number_2>
                </Grid_item_1>
            </Grid_Wrapper_2>
            <Stack direction={'row'} justifyContent={'center'}>
                <Box sx={{
                    width: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    height: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    borderTop: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderRight: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderLeft: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    borderBottom: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    boxSizing: 'border-box',
                }}></Box>
                <Box sx={{
                    width: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    height: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    borderTop: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderLeft: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderRight: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    borderBottom: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    boxSizing: 'border-box',
                }}></Box>
            </Stack>

            {/* comment 3 */}

            <Grid_Wrapper_1>
                <Grid_item_5 >
                    {width < 900 && <Box component='img' src={Pic3} height={'109px'} />}
                    {width >= 900 && <Box component='img' sx={{marginTop:'auto', marginBottom:'auto'}} src={Pic3} height={'266px'} />}
                    <Text_number_3 >
                        <Text_first>3</Text_first>
                        <Text_second>°</Text_second>
                    </Text_number_3>
                </Grid_item_5>
                <Grid_item_6>
                    <Text_style_2 sx={{ fontSize: '28px', fontWeight: 700, }}>Realize o pagamento com total segurança</Text_style_2>
                    <Text_style_3 sx={{ pt: '30px' }}>Após selecionar o pesquisador, você deposita o valor combinado pelo serviço na DocsTree, que o manterá em segurança até a conclusão do trabalho.</Text_style_3>
                    <Text_style_2 sx={{ pt: '30px' }}>Pagamento em garantia</Text_style_2>
                    <Text_style_3 sx={{ pt: '13px' }}>Seu dinheiro ficará protegido até que você decida liberar os fundos para o pesquisador.</Text_style_3>
                    <Text_style_2 sx={{ pt: '30px' }}>Garantia de reembolso</Text_style_2>
                    <Text_style_3 sx={{ pt: '13px', pb: '38px' }}>Caso o pesquisador não entregue o trabalho, seu dinheiro será devolvido.</Text_style_3>

                </Grid_item_6>
            </Grid_Wrapper_1>
            <Stack direction={'row'} justifyContent={'center'}>
                <Box sx={{
                    width: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    height: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    borderTop: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderRight: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderLeft: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    borderBottom: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    boxSizing: 'border-box',
                }}></Box>
                <Box sx={{
                    width: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    height: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    borderTop: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderLeft: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderRight: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    borderBottom: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    boxSizing: 'border-box',
                }}></Box>
            </Stack>

            {/* comment 4 */}

            <Grid_Wrapper_2>
                <Grid_item_4>
                    <Text_style_2 sx={{ fontSize: '28px', fontWeight: 700, }}>
                        Receba seus documentos!
                    </Text_style_2>
                    <Text_style_3 sx={{ pt: '30px' }}>
                        Desfrute de um serviço totalmente remoto ou receba documentos físicos, de acordo com sua preferência e orçamento.
                    </Text_style_3>
                    <Text_style_2 sx={{ pt: '30px' }}>
                        Documentos digitais
                    </Text_style_2>
                    <Text_style_3 sx={{ pt: '13px' }}>
                        Cada pedido inclui um espaço de trabalho online para que você esteja conectado ao pesquisador em todos os momentos. Os documentos serão enviados de forma digital, garantindo praticidade e agilidade.
                    </Text_style_3>
                    <Text_style_2 sx={{ pt: '30px' }}>
                        Documentos físicos e apostilados com verificação prévia
                    </Text_style_2>
                    <Text_style_3 sx={{ pt: '13px'}}>
                    Caso você opte por receber documentos físicos ou apostilados, nossos pesquisadores irão enviá-los pelo correio, dentro do fluxo de pedido. Antes da entrega, você terá a oportunidade de verificar e confirmar se o documento está correto, garantindo sua satisfação.
                    </Text_style_3>
                    <Text_style_2 sx={{ pt: '30px' }}>
                        Forneça feedback
                    </Text_style_2>
                    <Text_style_3 sx={{ pt: '13px', pb: '38px' }}>
                        Após a conclusão do serviço, compartilhe sua experiência avaliando todo o processo com o pesquisador.
                    </Text_style_3>
                </Grid_item_4>
                <Grid_item_1>
                    {width < 900 && <Box component='img' src={Pic4} height={'139px'} />}
                    {width >= 900 && <Box component='img' src={Pic4} height={'323px'} />}
                    <Text_number_4>
                        <Text_first>4</Text_first>
                        <Text_second>°</Text_second>
                    </Text_number_4>
                </Grid_item_1>
            </Grid_Wrapper_2>
            <Stack direction={'row'} justifyContent={'center'}>
                <Box sx={{
                    width: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    height: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    borderTop: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderRight: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderLeft: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    borderBottom: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    boxSizing: 'border-box',
                }}></Box>
                <Box sx={{
                    width: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    height: `${width * 0.05 > 72 ? 72 : width * 0.05}` + 'px',
                    borderTop: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderLeft: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' #F3F3F3',
                    borderRight: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    borderBottom: 'solid ' + `${width * 0.025 > 36 ? 36 : width * 0.025}` + 'px' + ' transparent',
                    boxSizing: 'border-box',
                }}></Box>
            </Stack>


            <Box sx={{ display: 'flex', justifyContent: 'center' }}><BTN_style_1  onClick={()=>currentUser?navigate('/createorder'):navigate('/login')}>Quero solicitar documento</BTN_style_1></Box>

        </RootStyle>
    )
}