/* eslint-disable import/no-anonymous-default-export */

import { styled } from '@mui/material/styles';
import React, {useState, useEffect, useLayoutEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
} from '@mui/material';
import useWindowSize from '../../hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import LinearProgress from '@mui/material/LinearProgress';
import Step0 from './step0'
import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import Step4 from './step4'
import  { Navigate } from 'react-router-dom'
import './style.css'
import API from '../../redux/API';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toast'
import { setCurrentOrder } from '../../redux/actions/order.action';
import {  useLocation } from 'react-router-dom';
import Loading from '../../components/loading/Loading';
const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const ContainerStyle1 = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '0px auto',
    marginTop: '80px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%'
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
    }
}));

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#313131',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const LinearProgressWithLabel = (props) => {
    console.log(props.value);
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: props.width > 640 ? 'row':'column-reverse' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <div className="mb:ml-[0px] md:ml-[10px] mb:mb-[25px] md:mb-[0px]">{((props.value>=50 && props.value<75) ? 75 : props.value===10 ? 25 :  props.value===25 ? 50 : props.value===75 ? 100 :   props.value===100 ? 125 : props.value)/25}/5</div>
        </Box>
      </Box>
    );
}

export default (props) => {
    const { width } = useWindowSize();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();


    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] whitespace-nowrap mb-[5px]",
        content: "!w-full !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px]"
    }

    const [progress, setProgress] = useState(10);
    const [loading, setLoading] = useState(false);
    const {currentUser} = useSelector(state => state.auth);
    const { currentProfile } = useSelector(state => state.auth);
    // if (!currentUser || currentProfile){
    //     return <Navigate to='/'  />
    // }
    // useLayoutEffect(()=>{
    //     if(currentUser && currentProfile){
    //         navigate(-1);
    //     }
    // }, [currentUser])
    const [locationSelected, setLocationSelected] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [id_documenttype, setid_documenttype] = useState('');
    const [nm_documentcountry, setnm_documentcountry] = useState('BR');
    const [nm_documentlocation, setnm_documentlocation] = useState('');
    const [nm_otherdocument, setnm_otherdocument] = useState('');
    const [nm_ancestor, setnm_ancestor] = useState('');
    const [ds_gender, setds_gender] = useState('');
    const [ic_searchdocument, setic_searchdocument] = useState(false);
    const [ic_fetchdocument, setic_fetchdocument] = useState(false);
    const [ic_digitaldocument, setic_digitaldocument] = useState(false);
    const [ic_physicaldocument, setic_physicaldocument] = useState(false);
    const [ic_stampeddocument, setic_stampeddocument] = useState(false);
    const [nm_ancestoralternative, setnm_ancestoralternative] = useState([]);
    const [allowUsers, setResearchList] = useState([]);
    const [family_members, setfamily_members] = useState({nm_spouse:'', nm_father:'', nm_mother:'', nm_otherperson:'', ds_otherpersonfamiliarity:''});
    
    
    const [life_event_dates, setlife_event_dates] = useState({
        baptism: {
            dt_baptism: null,
            dt_baptismalternativefrom: null,
            dt_baptismalternativeto: null,
            ds_baptismlocation: null,
        },
        birth: {
            dt_birth: null,
            dt_birthalternativefrom: null,
            dt_birthalternativeto: null,
            ds_birthlocation: null,
        },
        marriage: {
            dt_marriage: null,
            dt_marriagealternativefrom: null,
            dt_marriagealternativeto: null,
            ds_marriagelocation: null,
        },
        immigration: {
            dt_immigration: null,
            dt_immigrationalternativefrom: null,
            dt_immigrationalternativeto: null,
            ds_immigrationlocation: null,
        },
        death: {
            dt_death: null,
            dt_deathalternativefrom: null,
            dt_deathalternativeto: null,
            ds_deathlocation: null,
        },
    });


    


    const [ds_comments, setds_comments] = useState('');
    const [tp_ordervisibility, settp_ordervisibility] = useState(false);
    const [tp_order, settp_order] = useState(false);
    const [tp_orderexperience, settp_orderexperience] = useState('Beginner');
    const [ds_familysearchprofile, setds_familysearchprofile] = useState('')
    
    const [stampAvailable, setStampAvailable] = useState(true);

    
    const [needOtherType, setneedOtherType] = useState(false);
    const [otherDocOK, setOtherDocOK] = useState(false);


    const [isEditing, setisEditing] = useState(false);
    const [orderid, setOrderid] = useState('');


    const checkCanFetch = ()=>{
        if(ic_searchdocument && !ic_fetchdocument){
            setic_digitaldocument(true)
            setic_physicaldocument(false)
            return setic_stampeddocument(false)
        }
            setic_stampeddocument(false)
            setic_physicaldocument(false)
            setic_digitaldocument(false)
    }


    useEffect(()=>{
        checkCanFetch()
    },[ic_searchdocument,ic_fetchdocument,isEditing,stampAvailable])



useEffect(()=>{

    const path = location.pathname;
    const orderID = path.split('/')[2];

    if (path.split('/')[1]==='editorder' && orderID) {
        setisEditing(true)
        //console.log(`Can edit this order ${orderID}`);
            setOrderid(orderID)
        API.post(`order/getspecificorderinfo`,{orderID:orderID})
            .then((res)=>{
                if(res.data.status==='success'){
                    setic_searchdocument(res.data.singleOrder.ic_searchdocument)
                    setic_fetchdocument(res.data.singleOrder.ic_fetchdocument)

                    setlife_event_dates(res.data.singleOrder.life_event_dates)
                    setds_comments(res.data.singleOrder.ds_comments)
                    settp_ordervisibility(res.data.singleOrder.tp_ordervisibility)
                    settp_order(res.data.singleOrder.tp_order)
                    settp_orderexperience(res.data.singleOrder.tp_orderexperience)
                    setds_familysearchprofile(res.data.singleOrder.ds_familysearchprofile)
                    setfamily_members(res.data.singleOrder.family_members)
                    setResearchList(res.data.singleOrder.allowUsers)
                    setnm_ancestoralternative(res.data.singleOrder.nm_ancestoralternative)
                    setds_gender(res.data.singleOrder.ds_gender)
                    setid_documenttype(res.data.singleOrder.id_documenttype)
                    setnm_documentcountry(res.data.singleOrder.nm_documentcountry)
                    setnm_documentlocation(res.data.singleOrder.nm_documentlocation)
                    setnm_otherdocument(res.data.singleOrder.nm_otherdocument)
                    setnm_ancestor(res.data.singleOrder.nm_ancestor)

                    setic_stampeddocument(res.data.singleOrder.ic_stampeddocument)
                    setic_physicaldocument(res.data.singleOrder.ic_physicaldocument)
                    setic_digitaldocument(res.data.singleOrder.ic_digitaldocument)


                    // check if this order has started
                    if (res.data.singleOrder.cd_orderstatus !==0) {
                        toast.info(`Você não pode editar uma ordem em andamento.`)
                        navigate(`/order/${orderID}`)
                    } 
                }
            })
            .catch((err)=>{
                toast.error('Erro ao buscar ordem específica. Contate o suporte')
                navigate(-1)
            })
    }
},[])








    return (
       <RootStyle>
            {loading && <Loading nameToShow="Carregando"/>}
        {
            progress != 125 && 
            <ContainerStyle1 >
                <div className='mb:mb-[50px] md:mb-[40px] mb:mt-[-40px] md:mt-[0px] font-[Poppins] font-normal mb:text-[25px] md:text-[32px] leading-[148%] color-[#313131]' style={{color: "#313131"}}>Solicite seu documento</div>
                <Box sx={{ width: width>640 ?'60%':'100%' }}>
                    <LinearProgressWithLabel  
                        sx={{
                            'backgroundColor': '#D9D9D9',
                            '& .MuiLinearProgress-bar1Determinate': {
                                backgroundColor: '#17686E',
                            }
                        }}
                        width={width}
                     value={progress> 100 ? 100: progress} />
                </Box>
            </ContainerStyle1>
        }
        <div className='min-h-[520px]'>                
            {
                progress === 10 && 
                <Step0 
                    ic_searchdocument={ic_searchdocument} setic_searchdocument={setic_searchdocument}
                    ic_fetchdocument={ic_fetchdocument} setic_fetchdocument={setic_fetchdocument}
                    progress={progress} setProgress={setProgress}
                    isCreateOrder={true}
                ></Step0>

            }
            {
                progress === 25 && 
                <Step1 
                    checkCanFetch={checkCanFetch}
                    stampAvailable={stampAvailable}
                    setStampAvailable={setStampAvailable}
                    ic_searchdocument={ic_searchdocument}
                    ic_fetchdocument={ic_fetchdocument} 
                    nm_documentcountry={nm_documentcountry} setnm_documentcountry={setnm_documentcountry} 
                    nm_documentlocation={nm_documentlocation} setnm_documentlocation={setnm_documentlocation} 
                    id_documenttype={id_documenttype} setid_documenttype={setid_documenttype} 
                    ds_familysearchprofile={ds_familysearchprofile} setds_familysearchprofile={setds_familysearchprofile} 
                    nm_otherdocument={nm_otherdocument} setnm_otherdocument={setnm_otherdocument} 
                    ic_digitaldocument={ic_digitaldocument} setic_digitaldocument={setic_digitaldocument} 
                    ic_physicaldocument={ic_physicaldocument} setic_physicaldocument={setic_physicaldocument} 
                    ic_stampeddocument={ic_stampeddocument} setic_stampeddocument={setic_stampeddocument}
                    progress={progress} setProgress={setProgress}
                    setOtherDocOK={setOtherDocOK}
                    otherDocOK={otherDocOK}
                    needOtherType={needOtherType}
                    setneedOtherType={setneedOtherType}
                    isCreateOrder={true}
                    setLocationSelected={setLocationSelected}
                    locationSelected={locationSelected}
                    ></Step1>

            }
            {
                progress === 50 && 
                <Step2
                    tabIndex1={1}
                    ds_gender={ds_gender} setds_gender={setds_gender} 
                    nm_ancestor={nm_ancestor} setnm_ancestor={setnm_ancestor} 
                    nm_ancestoralternative={nm_ancestoralternative} setnm_ancestoralternative={setnm_ancestoralternative} 
                    family_members={family_members} setfamily_members={setfamily_members} 
                    life_event_dates={life_event_dates} setlife_event_dates={setlife_event_dates} 
                    progress={progress} setProgress={setProgress}
                    isCreateOrder={true}

                ></Step2>
            }
            {
                progress === 60 && 
                <Step2
                    tabIndex1={2}
                    ds_gender={ds_gender} setds_gender={setds_gender} 
                    nm_ancestor={nm_ancestor} setnm_ancestor={setnm_ancestor} 
                    nm_ancestoralternative={nm_ancestoralternative} setnm_ancestoralternative={setnm_ancestoralternative} 
                    family_members={family_members} setfamily_members={setfamily_members} 
                    life_event_dates={life_event_dates} setlife_event_dates={setlife_event_dates} 
                    progress={progress} setProgress={setProgress}
                    isCreateOrder={true}

                ></Step2>
            }
                        {
                progress === 70 && 
                <Step2
                    tabIndex1={3}
                    ds_gender={ds_gender} setds_gender={setds_gender} 
                    nm_ancestor={nm_ancestor} setnm_ancestor={setnm_ancestor} 
                    nm_ancestoralternative={nm_ancestoralternative} setnm_ancestoralternative={setnm_ancestoralternative} 
                    family_members={family_members} setfamily_members={setfamily_members} 
                    life_event_dates={life_event_dates} setlife_event_dates={setlife_event_dates} 
                    progress={progress} setProgress={setProgress}
                    isCreateOrder={true}

                ></Step2>
            }
            {
                progress === 75 && 
                <Step3
                    ds_comments={ds_comments} setds_comments={setds_comments}
                    progress={progress} setProgress={setProgress}
                    isCreateOrder={true}

                >
                </Step3>
            }
            {
                progress === 100 && 
                <Step4
                    tp_ordervisibility={tp_ordervisibility} settp_ordervisibility={settp_ordervisibility} 
                    tp_orderexperience={tp_orderexperience} settp_orderexperience={settp_orderexperience}
                    researcherList={allowUsers} setResearchList={setResearchList}
                    progress={progress} setProgress={setProgress}
                    isCreateOrder={true}

                >
                </Step4>
            }
            </div>

            <div className='w-[90%]' style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="flex mb:justify-center md:just`ify-end  mb-[80px] " style={progress === 70 ? {marginTop:'40px'} : {}}>
                <HeaderBTNStyle1 
                    style={{ marginRight: "10px" }}
                    onClick={()=>{
                        setLoading(true)
                        if(progress  === 100) {setProgress(progress - 25);setLoading(false)}
                        else if(progress  === 75) {setProgress(progress - 5);setLoading(false)}
                        else if(progress === 70) {setProgress(progress - 10);setLoading(false)}
                        else if(progress === 60) {setProgress(progress - 10);setLoading(false)}
                        else if(progress === 50) {setProgress(25);setLoading(false)}
                        else if(progress === 25) {setProgress(10);setLoading(false)}
                        else if(progress === 10) {setLoading(false);navigate('/home')}
                    }}                                
                >
                Voltar
                </HeaderBTNStyle1>
                <HeaderBTNStyle
                    style={{ marginLeft: "10px" }}
                    onClick={()=>{
                        setLoading(true)
                        if(progress === 50) {setProgress(progress + 10);setLoading(false)}
                        else if(progress === 60) {setProgress(progress + 10);setLoading(false)}
                        else if(progress === 70) {setProgress(progress + 5);setLoading(false)}
                        else if(progress < 50 && progress > 10) {setProgress(progress + 25);setLoading(false)}
                        else if(progress === 10) {setProgress(25);setLoading(false)}
                        else if(progress === 75 && !tp_order) {setProgress(progress + 25);setLoading(false)}
                        else if(!isEditing){
                            API.post('/order/createorder', {id_customer:currentUser.id === undefined ? currentUser._id : currentUser.id, id_documenttype, nm_documentcountry, nm_documentlocation, nm_otherdocument, nm_ancestor, ds_gender, ic_digitaldocument, ic_physicaldocument, ic_stampeddocument, nm_ancestoralternative, family_members, life_event_dates, ds_comments, tp_ordervisibility, tp_order, tp_orderexperience, ds_familysearchprofile, allowUsers, ic_searchdocument, ic_fetchdocument})
                                .then((result) => {
                                    if (result.data.status === "success") {
                                        toast.success('Você criou o pedido com sucesso!')
                                        setLoadingOpen(false)
                                        dispatch(setCurrentOrder(result.data.newOrder))
                                        navigate('/boostorder'); 
                                        setLoading(false)
                                    }
                                })
                                .catch((err) => {
                                        toast.error(JSON.parse(err.request.response).message)
                                        setLoadingOpen(false)
                                        setLoading(false)
                                });  
                        }
                        else if(isEditing){
                            API.post('/order/editsingleorder', {id_customer:tp_order?null: currentUser._id? currentUser._id : currentUser.id, id_documenttype, nm_documentcountry, nm_documentlocation, nm_otherdocument, nm_ancestor, ds_gender, ic_digitaldocument, ic_physicaldocument, ic_stampeddocument, nm_ancestoralternative, family_members, life_event_dates, ds_comments, tp_ordervisibility, tp_order, tp_orderexperience, ds_familysearchprofile, allowUsers, orderid})
                                .then((result) => {
                                    if (result.data.status === "success") {
                                        setLoadingOpen(false)
                                        dispatch(setCurrentOrder(result.data.singleOrder))
                                        setLoading(false)
                                        if (!tp_order) {
                                            navigate('/boostorder'); 
                                        }
                                        else{
                                            navigate(`/orderdetail_researcher/${orderid}`); 
                                        }
                                        toast.success('Você editou esse pedido!')
                                    }
                                })
                                .catch((err) => {
                                        toast.error(`Erro ao editar. Contate o suporte`)
                                        setLoadingOpen(false)
                                        setLoading(false)
                                        console.log(err);
                                });  
                        }
                        
                    }}
                    disabled={ 
                        progress === 10 ? (!ic_fetchdocument && !ic_searchdocument ):
                        progress === 25 ? (!nm_documentcountry || !id_documenttype || !ic_digitaldocument || !nm_documentlocation || (!isEditing && !locationSelected ) || (needOtherType && !otherDocOK)):
                        (progress === 50 ? (!ds_gender || !nm_ancestor || !/[a-zA-Z]/.test(nm_ancestor)) : false) 
                            
                    }
                        sx={{
                        '&:disabled' : {
                            backgroundColor: '#C6C6C6',
                            cursor: 'not-allowed'
                        }
                    }}>
                Próximo
                </HeaderBTNStyle>
            </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
                open={loadingOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
       </RootStyle>
    )
}