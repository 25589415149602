/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import {
    Box,
} from '@mui/material';
import { Markup } from 'interweave';
import DiamondIcon from '../../assets/icons/diamondicon.svg'
import DiamondIcon1 from '../../assets/icons/diamondicon1.svg'
import CheckImg from '../../assets/image/check.svg'
import AssessorImg from '../../assets/image/upgrade_plan1.png'

import { useSelector } from 'react-redux';
const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#BA483A',
    fontSize: '14px',
    width: '162px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px'
}));

function statement(props) {
    return(
        <div className='flex items-center mb-[10px]  h-[20px]'>
            {props.text ? <Box component={'img'} src={CheckImg} className='w-[10px] h-[10px]'/> : ""}
            <div className="ml-[18px] text-[14px] font-[Poppins] whitespace-nowrap"><Markup content={props.text} /></div>
            {props.withDiamond? <Box component={'img'} src={props.flag ? DiamondIcon1 : DiamondIcon} className=' w-[18px] h-[18px]'/> : ""}
        </div>
    );
}

export default (props) => {
    const data = props.data;
    const withDiamond = props.withDiamond;

    const { currentUser } = useSelector(state => state.auth);


    return (
        <div style={{display:'flex', flexDirection:'column', width:'100%',}}>
            <div className={currentUser.ic_currentplan >= props.data.ic_plan || props.data.ic_plan== 0 ? "opacity-30" : "opacity-100"}
                style={{display:'flex',flexDirection:'column', margin:'0px auto auto auto', justifyContent:'center', alignItems:'center', height:'150px', }}
            >
                <div className="text-[16px] font-[Poppins] text-[#313131] text-center mt-[10px]" style={{ top:'0px' }}>{currentUser.ic_currentplan == props.data.ic_plan?`${data.name} - atual`:`${data.name}`}</div>
                

                {data && data.oldprice && data.price && data.oldprice != data.price && data.oldprice>0 &&
                    <div style={{display:'flex', margin:'auto', justifyContent:'center',    fontFamily: 'Poppins', fontWeight: 400, color: '#C6C6C6',fontSize: '16px',paddingTop: '5px',}}><del>{data.oldprice?`R$${data.oldprice/12}` : "R$-"}</del></div>
                }
                {data && data.price && Number(data.price)>0 ?
                    <div style={{display:'flex', flexDirection:'row', margin:'auto', justifyContent:'center', alignItems:'baseline'}}  >
                        <div style={{fontFamily: 'Poppins',fontWeight: 500, color: '#313131',fontSize: '30px',whiteSpace: 'nowrap',}}>R$ {data.price/12}</div>
                        <div style={{display: 'flex', flexDirection: 'column',alignItems: 'center',}}>/Mês</div>
                    </div>
                    :
                    <></>
                }
                
                <div className='mt-[0px] flex items-center justify-center h-[39px] mb-[5px]' style={{margin:'auto',  }}>
                    {
                        data && data.oldprice && data.price && data.oldprice != data.price && data.oldprice>0 &&  <span className="line-through text-[12px] font-[Poppins] text-[#C6C6C6]">R${props.formatThousand(data.oldprice)||''}</span>
                    }

                    <span className={`font-[Poppins] text-[#313131] ${!data.price ? 'text-[30px]' : 'text-[15px]'}`} >&nbsp;R$ {data.price&&data.price>0?props.formatThousand(data.price):'0'}</span>
                    
                </div>
            </div>
                <div style={{display:'flex',flexDirection:'column', margin:'auto',  height:'250px', }}
                className={currentUser.ic_currentplan >= props.data.ic_plan || props.data.ic_plan== 0 ? "opacity-30" : "opacity-100"}
                >
                    {data.text.map((item, index) => {
                        if(index != 6)   return statement({text: item});
                        else return statement({text: item, withDiamond, flag: currentUser.ic_currentplan >= props.data.ic_plan || props.data.ic_plan== 0});
                    })}
                </div>
            {/* <div className='flex items-center justify-center mb:mb-[30px] xl:mb-[10px]'> */}
                <HeaderBTNStyle onClick={() => {
                    props.setcheckoutData({  
                        bidname:props.data.name,
                        bidImage:AssessorImg,
                        normalprice:props.data.oldprice,
                        promoprice:props.data.price,
                        moreinfo:data.text,
                        ic_currentplan:props.data.ic_plan,
                        id_plan:props.data._id,
                    }); 
                    props.setcheckout(true)}
                    } 
                    disabled={currentUser.ic_currentplan >= props.data.ic_plan || props.data.ic_plan== 0} 
                    sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'},display:'flex', bottom:'0', marginLeft:'auto',marginRight:'auto', justifyContent:'center', alignItems:'center' }}>Assinar</HeaderBTNStyle>
            {/* </div> */}

        </div>
    )
}