import React, {useState, useLayoutEffect, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toast';
import { API_BASE } from '../../config/constants';
import API from '../../redux/API';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';



import { styled } from '@mui/material/styles';

import Logo from "../../assets/image/logo.svg"
import UserIcon from "../../assets/icons/usericon.svg"
import { CheckBox } from '@mui/icons-material';

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    margin:'20px auto 20px auto',
    display:'flex',
    flexDirection:'column',
    justifyItems:'center',
    justifyContent:'center',


}));

const SubHeader = styled('div')(({ theme }) => ({
    width:'70%',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    textAlign:'center',
    margin:'20px auto 20px auto',


}));

const Title = styled('div')(({ theme }) => ({
    // margin:'20px auto 20px auto',
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '32px',
    display: 'flex',
    alignItems: 'center',
    alignContent:'center',
    justifyContent:'center',
    justifyItems:'center',
    textAlign: 'center',

    color: '#313131',
}));
const Subtitle = styled('div')(({ theme }) => ({
    margin:'20px auto 20px auto',
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    alignContent:'center',
    justifyContent:'center',
    justifyItems:'center',
    textAlign: 'center',
    color: '#313131',
}));

const DivButton = styled('div')(({ theme }) => ({
    width:'70%',
    display:'flex',
    // justifyContent:'left',
    textAlign:'center',
    margin:'20px auto 20px auto',
    gap:'20px',

    }));
    
const ButtonGreen = styled('div')(({ theme }) => ({

width:'315px',
height:'60px',
display:'flex',
background: '#17686E',
borderRadius: '5px',
color:'#fff',
// padding:'10px',
alignItems:'center',
justifyItems:'center',
justifyContent:'center',
fontSize:'18px',
fontWeight:'600',
cursor:'pointer',
}));
const ButtonWhite = styled('div')(({ theme }) => ({
width:'515px',
height:'60px',
display:'flex',
background: '#fff',
borderColor:'#17686E',
border:'1px solid',
color:'#17686E',
borderRadius: '5px',
alignItems:'center',
justifyItems:'center',
justifyContent:'center',
fontSize:'18px',
fontWeight:'600',
cursor:'pointer',
}));

const DivLogo = styled('div')(({ theme }) => ({
    width:'304px',
    height:'54.46px',
    margin:'20px auto 20px auto'

}));

const FirstPartPage1 = styled('div')(({ theme }) => ({
    width:'40%',
    margin:'0px auto 0px auto',
    display:'flex',
    flexDirection:'column',
    gap:'10px',
    

}));
const Title2 = styled('div')(({ theme }) => ({
    fontWeight:'600',
    fontSize:'20px',
    color:'#313131',
    textAlign:'justify',

}));
const Subtitle2 = styled('div')(({ theme }) => ({
    fontWeight:'300',
    fontSize:'14px',
    color:'#464A53',
    textAlign:'justify',
}));
const EmailSpace = styled('div')(({ theme }) => ({
    width:'258px',
    height:'29px',
    fontWeight:'300',
    fontSize:'14px',
    border:'1px solid',
    borderColor:'#C6C6C6',
    borderRadius:'15px',
    display:'flex',
    gap:'10px',
    alignContent:'center',
    alignItems:'center',
    padding:'10px',
    overflow:'hidden',
    margin:'10px auto 50px auto',

}));
const EmailText = styled('div')(({ theme }) => ({
    fontWeight:'400',
    fontSize:'12px',

}));

const ButtonUpdateGrey = styled('div')(({ theme }) => ({
    width:'180px',
    height:'40px',
    display:'flex',
    background: '#c6c6c6',
    borderColor:'#c6c6c6',
    border:'1px solid',
    color:'#fff',
    borderRadius: '5px',
    alignItems:'center',
    justifyItems:'center',
    justifyContent:'center',
    fontSize:'18px',
    fontWeight:'600',
    cursor:'pointer',
    margin:'20px auto 20px auto'
    }));
    


const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    margin:'0px 10px 0px 0px',
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));
  
  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#C6C6C6',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: '150%',
      height: '150%',
      backgroundImage: 'radial-gradient(#17686E,#17686E 28%,transparent 32%)',
      content: '""',
      margin:'-25% 0px 0px -25%'
    },
  });
    
// Inspired by blueprintjs
function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }
  


 




export default function UnsubscriptionPage(){
    const {currentUser} = useSelector(state => state.auth);
    const { currentProfile } = useSelector(state => state.auth);

    const navigate=useNavigate()
    const isMobile = window.innerWidth < 700;



    useEffect(()=>{
        let timeoutId = null;

        if(currentUser){
            const emailaux = currentUser.ds_email
            setEmail(emailaux)
        }
        else {
            timeoutId = setTimeout(() => {
                toast.error('Faça o login antes de se desinscrever');
            }, 0);
            navigate('/login')
        }
        
    },[currentUser]) 

    const [page,setPage] = useState(0)
    const [email,setEmail] = useState('')
    
    
    // const reason = ['Não quero mais receber estes e-mails','Estou recebendo muitos e-mails desta lista','Eu nunca me inscrevi para esta lista de e-mails','A pessoa mencionada não sou eu','Não tive boas experiências com a plataforma','Outro motivo']
    const [reason,setReason] = useState({})

    useEffect(()=>{
        API.post('user/getallunsubscribeid')
        .then((res)=>{
            if(res.data.status==='success'){
                setReason(res.data.allUnsubscribe)
            }
        })
        .catch((err)=>{
            toast.error('erro ao atualizar')
            console.log(err)
        })
    },[])

    const [selectedReason,setSelectedReason]=useState('')
    const [otherReason,setOtherReason]=useState('')
    const [reasonOK,setreasonOK]=useState(false)
    
    const page1Reasons = ['Quero receber e-mails na frequência padrão','Quero me descadastrar e não receber mais emails da plataforma']
    const [page1Reason,setpage1Reason]=useState(0)
    const [page1canUpdate,setpage1canUpdate]=useState(false)
    
    const [page2canUpdate,setpage2canUpdate]=useState(false)


    useEffect(()=>{
        if(page1Reason===0){
            setpage1canUpdate(true)
        }
        else if(page1Reason===1){
            setpage1canUpdate(true)
        }
        else{
            setpage1canUpdate(false)
        }
    },[page1Reason])

    const handleUpdatePage1 = ()=>{
        if(page1Reason===0){
            // save back as default
            API.post('user/resetnotifications',{ds_email:email})
            .then((res)=>{
                if(res.data.status==='success'){
                    toast.success(res.data.message)
                    setPage(4)
                }
            })
            .catch((err)=>{
                toast.error('erro ao atualizar')
                console.log(err)
            })
        }
        else if(page1Reason===1){
            setPage(2)
        }
    }
    const handlePage2SelectReason = (e)=>{
        const thisValue = e.target.value
        var isOtherReason = false
        const checkReason = reason.map((single) => {
            if (single.ic_otherreason) {
                const thisID = single._id ? single._id : single.id
              if (thisID === thisValue) {
                isOtherReason = true;
              }
            }
        });
          
        if(!isOtherReason){
            setSelectedReason(thisValue)
            setpage2canUpdate(true)
        }
        else if(isOtherReason){
            setSelectedReason(thisValue)
            setpage2canUpdate(false)
        }
        else{
            setpage2canUpdate(false)
        }
    }
    const handlePage2OtherReason = (e)=>{
        const thisValue = e.target.value
        setOtherReason(thisValue)

        if(thisValue.length>=10){
            setpage2canUpdate(true)
        }
        else{
            setpage2canUpdate(false)
        }
    }
    const handleUpdatePage2 = ()=>{
        const reasonsToSend = {
            id_unsubscription:selectedReason,
            ds_comments:otherReason,
            ds_email:email,
        }
        API.post('user/unsubscribe', reasonsToSend )
        .then((res)=>{
            if(res.data.status==='success'){
                toast.success(res.data.message)
                setPage(3)
            }
        })
        .catch((err)=>{
            toast.error('erro ao atualizar. Contate o suporte')
            console.log(err)
        })
    }





    return(
        <RootStyle style={isMobile?{width:'100%'}:{}}>
            {page===0 &&
                <>
                    <SubHeader style={isMobile?{width:'90%'}:{}}>
                        <Title>
                            Gostaríamos de manter contato
                        </Title>

                        <Subtitle>
                            Respeitamos a sua privacidade e você está no controle. Cancele a inscrição de emails de marketing ou gerencie suas preferências de notificação.
                        </Subtitle>
                    </SubHeader>
                    <DivButton style={isMobile?{width:'50%',flexDirection:'column'}:{}}>
                                <ButtonGreen onClick={()=>{setPage(1)}} style={isMobile?{width:'90%', fontSize:'12px', padding:'10px'}:{}}>Cancelar Inscrição</ButtonGreen>
                                <ButtonWhite onClick={()=>{
                                    navigate('/account', { state: { initial: 3 } })
                                    }} 
                                    style={isMobile?{width:'90%',  fontSize:'12px', padding:'10px'}:{}}
                                >Gerenciar todas as preferências de notificação</ButtonWhite>
                    </DivButton>
                </>
            }
            {page===1 &&
            
                <>
                    <DivLogo style={isMobile?{width:'60%'}:{}}>
                        <img src={Logo} width={'100%'}/>
                    </DivLogo>
                    <FirstPartPage1 style={isMobile?{width:'90%'}:{}}>
                        <Title2>Que pena!</Title2>
                        <Subtitle2>Nossa intenção sempre foi lhe oferecer a melhor experiência possivel. Ao se descadastrar, você deixará de receber e-mails.</Subtitle2>
                        <EmailSpace style={isMobile?{width:'80%'}:{}}>
                            <><img src={UserIcon} /></>
                            <EmailText>
                                {email}
                            </EmailText>
                        </EmailSpace>
                    </FirstPartPage1>
                    <FirstPartPage1 style={isMobile?{width:'90%'}:{}}>
                        <Title2>Você pode nos contar o motivo do cancelamento de sua inscrição?</Title2>
                        <Subtitle2>Sua colaboração é fundamental para que possamos oferecer sempre o melhor serviço e ter a felicidade de tê-lo de volta conosco.</Subtitle2>
                        <></>

                        <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            onChange={(e)=>{setpage1Reason(Number(e.target.value))}}
                            
                        >
                            {
                                page1Reasons.map((single,index)=>{
                                    return <FormControlLabel value={index} control={<BpRadio />} label={single} checked={page1Reason===index} />
                                })
                            }
                        </RadioGroup>
                        </FormControl>
                        <ButtonUpdateGrey 
                            onClick={()=>{if(page1canUpdate){handleUpdatePage1()}}}
                            sx={page1canUpdate&&{backgroundColor:'#17686E'}}
                        >Atualizar</ButtonUpdateGrey>
                    </FirstPartPage1>
            
                </>
            }
            {page===2 &&
            
                <>
                    <DivLogo style={isMobile?{width:'60%'}:{}} >
                        <img src={Logo} width={'100%'}/>
                    </DivLogo>
                    <FirstPartPage1 style={isMobile?{width:'90%'}:{}}>
                        <Title2>Que pena!</Title2>
                        <Subtitle2>Nossa intenção sempre foi lhe oferecer a melhor experiência possivel. Ao se descadastrar, você deixará de receber e-mails.</Subtitle2>
                        <Subtitle2>Ao se descadastrar, você deixará de receber e-mails.</Subtitle2>
                        <EmailSpace style={isMobile?{width:'80%'}:{}}>
                            <><img src={UserIcon} /></>
                            <EmailText>
                                {email}
                            </EmailText>
                        </EmailSpace>
                    </FirstPartPage1>
                    <FirstPartPage1 style={isMobile?{width:'90%'}:{}}>
                        <Title2>Você pode nos contar o motivo do cancelamento de sua inscrição?</Title2>
                        <Subtitle2>Sua colaboração é fundamental para que possamos oferecer sempre o melhor serviço e ter a felicidade de tê-lo de volta conosco.</Subtitle2>
                        <></>

                        <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            onChange={handlePage2SelectReason}
                        >
                            {reason && reason.map((single,index)=>{
                                return <FormControlLabel value={single._id?single._id:single.id?single.id:''} control={<BpRadio />} label={single.nm_subscription} />
                            })}
                        </RadioGroup>
                        </FormControl>
                        {reason && reason.map((single)=>{
                            if(single.ic_otherreason){
                                let thisID = single._id ? single._id : single.id
                                if(thisID ===selectedReason){
                                    return(
                                    <Box
                                    component="form"
                                    sx={{
                                      '& > :not(style)': { m: 1, width: '440px', height:'105px' },
                                      marginBottom:'20px'
                                    }}
                                    noValidate
                                    autoComplete="off"
                                  >
                                    <TextField 
                                        id="outlined-multiline-static"
                                        label="Qual motivo?"
                                        multiline
                                        rows={4}
                                        value={otherReason}
                                        onChange={handlePage2OtherReason}
                                        error={otherReason.length>0 && otherReason.length<10}
                                        helperText={otherReason.length>0 && otherReason.length<10 &&"Por favor, nos conte um pouco mais"}
                                    />
                                  </Box>
                                    )
                                }
                            }
                        })}
                        <ButtonUpdateGrey sx={page2canUpdate&&{backgroundColor:'#17686E', borderColor:'#17686E'}} onClick={()=>{if(page2canUpdate){handleUpdatePage2()}}}>Atualizar</ButtonUpdateGrey>
                    </FirstPartPage1>
            
                </>
            }
            
            {page===3 &&
            
                <>
                    <DivLogo style={isMobile?{width:'60%'}:{}} >
                        <img src={Logo} width={'100%'}/>
                    </DivLogo>
                    <FirstPartPage1 style={isMobile?{width:'90%'}:{}}>
                        <Title2>Que pena!</Title2>
                        <Subtitle2>Nossa intenção sempre foi lhe oferecer a melhor experiência possivel. Ao se descadastrar, você deixará de receber e-mails.</Subtitle2>
                        <Subtitle2>Ao se descadastrar, você deixará de receber e-mails.</Subtitle2>
                        <EmailSpace style={isMobile?{width:'80%'}:{}}>
                            <><img src={UserIcon} /></>
                            <EmailText>
                                {email}
                            </EmailText>
                        </EmailSpace>
                    </FirstPartPage1>
                    <FirstPartPage1 style={isMobile?{width:'90%'}:{}}>
                        <Subtitle2 style={{marginBottom:'10px'}}>Lamentamos que você tenha optado por cancelar as notificações por e-mail, mas entendemos e respeitamos sua decisão. Sua solicitação foi atendida e você não receberá mais mensagens da nossa empresa.</Subtitle2>

                        <Subtitle2 style={{marginBottom:'10px'}}>Nós valorizamos sua privacidade e agradecemos a confiança que você depositou em nós. Continuaremos a trabalhar para fornecer as melhores informações e benefícios, para que você possa desfrutar da nossa plataforma com tranquilidade.</Subtitle2>

                        <Subtitle2 style={{marginBottom:'50px'}}>Obrigado por nos informar sobre suas preferências..</Subtitle2>
                    </FirstPartPage1>
            
                </>
            }
            {page===4 &&
            
                <>
                    <DivLogo style={isMobile?{width:'60%'}:{}} >
                        <img src={Logo} width={'100%'}/>
                    </DivLogo>
                    <FirstPartPage1 style={isMobile?{width:'90%'}:{}}>
                        <Title2>Que ótima notícia!</Title2>
                        <Subtitle2>Obrigado por continuar a fazer parte da nossa plataforma!</Subtitle2>
                        <EmailSpace style={isMobile?{width:'80%'}:{}}>
                            <><img src={UserIcon} /></>
                            <EmailText>
                                {email}
                            </EmailText>
                        </EmailSpace>
                    </FirstPartPage1>
                    <FirstPartPage1 style={isMobile?{width:'90%'}:{}}>
                        <Subtitle2 style={{marginBottom:'10px'}}>Ficamos felizes em saber que você deseja continuar recebendo nossas notificações por e-mail.</Subtitle2>
                        <Subtitle2 style={{marginBottom:'10px'}}>Isso nos permite compartilhar com você as últimas novidades e promoções exclusivas da nossa plataforma. Saiba que sua privacidade é importante para nós e estamos comprometidos em manter suas informações seguras</Subtitle2>
                        <Subtitle2 style={{marginBottom:'50px'}}>Estamos sempre à disposição para ajudá-lo em qualquer dúvida ou necessidade. Novamente, obrigado por confiar em nós.</Subtitle2>
                    </FirstPartPage1>
            
                </>
            }

        </RootStyle>
    )
}