import { styled } from '@mui/material/styles';
import useWindowSize from '../../hooks/useWindowSize'
import {
    Stack,
    Box,
} from '@mui/material';
import CardItem from '../../components/card/SmallCard/ResHeader';
import home_top1 from '../../assets/image/researcher_top1.png'
import home_top2 from '../../assets/image/researcher_top2.png';
import home_top3 from '../../assets/image/researcher_top3.png';

// -------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('1200')]: {
        display: 'grid',
        gridTemplateColumns: '100% 638px'
    }
}));

const Box_left = styled('div')(({ theme }) => ({
    width: '100%',
    zIndex: 1,
    position: 'relative',
    // borderRight: '10px red dashed ',
    [theme.breakpoints.down('sm')]: {
        marginTop: '139px',
    },
}));

const Box_right = styled('div')(({ theme }) => ({
    width: '100%',
    position: 'absolute',
    height: '100%',
    maxHeight: '759px',
    top: 0,
    right: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionX: 'right',
    backgroundPosition: '50% 50%',
    backgroundImage: `url(${home_top1})`,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
        display: 'block',
        backgroundImage: `url(${home_top3})`, 
    },
    [theme.breakpoints.up('lg')]: {
        width: '532px',
    },
    [theme.breakpoints.up('xl')]: {
        width: '51%',
    }
}));

const Text_style_1 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '25px',
    fontWeight: 275,
    lineHeight: '100%',
    color: '#313131',
    [theme.breakpoints.up('sm')]: {
        fontSize: '50px',
        textShadow: '0px 0px 2px white',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '75px',
    }
}));

const Text_style_2 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.up('sm')]: {
        //color: '#FFFF',
        textShadow: '0px 0px 2px white',
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: '477px',
    }
}));

const Box_header_text = styled('div')(({ theme }) => ({
    padding: '17px 0px 380px 18px',
    marginLeft: '5px',
    [theme.breakpoints.down('sm')]: {
        backgroundColor: 'white',
    },
    [theme.breakpoints.up('sm')]: {
        marginLeft: '0px',
        padding: '49px 0px 380px 60px',
    },
    [theme.breakpoints.up('lg')]: {
        padding: '24px 0px 380px 125px',
    }
}));

const Card_wrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    padding: '30px 20px',
    left: '5px',
    [theme.breakpoints.down('sm')]: {
        marginTop: '8%',
    },
    [theme.breakpoints.up('sm')]: {
        padding: '50px 20px',
        left: '40px',
    },
    [theme.breakpoints.up('md')]: {
        padding: '23px 20px',
        left: '45px',
    },
    [theme.breakpoints.up('lg')]: {
        left: '105px',
    }
}));

const Effect_line = styled('div')(({ theme }) => ({
    position: 'absolute',
    zIndex: 1,
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '1px',
        top: '131px',
        borderTop: 'dashed 10px white',
        borderRight: 'none',
    },
    [theme.breakpoints.up('lg')]: {
        borderTop: 'none',
        width: '1px',
        height: '100%',
        float: 'left',
        borderRight: 'dashed 10px white',
    },
    [theme.breakpoints.up('xl')]: {
        borderTop: 'none',
        width: '1px',
        height: '100%',
        float: 'left',
        borderRight: 'dashed 10px white',
    }
}));

const Effect_line_mobile = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        zIndex: 1,
        boxSizing: 'border-box',
        width: '100%',
        height: '1px',
        top: '131px',
        borderTop: 'dashed 10px white',
        borderRight: 'none',
    },
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    }
}));

////data_card

const card_info = [
    {
        header: 'Temos a plataforma para você trabalhar e se tornar um pesquisador, grátis.',
        content: 'Você pode se inscrever grátis e se tornar um pesquisador, ajudando nossos clientes a encontrarem seus documentos.',
        button_text: 'Seja pesquisador',
    },
]

export default function Slide_part() {
    const { width } = useWindowSize()

    return (
        <RootStyle>
            <Effect_line_mobile />
            {width < 600 && <Box component='img' src={home_top2} width={'100%'} height={'167px'} sx={{ position: 'absolute' }} />}
            {width < 600 && <Box sx={{ backgroundColor: '#BA483A', width: '32px', height: '32px', position: 'absolute', top: '139px' }} />}
            
            <Box_left>
                <Box_header_text>
                    <Text_style_1 >Se torne um</Text_style_1>
                    <Text_style_1 sx={{ color: '#17686E', fontWeight: 700, width: '470px', mt: '20px' }}>Pesquisador de documentos</Text_style_1>
                    <Text_style_2 sx={{ mt: '19px', width: '100%', }}>Faça parte da nossa comunidade de pesquisadores e auxilie nossos clientes na obtenção de documentos importantes de que precisem - e seja recompensado por sua contribuição!</Text_style_2>

                    {width < 600 && <Box sx={{ backgroundColor: '#17686E', width: '35px', height: '25px', position: 'absolute', top: '45%', left: '13px' }}></Box>}
                    <Card_wrapper>
                        <Stack sx={{ flexDirection: 'row' }}>
                            {card_info.map((card, index) => (
                                <CardItem key={index} card={card} />
                            ))}
                        </Stack>
                    </Card_wrapper>
                </Box_header_text>

            </Box_left>
            <Box_right>
            <Effect_line></Effect_line>
            </Box_right>
            {width > 1200 && <Box sx={{ backgroundColor: '#BA483A', width: '32px', height: '32px', position: 'absolute', top: '17px', zIndex: -1 }}></Box>}
            {width > 1200 && <Box sx={{ backgroundColor: '#17686E', width: '35px', height: '70px', position: 'absolute', top: '312px', zIndex: -1 }}></Box>}
            {width > 1200 && <Box sx={{ backgroundColor: '#BA483A', width: '30px', height: '32px', position: 'absolute', top: '726px', zIndex: -1 }}></Box>}
            {width > 1200 && <Box sx={{ backgroundColor: '#BA483A', width: '24px', height: '25px', position: 'absolute', top: '437px', left: '722px', zIndex: -1 }}></Box>}
            {width > 1200 && <Box sx={{ backgroundColor: '#BA483A', width: '31px', height: '32px', position: 'absolute', top: '726px', left: '593px', zIndex: -1 }}></Box>}
        </RootStyle>
    )
}