import { styled } from '@mui/material/styles';
import Card_1 from '../../components/card/PlanCards2/card1.js';
import Card_2 from '../../components/card/PlanCards2/card2.js';
import Card_3 from '../../components/card/PlanCards2/card3.js';

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
}));

const Text_style_1 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 400,
    color: '#313131',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
        fontSize: '22px',
    },
}));

const Text_style_2 = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    color: '#313131',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
        fontSize: '18px',
    },
}));


const Text_header = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '33px 20px 60px',
    [theme.breakpoints.up('md')]: {
        padding: '30px 40px 84px',
    },
}));


const Card_wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: '0px 7% 60px 7%',
}));

const Card_inner = styled('div')(({ theme }) => ({
    padding: '20px',
    [theme.breakpoints.up('900')]: {
    },
}));

const Text_span = styled('span')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 400,
    color: '#17686E',
    [theme.breakpoints.up('md')]: {
        fontSize: '22px',
    },
}));

export default function Body() {

    return (
        <RootStyle>
            <Text_header>
                    <Text_style_1>Seu plano não é suficiente para enviar uma proposta. O nível exigido é 
                        <Text_span>&nbsp;Assessor.</Text_span></Text_style_1>
                <Text_style_2>Faça um upgrade no seu plano para liberar propostas deste nível.</Text_style_2>
            </Text_header>

            <Card_wrapper>
                <Card_inner>
                    <Card_1 />
                </Card_inner>
                <Card_inner>
                    <Card_2 />
                </Card_inner>
                <Card_inner>
                    <Card_3 />
                </Card_inner>
            </Card_wrapper>
        </RootStyle >
    )
}