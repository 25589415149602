// Importa as funções combineReducers e configureStore da biblioteca @reduxjs/toolkit
import { combineReducers, configureStore } from "@reduxjs/toolkit";

// Importa os reducers update.reducer, profile.reducer, auth.reducer, order.reducer e notification.reducer
import updateReducer from './reducers/update.reducer';
import profileReducer from './reducers/profile.reducer'
import { composeWithDevTools } from "redux-devtools-extension";
import authReducer from './reducers/auth.reducer';
import orderReducer from './reducers/order.reducer'
import notificationReducer from './reducers/notification.reducer'

// Combina os reducers em um único objeto rootReducer
const rootReducer = combineReducers({
    updateState: updateReducer,
    profile: profileReducer,
    auth: authReducer,
    order: orderReducer,
    notification: notificationReducer
})

// Configura e exporta a store do Redux com o rootReducer e as ferramentas de desenvolvimento do Redux
export default configureStore({
    reducer: rootReducer
}, composeWithDevTools);