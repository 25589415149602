import React, { useEffect, useState, useRef  } from "react";
import { toast } from "react-toast";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import './Stripe.css'

const buttonSpanStyles = {
  display:'flex',
  gap:'10px',
  fontWeight: 'bold',
  color: '#fff',
};



export default function CheckoutForm(props) {

  const stripe = useStripe()
  const elements = useElements()

  useEffect(()=>{
    props.setstripe(stripe)
    props.setelements(elements)
  },[stripe,elements])




  const paymentElementOptions = {
    layout: "tabs", 
    theme: 'flat'

  }



  useEffect(()=>{
    if (props.isLoading || !stripe || !elements) {
      return props.setcanclick(false)
    }
    return props.setcanclick(true)
  },[props.isLoading , stripe,  elements])


  function formatPriceByLocation(location, price) {
    const locationToFormatMap = {
      'Brazil': 'pt-BR',
      'France': 'fr-FR',
      'United States': 'en-US',
      // Add more location-format pairs as needed
    };
  
    const format = locationToFormatMap[location];
  
    if (format) {
      const formattedPrice = price.toLocaleString(format, { style: 'currency', currency: 'BRL' });
      return `${formattedPrice}`;
    } else {
      return 'Formatting information not available for this location.';
    }
  }
  
  return (
    <form id="payment-form" onSubmit={props.handleSubmit} >
      <PaymentElement id="payment-element" options={paymentElementOptions} />

      <button style={
              props.isMobile?
              {
                display:'flex',
                flexDirection:'column',
                width:'100%',
                maxHeight: '100px',
                  // maxWidth: '250px',
                  margin:'20px auto 10px auto',
              alignContent:'center',
                alignItems:'center',
                justifyContent:'center',
                justifyItems:'center',
                padding:'10px 15px 10px 15px',

                backgroundColor: !props.canclick?'grey':'#17686E',
                borderRadius: '5px',
                border: 'none',
                textTransform: 'uppercase',
                // letterSpacing: '.5px',
                fontSize: '10pt',
                color: !props.canclick?'black':'#fff',
                cursor: 'pointer',

              }
              :
              {
                  display:'flex',
                  width:'100%',
                  maxHeight: '100px',
                  // maxWidth: '250px',
                  margin:'10px auto 10px auto',
                  alignContent:'center',
                  alignItems:'center',
                  justifyContent:'center',
                  justifyItems:'center',
                  gap:'10px',
                  padding:'5px 10px 5px 10px',

                  backgroundColor: !props.canclick?'grey':'#17686E',
                  borderRadius: '5px',
                  border: 'none',
                  textTransform: 'uppercase',
                  // letterSpacing: '.5px',
                  fontSize: '12pt',
                  color: !props.canclick?'black':'#fff',
                  cursor: 'pointer',
                  // transition: 'all 1s ease-out',
              
            }
            }  
            disabled={props.isLoading || !stripe || !elements}
              id="submit"
            >
                  {props.isLoading || !props.cardinfo.promoprice ?
                    <><div className="custom-spinner"><div className="spinner-inner" /></div><div style={buttonSpanStyles}>Aguarde</div></>
                    : 
                      <div style={buttonSpanStyles}>Pagar {props.formatpricetoshow(props.priceafterpromocode())}</div>
                  }
                  
            </button>


    </form>
  );
}