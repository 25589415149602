import React, {useState, useEffect, useLayoutEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useWindowSize from '../../hooks/useWindowSize'
import PartyPopperIcon from '../../assets/icons/party-popper.gif'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { Box, TextField,InputAdornment, TextareaAutosize} from '@mui/material';
import SecurityIcon from '../../assets/icons/securityicon1.svg';
import PasswordIcon from '../../assets/icons/passwordicon1.svg';
import Tooltip from '@mui/material/Tooltip';
import InformationIcon from '../../assets/icons/informationicon.svg';
import WarningIcon from '../../assets/icons/Warning.svg'
import SuccessIcon from '../../assets/icons/successicon.gif'

import API from '../../redux/API';
import { toast } from 'react-toast';

import './style.css'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, padding: '0px 43px' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const HeaderBTNStyle = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    borderRadius: '5px',
    backgroundColor: '#17686E',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
}));

const HeaderBTNStyle1 = styled('button')(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#17686E',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '180px',
    height: '40px',
    textAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: '8px',
    marginTop: '11px',
    lineHeight: '21px',
    border: '1px solid #17686E'
}));


const HeaderText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 500,
    color: '#313131',
    textAlign: 'center',
    lineHeight: '100%',
    [theme.breakpoints.up('md')]: {
        textAlign: 'left'
    },
}));



const GridWrapper = styled('div')(({ theme }) => ({
    padding: '40px 0px 10px 0px',
    [theme.breakpoints.up('md')]: {
        padding: '15px 3px 10px 3px'
    },
}));

export default function Modal({ openModal, setOpenModal, confirmFunc, pageNum, orderID, researcherID, isDigital, isPhysical, isStamped, isValidatedDIGITAL, }) {

    const { width } = useWindowSize();
    const style = {
        content: "!w-[80%] !font-[Poppins] !font-normal !text-[14px] !leading-[24px] !text-[#313131] !h-[40px] !rounded-[7px]",
    }
    const dispatch = useDispatch();
    const [initialPage, setInitialPage] = useState(pageNum);
    const handleClose = () => {
        setOpenModal(false);
    };
    const handlePreviousPage = () => {
        if(page !== 1){
            setPage(page-1);
        }
        else{
            setOpenModal(false);
        }
    }



    const handleNextPage = () => {

        if(page === 1){




            // setOpenModal(false);
            confirmFunc();
            setPage(page + 1)

        }
        else if(page === 2){
            window.location.reload()
        }
        else{
            setPage(page + 1)
        }
    }




    useLayoutEffect(() => {
        setInitialPage(pageNum);
        setPage(pageNum);
      }, [openModal]);
    const [page, setPage] = useState(openModal);
    
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openModal} sx={{
                    '.MuiPaper-root': {
                        maxWidth: '837px',
                        width: '100%',
                    }
                }}
            >
                <div className='min-h-[350px]'>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
                
                
                {
                    page === 1 &&
                    <DialogContent className='mb:mt-[50px] md:mt-[118px] !overflow-unset '>
                        <div className='flex flex-col items-center'>
                            <div className='w-[90%] min-h-[120px] flex md:flex-row mb:flex-col mb:items-center md:items-start'>
                                <div className="mb:mr-[0px] md:mr-[25px] !w-[117px]">
                                    <Box component="img" src={WarningIcon} alt="alerta-icone" className='w-[117px] h-[117px]'/>
                                </div>
                                <div className='flex flex-col justify-center w-[90%]'>
                                    <div className='font-[Poppins] font-medium mb-text-[18px] md:text-[22px] text-[#313131] leading-[100%] mb:text-center md:text-left'>
                                    Enviar para validação do cliente
                                    </div>
                                    <div className='w-[95%] mt-[20px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                                    <div className="w-[95%] mt-[18px] font-[Poppins] font-light text-[14px] text-[#313131] leading-[120%] mb:text-center md:text-left">
                                    Após solicitar validação dos anexos para o cliente, você não poderá adicinar nem deletar mais anexos.
                                    </div>
                                    <div className="w-[95%] mt-[18px] font-[Poppins] font-light text-[14px] text-[#313131] leading-[120%] mb:text-center md:text-left">
                                    Não esqueça de confirmar todos os anexos corretamente, para que não haja problemas com seu pedido.
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    
                }
                {
                    page === 2 &&
                    <DialogContent className='mb:mt-[50px] md:mt-[118px] !overflow-unset '>
                        <div className='flex flex-col items-center'>
                        <div className='w-[90%] min-h-[120px] flex md:flex-row mb:flex-col items-center'>
                            <div className="mb:mr-[0px] md:mr-[25px] !w-[117px]">
                                <Box component="img" src={SuccessIcon} alt="sucesso-icone" className='max-w-[117px] h-[117px]'/>
                                </div>
                                <div className='flex flex-col justify-center w-[90%]'>
                                    <div className='font-[Poppins] font-medium mb-text-[18px] md:text-[22px] text-[#313131] leading-[100%]  mb:text-center md:text-left'>
                                    Enviado para validação com sucesso
                                    </div>
                                    <div className='w-[95%] mt-[20px] border-solid border-[1px] border-[#C6C6C6] opacity-90 h-[0px]'></div>
                                    <div className="w-[95%] mt-[18px] font-[Poppins] font-light text-[14px] text-[#313131] leading-[120%] mb:text-center md:text-left">
                                    Agora é só aguardar o cliente validar o trabalho que você realizou.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    
                }
                           
                </div>
                <div className='md:mt-[0px] mb:mt-[50px]'>
                {
                    page === 1 &&
                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'> 
                        <HeaderBTNStyle1 onClick={handlePreviousPage} className="mb:mr-[0px] md:mr-[26px]" sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Voltar</HeaderBTNStyle1>
                        <HeaderBTNStyle onClick={handleNextPage} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Enviar</HeaderBTNStyle>
                    </div>
                }
                {
                    page === 2 &&
                    <div className='flex w-full items-center justify-center mb-[30px] mb:flex-col md:flex-row'> 
                         <HeaderBTNStyle onClick={handleNextPage} sx={{'&:disabled': {backgroundColor: '#C6C6C6', cursor: 'not-allowed'}}}>Voltar para pedido</HeaderBTNStyle>
                    </div>
                }
                </div>
                {/* <Stack flexDirection={ width > 640 ? 'row' : 'column'} justifyContent={'center'} className="items-center" >
                <HeaderBTNStyle onClick={handleConfirm}  disabled={(!verifyHuman || !email) ? true : false} sx={{'&:disabled': {backgroundColor: '#C6C6C6'}}}>Enviar</HeaderBTNStyle>
                </Stack> */}
            </BootstrapDialog>
        </div >
    );
}
