import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toast';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: '700px',
    paddingBottom: '53px',
    [theme.breakpoints.up('sm')]: {
        paddingBottom: '80px',
    }
}));

const Text_style = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '25px',
    fontWeight: 400,
    color: '#17686E',
    [theme.breakpoints.up('sm')]: {
        fontSize: '40px',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '65px',
    }
}));

const Text_header = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: '80px 0px 22px',
    [theme.breakpoints.up('sm')]: {
        padding: '113px 0px 47px',
    },
}));


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    // width:'50%',
    borderRadius: '10px',
    boxShadow: '0px 20px 95px rgba(201, 203, 204, 0.3)',
    margin: '20px 20px 20px 20px',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (    
    <MuiAccordionSummary
        expandIcon={
            props.isExpanded.includes(props.index)?
            <RemoveIcon sx={{ fontSize: '28px', color: '#313131' }} />
            :
            <AddIcon sx={{ fontSize: '28px', color: '#313131' }} />        
    }
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'white',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        // transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('900')]: {
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(4),
        },
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const Text_title = styled('h3')(({ theme }) => ({
    // display: 'flex',
    // flexShrink: 0,
    fontFamily: 'Poppins',
    color: '#313131',
    fontWeight: 600,
    fontSize: '14px',
    // overflowWrap: 'break-word', // Updated property for auto line break
    [theme.breakpoints.up('900')]: {
      fontSize: '20px',
    },
}));

const Text_body = styled('div')(({ theme }) => ({
    flexShrink: 0,
    fontFamily: 'Poppins',
    color: '#313131',
    fontWeight: 400,
    fontSize: '14px',
    padding: '10px 16px 11px 40px',
    [theme.breakpoints.up('900')]: {
        fontSize: '16px',
        padding: '20px 90px 25px 60px',
    },
}));

const panel_lists = [
    {
        header: 'Por que devo usar a plataforma?',
        body: 'Relation so in confined smallest children unpacked delicate. Why sir end believe uncivil respect. Always get adieus nature day course for common. My little garret repair to desire he esteem.',
    },
    {
        header: 'Como vou receber meus documentos solicitados?',
        body: 'Relation so in confined smallest children unpacked delicate. Why sir end believe uncivil respect. Always get adieus nature day course for common. My little garret repair to desire he esteem.',
    },
    {
        header: 'Qual o prazo de recebimento do documento?',
        body: 'Relation so in confined smallest children unpacked delicate. Why sir end believe uncivil respect. Always get adieus nature day course for common. My little garret repair to desire he esteem.',
    },
    {
        header: 'É seguro pedir um documento na plataforma?',
        body: 'Relation so in confined smallest children unpacked delicate. Why sir end believe uncivil respect. Always get adieus nature day course for common. My little garret repair to desire he esteem.',
    },
    {
        header: 'E se o pesquisador não achar meu documento?',
        body: 'Relation so in confined smallest children unpacked delicate. Why sir end believe uncivil respect. Always get adieus nature day course for common. My little garret repair to desire he esteem.',
    },
]

export default function FAQs() {

    const [isExpanded, setIsExpanded] = useState([]);

React.useEffect(()=>{
    // toast(isExpanded)
},[isExpanded])
    return (
        <RootStyle>
            <Text_header>
                <Text_style>FAQs</Text_style>
            </Text_header>

            {/* <div> */}
                {panel_lists.map((card, index) => (
                    <Accordion>
                        <AccordionSummary
                            aria-controls={"panel" + { index } + "1bh-content"}
                            onClick={() => {
                                if (isExpanded.includes(index)) {
                                  const aux = [...isExpanded];
                                  const indexToRemove = aux.indexOf(index);
                                  aux.splice(indexToRemove, 1);
                                  setIsExpanded(aux);
                                } else {
                                  const aux = [...isExpanded];
                                  aux.push(index);
                                  setIsExpanded(aux);
                                }
                              }}
                                                          index={index}
                            isExpanded={isExpanded}
                        >
                            <Text_title>{card.header}</Text_title>
                            {/* {card.header} */}

                        </AccordionSummary>
                        <AccordionDetails>
                            <Text_body>{card.body}</Text_body>
                        </AccordionDetails>
                    </Accordion>
                ))}
            {/* </div> */}
        </RootStyle>
    )
}