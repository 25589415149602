import { styled } from '@mui/material/styles';
import {
    Stack,
} from '@mui/material';
import { useSelector } from 'react-redux';
import API from '../../redux/API';
import * as React from 'react'
// components

// -------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    paddingTop: '20px',
}));

const TitleText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    color: '#313131',
}));

const ContainerStyle = styled('div')(({ theme }) => ({
    border: '1px solid #E2E4E5',
    borderRadius: '7px',
    marginTop: '3px',
    paddingLeft: '36px',
    paddingTop: '10px',
    paddingBottom: '10px'
}));

const DescriptionText = styled('div')(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    color: '#313131',
}));

export default function DataCard({ languages, currentUser }) {
    const [languageList, setLanguageList] = React.useState([])
    const [levellist, setLevellist] = React.useState([])
    React.useLayoutEffect(() => {
        if(languageList.length === 0)
        {
            API.get(`user/getlanguagelist?language=${'pt'}`)
            .then((result) => {
                let temp = result.data.languagelist;
                setLanguageList(temp)
            })
            .catch((err) => {
                console.error(err.request.response)
            });
        } 
        if(levellist.length === 0)
        {
            API.get(`user/getlanguageproficiency?language=${'pt'}`)
            .then((result) => {
                let temp = result.data.proficiencylist;
                setLevellist(temp)
            })
            .catch((err) => {
                console.error(err.request.response)
            });
        } 
    },[])
    const getLanguage = (id) => {
        let temp;
        languageList.map((item) => {
            if(item._id == id) temp = item.nm_language;
        })
        return temp;
    }
    const getProficiency = (id) => {
        let temp;
        levellist.map((item) => {
            if(item._id == id) temp = item.nm_proficiency;
        })
        return temp;
    }
    return (
        <RootStyle>
            <TitleText>Idiomas</TitleText>
            <ContainerStyle>
                <ul className="list-disc">
                    {currentUser.about_me?.languages !== undefined && currentUser.about_me?.languages.map((item, index) => (
                        <li key={index}>
                            <Stack flexDirection={'row'} >
                                <DescriptionText sx={{ fontWeight: 600 }} >{getLanguage(item.id_language)}:&nbsp;</DescriptionText>
                                <DescriptionText>{getProficiency(item.id_languageproficiency)}</DescriptionText>
                            </Stack>
                        </li>
                    ))}
                </ul>

            </ContainerStyle>
        </RootStyle>
    )
}