/* eslint-disable import/no-anonymous-default-export */

import { styled } from '@mui/material/styles';
import React, {useState, useEffect} from 'react';
import { useRef } from 'react';
import TextField from '@mui/material/TextField';
import useWindowSize from '../../hooks/useWindowSize'
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import API from '../../redux/API';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { current } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const ContainerStyle = styled('div')(({ theme }) => ({
    width: '90%',
    margin: '0px auto',
    marginTop: '25px',
    marginBottom: '0px',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
        width: '82.5%',
    },
    [theme.breakpoints.up('md')]: {
        width: '82.5%',
    }
}));

export default ({
    tp_ordervisibility, settp_ordervisibility,
    tp_orderexperience, settp_orderexperience,
    researcherList, setResearchList }) => {

        const {currentUser} = useSelector(state => state.auth);

    const inputField = useRef(null);
    const { width } = useWindowSize();
    const [option, setOption] = useState(0);
    const style={
        title: "font-[Poppins] font-light text-[14px] leading-[148%] text-[#313131] whitespace-nowrap mb-[5px]",
        selected: 'cursor-pointer mr-[15px] w-[187px] h-[132px] bg-[#17586E] bg-opacity-[0.11] border-solid border-[2px] border-[#17686E] rounded-[7px] text-center',
        normal: 'cursor-pointer mr-[15px] w-[187px] h-[132px] border-solid border-[1px] border-[#C6C6C6] rounded-[7px] text-center',
        subtitle: 'mt-[10px] font-[Poppins] font-bold text-[14px] leading-[148%] text-[#313131] text-center',
        content: 'mt-[20px] font-[Poppins] font-normal text-[12px] leading-[148%] text-[#313131] text-center',
    }
    const [experts, setExperts] = useState([]);
    const [expert, setExpert] = useState('')
    const deleteExpertName = (index) => {
        let temp = [...experts];
        temp.splice(index,1);
        setExperts(temp);
    }
    const [isPublic, setPublic] = useState(true);
    const [all_researchers, setall_researchers] = useState([]);
    const [selected_cd_researcher, setselected_cd_researcher] = useState([]);





    useEffect(()=>{
        if(currentUser && currentUser._id && all_researchers.length === 0){
            //console.log(`currentUser._id = ${currentUser._id}`);
            API.post(`user/getallresearchers`)
            .then((result) => {
                var allcd_researcher = []
                if(result.data.allresearchers && result.data.allresearchers.length>0){
                    var aux = [...result.data.allresearchers]
                    allcd_researcher = aux.map((singleUser)=>{
                        //console.log(`${singleUser._id} ====== ${currentUser._id}`);
                        if (singleUser.cd_researcher && singleUser._id && singleUser._id !== currentUser._id) {
                            return singleUser 
                        }
                        return null
                    })
                }
                allcd_researcher = allcd_researcher.filter(Boolean)
                setall_researchers(allcd_researcher)

                return allcd_researcher.forEach((single)=>{if(single.cd_researcher && single.nm_user){ return console.log(single.cd_researcher, ' | ', single.nm_user, ' | ', single._id)}})
                

            })
            .catch((err) => {
                return console.error(err.message?err.message:'ERRO BUSCANDO PESQUISADORES')
            });
        } 
    }, [currentUser]);

    useEffect(()=>{
        if(selected_cd_researcher){
            //console.log(selected_cd_researcher);
            const aux = selected_cd_researcher.map((single)=>{if(single.cd_researcher){return single.cd_researcher}}).filter(Boolean)
            //console.log(aux);
            setResearchList(aux)
        }
    },[selected_cd_researcher])

    return (
            <ContainerStyle className='flex-col mb:!mb-[0px] md:!mb-[100px]'>
                <div>
                <div className="font-[Poppins] font-medium text-[22px] leading-[100%] color-[#313131] mt-[35px]">Defina o nível de experiência desejado</div>
                <div className='flex flex-col mb:items-start md:items-center '>
                    <div className=" overflow-x-auto  mb:w-full sm:w-[96%] mb:block sm:flex justify-start mb:ml-[0px] sm:ml-[20px] mt-[21px]">
                        <div className='min-w-[606px] mb:w-[100%] sm:w-[80%] flex mb-[0px] mb:mr-[0px] sm:mr-[25px]'>
                            <div className='flex '>
                                <div className={tp_orderexperience === 'Beginner' ? style.selected : style.normal} onClick={() => settp_orderexperience('Beginner')}>
                                    <div className={style.subtitle}>Iniciante</div>
                                    <div className={style.content}>Estou a procura de um pesquisador com os menores valores.</div>
                                </div>
                                <div className={tp_orderexperience === 'Specialist' ? style.selected : style.normal} onClick={() => settp_orderexperience('Specialist')}>
                                    <div className={style.subtitle}>Especialista</div>
                                    <div className={style.content}>Estou a procura de uma combinação de experiência e valor</div>
                                </div>
                                <div className={tp_orderexperience === 'Assessor' ? style.selected : style.normal} onClick={() => settp_orderexperience('Assessor')}>
                                    <div className={style.subtitle}>Assessor</div>
                                    <div className={style.content}>Estou disposto a pagar valores elevados para pesquisadores experientes</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div className="font-[Poppins] font-medium text-[22px] leading-[100%] color-[#313131] mt-[25px] ">Visibilidade da solicitação</div>
                    <div className='flex flex-col'>
                            <RadioGroup col>
                                        <FormControlLabel       
                                            className='w-fit' value="male" control={<Radio      
                                            onChange={() => {settp_ordervisibility(false)}}         
                                            checked={!tp_ordervisibility}          
                                            sx={{
                                                color: '#313131',
                                                '&.Mui-checked': {
                                                    color: '#17686E',
                                                }
                                            }}
                                        />} label={<div className="flex flex-col items-start w-fit"><div className="mr-[15px] font-[Poppins] font-bold text-[14px] leading-[148%] text-[#313131]">Público</div></div>} />
                                        <div className="mb-[25px] ml-[32px] font-[Poppins] font-normal text-[12px] leading-[100%] color-[#313131]">Vísivel para todos os pesquisadores.</div>
                                        <FormControlLabel className='w-fit'    value="female" control={<Radio        
                                            onChange={() => {settp_ordervisibility(true)}}           
                                            checked={tp_ordervisibility}   
                                                         
                                            sx={{
                                                color: '#313131',
                                                '&.Mui-checked': {
                                                    color: '#17686E',
                                                }
                                            }}      
                                        />} label={<div className="flex items-center w-fit"><div className="mr-[15px] font-[Poppins] font-bold text-[14px] leading-[130%] text-[#313131]">Privado </div></div>} />
                                        <div className="mb-[10px] ml-[32px] font-[Poppins] font-normal text-[12px] leading-[100%] color-[#313131]">Apenas pesquisadores convidados podem participar. </div>
                                        <div >
                                            <div className={"ml-[50px] font-[Poppins] font-normal text-[12px] leading-[100%] "+ (!tp_ordervisibility ? "text-[#C6C6C6]" : "text-[#313131]")}>Busque o pesquisador:</div>
                                            <div className='ml-[50px] mt-[10px]'>



                                            <Autocomplete

                                                disabled = {!tp_ordervisibility}

                                                    multiple
                                                    id="checkboxes-tags-demo"
                                                    options={all_researchers}
                                                    disableCloseOnSelect
                                                    value={selected_cd_researcher}
                                                    getOptionLabel={(option) => option.cd_researcher}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props}
                                                            value={option.cd_researcher}
                                                            key={option.cd_researcher}
                                                        >
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                            value={option.cd_researcher}
                                                            key={option.cd_researcher}
                                                        />
                                                        {`${option.cd_researcher} | ${option.nm_user}`}
                                                        </li>
                                                    )}
                                                    onChange={(e,newValue)=>{
                                                        //console.log('===== all_researchers ===== ');
                                                        for (let index = 0; index < newValue.length; index++) {
                                                            const element = newValue[index];
                                                            //console.log((element));
                                                            
                                                        }
                                                        // const aux = newValue.map((single)=>{if(single.cd_researcher){return single.cd_researcher}}).filter(Boolean)
                                                        setselected_cd_researcher(newValue)
                                                
                                                    }}

                                                    sx={{
                                                        'width':'298px !important', 
                                                        '& .MuiAutocomplete-inputRoot': {height: '40px', padding: '0px !important' ,paddingLeft: '10px !important', fontFamily: 'Poppins',fontSize: '12px'},
                                                    }}

                                                    renderInput={(params) => <TextField    {...params}  sx={{'& .MuiInputBase-input::placeholder': {color: '#313131', opacity: 1}}} placeholder='Busque por ID do pesquisador' />}
                                                    />

                                            </div>

                                        </div>
                                      
                            </RadioGroup>
                    </div>
                </div>


            </ContainerStyle>
    )
}