import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ImagetoShow1 from "../../assets/image/import-plan1.png"
import ImagetoShow2 from "../../assets/image/import-plan2.png"
import ImagetoShow3 from "../../assets/image/import-plan3.png"
import SingleCardPlan from "./SingleCardPlan";
import { toast } from 'react-toast';
import Paymentdetail from '../../components/Checkout/Paymentdetail';
import Checkout from '../../components/Checkout';
import API from '../../redux/API';

const RootStyle = styled('div')(({ theme }) => ({
    width: '90%',
    margin:'20px auto 80px auto',
}));

const SubHeader = styled('div')(({ theme }) => ({
}));

const MainTitle1 = styled('div')(({ theme }) => ({
    display:'flex', 
    textAlign:'center', 
    justifyItems:'center', 
    marginLeft:'auto',
    fontSize:'60px',
    color: '#313131',
    fontFamily: 'Poppins',
}));

const MainTitle2 = styled('div')(({ theme }) => ({
    display:'flex', 
    textAlign:'center', 
    justifyItems:'center', 
    marginRight:'auto',
    marginLeft:'auto',
    fontSize:'30px',
    color: '#17686E',
    fontFamily: 'Poppins',
    fontWeight:'bold',
    justifyContent:'center',
    [theme.breakpoints.up('md')]: {
        fontSize: '40px',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '60px',
    }
}));

const Explanation = styled('div')(({ theme }) => ({
    display:'flex', 
    textAlign:'center', 
    justifyItems:'center', 
    marginRight:'auto',
    fontSize:'18px',
    color: '#313131',
    fontFamily: 'Poppins',
    fontWeight:'bold',
    marginLeft:'15px',
    marginBottom:'10px',
    justifyContent:'center',
    [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
    }
}));

const Photo = styled('img')(({ theme }) => ({
    objectFit:'cover',
}));

const ShowPlans = styled('div')(({ theme }) => ({
    display:'flex',
    margin:'20px auto 10px auto',
    width:'80%',
    overflow:'auto',
    padding: '40px',
}));


export default function ImportPlans(){
    const {currentUser} = useSelector(state => state.auth);
    const navigate = useNavigate()
    const [openModal,setopenModal]=useState(false)
    const [cardContent,setCardContent]=useState({})        
    const [checkoutData,setcheckoutData]=useState({})    
    const [isPay,setisPay] =useState(false) 

    const [isMobile, setIsMobile] = useState(false);
    const [allPlans, setallPlans] = useState([]);

    useEffect(() => {
          setIsMobile(window.innerWidth <= 760); // Adjust the threshold as needed

          API.post('order/getallplans_import',{nm_language:'BR'})
          .then((res)=>{
              if (res.data.allPlans) {
                  var aux = res.data.allPlans
                  aux[0].image=ImagetoShow1
                  aux[1].image=ImagetoShow2
                  aux[2].image=ImagetoShow3
                  setallPlans(aux)
              }
          })
          .catch((err)=>{
              toast.error(err.message?err.message:'Erro ao buscar planos. Contate o suporte.')
          })
  
    }, []);
    


    
    // const allPlans = [
    //     {
    //         importNumber:5,
    //         title:'Iniciante',
    //         normalPrice:'69',
    //         currentPrice:'59',
    //         benefits:['2 país como especialidade','4 dias úteis para receber','15% de taxa de intermediação','Propostas até especialista','90 propostas mensais','10 projetos em execução'],
    //         isHighlighted:false,
    //         comment:'Perfeito para quem está iniciando sua jornada de pesquisa e possui pouco histórico!',
    //         image:ImagetoShow1,
    //         nm_language:'BR',
    //     },
    //     {
    //         importNumber:15,
    //         title:'Especialista',
    //         normalPrice:'69',
    //         currentPrice:'59',
    //         benefits:['2 país como especialidade','4 dias úteis para receber','15% de taxa de intermediação','Propostas até especialista','90 propostas mensais','10 projetos em execução'],
    //         isHighlighted:true,
    //         comment:'Perfeito para quem está iniciando sua jornada de pesquisa e possui pouco histórico!',
    //         image:ImagetoShow2,
    //         nm_language:'BR',

    //     },
    //     {
    //         importNumber:35,
    //         title:'Assessor',
    //         normalPrice:'69',
    //         currentPrice:'59',
    //         benefits:['2 país como especialidade','4 dias úteis para receber','15% de taxa de intermediação','Propostas até especialista','90 propostas mensais','10 projetos em execução'],
    //         isHighlighted:false,
    //         comment:'Perfeito para quem está iniciando sua jornada de pesquisa e possui pouco histórico!',
    //         image:ImagetoShow3,
    //         nm_language:'BR',

    //     },
    // ]

    useEffect(()=>{
        if(!currentUser || currentUser.ic_profile === 0){
            navigate('/researcher')
            toast.info('Você precisa de uma conta como pesquisador para contratar um dos planos')
        }
    },[currentUser])

    return(

<RootStyle >

    {openModal && <Checkout openModal={openModal} setopenModal={setopenModal} type={'getMoreImportPlans'} cardContent={cardContent} checkoutData={checkoutData} setisPay={setisPay} isPay={isPay}/>}

    <div >

            <SubHeader>

                {currentUser && !currentUser.qt_importavaiable &&
                    <h3 style={{color:'#BA483A', fontWeight:'600', fontFamily:'Poppins', fontSize:'30px', textAlign:'center'}}>Suas importações esgotaram!</h3>
                }

                <div style={{display:'flex', textAlign:'center', justifyContent:'center', marginBottom:'20px'}}>
                    <MainTitle2>Acelere o sucesso do seu perfil</MainTitle2>
                </div>
                <Explanation>Aproveite nossos pacotes únicos que foram projetados para o seu sucesso, compre e chegue mais rápido a sua primeira pesquisa</Explanation>
            </SubHeader>

            <ShowPlans style={isMobile?{width:'100%',gap:'20px'}:{padding:'auto 50px auto 50px', justifyItems:'center', justifyContent:'center', gap:'20px'}}>
                {allPlans && allPlans.map((single)=>{
                    return(
                        <div style={{display:'flex',  }}>
                        {
                            !isMobile?
                        
                        <div style={!single.isHighlighted?{width:'300px', minHeight:'300px', display:'flex', margin:'auto auto auto auto', }:{width:'350px', minHeight:'400px', display:'flex', margin:'auto auto auto auto', }}>
                            <SingleCardPlan 
                                title={single.title} 
                                normalPrice={single.normalPrice} 
                                currentPrice={single.currentPrice} 
                                benefits={single.benefits} 
                                isHighlighted={single.isHighlighted}
                                comment={single.comment}
                                importNumber={single.importNumber}
                                image={single.image}
                                setopenModal={setopenModal}
                                setCardContent={setCardContent}
                                setcheckoutData={setcheckoutData}
                                single={single}
                                importID={single._id}
                                />
                        </div>
                        :
                        <div style={!single.isHighlighted?{width:'300px', minHeight:'300px', display:'flex', margin:'auto auto auto auto', }:{width:'350px', minHeight:'400px', display:'flex', margin:'auto auto auto auto', }}>
                            <SingleCardPlan 
                                title={single.title} 
                                normalPrice={single.normalPrice} 
                                currentPrice={single.currentPrice} 
                                benefits={single.benefits} 
                                isHighlighted={single.isHighlighted}
                                comment={single.comment}
                                importNumber={single.importNumber}
                                image={single.image}
                                setopenModal={setopenModal}
                                setCardContent={setCardContent}
                                setcheckoutData={setcheckoutData}
                                single={single}
                                importID={single._id}

                            />
                        </div>
                        }
                        </div>
                    )
                })}
            </ShowPlans>
            </div>

</RootStyle>

    )
}