import { styled } from '@mui/material/styles';

const BtnGreen = styled('button')(({ theme }) => ({
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 600,
    backgroundColor: '#17686E',
    border: '1px solid #17686E',
    borderRadius: '5px',
    padding: '7.52px 13px 8.45px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    width: '100%',
    fontFamily: 'Poppins',
    [theme.breakpoints.up('sm')]: {
        minWidth: '180px',
        width: 'unset',
    },
    [theme.breakpoints.up('md')]: {
        padding: '10px 17px 9px 16px',
    }
}));

const BtnGray = styled('button')(({ theme }) => ({
    color: '#fff',
    fontSize: '14px',
    fontWeight: 600,
    backgroundColor: '#C6C6C6',
    border: '1px solid #C6C6C6',
    borderRadius: '5px',
    padding: '7.52px 13px 8.45px',
    cursor: 'not-allowed',
    whiteSpace: 'nowrap',
    width: '100%',
    fontFamily: 'Poppins',
    [theme.breakpoints.up('sm')]: {
        minWidth: '180px',
        width: 'unset',
    },
    [theme.breakpoints.up('md')]: {
        padding: '10px 17px 9px 16px',
    }
}));

const BtnRed = styled('button')(({ theme }) => ({
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 600,
    backgroundColor: '#BA483A',
    border: '1px solid #BA483A',
    borderRadius: '5px',
    padding: '7.52px 13px 8.45px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    width: '100%',
    fontFamily: 'Poppins',
    [theme.breakpoints.up('sm')]: {
        minWidth: '180px',
        width: 'unset',
    },
    [theme.breakpoints.up('md')]: {
        padding: '10px 17px 9px 16px',
    }
}));

const BtnWhite = styled('button')(({ theme }) => ({
    color: '#17686E',
    fontSize: '14px',
    fontWeight: 600,
    backgroundColor: '#fffff',
    border: '1px solid #17686E',
    borderRadius: '5px',
    padding: '7.52px 13px 8.45px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    fontFamily: 'Poppins',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        minWidth: '180px',
        width: 'unset',
    },
    [theme.breakpoints.up('md')]: {
        padding: '10px 17px 9px 16px',
    }
}));


export default function ProposalButton({ type, fontsize, text, event }) {
    return (
        <>
            {type == 1 && <BtnGreen  sx={{ fontSize: fontsize + 'px' }} onClick={event} > {text} </BtnGreen>}
            {type == 2 && <BtnWhite sx={{ fontSize: fontsize + 'px' }} onClick={event} > {text} </BtnWhite>}
            {type == 3 && <BtnWhite sx={{ fontSize: fontsize + 'px' , border: 'none', color: '#313131', fontWeight: '400'}} onClick={event} > {text} </BtnWhite>}   
            {type == 4 && <BtnRed sx={{ fontSize: fontsize + 'px' }} onClick={event} > {text} </BtnRed>}
            {type == 5 && <BtnGray sx={{ fontSize: fontsize + 'px' }} onClick={event} > {text} </BtnGray>}
        </>
    )
}